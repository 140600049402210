<div ng-controller="registerFromController">
  <div class="text-center mb-4">
    <h2>Register</h2>
  </div>
  <form class="form-signin" (ngSubmit)="user_register()" #registerFrom="ngForm">
    <div class="alert alert-primary" role="alert" *ngIf="isRegistered">
      You have successfully registered!
      Go to your Email and Click activate link
      <br />
      <a class="alert-link" routerLink="/signin">Click here back to sign in</a>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="!pwdValid() && !password.pristine && !ConfirmedPassword.pristine"
    >
      Password and Confirmed Password are not match!
    </div>
    <div
    class="alert alert-danger"
    role="alert"
    *ngIf="!validateEmail() && !email.pristine && !isRegistered"
    >
      Email format is not correct! Please Check!
    </div>

    <div class="alert alert-warning" role="alert" *ngIf="error">
      {{ error.errors[0].message }}
    </div>
    <div class="form-label-group">
      <input
        type="text"
        class="form-control"
        id="username"
        required
        autofocus
        [(ngModel)]="register.username"
        name="username"
        required
      />
      <label for="username">* Username</label>
    </div>
    <div class="form-label-group">
      <input
        type="text"
        class="form-control"
        id="phone"
        required
        autofocus
        [(ngModel)]="register.phone"
        name="phone"
        required
      />
      <label for="phone">* Phone</label>
    </div>
    <div class="form-label-group">
      <input
        type="email"
        class="form-control"
        id="email"
        name="email"
        required
        autofocus
        [(ngModel)]="register.email"
        #email="ngModel"
        required
      />
      <label for="inputEmail">* Email</label>
    </div>
    <div class="form-label-group">
      <input
        type="password"
        class="form-control"
        id="password"
        required
        autofocus
        [(ngModel)]="register.password"
        name="password"
        #password="ngModel"
        required
      />
      <label for="password">* Password</label>
    </div>
    <div class="form-label-group">
      <input
        type="password"
        class="form-control"
        id="ConfirmedPassword"
        required
        autofocus
        [(ngModel)]="confirmedPassword"
        name="confirmedPassword"
        #ConfirmedPassword="ngModel"
        [(ngModel)]="confirmedPassword"
      />
      <label for="ConfirmedPassword">* Confirmed Password</label>
      <small class="form-text text-danger">* Fields all required</small>
    </div>
    <button
      class="btn btn-lg btn-primary btn-block"
      [disabled]="!formValid()"
      type="submit"
    >
      Register
    </button>
    <button
      class="btn btn-lg btn-primary btn-block"
      type="submit"
      routerLink="/signin"
    >
      Back to Sign in
    </button>
  </form>
</div>
