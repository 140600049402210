export class Paymentold {
  public id: number;
  public inboundId: number;
  public dealId: number;
  public confirmId: number;
  public company: string;
  public individual: string;
  public tracking: string;
  public upc: string;
  public quantity: number;
  public propose: number;
  public price: number;
  public amount: number;
  public status: number;

  constructor(
    inboundId: number,
    dealId: number,
    confirmId:number,
    company: string,
    individual: string,
    tracking: string,
    upc: string,
    quantity: number,
    propose: number,
    price: number,
    amount: number,
    status: number
  ) {
    this.inboundId = inboundId;
    this.dealId = dealId;
    this.confirmId = confirmId;
    this.company = company;
    this.individual = individual;
    this.tracking = tracking;
    this.upc = upc;
    this.quantity = quantity;
    this.propose = propose;
    this.price = price;
    this.amount = amount;
    this.status = status;
  }

  public clear() {
    this.inboundId = null;
    this.dealId = null;
    this.confirmId = null;
    this.company = '';
    this.individual = '';
    this.tracking = '';
    this.upc = '';
    this.quantity = null;
    this.propose = null;
    this.price = null;
    this.amount = null;
    this.status = 0;
  }
}
