import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class BillPayService {
 
  addBillPayUrl = environment.apiUrl + 'billpays/billpay';
  countUrl = environment.apiUrl + 'billpays/count';
  retrieveUrl = environment.apiUrl + 'billpays/allbillpay';
  constructor(private http: HttpClient) {}

  addBillPay(name: string, address: string,account: string,routing: string,phone: string,creator: string): Observable<string> {
    const creatorJSON =
      '{ "name" : ' +
      '"' +
      name +
      '"' +
      ',  "address" : ' +
      '"' +
      address +
      '"' +
      ', "account" : ' +
      '"' +
      account +
      '"' +
      ', "routing" : ' +
      '"' +
      routing +
      '"' +
      ', "phone" : ' +
      '"' +
      phone +
      '"' +
      ', ' +
      '"creator" : ' +
      '"' +
      creator +
      '"' +
      ' }';

    return this.http.post<string>(
      this.addBillPayUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  count(creator: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';

    return this.http.post<string>(
      this.countUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  retrieve(creator: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.retrieveUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
}
