export class Payment {
  public id: number;
  public inboundId: number;
  public dealId: number;
  public receiptNumber:string;
  public company: string;
  public individual: string;
  public billQty: number;
  public confirmedQty: number;
  public paidQty:number
  public billTotal: number;
  public confirmedTotal:number;
  public paidTotal: number;
  public adjustment: number;
  public status: number;
  public description: string;
  public note: string;
  public isDelete: number;
  public receiptDate: string;

  constructor(
    inboundId: number,
    dealId: number,
    receiptNumber,
    company: string,
    individual: string,
    billQty:number,
    confirmedQty:number,
    paidQty:number,
    billTotal:number,
    confirmedTotal:number,
    paidTotal:number,
    adjustment:number,
    status: number,
    description: string,
    note: string,
    isDelete: number,
    receiptDate: string    
  ) {
    this.inboundId = inboundId;
    this.dealId = dealId;
    this.receiptNumber = receiptNumber;
    this.company = company;    
    this.individual = individual;
    this.billQty = billQty;
    this.confirmedQty = confirmedQty;
    this.paidQty = paidQty;
    this.billTotal=billTotal;
    this.confirmedTotal = confirmedTotal
    this.paidTotal=paidTotal;
    this.adjustment= adjustment;
    this.status= status;
    this.description= description;
    this.note= note;
    this.isDelete= isDelete;
    this.receiptDate= receiptDate;   
  }

  public clear() {
    this.inboundId = null;
    this.dealId = null;
    this.receiptNumber = '';
    this.company = '';
    this.individual = '';
    this.billQty = null;
    this.confirmedQty = null;
    this.paidQty = null;
    this.billTotal=null;
    this.confirmedTotal = null;
    this.paidTotal=null;
    this.adjustment= null;
    this.status= 0;
    this.description= '';
    this.note= '';
    this.isDelete= 0;
    this.receiptDate= '';    
  }
}
