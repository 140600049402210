<!-- Dashboard: -->
<div class="container">
  <h1>Dashboard</h1>
  <hr/>
  <div class="row">
    <div class="col-lg-3 col-md-4">
      <div class="card">
        <div class="card-block">
          <h3 class="card-header">Deals</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a class="card-link" routerLink="/deals/active">Active</a>
            <span class="badge badge-primary badge-pill amountPill">{{activeLen}}</span>
          </li>
          <li class="list-group-item">
            <a class="card-link" routerLink="/deals/expired">Expired</a>
            <span class="badge badge-primary badge-pill amountPill">{{expiredLen}}</span>
          </li>
          <li class="list-group-item">
            <a class="card-link" routerLink="/deals/public">Public</a>
            <span class="badge badge-primary badge-pill amountPill">{{publicLen}}</span>
          </li>
          <li class="list-group-item">
            <a class="card-link" routerLink="/deals/propose">Proposed</a>
            <span class="badge badge-primary badge-pill amountPill">{{proposedLen}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-3 col-md-4">
      <div class="card">
        <div class="card-block">
          <h3 class="card-header">Inbounds</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a class="card-link" routerLink="/inbounds/inboundToConfirm"
              >To Confirm
            </a>
            <span class="badge badge-primary badge-pill amountPill">{{toConfirmLen}}</span>
          </li>
          <li class="list-group-item">
            <a class="card-link" routerLink="/inbounds/inboundReported"
              >Reported
            </a>
            <span class="badge badge-primary badge-pill amountPill">{{reportedLen}}</span>
          </li>
          <!--隐藏payment-->
          <!--
          <li class="list-group-item">
            <a class="card-link" routerLink="/inbounds/inboundPayment"
              >Payment
            </a>
            <span class="badge badge-primary badge-pill amountPill">{{paymentLen}}</span>
          </li>
          -->
        </ul>
      </div>
    </div>
    <!-- this is only for individuals -->
    <div class="col-lg-3 col-md-4" *ngIf="!privilege">
      <div class="card">
        <div class="card-block">
          <h3 class="card-header">More</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a class="card-link" routerLink="/user_join">Join a Company </a>
          </li>
          <li class="list-group-item">
            <a class="card-link" routerLink="/warehouse_policy"> Warehouse Policy </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- this is only for company member -->
    <div class="col-lg-3 col-md-4" *ngIf="privilege">
      <div class="card">
        <div class="card-block">
          <h3 class="card-header">Products</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a class="card-link" routerLink="/products">Existing Products </a>
            <span class="badge badge-primary badge-pill amountPill">{{productLen}}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- this is only for company member -->
    <div class="col-lg-3 col-md-4" *ngIf="privilege">
      <div class="card">
        <div class="card-block">
          <h3 class="card-header">UserGroups</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a class="card-link" routerLink="/groups/group_active"
              >Active Followers
            </a>
            <span class="badge badge-primary badge-pill amountPill">{{followerLen}}</span>
          </li>
          <li class="list-group-item">
            <a class="card-link" routerLink="/groups/user_groups">UserGroups </a>
            <span class="badge badge-primary badge-pill amountPill">{{groupLen}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

