import { Identifier } from 'babel-types';

export class Inbound {
  public id: number;
  public product: string;
  public product_link: string;
  public price: string;
  public quantity: number;
  public warehouse: string;
  public company: string;
  public individual: string;
  public status: number;
  public companyStatus: number;
  public dealId: number;
  public propose: number;
  public proposeStatus: number;
  public publicWarehouse: boolean;
  public bonus: number;
  public payable: number;
  constructor(
    product: string,
    product_link: string,
    price: string,
    quantity: number,
    warehouse: string,
    company: string,
    individual: string,
    status: number,
    companyStatus: number,
    dealId: number,
    publicWarehouse: boolean,
    payable: number
  ) {
    this.product = product;
    this.product_link = product_link;
    this.price = price;
    this.quantity = quantity;
    this.warehouse = warehouse;
    this.company = company;
    this.individual = individual;
    this.status = status;
    this.companyStatus = companyStatus;
    this.dealId = dealId;
    this.publicWarehouse = publicWarehouse;
    this.payable = payable;
  }

  public clear() {
    this.id = null;
    this.product = '';
    this.product_link = '';
    this.price = '';
    this.quantity = null;
    this.warehouse = '';
    this.company = '';
    this.individual = '';
    this.status = null;
    this.companyStatus = null;
    this.dealId = null;
    this.publicWarehouse = true;
    this.bonus = 0;
    this.payable = null;
  }
}
