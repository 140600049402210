<div class="wrapper">
<table class="table table-hover" id="GroupTable">
  <thead class="thead-light">
    <tr>
      <th scope="col" class="hide">#</th>
      <th scope="col">Followers</th>
      <th scope="col" class="hide">Email</th>
      <th scope="col" class="hide">Status</th>
      <th scope="col" class="hide">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let follower of followersList; index as i">
      <tr>
        <th scopr="row" class="hide">{{ ('000' + follower.id).slice(-4) }}</th>
        <td>
          {{ follower.individual }}
          <p class="text-danger show">
            Email: {{ follower.email }}
          </p>
          <p class="text-danger show">
            Status: {{ statusLevel[follower.status] }}
          </p>
          <div>
            <button
              type="button"
              class="btn btn-outline-success btn-block show"
              data-toggle="modal"
              data-target="#userInformForm"
              (click)="subSelect(i)"
            >
              Edit
            </button>
            <button
              class="btn btn-outline-success btn-block show"
              *ngIf="!follower.status"
              (click)="setActive()"
            >
              Approve
            </button>
          </div>
        </td>
        <td class="hide">{{ follower.email }}</td>
        <td class="hide">{{ statusLevel[follower.status] }}</td>
        <td class="hide">
          <div>
            <button
              type="button"
              class="btn btn-outline-success btn-block"
              data-toggle="modal"
              data-target="#userInformForm"
              data-whatever="@mdo"
              (click) = "subSelect(i)"
            >
              Edit
            </button>
            <button
              class="btn btn-outline-success btn-block"
              *ngIf="!follower.status"
              (click)="approve(i)"
            >
              Approve
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<!--分页实现Begin-->
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  (pageChange)="onPageChange($event)">
</kendo-datapager>
</div>

<div
  class="modal fade"
  id="userInformForm"
  tabindex="-1"
  role="dialog"
  aria-labelledby="userInformForm"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="userInformForm">
          User Information
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Follower:</label>
            {{selectSubscription.individual}}
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Status:</label>
            {{ statusLevel[selectSubscription.status] }}
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-primary" (click)="setBlock()">
              Block
            </button>
            <button
              type="button"
              class="btn btn-primary ml-2"
              (click)="setActive()"
            >
              Active
            </button>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
