export class Receipt {
  public id: number;
  public inboundId: number;
  public dealId: number;
  public product: string;
  public receiptNumber: string;
  public company: string;
  public individual: string;
  public tracking: string;
  public upc: string;
  public quantity: number;
  public price: number;
  public tax: number;
  public amount: number;
  public subtotal: number;
  public status: number;
  public terms: string;
  public isDelete: number;
  public receiptDate: string;
  public dueDate: string;

  constructor(
    inboundId: number,
    dealId: number,
    product: string,
    receiptNumber:string,
    company: string,
    individual: string,
    tracking: string,
    upc: string,
    quantity: number,
    price: number,
    tax: number,
    amount: number,
    subtotal: number,
    status: number,
    terms:string,
    isDelete:number,
    receiptDate:string,
    dueDate:string

  ) {
    this.inboundId = inboundId;
    this.dealId = dealId;
    this.product = product;
    this.receiptNumber = receiptNumber;
    this.company = company;
    this.individual = individual;
    this.tracking = tracking;
    this.upc = upc;
    this.quantity = quantity;    
    this.price = price;
    this.tax = tax;
    this.amount = amount;
    this.subtotal = subtotal;
    this.status = status;
    this.terms = terms;
    this.isDelete =isDelete,
    this.receiptDate = receiptDate;
    this.dueDate = dueDate;

  }

  public clear() {
    this.inboundId = null;
    this.dealId = null;
    this.product = '';
    this.receiptNumber = null;
    this.company = '';
    this.individual = '';
    this.tracking = '';
    this.upc = '';
    this.quantity = null;    
    this.price = null;
    this.tax = null;
    this.amount = null;
    this.subtotal = null;
    this.status = 0;
    this.terms = '';
    this.isDelete = 0; 
    this.receiptDate = '';
    this.dueDate = '';
  }
}
