/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./alert.component";
import * as i3 from "@angular/router";
import * as i4 from "./alert.service";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAlert(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.message; _ck(_v, 3, 0, currVal_1); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 245760, null, 0, i2.AlertComponent, [i3.Router, i4.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i0.ɵccf("alert", i2.AlertComponent, View_AlertComponent_Host_0, { id: "id", fade: "fade" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
