import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Confirm } from '../models/confirm';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class ConfirmService {
  allUrl = environment.apiUrl + 'confirms/allConfirm';
  trackingUrl = environment.apiUrl + 'confirms/allTrackingConfirm';

  constructor(private http: HttpClient) {}

  allConfirm(inboundId: number, upc: string): Observable<any> {
    const inboundIdJSON = '{ "inboundId" : ' + '"' + inboundId + '",'
                      + '"upc" : ' + '"' + upc + '"'+ ' }';

    return this.http.post<any>(
      this.allUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );
  }

  allTrackingConfirm(inboundId: number, tracking: string): Observable<any> {
    const inboundIdJSON = '{ "inboundId" : ' + '"' + inboundId + '",'
                      + '"tracking" : ' + '"' + tracking + '"'+ ' }';

    return this.http.post<any>(
      this.trackingUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );
  }
}
