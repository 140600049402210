import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterFormComponent } from './components/users/register-form/register-form.component';
import { SignInFormComponent } from './components/users/sign-in-form/sign-in-form.component';
import { DealsComponent } from './components/deals/deals.component';
import { AuthGuard } from './guards/auth.guard';
import { ActiveDealsComponent } from './components/deals/active-deals/active-deals.component';
import { ExpiredDealsComponent } from './components/deals/expired-deals/expired-deals.component';
import { PublicDealsComponent } from './components/deals/public-deals/public-deals.component';
import { ProposeDealsComponent } from './components/deals/propose-deals/propose-deals.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductsActiveListComponent } from './components/products/products-active-list/products-active-list.component';
import { GroupsComponent } from './components/groups/groups.component';
import { GroupsActiveListComponent } from './components/groups/groups-active-list/groups-active-list.component';
import { InboundComponent } from './components/inbound/inbound.component';
import { ToConfirmListComponent } from './components/inbound/to-confirm-list/to-confirm-list.component';
import { ReportedListComponent } from './components/inbound/reported-list/reported-list.component';
import { PaymentListComponent } from './components/inbound/payment-list/payment-list.component';
import { HistoryListComponent } from './components/inbound/history-list/history-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserGroupsComponent } from './components/groups/user-groups/user-groups.component';
import { UserJoinComponent } from './components/groups/user-join/user-join.component';
import { ActivateComponent } from './components/users/activate/activate.component';
import { StorageComponent } from './components/storage/storage.component';
import { UsersComponent } from './components/users/users.component';

import { BillComponent } from './components/bill/bill.component';
import { BillPayComponent } from './components/billpay/billpay.component';

import { RequestResetComponent } from './components/users/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/users/response-reset/response-reset.component';

import { WarehousePolicyComponent } from './components/policy/warehouse-policy/warehouse-policy.component';

const routes: Routes = [
  { 
    path: 'storages',
    canActivate: [AuthGuard],
    component: StorageComponent
  },
  { 
    path: 'bills',
    canActivate: [AuthGuard],
    component: BillComponent
  },
  { 
    path: 'billpays',
    canActivate: [AuthGuard],
    component: BillPayComponent
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent
  },
  {
    path: 'userInfo',
    canActivate: [AuthGuard],
    component: UsersComponent
  },
  {
    path: 'activate',
    component: ActivateComponent
  },
  {
    path: 'register',
    component: RegisterFormComponent
  },
  {
    path: 'signin',
    component: SignInFormComponent
  },
  {
    path: 'req-reset-password',
    component: RequestResetComponent
  },
  {
    path: 'response-reset-password/:token',
    component: ResponseResetComponent
  },
  {
    path: 'deals',
    component: DealsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ActiveDealsComponent },
      { path: 'active', component: ActiveDealsComponent },
      { path: 'expired', component: ExpiredDealsComponent },
      { path: 'public', component: PublicDealsComponent },
      { path: 'propose', component: ProposeDealsComponent }
    ]
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'product_active', component: ProductsActiveListComponent },
      { path: '', component: ProductsActiveListComponent }
    ]
  },
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'group_active', component: GroupsActiveListComponent },
      { path: 'user_groups', component: UserGroupsComponent },
      { path: '', component: GroupsActiveListComponent }
    ]
  },

  { path: 'user_join', component: UserJoinComponent, canActivate: [AuthGuard] },
  { path: 'warehouse_policy', component: WarehousePolicyComponent, canActivate: [AuthGuard] },
  {
    path: 'inbounds',
    component: InboundComponent,
    canActivate: [AuthGuard],
    children: [
      
      {
        path: '',
        component: ToConfirmListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inboundToConfirm',
        component: ToConfirmListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inboundReported',
        component: ReportedListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inboundPayment',
        component: PaymentListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inboundHistory',
        component: HistoryListComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '**', redirectTo: 'signin' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
