import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import { User } from '../models/user';
//import { GeTuiSdkPlugin } from 'GeTuiSdkPlugin';

import { environment } from '../../environments/environment';
//declare const initGeTuiSdk: any;
declare const GeTuiSdkPlugin: any;


const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  updateUrl = environment.apiUrl + 'user/updateUser';


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(user: User) {
    return this.http
      // .post<User>(environment.apiUrl + 'signin', user, httpOptions)
      .post<User>(environment.apiUrl + 'users/signin', user, httpOptions)
      .pipe(
        map(logInUser => {
           //console.log(logInUser);
          // login successful if there's a jwt token in the response
          if (logInUser) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //console.log(typeof GeTuiSdkPlugin);
            if (typeof GeTuiSdkPlugin != 'undefined') {
              GeTuiSdkPlugin.initialize(); //再次初次化GeTuiSdkPlugin
            }            
            localStorage.setItem('currentUser', JSON.stringify(logInUser));
            this.currentUserSubject.next(logInUser);
          }

          return logInUser;
        })
      );
  }
  //更新用户cid
  /*
  updateUser(user: User): Observable<User> {
    return this.http.post<User>(this.updateUrl, user, httpOptions);
  }*/
  logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem('UserClientId');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
