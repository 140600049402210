import { Component } from '@angular/core';
import { environment } from '../environments/environment';
//字体
import { faCoffee} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(){
    // print apiUrl
    console.log('APIUrl: '+ environment.apiUrl);
}
  title = 'mean-app';
  faCoffee = faCoffee;
}
