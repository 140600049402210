//import { Component, OnInit,ViewChild } from '@angular/core';
import { 
  Component,
  OnInit,
  ViewChild,
  VERSION,
  Renderer2,
  QueryList,
  ElementRef,
  ViewChildren
} from "@angular/core";

import { Router,ActivatedRoute } from '@angular/router';
import { Deal } from '../../models/deal';
import { AuthenticationService } from '../../services/authentication.service';
import { ProductService } from '../../services/product.service';
import { first } from 'rxjs/operators';
import { DealService } from '../../services/deal.service';
import { GroupService } from '../../services/group.service';
import { Email } from '../../models/email';
//导出模块
import { ActiveDealsComponent } from './active-deals/active-deals.component';
import { ExpiredDealsComponent } from './expired-deals/expired-deals.component';
import { ProposeDealsComponent } from './propose-deals/propose-deals.component';
import { PublicDealsComponent } from './public-deals/public-deals.component';
//搜索防抖
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss'],
  providers: [ProductService, AuthenticationService, DealService, GroupService]
})
export class DealsComponent implements OnInit {
  public productList: any;
  public privilege: any;
  // Set the initial status of the create page as well as teh modal window
  public deal = new Deal(0, '', null, 0, '', '', true, false, true, false, '');
  public month: string;
  public days: string;
  public year: string;
  public hour: string;
  public minutes: string;
  public selectProduct: any;
  public error: string;
  public isCreated = false;
  public isPublic: any;
  public isPrivate: any;
  public memberList: any;
  public groupList: any;
  public membersTemp: any;
  public today: Date;
  public email = new Email();
  public receiver: any;

  public ClassName: string;
  public searchvalue : string;
  public ansArray: any;
  public pageSize=10;

  private getDataTerms = new Subject<string>();

  //data: string; 父组件中获得子组件的引用
  @ViewChild(ActiveDealsComponent, {static: false})
  ActiveDeals:ActiveDealsComponent; 

  @ViewChild(ExpiredDealsComponent, {static: false})
  ExpiredDeals:ExpiredDealsComponent; 

  @ViewChild(ProposeDealsComponent, {static: false})
  ProposeDeals:ProposeDealsComponent; 

  @ViewChild(PublicDealsComponent, {static: false})
  PublicDeals:PublicDealsComponent; 
  

  constructor(
    private dealService: DealService,
    private productService: ProductService,
    private groupService: GroupService,
    private authenticationService: AuthenticationService,
    private router: Router, // private homeActiveDealListComponent: HomeActiveDealListComponent
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  activeTab(tabname: any) {
    let el = this.elRef.nativeElement
      .querySelector(".container")
      .querySelector("ul")      
      .querySelectorAll("a");
    el.forEach((value, index) => {
      //console.log(value);
      var tname = value.innerHTML;
      //alert(tabname + "==" + tname);      
      this.renderer.removeClass(el[index], "active");

      if (tabname == tname) {
        this.renderer.addClass(el[index], "active");
      } else {
      }
    });
  }

  ngOnInit() {
    
    //input输入搜索防抖
    this.getDataTerms
      .pipe(
        // 请求防抖 300毫秒
        debounceTime(300),
        distinctUntilChanged())
       .subscribe(term => {
        //if (term.length!=0) {
        this.search(term);
        //}        
        //console.log(term);
        // term是用户输入的值
    });

    this.today = new Date();
    this.today.setDate(this.today.getDate() + 3);
    // console.log(this.today);
    this.month = (this.today.getMonth() + 1).toString().slice(-2);
    // console.log(this.month);
    this.days = this.today
      .getDate()
      .toString()
      .slice(-2);
    // console.log(this.days);
    this.year = this.today.getFullYear().toString();
    this.hour = this.today.getHours().toString();
    this.minutes = this.today.getMinutes().toString();
    if (this.router.url === '/deals' || this.router.url === '/deals/active') {
      //debugger;
      document.getElementById('active-tab').classList.add('active');
    } else if (this.router.url === '/deals/expired') {
      document.getElementById('expired-tab').classList.add('active');
    } else if (this.router.url === '/deals/propose') {
      document.getElementById('proposed-tab').classList.add('active');
    }
    if (this.authenticationService.currentUserValue) {
      this.deal.creator = this.authenticationService.currentUserValue[0];
      this.receiver = this.authenticationService.currentUserValue[4];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.getNameId();
        if (this.productList) {
          this.selectProduct = this.productList[0];
        }
      } else {
        this.privilege = false;
      }
    }
  }

  getNameId() {
    this.productService
      .productNameId(this.deal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.productList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            // $('.selectpicker').addClass('fixedWidth').selectpicker('setStyle');
            $('.selectpicker').selectpicker('render');
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  setProductNameId() {
    if (this.selectProduct) {
      this.deal.products = this.selectProduct.id;
      this.deal.productName = this.selectProduct.name;
    }
    document.getElementById('active-tab').classList.add('active');
  }

  create() {
    if (this.month && this.days && this.year && this.hour && this.minutes) {
      const date =
        this.year +
        '-' +
        this.month +
        '-' +
        this.days +
        ' ' +
        this.hour +
        ':' +
        this.minutes +
        ':' +
        '00';
      this.deal.expiresAt = date;
      //判断没有需要选择Deal Public Or Private
      if (typeof(this.membersTemp) == "undefined")  {
         this.error = 'please fill out all parts';
         return;
      }

      this.deal.members = this.membersTemp.join();
    } else {
      // TODO: need to improve error handle.
      this.error = 'please fill out all parts';
      return;
    }

    if (this.router.url === '/deals' || this.router.url === '/deals/active') {
      this.deal.publicDeal = false;
    }
    else if (this.router.url === '/deals/public') {
      this.deal.publicDeal = true;      
    }
    // console.log(this.deal);
    this.dealService
      .createDeal(this.deal)
      .pipe(first())
      .subscribe(
        data => {
          this.isCreated = true;
          window.location.reload();
          // console.log(data);
          // this.homeActiveDealListComponent.dealList[data.id] = this.deal;
          // console.log(this.homeActiveDealListComponent.dealList[''])
          // this.deal.clear();
          // this.ngOnInit();
        },
        error => {
          console.log(error);
        },
        () => {
          this.emailNotification(this.deal);
          this.deal.clear();
        }
      );
  }

  emailNotification(deal) {
    if (this.deal.notify === true) {
      this.email.dealProduct = deal.products;
      this.email.emailList = deal.members;
      this.email.receiver = this.receiver;
      this.email.emailSubject = deal.creator + ' | ' + ' New Offer : ';
/*
      this.email.emailText = deal.creator + ' has created an offer\n\n' + 'Product: ' + deal.productName + '\n\nPrice: $' + deal.price + '\n\nQuantity: ' + deal.quantity + '\n\nExpire time: ' + deal.expiresAt + '\n\nNote:\n\n' + deal.note + '\n\nClick the link below to see all active offers\n\nhttps://m.ey-online.com/home/active';
*/
      this.email.emailTextBeforeImage = deal.creator + ' has created a new offer<br><br>' + 'Product: ' + deal.productName + '<br><br>';
      var htmlNote = deal.note.replace(/(\r\n|\n|\r)/gm, "<br>");
      this.email.emailTextAfterImage = '<br><br>Price: $' + deal.price + '<br><br>Quantity: ' + deal.quantity + '<br><br>Expire time: ' + deal.expiresAt + '<br><br><font color="red">Note:<br><br>' + htmlNote + '</font><br><br>Click the link below to see all active offers<br><br>https://m.brandnewegg.com/deals/active';
      this.dealService
        .emailNotification(this.email)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('done');
          }
        );
    }
  }
  getSearchValue() {
    const input = document.getElementById('searchInput') as HTMLInputElement;
    const value = input.value.toUpperCase();
    this.getDataTerms.next(value);
  }

  search(filter) {

    const table = document.getElementById('dealTable');
    const tr = table.getElementsByTagName('tr');
    /*
    if (this.searchvalue === filter ) {
      return;
    }*/
    
    //console.log(filter);
    switch (this.ClassName) {
      case 'ActiveDealsComponent':
         this.ActiveDeals.filter = filter;
         this.ActiveDeals.skip   = 0;
         
         this.ActiveDeals.dealList = [];
         this.ActiveDeals.IsCompany =  this.authenticationService.currentUserValue[2] === 'company' ? true : false  ;
         this.ActiveDeals.currentUser = this.authenticationService.currentUserValue[0];
         if (this.ActiveDeals.IsCompany) {
           this.ActiveDeals.enableCreate();
           this.ActiveDeals.privilege = true;
           this.ActiveDeals.getActiveList(this.ActiveDeals.creator);
           this.ActiveDeals.getAllCount(this.ActiveDeals.currentUser,this.ActiveDeals.currentUser,filter,'ActiveDeals',this.ActiveDeals.IsCompany);
         } else {
           this.ActiveDeals.privilege = false;
           this.ActiveDeals.getfollowing(this.ActiveDeals.creator);
         }
         
         //this.ActiveDeals.getAllCount(this.ActiveDeals.currentUser,this.ActiveDeals.currentUser,filter,'ActiveDeals',this.ActiveDeals.IsCompany); //返回总行数         
         
         break;
      case 'ExpiredDealsComponent':
         this.ExpiredDeals.filter = filter;
         this.ExpiredDeals.expiredList = [];
         this.ExpiredDeals.skip   = 0;

         //debugger;         
         this.ExpiredDeals.IsCompany =  this.authenticationService.currentUserValue[2] === 'company' ? true : false  ;
         
         
         if (this.ExpiredDeals.IsCompany) {
           this.ExpiredDeals.disableCreate();
           this.ExpiredDeals.privilege = true;
           this.ExpiredDeals.expiredRetrieve(this.ExpiredDeals.currentUser);
           this.ExpiredDeals.getAllCount(this.ExpiredDeals.currentUser,this.ExpiredDeals.currentUser,filter,'ExpiredDeals',this.ExpiredDeals.IsCompany);
         } else {
           this.ExpiredDeals.privilege = false;
           this.ExpiredDeals.getfollowing(this.ExpiredDeals.currentUser);
           //if (typeof this.ExpiredDeals.followingList !== "undefined" && this.ExpiredDeals.followingList.length>0) {
           //  this.ExpiredDeals.getAllCount(this.ExpiredDeals.creator,this.ExpiredDeals.currentUser,filter,'ExpiredDeals',this.ExpiredDeals.IsCompany);
           //}
           
         }
         
         break;
      case 'ProposeDealsComponent':
         this.ProposeDeals.filter = filter;
         this.ProposeDeals.IsCompany =  this.authenticationService.currentUserValue[2] === 'company' ? true : false  ;
         this.ProposeDeals.proposeList = [];
         this.ProposeDeals.skip   = 0;

         if (this.ProposeDeals.IsCompany) { 
            this.ProposeDeals.disableCreate();
            this.ProposeDeals.privilege = true;
            this.ProposeDeals.proposeRetrieveCompany(this.ProposeDeals.currentUser);
            this.ProposeDeals.getAllCount(this.ProposeDeals.currentUser,this.ProposeDeals.currentUser,filter,'ProposeDeals',this.ProposeDeals.IsCompany);
          } else {
            this.ProposeDeals.privilege = false;
            this.ProposeDeals.proposeIndividual(this.ProposeDeals.currentUser);
          }
         break;
      case 'PublicDealsComponent':
         this.PublicDeals.filter = filter;
         this.PublicDeals.IsCompany =  this.authenticationService.currentUserValue[2] === 'company' ? true : false  ;
         this.PublicDeals.dealList = [];
         this.PublicDeals.skip   = 0;

         if (this.PublicDeals.IsCompany) {
           this.PublicDeals.enableCreate();
           this.PublicDeals.privilege = true;
           this.PublicDeals.getPublicList(this.PublicDeals.creator);
         } else {
           this.PublicDeals.privilege = false;
           this.PublicDeals.getfollowing(this.PublicDeals.creator);
         }
         this.PublicDeals.getAllCount(this.PublicDeals.currentUser,this.PublicDeals.currentUser,filter,'PublicDeals',this.PublicDeals.IsCompany);

         break;
      default :
         break;
    }
     
    this.searchvalue = filter;

    // Loop through all table rows, and hide those who don't match the search query
    // tslint:disable-next-line:prefer-for-of
    /* 升级查询功能 取消 tom.lee
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].getElementsByTagName('td')[0];
      // console.log(tr[i].getElementsByTagName('td'));
      if (td) {
        const txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }*/
  }
  //切换时清除
  clearSearch()
  {
    this.getDataTerms.next('');
  }

  makePublic() {
    this.membersTemp = '';
    this.isPublic = true;
    this.isPrivate = false;
    this.deal.dealPublic = true;
    this.groupService
      .allSubscribers(this.deal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.memberList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
            $('.selectpicker').selectpicker('selectAll');
          });
        }
      );
  }

  makePrivate() {
    this.membersTemp = '';
    this.isPublic = false;
    this.isPrivate = true;
    this.deal.dealPublic = false;
    this.groupService
      .groupRetrieve(this.deal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.groupList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  //激活主页
  onRouterOutletActivate(event : any) {
    //debugger;
    if (this.router.url === '/deals/active') {
      this.ClassName = 'ActiveDealsComponent';
      this.activeTab('Active Deals');
    } else if (this.router.url === '/deals/expired') {
      this.ClassName = 'ExpiredDealsComponent';
      this.activeTab('Expired Deals');
    } else if (this.router.url === '/deals/public') {
      this.ClassName = 'PublicDealsComponent';
      this.activeTab('Public Deals');
    } else if (this.router.url === '/deals/propose') { 
      this.ClassName = 'ProposeDealsComponent';
      this.activeTab('Proposed Deals');
    } else {
      this.ClassName = 'ActiveDealsComponent';
      this.activeTab('Active Deals');
    }
    //this.ClassName = event.constructor.name;   
     
    this.ansArray = true;
    const input = document.getElementById('searchInput') as HTMLInputElement;      
    const eventEnter = new KeyboardEvent("keydown",{
        "key": "Enter"
    });

    //模拟回车键
    input.value = '';
    input.focus();
    input.dispatchEvent(eventEnter);

    this.clearSearch(); //切换清除功能

   
   


  }

}
