import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Subscription } from '../models/subscription';
import { Observable } from 'rxjs';

import { Group } from '../models/group';
import { environment } from '../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  // // public subscription = new Subscription(null, null, null);
  // // URL to web api
  // allUrl = environment.apiUrl + 'subscription/find';
  // setActiveUrl = environment.apiUrl + 'subscription/setactive';
  // setBlockUrl = environment.apiUrl + 'subscription/setblock';
  // /* --- ABANDONED in v2 Start --- */
  // acceptUrl = environment.apiUrl + 'subscription/approve';
  // tenBlockedUrl = environment.apiUrl + 'subscription/findTenBlocked';
  // tenWaitingUrl = environment.apiUrl + 'subscription/findTenWaiting';
  // /* --- ABANDONED in v2 End --- */
  // findfollowingUrl = environment.apiUrl + 'subscription/findfollowing';
  // checkRelationshipUrl = environment.apiUrl + 'subscription/check';
  // createUrl = environment.apiUrl + 'subscription/create';

  // /**
  //  * user-group URL
  //  */

  // userGroupCreateUrl = environment.apiUrl + 'group/create';
  // userGroupDeleteUrl = environment.apiUrl + 'group/delete';
  // userGroupRetrieveUrl = environment.apiUrl + 'group/retrieve';
  // updateGroupUrl = environment.apiUrl + 'group/update';

  // public subscription = new Subscription(null, null, null);
  // URL to web api
  allUrl = environment.apiUrl + 'subscriptions/allSubscriptions';
  setActiveUrl = environment.apiUrl + 'subscriptions/setActive';
  setBlockUrl = environment.apiUrl + 'subscriptions/setBlock';
  findfollowingUrl = environment.apiUrl + 'subscriptions/findfollowing';
  checkRelationshipUrl = environment.apiUrl + 'subscriptions/check';
  createUrl = environment.apiUrl + 'subscriptions/subscription';
  subAllCountUrl = environment.apiUrl + 'subscriptions/getAllCount';



  /**
   * user-group URL
   */

  userGroupCreateUrl = environment.apiUrl + 'userGroups/userGroup';
  userGroupDeleteUrl = environment.apiUrl + 'userGroups/deleteUserGroup';
  userGroupRetrieveUrl = environment.apiUrl + 'userGroups/allUserGroups';
  updateGroupUrl = environment.apiUrl + 'userGroups/updateGroup';
  GetAllCountUrl = environment.apiUrl + 'userGroups/getAllCount';


  constructor(private http: HttpClient) {}

  updateGroup(groupId: number, groupMember: string): Observable<any> {
    const creatorJSON =
      '{ "groupId" : ' +
      '"' +
      groupId +
      '"' +
      ', ' +
      '"groupMember" : ' +
      '"' +
      groupMember +
      '"' +
      ' }';
    return this.http.post<any>(
      this.updateGroupUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //groups-active-list
  allSubscribers(company: string, filter = '',skip=0 , pageSize=10000000000): Observable<string> {
    //const creatorJSON = '{ "company" : ' + '"' + company + '"' + ' }';
    // console.log(JSON.parse(creator));
    let creatorJSON = '{ "creator" : ' + '"' + company + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    if (filter.length != 0) {
       creatorJSON = '{ "creator" : ' + '"' + company + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }
    //debugger;
    return this.http.post<string>(
      this.allUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  setActive(subscription: Subscription): Observable<Subscription> {
    return this.http.post<Subscription>(
      this.setActiveUrl,
      subscription,
      httpOptions
    );
  }

  setBlock(subscription: Subscription): Observable<Subscription> {
    return this.http.post<Subscription>(
      this.setBlockUrl,
      subscription,
      httpOptions
    );
  }

  findfollowing(individual: string): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.findfollowingUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  checkRelationship(individual: string, company: string): Observable<string> {
    const creatorJSON =
      '{ "individual" : ' +
      '"' +
      individual +
      '"' +
      ', ' +
      '"company" : ' +
      '"' +
      company +
      '"' +
      ' }';
    // console.log(creatorJSON);
    return this.http.post<string>(
      this.checkRelationshipUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  create(individual: string, company: string, email: string): Observable<string> {
    const creatorJSON =
      '{ "individual" : ' +
      '"' +
      individual +
      '"' +
      ', ' +
      '"company" : ' +
      '"' +
      company +
      '"' +
      ', ' +
      '"email" : ' +
      '"' +
      email +
      '"' +
      ' }';
    return this.http.post<string>(
      this.createUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  /**
   *
   * user-group service
   */
  groupCreate(group: Group): Observable<Group> {
    return this.http.post<Group>(this.userGroupCreateUrl, group, httpOptions);
  }

  groupDelete(id: number): Observable<string> {
    const creatorJSON = '{ "id" : ' + '"' + id + '"' + ' }';
    return this.http.post<string>(
      this.userGroupDeleteUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //user-groups
  groupRetrieve(creator: string, filter = '',skip=0 , pageSize=10000000000): Observable<string> {
    
    //const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';
    let creatorJSON = '{ "creator" : ' + '"' + creator + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    if (filter.length != 0) {
       creatorJSON = '{ "creator" : ' + '"' + creator + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }
    //debugger;
    return this.http.post<string>(
      this.userGroupRetrieveUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //返回数据总条数
  getAllCount(company: string ,filter: string ,groupstype: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + company  + '","filter" : "'+ filter + '","groups" : "'+ groupstype +  '" }';    
    return this.http.post<string>(
      this.GetAllCountUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //返回数据总条数
  subAllCount(company: string ,filter: string ,groupstype: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + company  + '","filter" : "'+ filter + '","groups" : "'+ groupstype +  '" }';    
    return this.http.post<string>(
      this.subAllCountUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

}
