<div class="container">
  <h1>Products</h1>
  <hr/>
  <ul class="nav nav-tabs" id="dealTabs" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active bg-light"
        id="active-tab"
        data-toggle="tab"
        routerLink="product_active"
        role="tab"
        aria-controls="active"
        aria-selected="true"
        >Active Products</a
      >
    </li>
  </ul>
  <br>
    <form class="float-left my-2">
      <input
        class="form-control"
        type="search"
        placeholder="Search"
        aria-label="Search"
        id="productSearchInput"
        (keyup)="getSearchValue()"
      />
    </form>
    <div class="float-right my-2">
      <button
        type="button"
        class="btn btn-outline-success btn-block"
        data-toggle="modal"
        data-target="#createForm"
        data-whatever="@mdo"
      >
        Create
      </button>
  </div>
  <!--分页设置 tom.lee-->
  <div class="tab-content" id="productsLists" *ngIf="ClassName=='ProductsActiveListComponent'">
    <app-products-active-list  [answers]='ansArray'></app-products-active-list>
  </div>

  <div class="tab-content" id="productsLists" [hidden]="true">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>
  
  <!-- 
  <div class="wrapper">
    分页数据传输 页面切换隐藏/显示 tom.lee 
    <app-products-active-list  [hidden]="ClassName!='ProductsActiveListComponent'"></app-products-active-list>
  </div>-->  
  
  <div
    class="modal fade"
    id="createForm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createFrom"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createFrom">Product Create Form</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form ngNativeValidate>
            <div class="form-group">
              <label for="product-name" class="col-form-label">Name:</label>
              <input
                type="text"
                class="form-control"
                id="product-name"
                name="name"
                [(ngModel)]="product.name"
              />
              <small>* Required</small>
            </div>
            <div class="form-group">
              <label for="product-condition" class="col-form-label"
                >Condition:</label
              >
              <input
                type="text"
                class="form-control"
                id="product-condition"
                name="condition"
                [(ngModel)]="product.condition"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="product-price" class="col-form-label">Price:</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control"
                id="product-price"
                name="msrp"
                [(ngModel)]="product.msrp">
              </div>
            </div>
            <div class="form-group">
              <label for="product-asin" class="col-form-label">ASIN:</label>
              <input
                type="text"
                class="form-control"
                id="product-asin"
                name="asin"
                [(ngModel)]="product.asin"
              />
              <small>Each ASIN consists of 10 alphanumerics. Search listings with the
                same ASINs.</small>
            </div>
            <div class="form-group">
              <label for="product-weight" class="col-form-label">Weight:</label>
              <input
                type="text"
                class="form-control"
                id="product-weight"
                name="weight"
                [(ngModel)]="product.weight"
              />
            </div>
            <div class="form-group">
              <label for="product-Note" class="col-form-label">Note:</label>
              <textarea
                type="text"
                class="form-control"
                id="product-note"
                cols="30"
                rows="10"
                name="note"
                [(ngModel)]="product.note"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="product_link" class="col-form-label">Product Image Link</label>
              <input type="text" class="form-control" id="product_link" name="productLink" [(ngModel)]="product.link" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" (click)="create()" data-dismiss="modal" *ngIf="product.name">Add</button>
          <button type="button" class="btn btn-primary" (click)="create()" data-dismiss="modal" *ngIf="!product.name" disabled>Add</button>
        </div>
      </div>
    </div>
  </div>
</div>
