import { Component,ViewChild, OnInit,Input,AfterViewInit} from '@angular/core';
import { aggregateBy } from "@progress/kendo-data-query";
//import { sampleProducts } from "../payment-list/product";
import { GridComponent, GridDataResult } from "@progress/kendo-angular-grid";
//import { CategoriesService } from "./northwind.service";
import { Observable } from "rxjs";
import { first } from 'rxjs/operators';
import { PaymentService } from '../../../services/payment.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AlertService } from '../../../_alert';
import { Paymentold } from '../../../models/paymentold';
import { Receipt } from '../../../models/receipt';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

import { InboundService } from '../../../services/inbound.service';

import { Payment } from '../../../models/payment';
import { inboundPayment } from '../../../models/inboundpayment';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';  



declare var jQuery: any;
//let me = this;
declare var $: any;
declare var kendo:any;
declare var detailsTemplate:any;
declare var angular:any;

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};

@Component({
  selector: 'app-payment-list',
  providers: [PaymentService,AuthenticationService,InboundService],
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {

  closeResult: string;
  options = {
        autoClose: false, //true Auto close alert after three seconds
        keepAfterRouteChange: true //true Keep displaying after one route change
  };


  constructor(private paymentService: PaymentService,
              private authenticationService: AuthenticationService,
              protected alertService: AlertService, 
              private httpClient: HttpClient,
              private inboundService: InboundService,
              private modalService: NgbModal,
  	) { }

  public pageSize = 5; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //总条数
  public filter = '';
  public noRecord: any;
  public decimals = 2 ;


  public aggregates: any[] = [{ field: "UnitPrice", aggregate: "sum" }];

  //public products: any[] = sampleProducts;

  //public total: any = aggregateBy(this.products, this.aggregates);

  public view: Observable<GridDataResult>;
  @ViewChild(GridComponent, {static: false}) grid: GridComponent;

  public paydata = [];
  public payfields = [];
  public payList = [];
  public payListFields = [];
  public receipt = [];
  public payment = [];
  public receiptList = [];
  public receiptFields = [];
  public recordList: any;

  //public row = [];
  //public confirmList: any;
  public myVar : string = "blue";
  public currSideNavId: number = 1;
  public currentUser: any;
  public showDetails: any;
  public confirmId: number = 0;
  public individual = '';
  public receiptDate = 'Date';
  public DueDate = '';
  public Conditions = '';
  public iscompany = true;
  public isAdd = 'add';
  public privilege: any;

  public paymentold = new Paymentold(null, null, null, '', '', '', '', null, null,null,null,1);   
  public receipts = new Receipt(null, null,'','','','','','',null,null,null,null,null,null,'',null,'','');
  
  public payments = new Payment( null,null,'','','',null,null,null,null,null,null,null,null,'','', null, '' );
  public inboundPayments = new inboundPayment( null,null,null,'','','',null,null,null,null,null,null,null,null,null);


  //inboundId, dealId, receiptNumber, company ,individual, tracking,upc,quantity,price,tax,amount,status,terms,receiptDate,dueDate
 
  public ConfirmedQty = 0;
  public BillTotal = 0;
  public oldAdj = 0.00;

  public group: any[] = [
    {
      field: "Discontinued",
      aggregates: this.aggregates,
    },
  ];

  public receiptForm: FormGroup = new FormGroup({
    ReceiptNumber: new FormControl(),
    IndividualUser: new FormControl(),
    receiptDate: new FormControl(new Date()),
    DueDate: new FormControl(new Date()),    
    ConditionsValue: new FormControl(),
    note: new FormControl(),
    BillTotal: new FormControl(),
    Adjustment: new FormControl()  
  });
  
  public loadExternalScript(url: string) {
  	const body = <HTMLDivElement> document.body;
	const script = document.createElement('script');
	script.innerHTML = '';
	script.src = url;
	script.async = true;
	script.defer = true;
	body.appendChild(script);
  };

  public status = ['Waiting', 'confirmed', 'paid'];
  /*
  public onChange(value: string): void {  	
  	if (value != null)
  	{
  		let billTotal = parseFloat(this.receiptForm.value.BillTotal) + parseFloat(value);

  	    this.receiptForm.patchValue({
		  BillTotal: billTotal.toFixed(2)
	    }); 
    }
     //console.log(`valueChange ${value}`);
  }*/

  public AdjonFocus(): void {
    this.oldAdj = this.receiptForm.value.Adjustment;    
  }

  public AdjonBlur(): void {

  	let adj = this.receiptForm.value.Adjustment,
  	    bil = 0.00,
  	    curAdj = 0.00;

  	if (adj != null) {
  		curAdj = parseFloat(this.receiptForm.value.Adjustment) - this.oldAdj;

  		bil = parseFloat(this.receiptForm.value.BillTotal) + curAdj;
  		 this.receiptForm.patchValue({
		  BillTotal: bil.toFixed(2)
	    });

  		this.oldAdj = 0.00; 

  	}
    //console.log(this.receiptForm.value.BillTotal);
    //console.log(this.receiptForm.value.Adjustment);    

  }

  //getReceiptNumberUrl = environment.apiUrl + 'payment/getReceiptNumber';

  @Input('answers') ans:any;
  ngOnInit() {

   	let me = this;
    this.iscompany = this.authenticationService.currentUserValue[2] === 'company' ? true : false;

       
    this.payListFields = [
        {selectable: true,width: 42,attributes: {"class": "checkbox-align",},headerAttributes: {"class": "checkbox-align",}},        
        { hidden: true, field: "id" },
        { hidden: true, field: "dealId" },
        { hidden: true, field: "company" },
        {field: "inboundId",title: "#",width: 70},
        {field: "product",title: "Inbound",width: 500},
        this.authenticationService.currentUserValue[2] === 'company' ? {field: "individual",title: "individual",width: 100} : '',
        {
        	field: "upc",
        	title: "UPC",
        	groupHeaderTemplate: "UPC: #=data.value#,Total Amount: #=kendo.format('{0:c}', aggregates.amount.sum)#",
        	width: 100
        },
        {field: "quantity", title: "Qty", width: 80,aggregates: ["sum"],footerTemplate:"Qty: #=sum#"},
        {field: "price",title: "Price",width: 80},
        {field: "amount",title: "Amount",width: 90,aggregates: ["sum"],footerTemplate:"Total: #=sum#"},
        {field: "tracking",title: "Tracking Number", width: 120},       
        {field: "status",title: "Status", width: 90,template: "<span id='badge_#=status#' class='badgeTemplate'></span>"},        
        {command:{ text: this.authenticationService.currentUserValue[2] === 'company' ? "Comfirm" : "Pay", click: this.Payment ,iconClass: "k-icon k-i-cart",className:"k-state-disabled excludeCategorization"}, title: "Actions", width: 110 }];
       

  	if (!this.ans) return; 	

  	$(document).ready(function(){
	  $('[data-toggle="tooltip"]').tooltip();   
	});

  	if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      
      let desc = $("#py-description").kendoTextArea({
        rows: 4,
        maxLength:200,
        readonly: this.iscompany ? true : false 
      });

      let note = $("#note").kendoTextArea({
        rows: 4,
        maxLength:200,
        placeholder: "Payment is due within 3 days.",
        readonly: this.iscompany ? false : true 
      });

      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true ;  
        //this.PageCount(this.currentUser,true,this.filter); //返回总行数
        //this.getRecords(this.currentUser,this.iscompany);

      } else {
        this.privilege = false;
        //this.PageCount(this.currentUser,false,this.filter); //返回总行数 
        //this.getRecords(this.currentUser,this.iscompany);
      }
      this.PageCount(this.currentUser,true,this.filter); //返回总行数
      this.getRecords(this.currentUser,this.iscompany);
      // this.userEmail = 'admin';
    } else {
      // TODO: add no userEmail error
    }


    /*
    this.currentUser =  this.authenticationService.currentUserValue[0];
  	if (this.authenticationService.currentUserValue[2] === 'company') {
  	    this.getCompanyList(this.currentUser);
  	} else {
  	    (<HTMLInputElement>document.getElementById('checkaaen')).hidden = true; //护士账号隐藏
  	    this.getConfirmed(this.currentUser);
  	} */	
  	
  }

  getRecords(userEmail,iscompany) {
    this.inboundService
      .paymentRecords(userEmail, this.pageSize , this.skip, this.filter,iscompany)
      .pipe(first())
      .subscribe(
        data => {
          this.recordList = data;          
        },
        error => {
          console.log(error);
        },
        () => {
          if (this.recordList.length < 1) {
            this.noRecord = true;
          }
        }
      );
  }
  //公司账号
  /*
  getRecordsCompany(userEmail) {
    this.inboundService
      .toConfirmRecordsCompanyKendo(userEmail,this.pageSize , this.skip, this.filter)
      .pipe(first())
      .subscribe(data => {
        //debugger;
        this.recordList = data;       
      });
  }*/

  //返回选中的求款清单  
  selectpay() {	
  	
    var trs = $("#newreceipt").find('tr'),
        crow = [],
        rows = [],
        str  = '',
        temp = '',
        cellvalue = '',
        grid = $("#newreceipt").getKendoGrid(),
        paygrid = $("#paymentGrid").data("kendoGrid"),
        cells=[],
        frow =[];
    if (grid) {    
	         
	    var trs = $("#newreceipt").find('tr');
	    //过滤筛选选中的数据进行导出
	    for (var i = 1; i < trs.length; i++) {
	      if ($(trs[i]).find(":checkbox").is(":checked")) {
	      	rows.push(grid.dataSource.options.data[i-1]);
	      	this.payList.push(grid.dataSource.options.data[i-1]);        
	       
	      }
	    }
    
    }

    paygrid.refresh();
    //$("#selectInbound").kendoGrid().data("kendoGrid").refresh();  	
  	this.ngOnInit();
  }
  //获取已收货的Inbound --护士帐号
  /*
  getConfirmed(individual) {
    this.paymentService
      .getConfirmed(individual)
      .pipe(first())
      .subscribe(data => {
        //debugger;
        if (data.length>0) {
        	let row = [];
        	let em = this;
        	this.paydata = [];

	      	data.forEach(function(item){
	      		row = [];
		  		row['product']   = item.inbound.product;
		  		row['price']     = item.inbound.price;
		  		row['proposed']  = item.inboundQty;
		  		row['confirmed'] = item.confirmedQty;
		  		row['quantity']  = row['confirmed'];
		  		row['amount']    = (item.inbound.price * item.confirmedQty).toFixed(2);
		  		row['upc']       = item.upc; 
		  		row['tracking']  = item.tracking;
		  		row['dealId']    = item.dealId;
		  		row['id']        = item.id; 
		  		row['inboundId'] = item.inboundId;
		  		row['status']    = item.inboundPaymentOld !== null ? item.inboundPaymentOld.status : 0 ;	 
		  		row['company']   = item.company;

			    em.paydata.push(row);
			    //em.payList.push(row);
			})
			this.payList  = Object.assign([], this.paydata);
       }
  	   this.CreatePaymentGrid();
  	   //this.getReceiptList(individual);
  	   //this.CreateReceiptGrid();
      });
  }
  //公司帐号
  getCompanyList(company) {

  	this.paymentService
      .getCompanyList(company)
      .pipe(first())
      .subscribe(data => {
        //debugger;
        if (data.length>0) {
        	let row = [];
        	let em = this;
        	this.paydata = [];

	      	data.forEach(function(item){
	      		row = [];
		  		row['product']   = item.inbound.product;
		  		row['price']     = item.inbound.price;
		  		row['proposed']  = item.inboundQty;
		  		row['confirmed'] = item.confirmedQty;
		  		row['quantity']  = row['confirmed'];
		  		row['amount']    = (item.inbound.price * item.confirmedQty).toFixed(2);
		  		row['upc']       = item.upc; 
		  		row['tracking']  = item.tracking;
		  		row['dealId']    = item.dealId;
		  		row['id']        = item.id; 
		  		row['inboundId'] = item.inboundId;
		  		row['status']    = item.inboundPayment !== null ? item.inboundPayment.status : 0 ;	 
		  		row['company']   = item.company;
			    row['individual']= item.individual;

			    em.paydata.push(row);
			    //em.payList.push(row);
			})
			this.payList  = Object.assign([], this.paydata);
       }
  	   this.CreatePaymentGrid();
  	   //this.getReceiptList('');
  	   
      });

  }*/

  Payment() {

  }
  /*
  async checkReceiptNumber(individual) {
  	const receiptJSON = '{ "individual" : ' + '"' + individual + '"'+ ' }'; 
  	const data = await this.httpClient.post(this.getReceiptNumberUrl,receiptJSON,httpOptions).toPromise();
   	//let receiptNumber = await this.getReceiptNumber(individual);
  	return data;
  }*/
  
  //获取付款编号
  getReceiptNumber(individual) {
    
    return new Promise((resolve, reject) => {
      this.paymentService
      .getReceiptNumber(individual)
      .pipe(first())
      .subscribe(data => {
      	 let str = data === null ? '1' : (parseInt(data["receiptNumber"])+1).toString();      
         str = str.padStart(3,'0');

         this.receiptForm.patchValue({
		    ReceiptNumber: str
		 });  
      	 //return str;        
      });
    });

  }

  //获取付款列表
  getReceiptList(individual) {    
      this.paymentService
      .getReceiptList(individual)
      .pipe(first())
      .subscribe(data => {
        this.receiptList = data;
        this.CreateReceiptGrid();	
      	 //return str;        
      });
  }

  //添加 New Kendo Grid 
       /*
		  CreatePaymentGrid() {
		  	//let me = this;
		  	let em = this;
		  	let toolbar = [];
		  	//let iscompany = em.authenticationService.currentUserValue[2] === 'company' ? true : false;
		  	if (em.iscompany) {
		 		toolbar = [{name: 'ExportToExcel',text: "Export to Excel",iconClass:"k-icon k-i-excel"},{name: 'batchtopay',text: "Batch To Pay",iconClass:"k-icon k-i-select-all",class:"btn btn-info btn-lg"},{name: 'newreceipt',text: "New Receipt",iconClass:"k-icon k-i-calculator",class:"btn btn-info btn-lg"},"search"];
		  	}  else {
		  		toolbar = [{name: 'ExportToExcel',text: "Export to Excel",iconClass:"k-icon k-i-excel"},{name: 'batchtopay',text: "Batch To Pay",iconClass:"k-icon k-i-select-all",class:"btn btn-info btn-lg"},"search"];
		  	}
		  	

			(function ($) {

				
		        function onSelect(e) {
		        	if (em.iscompany) {
		        		em.getReceiptList('');
		        	} else {
		        		em.getReceiptList(em.currentUser);
		        	}
		        }
		        function onShow(e) {
		           //alert('test2');
		        }

		        function onActivate(e) {
		        	//alert('test3');        	
		        }
		       
				//$("#inboundSearchInput").hide();
				var tabStrip = $("#tabstrip").kendoTabStrip({
					select: onSelect,
					activate: onActivate,
					show: onShow
			        //animation: false
			        }).data("kendoTabStrip");
		        tabStrip.select(0);


				var grid = $("#paymentGrid").data("kendoGrid"),
				    payGrid = $("#newreceipt").data("kendoGrid"),
		                  a = this,
				    THISPAGE = {

					  	init: function() {
							this.initDom();
							this.loadGrid();         
						},
					    initDom: function() {	     
					        this._selectRows = [];
					    },			    
				        loadGrid: function() {
				        	//var	a = this,
				        	grid = $("#paymentGrid").kendoGrid({
				                toolbar:toolbar,  
				                search: {
				                    fields: ["inboundId","product","upc",em.iscompany ? "individual" : "","tracking"]
				                },
				                dataSource: {                    
				                    data : em.payList,
				                    group: {
				                        field: "upc",
				                        dir: "desc",
				                        aggregates: [
				                            { field: "amount", aggregate: "sum" }
				                        ]
				                    },
				                    schema: {
				                        model: {
				                            id: "id",
				                            fields: {
				                                id: { editable: false,field: "id", type: "number"},
				                                dealId: { editable: false,field: "dealId", type: "number"},
				                                inboundId: { editable: false,field: "inboundId", type: "number"},
				                                product: { editable: false, fileld:"product" ,type: "string" },
				                                upc: { editable: false,field: "upc", type: "string"},
				                                individual: { editable: false,field: "individual", type: "string"},
				                                price: { field: "price", type: "number"},
				                                quantity: { field: "quantity", type: "number"},
				                                amount:{ field: "amount", type: "number"},
				                                tracking: { editable: false,field: "tracking", type: "string"},
				                                status: { editable: false,field: "status", type: "string"},
				                                company: { editable: false,field: "company", type: "string"}
				                            }
				                        }
				                    },
				                    pageSize: 10,		                                                       
				                    aggregate: [{field: "quantity", aggregate: "sum"},
				                                {field: "amount", aggregate: "sum"}]
				                },
				                //height: $(window).height()-30,	             
				                sortable: true,
				                dataBound: this.onDataBound,
				                change: this.onChange,            
				                filterable: false,
				                groupable: true,	           
				                //菜单显示/隐藏	
				                columnMenu: false,     
				                pageable: {
				                    pageSizes: [5, 20, 30, 50, 100, "all"],
				                    numeric: false,
				                    input: true,
				                    refresh: true,
				                },	            
				                //editable: true,       //批量修改
				                editable:'inline',      //单行修改
				                batch: true,
				                reorderable: true,
				                resizable: true,
				                columns: em.payListFields 
				                //selectable: "multiple row", //多行选择		                
				                //selectable: true
				            }).data("kendoGrid");
				           
			                //initialGridWidth = grid.wrapper.width();	              
				            $(".k-grid-save-changes").click(function(e) {            
				            });
				            $(".k-grid-cancel-changes").click(function(e) {              
				            });
				            $(".k-grid-Delete").click(function(e) {               
				            });  
				            $(".k-grid-pdf").click(function(e) {            
				            });             
				            //导出 excel 
				            $(".k-grid-ExportToExcel").click(function(e) {
				            	e.preventDefault();
				            	e.preventDefault();
				            	var rows = [];
				            	rows = THISPAGE.selectRows(e);	
				            	THISPAGE.excelExport(rows);	            	    
				            });
				            //创建新付款单 
				            $(".k-grid-newreceipt").click(function(e) {
				            	e.preventDefault();	
				            	THISPAGE.AddReceipt(e);	                		                
				            	$("#openReceipt").click();           
				            });
				            $(".k-grid-Pay").click(function(e) {
				            	e.preventDefault();
				            	var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));

				            	em.paymentold.inboundId =  dataItem.inboundId;
				            	em.paymentold.dealId    =  dataItem.dealId;
				            	em.paymentold.company   =  dataItem.company;
				            	em.paymentold.individual=  em.currentUser;
				            	em.paymentold.tracking  =  dataItem.tracking;
				            	em.paymentold.upc       =  dataItem.upc;

				            	em.paymentold.quantity  =  dataItem.confirmed;
				            	em.paymentold.propose   =  dataItem.proposed;
				            	em.paymentold.price     =  dataItem.price;
				            	em.paymentold.amount    =  dataItem.amount;
				            	em.paymentold.confirmId =  dataItem.id;
				            	em.paymentold.status    =  1 //0 -- Unpaid 1-- Paying 2-- Paid 

				            	em.paymentService
							      .createPayment(em.paymentold)
							      .pipe(first())
							      .subscribe(data => {					       
							        this.confirmId = data;
							        var grid = this,
							            inboundid = ""; 

							        if(this.confirmId>0) {
							        	//this.isCreated = true;
							            //this.onDataBound;
							            em.payList.forEach((value, index) => {
							            	//console.log(value);
							            	//console.log(valueindex);
							            	if(value['id'] == this.confirmId) {
							            		em.payList[index].status = 1;
							            		inboundid = em.payList[index].inboundId;
							            	}
							            });

								        var grid = $("#paymentGrid").data("kendoGrid");
								            grid.table.find("tr").each(function () {
						                        $(this).find(".badgeTemplate").removeClass('k-badge-error');
					                        });
							            em.paymentold.clear();
							            THISPAGE.onDataBound(e);
							            em.alertService.success('Success!!(#:' + inboundid + ')' , em.options);
							        }					       
							        
							    });
				            	
				            });
				            $(".k-grid-Comfirm").click(function(e) {
				            	e.preventDefault();
		                        //获取行数据
				            	var dataItem = grid.dataItem($(e.currentTarget).closest("tr")),
				            	    notifications = (<HTMLInputElement>document.getElementById('aaen')).checked;

				            	em.paymentService
						          .confirm(dataItem.id,notifications?1:0)
						          .pipe(first())
						          .subscribe(
						            data => {
						            	this.confirmId = data;
						            	var inboundid = "";
						            	if (data.length == 1) {

						            		em.payList.forEach((value, index) => {						            	
								            	if(value['id'] == dataItem.id) {
								            		em.payList[index].status = 2;
								            		inboundid = em.payList[index].inboundId;
								            	}
								            });

						            		var grid = $("#paymentGrid").data("kendoGrid");
								            grid.table.find("tr").each(function () {
						                        $(this).find(".badgeTemplate").removeClass('k-badge-info');
						                        $(this).find(".badgeTemplate").removeClass('k-badge-error');
					                        });

						            		THISPAGE.onDataBound(e);
						            		em.alertService.success('Success!!(#:' + inboundid + ')', em.options);
						            	}
						              //this.ngOnInit();
						            },
						            error => {
						              console.log(error);
						            },
						            () => {
						            }
						          );

				            });		            
				            //批量修改
				            $(".k-grid-batchtopay").click(function(e) {
				            	e.preventDefault();
				            	var rows = [];
				            	var ids  = "";
				            	rows = THISPAGE.selectRows(e);


		                        if (rows.length != 0) {

		                        	if(em.iscompany) {
		                        		//公司帐号
		                        		for (let row = 1; row < rows.length; row++) {
						            		em.payList.forEach((value, index) => {
						            			if(value['id'] == rows[row].cells[0].value && parseInt(value['status']) == 1 ) {
						            				ids = ids + rows[row].cells[0].value + ',';
										        }
										    });
				                        }                        
				                        ids = ids.substring(0,ids.lastIndexOf(","));

		                        		var notifications = (<HTMLInputElement>document.getElementById('aaen')).checked;
					            	    em.paymentService		            	    
								          .confirm(ids,notifications?1:0)
								          .pipe(first())
								          .subscribe(
								            data => {
								            	this.confirmId = data;
								            	var inboundid = "";
								            	if (data.length == 1) {

								            		var arr = ids.split(',');
								            		for (let i = 0; i < arr.length; i++) {
								            			em.payList.forEach((value, index) => {
								            			   	if(value['id'] == arr[i]) {
								            			   		em.payList[index].status = 2;
										            		    inboundid = em.payList[index].inboundId;
										            	    }
										                });
								            		} 		

								            		var grid = $("#paymentGrid").data("kendoGrid");
										            grid.table.find("tr").each(function () {
								                        $(this).find(".badgeTemplate").removeClass('k-badge-info');
								                        $(this).find(".badgeTemplate").removeClass('k-badge-error');
							                        });

								            		THISPAGE.onDataBound(e);
								            		em.alertService.success('Success!!(#:' + ids + ')', em.options);
								            	}
								              //this.ngOnInit();
								            },
								            error => {
								              console.log(error);
								            },
								            () => {
								            }
								          );

		                        	} else {                        		
		                        		//护士帐号
		                        		//var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));				       	
						            	var at = 0 ;
						            	for (let row = 1; row < rows.length; row++) {
		                                    //at = 0;
						            		em.payList.forEach((value, index) => {
						            			//console.log(value['id']);
						            			//console.log(rows[row].cells[0].value);
						            			//console.log(parseInt(value['status']));
						            			
						            			if(value['id'] == rows[row].cells[0].value && parseInt(value['status']) == 0 ) {

						            				em.paymentold.inboundId = rows[row].cells[3].value;
									            	em.paymentold.dealId    =  rows[row].cells[1].value;
									            	em.paymentold.company   =  rows[row].cells[2].value;
									            	em.paymentold.individual=  em.currentUser;
									            	em.paymentold.tracking  =  rows[row].cells[9].value;
									            	em.paymentold.upc       =  rows[row].cells[5].value;

									            	em.paymentold.quantity  =  rows[row].cells[6].value;
									            	em.paymentold.propose   =  0; //dataItem.proposed;
									            	em.paymentold.price     =  rows[row].cells[7].value;
									            	em.paymentold.amount    =  rows[row].cells[8].value;
									            	em.paymentold.confirmId =  rows[row].cells[0].value;
									            	em.paymentold.status    =  1 //0 -- Unpaid 1-- Paying 2-- Paid 

									            	em.paymentService
												      .createPayment(em.paymentold)
												      .pipe(first())
												      .subscribe(data => {					       
												        this.confirmId = data;
												        var grid = this,
												            inboundid = ""; 

												        if(this.confirmId>0) {								        	
												            em.payList.forEach((value, index) => {
												            	if(value['id'] == this.confirmId) {
												            		em.payList[index].status = 1;
												            		inboundid = em.payList[index].inboundId;
												            	}
												            });

													        var grid = $("#paymentGrid").data("kendoGrid");
													            grid.table.find("tr").each(function () {
											                        $(this).find(".badgeTemplate").removeClass('k-badge-error');
										                        });
												            em.paymentold.clear();
												            THISPAGE.onDataBound(e);
												            
												        }
												        
												    });				            				
										        }
										    });

						            		//rows[row].cells[0].value
						            		

						            	}
						            	em.alertService.success('Success!!(#:' + ids + ')' , em.options);
						            	
		                        	}		            	    

		                        }

				            });	           
				            //单击行
				            grid.element.on('click', 'tbody>tr>td:not(.k-edit-cell)', function (e) {
				                grid.closeCell();
				            });
				            //双击行
				            grid.element.on("dblclick", "tbody>tr>td:not(.k-edit-cell)", "dblclick", function (e) {
				                grid.editCell($(this));
				            });
				            //搜索
				           
			                //$(".kd-checkbox").click(checkBoxChange);
			                //$(".k-menu-link").click(checkBoxChenge);
					    }, // end loadGrid			   
					    onChange: function(e){
					    	var rows = e.sender.select();			    	
				            rows.each(function(e) {
				                var grid = $("#paymentGrid").data("kendoGrid");
				                var dataItem = grid.dataItem(this);
				                //console.log(dataItem);
				            })

					    },
					    onDataBound: function(e){
					        //kendoConsole.log("Grid data bound");
					        e.preventDefault();
					        var grid = $("#paymentGrid").data("kendoGrid");
					        grid.table.find("tr").each(function () {
					        	var dataItem = grid.dataItem(this);
					        	var themeColor = dataItem.status != 0 ? (dataItem.status == 1 ? 'info' : 'success') : 'error' ;
		                        var text = dataItem.status != 0 ? (dataItem.status == 1 ? 'Paying' : 'Paid') : 'Unpaid';
		                        
					            $(this).find(".badgeTemplate").kendoBadge({
					            	themeColor: themeColor,
					        	    text: text,
		                        });
		                        // 如果未付款，设置为Enabled 否则disabled                        
		                        if(em.iscompany) {
		                        	if (dataItem.status == 1) {
		                        		$(this).find(".excludeCategorization").removeClass("k-state-disabled");
		                        	} else if(dataItem.status == 2) {
		                        		$(this).find(".excludeCategorization").addClass("k-state-disabled");
		                        	}
		                        } else {
		                        	if (dataItem.status == 0) {
			                        	$(this).find(".excludeCategorization").removeClass("k-state-disabled");
			                        } else {
			                        	$(this).find(".excludeCategorization").addClass("k-state-disabled");
			                        }
		                        } 

		                        kendo.bind($(this), dataItem);
					        });			       
					    },
					    onDataBinding:function(arg){
					        //console.log("Grid data binding");
					    },
					    onSorting:function(arg){
					        //console.log("Sorting on field: " + arg.sort.field + ", direction:" + (arg.sort.dir || "none"));
					    },
					    onFiltering:function(arg){
					    },
					    onPaging:function(arg){
					    },
					    onGrouping:function(arg){
					    },
					    onGroupExpand:function(arg){
					    },
					    onGroupCollapse:function(arg){
					    },    
					    setGridWidth:function(e){	       
					    },
					    //保存 grid options到本地 localStorage
					    setOptions: function(e){
					        e.preventDefault();
					        grid = $("#kendoGrid").data("kendoGrid");
					    },
					    //恢复 grid 本地localStorage参数
					    loadOptions: function(e){
					        //e.preventDefault();
					        grid = $("#kendoGrid").data("kendoGrid");       
					        //debugger;        
					        
					        var options =  false ;//localStorage["kendo-grid-options"];
					        //取消 
					        if (options) {
					            var parsedOptions = JSON.parse(options);          
					           
					            //console.log(parsedOptions.columns);
					            //因为Kendo UI hidden bug 使用这个方法修正
					            for (var d = 0; d < parsedOptions.columns.length; d++) 
					            {
					                if (parsedOptions.columns[d]['title'] == "condition") {
					                    parsedOptions.columns[d]['editor'] = this.clientconditionEditor;
					                }                

					                if (parsedOptions.columns[d]['hidden'] === false) {
					                    //parsedOptions.columns[d]['hidden'] = 0 ;
					                } else if (parsedOptions.columns[d]['hidden'] === true) {
					                    //console.log(d);
					                    grid.hideColumn(d,'load');
					                    //parsedOptions.columns[d]['hidden'] = !0 ;
					                } 

					            }            

					            //重设列宽
					            THISPAGE.setColumnWidths(grid,parsedOptions);            
					            //Also note: I do not want to use grid.setOptions, because its limitations, ruining attached events and header (in case of using ASP MVC helper to render the grid)
					            //因为它的局限性破坏了附加的事件和标头（在使用ASP MVC帮助器渲染网格的情况下）

					          
					        }			      
					       
					    },
					    clientconditionEditor: function(container, options){
					    },    
					    clientReceivedEditor: function(container, options){  
					    },
					    //自动调整列宽度，导致加载页面速慢，不建议使用。
					    autoFitColumn: function(e){

					        grid = $("#kendoGrid").data("kendoGrid");

					        //使用下面操作会令页面加载较慢，尽量少使用
					        //debugger;
					        for (var i = 0; i < grid.columns.length; i++) {
					          if (i!=1 || (i>=18 && i<=21)) {
					            grid.autoFitColumn(i);
					          }                     
					        }

					    },
					    //导出到Excel
					    excelExport: function(rows){
					    	 var workbook = new kendo.ooxml.Workbook({
					         sheets: [
					            {
					              columns: [
					                { autoWidth: true },
					                { autoWidth: true }
					              ],
					              title: "Orders",
					              rows: rows
					            }
					          ]
					        });
					        kendo.saveAs({dataURI: workbook.toDataURL(), fileName: "payment.xlsx"});
					    },
					    //设置列宽度
					    setColumnWidths: function(grid, options){        
					        var lockedCount = 0;
					        for (var i = 0; i < options.columns.length; i++) {
					            if (options.columns[i].hasOwnProperty('locked')) {
					                if (options.columns[i].locked) {
					                    lockedCount++;
					                }
					            }
					        }

					        for (var i = 0; i < options.columns.length; i++) {
					            var width = options.columns[i].width;
					            grid.columns[i].width = width;
					            //console.log(grid.columns[i].width);
					            if (options.columns[i].hasOwnProperty('locked') && options.columns[i].locked) {
					                $("#grid .k-grid-header-locked").find("colgroup col").eq(i).width(width);
					                $("#grid .k-grid-content-locked").find("colgroup col").eq(i).width(width);

					            } else {
					                $("#grid .k-grid-header-wrap").find("colgroup col").eq(i-lockedCount).width(width);
					                $("#grid .k-grid-content").find("colgroup col").eq(i - lockedCount).width(width);
					            }
					        }
					        // Hack to refresh grid visual state
					        grid.reorderColumn(1, grid.columns[0]);
					        grid.reorderColumn(1, grid.columns[0]);
					    },
					    addEvent: function(field,popup) {	       
					    },
					    //测试菜单
					    changeLabelText: function(text) {
					        if (text === "Select All") return text;
					        return text + " + myText";
					    },
					    Payment: function(e) {

					    	 e.preventDefault();
					    	 var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

					    	
					    	//alert('test');
					    },
					    //过虑选中行			    
					    selectRows: function(e) {

					    	var grid = $("#paymentGrid").getKendoGrid(),
					    	    cells=[],
		                        crow =[],
		                        frow =[],
		                        rows =[],
		                        str  = "";

		                    if (grid) {
		                    	
		                    	// get the date columns from the datasource
		                        var dateColumnList = [],
		                            fields = grid.dataSource.options.schema.model.fields,
		                            options = localStorage["kendo-grid-options"];

		                        // only check visible columns
		                        var visibleColumns = grid.columns.filter(function(col) { return col });
		                        visibleColumns.forEach(function (col, index) {
		                        	var fieldName = col.field;                       
		                            var match = fields[fieldName];
		                            // determine if this is a date column that will need a date/time format
		                            if (match && match.type !== 'undefined' || fieldName == 'dealId' || fieldName == 'id') {
		                            	// give each column a format from the grid settings or a default format
		                                //str = '{"value":"' + fieldName + '"}';
		                                //json = JSON.parse(str);
		                                cells.push(fieldName);
		                                str = "{\"value\":\"" + fieldName + "\"}";
		                                str = JSON.parse(str); 
		                                frow.push(str);
		                            }
		                        });

		                        var trs = $("#paymentGrid").find('tr'),
		                            cellvalue = "",
		                            temp      = "";
		                    
		                        //列名称
		                        rows.push({
		                            cells: frow
		                        })
		                        //过滤筛选选中的数据
		                        for (var i = 0; i < trs.length; i++) {

		                        	trs[i].classList.forEach(function (col, index) {
		                        	
			                        	if ($(trs[i]).find(":checkbox").is(":checked") && col == "k-master-row") {
			                        		var dataItem = grid.dataItem(trs[i]);
			                        		for (var cellIndex = 0; cellIndex < cells.length; cellIndex++) {
			                        			cellvalue = "dataItem." + cells[cellIndex];
			                        			temp = eval(cellvalue) == null ? '' : eval(cellvalue).toString();
			                            
			                                    str = "{\"value\":\"" + temp + "\"}";
			                                    str = JSON.parse(str); 
			                                    crow.push(str);
			                                }
			                                rows.push({
			                                   cells: crow
			                                })
			                                crow =[];
			                            }
		                            })
		                        }
					        }

					        return rows;
					    },
					    //添加付款单
					    AddReceipt: function(e) {
					    	
					    	//var dt = new Date();
					    	let begin_date = new Date();
					    	let end_date   = new Date();
					    	let individual = '';                  
					    
					       	let receiptDate = $("#receiptDate").kendoDatePicker({
					    		format: "yyyy-MM-dd",
					    		value: begin_date
					    	});

					    	end_date.setDate(end_date.getDate() + 3);			      	

					      	let dueDate     =  $("#dueDate").kendoDatePicker({
					      		format: "yyyy-MM-dd",
					      		value: end_date
					      	});

					      	$("#Conditions").kendoTextArea({
				                rows: 8,
				                maxLength:200,
				                placeholder: "Payment is due within 3 days."
				            });

				             $("#Conditions").on('input', function (e) {
				                $('.k-counter-container .k-counter-value').html($(e.target).val().length);
				            });		            

				            let rows = THISPAGE.selectRows(e),
				                row  = {};

				            //var test = [ { product: "Jane", quantity: 2000 }, { product: "Bill", quantity: 2000 } ];     

				            em.receipt = [];    
		                    
				            if (rows.length > 0) {
				            	for (let arr = 1; arr < rows.length; arr++) {

							  		row['id']        =  rows[arr].cells[0].value;
								   	row['dealId']    =  rows[arr].cells[1].value;
					               	row['company']   =  rows[arr].cells[2].value;
					               	row['inboundId'] =  rows[arr].cells[3].value;
					            	row['product']   =  rows[arr].cells[4].value;
					            	row['individual']=  rows[arr].cells[5].value;
		                            row['upc']       =  rows[arr].cells[6].value;
		                            row['quantity']  =  rows[arr].cells[7].value;
		                            row['price']     =  rows[arr].cells[8].value;
					            	row['amount']    =  rows[arr].cells[9].value;
					            	row['tracking']  =  rows[arr].cells[10].value;
					            	row['status']    =  rows[arr].cells[11].value;
					            	row['tax']       =  0;

					            	em.receipt.push(row);
					            	individual = row['individual'];
					            	//em.receiptForm.value.IndividualUser = row['individual'];
					            	
					            	row = {};
					            
					            }

				            };

				            const receiptNumber = em.getReceiptNumber(individual);

				            em.receiptForm.patchValue({			      		
				  			    IndividualUser: individual,
				  				receiptDate: em.formatDate(begin_date),
				  				DueDate: em.formatDate(end_date),
				  				ConditionsValue:''
					        });

				            //em.receiptForm.patchValue({
				            //	IndividualUser: individual
					        //});  

				            //em.receiptForm.value.refresh;
					    	em.payfields = [

			  	                {
							      field: "product",
							      title: "Product" ,
							      width: 400
							    }, {
							      field: "quantity",
					              title: "Qty",
					              width: 80
							    }, {
							  	  field: "price",
					              title: "Unit Price",
					              width: 80
							    } , {
							      field: "amount",
					              title: "Amount",
					              width: 80,
					              aggregates: ["sum"],footerTemplate:"Subtotal: #=sum#"
							    }, {
							      field: "tax",
					              title: "Tax",
					              width: 80
							    }
							   
						    ];

						    em.isAdd = 'add';

					    	$("#newreceipt").kendoGrid({					  
								  columns: em.payfields, 
								  editable: true, 
								  toolbar: ["create"],
								  height: 500,
								  //dataSource: em.receipt, //[ { product: "Jane", quantity: 2000 }, { product: "Bill", quantity: 2000 } ]						  
								  dataSource: {
								  	data:em.receipt,
								  	autoSync: true,
								  	schema: {
				                        model: {
				                            id: "id",
				                            fields: {
				                            	id: { editable: false, nullable: true },
				                                product: { editable: false,validation: { required: true } },
				                                price: { editable: false,field: "price", type: "number"},
				                                quantity: { editable: false,field: "quantity", type: "number"},
				                                amount:{ field: "amount", type: "number"},
				                                tax: { field: "tax", type: "string"}
				                            }
				                        }
				                    },
				                    aggregate: [{field: "confirmed", aggregate: "sum"},
				                                {field: "amount", aggregate: "sum"}]
								  }
								 
								});

					    }

				    };		   
			 	
			 	THISPAGE.init();

			})(jQuery);  
		  } //end CreatePaymentGrid
		  */
  //begin CreateReceiptGrid

  CreateReceiptGrid() {
  
    let em =this;
    if (em.iscompany) {
    	this.receiptFields = [
            {hidden: true, field: "receiptNumber" },
	        {field: "individual",title: "Individual",width: 60},
	        {field: "receiptNumber",title: "receipt #",width: 40},
  	        {field: "receiptDate",title: "Receipt Date",width: 60},	              
	        {field: "dueDate",title: "Due Date",width: 60},	
	        {field: "subtotal",title: "amount",width: 80,aggregates: ["sum"],footerTemplate:"Total: #=sum#"},
	        {field: "terms",title: "Terms & Conditions",width: 180},
	        {command:[{ text:  "Edit", click: em.Payment ,iconClass: "k-icon k-i-edit"},{ text:  "Delete", click: em.Payment ,iconClass: "k-icon k-i-delete"}], title: "Actions", width: 110 }
	    ];
     } else {
    	this.receiptFields = [
            {hidden: true, field: "receiptNumber" },
	        {field: "receiptNumber",title: "receipt #",width: 40},
  	        {field: "receiptDate",title: "Receipt Date",width: 60},	              
	        {field: "dueDate",title: "Due Date",width: 60},	
	        {field: "subtotal",title: "amount",width: 80,aggregates: ["sum"],footerTemplate:"Total: #=sum#"},
	        {field: "terms",title: "Terms & Conditions",width: 180},
	        {command:{ text: "View", click: em.Payment ,iconClass: "k-icon k-i-detail-section"}, title: "Actions", width: 70 }
	    ];

     } 

  	(function ($) {
        		
        let THISRECEIPT = {

        	init: function() {
			   this.loadGrid();
			   this.initDom();         
			},

			loadGrid: function() {

				$("#receiptGrid").kendoGrid({
					toolbar: ["search"],  
		            search: {
		            	fields: ["receiptNumber","receiptDate","dueDate",em.iscompany ? "individual" : "","terms"]
		            },
			        columns: em.receiptFields,
			        dataSource: {
			            data: em.receiptList,
			            schema: {
	                        model: {
	                            id: "receiptNumber",
	                            fields: {
	                            	receiptNumber: { editable: false, nullable: true },
	                                individual: { editable: false, type: "string" },
	                                receiptDate: { editable: false,field: "receiptDate"},
	                                dueDate: { editable: false,field: "dueDate"},
	                                subtotal: { editable: false,field: "subtotal", type: "number"}
	                              
	                            }
	                        }
	                    },
	                    pageSize: 10,
	                    aggregate: [{field: "subtotal", aggregate: "sum"}]	                                
			        },			        
			        pageable: {
			        	pageSizes: [5, 20, 30, 50, 100, "all"],
	                    numeric: false,
	                    input: true,
	                    refresh: true,
	                }//,
			        //selectable: "multiple, row" 多行选择
			       
			    });

			    
			},

			initDom:function(e) {

				$(".k-grid-Edit").click(function(e) {
				    e.preventDefault();
				    //console.log('123');
				    THISRECEIPT.editReceipt(e);
				});
				$(".k-grid-View").click(function(e) {
				   	e.preventDefault();
				   	THISRECEIPT.editReceipt(e);
				});
				$(".k-grid-Delete").click(function(e) {
				   	e.preventDefault();
				   	THISRECEIPT.DelReceipt(e);
				});
			},

			editReceipt: function(e) {

			    var grid = $("#receiptGrid").data("kendoGrid");	
            	var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));


	            if (dataItem.id != "") {
	            	em.setReceiptChange(dataItem.id,dataItem.individual);
	            }

	            $("#openReceipt").click(); 
	            
			},

			DelReceipt: function(e) {

				var grid = $("#receiptGrid").data("kendoGrid");	
            	var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
	            if (dataItem.id != "") {
	            	this.paymentService
	            	   .DelReceipt(dataItem.id,dataItem.individual)
				       .pipe(first())
				       .subscribe(data => {
				      	 //return str;        
				    });
	            }

			}
           
	    } //end THISRECEIPT

	    THISRECEIPT.init();

	})(jQuery);

  } //end CreateReceiptGrid

  //保存数据  
  async saveReceipt(email) {

  	
    let grid = $("#newreceipt").getKendoGrid(),
        data = grid.dataSource.options.data,
        ts   = this,
        confirmedQty = {};
    

    if (data.length>0) {

      //confirmedQty = await ts.getInboundConfirmBill(data[0].dealId,data[0].inboundId);

      ts.payments.inboundId     = data[0].inboundId;
      ts.payments.dealId        = data[0].dealId;
      ts.payments.receiptNumber = ts.receiptForm.value.ReceiptNumber;
      ts.payments.company       = data[0].company;
      ts.payments.individual    = data[0].individual;
      //ts.payments.billQty       = confirmedQty[0].billQty === null ? 0 : confirmedQty[0].billQty ;
      //ts.payments.confirmedQty  = confirmedQty[0].confirmedQty === null ? 0 : confirmedQty[0].confirmedQty ;

      ts.payments.paidQty       = data[0].paidQty;
      ts.payments.billTotal     = ts.receiptForm.value.BillTotal;
      ts.payments.confirmedTotal= 0;
      ts.payments.paidTotal     = ts.receiptForm.value.BillTotal;
      ts.payments.adjustment    = ts.receiptForm.value.Adjustment;
      
      
      
      ts.payments.description   = ts.receiptForm.value.ConditionsValue;
      ts.payments.note          = ts.receiptForm.value.note;
      
      ts.payments.isDelete      = 0;
      ts.payments.receiptDate   = ts.receiptForm.value.receiptDate;
      ts.payments.id            = data[0].paymentId;
      
      if (ts.iscompany && data[0].status < 2) { 
      	ts.payments.status      = 1;
      } else {
      	ts.payments.status      = 0;
      }

      if (email == 'send') {
      	ts.exportData(data);
      	//ts.updatePayments(ts.payments,'send');    //取消发送邮件  
      } else {
      	ts.updatePayments(ts.payments,'nosend');
      }

      

      //if (email == 'send') {
      //	ts.exportData(data);
      	/*
      	for (const col of data) {
	      //data.forEach(function (col, index) {
	        //id、inboundId、dealId、paymentId、company、individual、upc、confirmedQty、confirmedPrice、billQty、billPrice、 paidQty、paidPrice、amount、status、isDelete
	        
	        ts.inboundPayments.inboundId     = col.inboundId;
	        ts.inboundPayments.dealId        = col.dealId;
	        ts.inboundPayments.paymentId     = col.paymentId;    //['id'];
	        ts.inboundPayments.company       = col.company;
	        ts.inboundPayments.individual    = col.individual;
	        ts.inboundPayments.upc           = col.upc;
	        
	        ts.inboundPayments.billQty       = ts.payments.billQty;
	        ts.inboundPayments.confirmedQty  = ts.payments.confirmedQty;
	        ts.inboundPayments.paidQty       = col.paidQty;

	        ts.inboundPayments.confirmedPrice= col.paidPrice;
	        ts.inboundPayments.billPrice     = col.paidPrice;
	        ts.inboundPayments.paidPrice     = col.paidPrice;
	        
	        ts.inboundPayments.amount        = col.amount; 
	        ts.inboundPayments.status        = 0; 
	        ts.inboundPayments.isDelete      = 0;

	        //ts.CreateInboundPayments(ts.inboundPayments);
	    }*/
      //}


    }
    
 }
 //日期转字符串
 formatDate(date) {

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
 }

 setReceiptChange(receiptNumber,individual) {
     let em = this;
     let readonly = em.iscompany ? false : true;
 	 this.paymentService
      .setReceiptChange(receiptNumber,individual)
      .pipe(first())
      .subscribe(data => {
      	if (data.length>0) {
      		
      		let row ={};
      		em.receipt = [];
      		em.isAdd = 'edit';

      		em.payfields = [
      		     {
			        field: "id",				    
				    hidden: true
				},{
			        field: "product",
				    title: "Product" ,
				    width: 400
				}, {
				    field: "quantity",
		            title: "Qty",
		            width: 80
				}, {
				    field: "price",
		            title: "Unit Price",
		            width: 80
				} , {
				    field: "amount",
		            title: "Amount",
		            width: 80,
		            aggregates: ["sum"],footerTemplate:"Subtotal: #=sum#"
				}, {
				    field: "tax",
		            title: "Tax",
		            width: 80
				}
			];
      		
      		/*
      		em.receiptForm.patchValue({
  			    IndividualUser: data[0].individual,
  				receiptDate: em.formatDate(data[0].receiptDate),
  				DueDate: em.formatDate(data[0].dueDate),
  				ReceiptNumber: data[0].receiptNumber,
  				ConditionsValue:data[0].terms
	        });*/

	        let receiptDate = $("#receiptDate").kendoDatePicker({
			    format: "yyyy-MM-dd",
			    readonly: readonly,
			    value: em.formatDate(data[0].receiptDate)
			});
			let dueDate     =  $("#dueDate").kendoDatePicker({
				format: "yyyy-MM-dd",
				readonly: readonly,
				value: em.formatDate(data[0].dueDate)
			});

			$("#Conditions").kendoTextArea({
                rows: 8,
                maxLength:200,
                readonly: readonly,
                placeholder: "Payment is due within 3 days."
            });

            if (readonly) {
            	$("#saveReceipt").hide();
            }

      		data.forEach(function(item){
      			

		        row['id']        =  item.id;
				row['dealId']    =  item.dealId;
	            row['company']   =  item.company;
	            row['inboundId'] =  item.inboundId;
	            row['product']   =  item.product;
	            row['individual']=  item.individual;
                row['upc']       =  item.upc;
                row['quantity']  =  item.quantity;
                row['price']     =  item.price;
	            row['amount']    =  item.amount;
	            row['tracking']  =  item.tracking;
	            row['status']    =  item.status;
	            row['tax']       =  item.tax;


	            em.receipt.push(row);
	            	
	            row = {};
      		})

      		$("#newreceipt").kendoGrid({      			
            	columns: em.payfields, 
				editable: true, 
				toolbar: ["create"],
				height: 500,
				dataSource: {
					data:em.receipt,
				  	autoSync: true,
				  	schema: {
                        model: {
                            id: "id",
                            fields: {
                            	id: { editable: false, nullable: true },
                                product: { editable: false,validation: { required: true } },
                                price: { editable: false,field: "price", type: "number"},
                                quantity: { editable: false,field: "quantity", type: "number"},
                                amount:{ field: "amount", type: "number",editable: !readonly},
                                tax: { field: "tax", type: "string",editable: !readonly}
                            }
                        }
                    },
                    aggregate: [{field: "confirmed", aggregate: "sum"},
                                {field: "amount", aggregate: "sum"}]
				}					  
				 
			})
      	} //end if                  
      });


 }

 select(i) {

 	const em = this;
	const readonly = em.iscompany ? true : false;

	const receiptNumber = this.recordList[i].receiptNumber;
	const individual    = this.recordList[i].individual;

	this.paymentService
	    .getPaymentList(receiptNumber,individual)
	    .pipe(first())
	    .subscribe(data => {
	  	if (data.length>0) {
	  		
	  		let row ={};
	  		em.payment = [];
	  		em.isAdd = 'edit';

	  		this.payfields = [
		      {
		        field: "product",
		        title: "Product" ,
		        width: 350
		      }, {
		        field: "upc",
		        title: "upc",
		        width: 100
		      }, {
		        field: "paidQty",
		        title: "Paid Qty",        
		        width: 90,        
		        //template: "#if (paidQty > CheckpaidQty) { amount=CheckpaidQty * paidPrice; #<span>#: CheckpaidQty #<span>#} else { amount=paidQty * paidPrice;#<span>#: paidQty #<span>#}#",
		        aggregates: ["sum"],footerTemplate:"paidQty: #=sum#"
		      }, {
		        field: "paidPrice",
		        title: "Paid Price",
		        format: "{0:c}",
		        width: 90
		      } , {
		        field: "amount",
		        title: "Amount",
		        format: "{0:c}",
		        width: 90,
		        aggregates: ["sum"],footerTemplate:"paidTotal: #=kendo.toString(sum,'0.00')#"
		      },
		      {
		        field:"CheckpaidQty",
		        hidden:true
		      },
		      {
		        field:"id",
		        hidden:true
		      }
		     
		    ];
	  		
	  		em.receiptForm.patchValue({
				    IndividualUser: data[0].individual,
					receiptDate: em.formatDate(data[0].receiptDate),
					//DueDate: em.formatDate(data[0].dueDate),
					ReceiptNumber: data[0].receiptNumber,
					ConditionsValue:data[0].description,
					note:data[0].note,
					BillTotal:data[0].billTotal,
	                Adjustment: parseFloat(data[0].adjustment)
	        });

	        let receiptDate = $("#receiptDate").kendoDatePicker({
			    format: "yyyy-MM-dd",
			    readonly: readonly,
			    value: em.formatDate(data[0].receiptDate)
			});

			//if (em.iscompany && this.recordList[i].status === 0) {
			//	document.getElementById('saveReceipt').innerText  = 'Confirm';
			//	$("#saveReceiptSend").hide();
				//document.getElementById('saveReceipt').textContent= 'Confirm';
				//(<HTMLInputElement>document.querySelector('#saveReceipt')).value = 'Confirm';
			//} 						

	        if (!em.iscompany && this.recordList[i].status !== 0) {
	        	$("#saveReceipt").hide();
	        	$("#saveReceiptSend").hide();
	        } else if (em.iscompany && parseInt(this.recordList[i].status)<2) {
	        	$("#saveReceipt").show();	        	
	        	//document.getElementById('saveReceipt').innerText  = 'Edit';
	        	$("#saveReceiptSend").hide();
	        } else if (em.iscompany && this.recordList[i].status === 2) { 
	        	//document.getElementById('saveReceipt').innerText  = 'Edit';
				$("#saveReceipt").hide();
				$("#saveReceiptSend").hide();
	        } else {
	        	$("#saveReceipt").show();
	        	$("#saveReceiptSend").show();
	        }

	  		data.forEach(function(item){

				row['dealId']    =  item.dealId;
	            row['company']   =  item.company;
	            row['inboundId'] =  item.inboundId;
	            row['product']   =  item.inbound.product;
	            row['individual']=  item.individual;
	            row['upc']       =  item.inboundPayment == null ? '' : item.inboundPayment.upc;
	            //row['quantity']  =  item.quantity;

       	        row['id']        =  item.inboundPayment == null ? 0 : item.inboundPayment.id;
	            row['paidQty']   =  item.inboundPayment == null ? 0 : item.inboundPayment.paidQty;
		        row['paidPrice'] =  item.inboundPayment == null ? 0 : item.inboundPayment.paidPrice;
		        row['amount']    =  item.inboundPayment == null ? 0 : item.inboundPayment.amount;
	            row['paymentId']  = item.inboundPayment == null ? 0 : item.inboundPayment.paymentId;

	            //row['price']     =  item.price;
	            row['status']    =  item.status;

	            row['CheckpaidQty'] = row['paidQty']; //item.paidQty;

	            em.payment.push(row);
	            	
	            row = {};
	  		})

	  		$("#newreceipt").kendoGrid({
	  		    columns: this.payfields, 
                editable: true, 
                //toolbar: ["create"],
                height: em.iscompany ? 575 : 505,
                //selectable: "multiple, row",      			
	        	dataSource: {
					data:em.payment,
				  	//autoSync: true,
				  	schema: {
	                    model: {
	                        id: "id",
	                        fields: {
	                        	id: { editable: false, nullable: true },
	                            product: { editable: false,validation: { required: true } },
	                            paidPrice: { editable: false,field: "paidPrice", type: "number"},
	                            paidQty: { editable: false,field: "paidQty", type: "number"},
	                            amount:{ field: "amount", type: "number",editable: false},
	                            upc: { field: "upc", type: "string",editable: false}
	                        }

	                    }
	                },
	                aggregate: [{field: "paidQty", aggregate: "sum"},
	                            {field: "amount", aggregate: "sum",format: "{0:c}"}]
				},
				dataBound: function (e) {
                    /*
                    //if (e.field && e.action == "itemchange") {
                    let grid = $("#newreceipt").data("kendoGrid");
                    //let data = grid.dataSource.options.data;
                    //let model = e.items[0];
                    let items = grid.dataSource.options.data;//this.view();
                    let amount= 0.00;
                    let qty = 0;

                    for (var idx = 0; idx < items.length; idx++) {
                      var item = items[idx];
                      //this.receipt[idx].amount =  (this.receipt[idx].paidQty * this.receipt[idx].paidPrice).toFixed(2);
                      var paidQty = item.paidQty > item.CheckpaidQty ? item.CheckpaidQty : parseInt(item.paidQty); 
                      //e.items[idx].paidQty = paidQty;
                      qty += paidQty;
                      amount += paidQty * item.paidPrice;   // parseFloat(item.amount);
                    }

                   
                    //this.aggregates().amount.sum = amount.toFixed(2);
                    //this.aggregates().paidQty.sum = qty;

                    (<HTMLInputElement>document.getElementById('BillTotal')).value = amount.toFixed(2); //.toString();

                    em.receiptForm.patchValue({
                      BillTotal:amount.toFixed(2)                      
                    });   
                    
                    //if (typeof(grid.footer) != "undefined") {
                    //  grid.footer.find(".k-footer-template").replaceWith(grid.footerTemplate(this.aggregates()));
                    //}
                   
                  //} */         
                }					  
				 
			})
	  	} //end if                  
	});
 }

 updatePayments(payment,isemail) {
	 let em = this;
	 this.paymentService
        .UpdatePayments(payment,isemail)
        .pipe(first())
        .subscribe(
          data => {
          	if (this.authenticationService.currentUserValue[2] === 'company') {
              this.privilege = true ;  
              //this.getRecords(this.currentUser,this.iscompany);
            } else {
              this.privilege = false;
              //this.getRecords(this.currentUser,this.iscompany);
            }          	
          	this.getRecords(this.currentUser,this.iscompany);

            
          }          
      )
 }

 //统计总页数
  PageCount(individual,iscompany,filter) {
    //debugger;
    this.paymentService
      .PageCount(individual,iscompany,filter)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }

  //搜索过虑 userEmail 账号 filter 搜索值  个人用户
  SearchList(userEmail,iscompany,filter) {
    this.paymentService
      .SearchList(userEmail, this.pageSize , 0 , filter,iscompany)
      .pipe(first())
      .subscribe(data => {
        this.recordList = data;
      });
  }

   //Kendo页更改函数
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    /*
    if (this.authenticationService.currentUserValue[2] === 'company') {
      this.getRecords(this.currentUser,this.iscompany);
    } else {
      this.getRecords(this.currentUser,this.iscompany);
    }*/
    this.getRecords(this.currentUser,this.iscompany);

     
    //debugger;
    //this.pageData();
  }
  //删除资料
  DeletePayment(content,i) {

  	const id = this.recordList[i].id;
  	this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      this.closeResult = `Closed with: ${result}`;  
      if (result === 'yes') {  
        this.paymentService
            .Delete(id)
            .pipe(first())
            .subscribe(data => {
            	if (this.authenticationService.currentUserValue[2] === 'company') {
	               this.privilege = true ;  
	               //this.getRecords(this.currentUser,this.iscompany);
	            } else {
	               this.privilege = false;
	               //this.getRecords(this.currentUser,this.iscompany);
	            }
                this.getRecords(this.currentUser,this.iscompany);
     
        });  
      }  
    }, (reason) => {  
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;  
    });
  	
  }

  getDismissReason(reason: any): string {  
    if (reason === ModalDismissReasons.ESC) {  
      return 'by pressing ESC';  
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {  
      return 'by clicking on a backdrop';  
    } else {  
      return `with: ${reason}`;  
    }  
  }

  exportData(data) {

  	let rows =[],
  	    row  =[],
  	    Total=0.00,
  	    frow =[];

  	let str = '';
  	let stra= {};
  	str = JSON.parse("{\"value\":\"Date\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"Receipt Number\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"Inbound\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"Product Name\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"UPC\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"Quantity\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"Price\"}");
  	frow.push(str);
  	str = JSON.parse("{\"value\":\"Total\"}");
  	frow.push(str);

  	let filename = 'PAYMENT';

  	rows.push({
  		cells: frow
    })


  	if (data.length>0) {
  		for (const col of data) {
  			
  			str = JSON.parse("{\"value\":\"" + this.receiptForm.value.receiptDate + "\"}");
  			row.push(str);
  			str = JSON.parse("{\"value\":\"" + this.receiptForm.value.ReceiptNumber + "\"}");
  			row.push(str);
  			str = JSON.parse("{\"value\":\"" + col.inboundId + "_" + col.individual  + "\"}");
  			row.push(str);
  			//str = JSON.parse("{\"value\":\"" + col.product + "\"}");
            stra = {"value":col.upc};
  			row.push(stra);
  			str = JSON.parse("{\"value\":\"" + col.upc + "\"}");
  			row.push(str);
  			str = JSON.parse("{\"value\":\"" + col.paidQty + "\"}");
  			row.push(str);
  			str = JSON.parse("{\"value\":\"" + col.paidPrice.toFixed(2) + "\"}");
  			row.push(str);
  			str = JSON.parse("{\"value\":\"" + col.amount.toFixed(2) + "\"}");
  			row.push(str);

  			Total = Total + parseFloat(col.amount);

  			rows.push({
                cells: row
            })
  			//row['paymentId']  = col.paymentId
	        //row['paidQty']    = col.paidQty;
	        //row['paidPrice']  = col.paidPrice; 
	        //row['amount']     = col.amount;
	        //row['product']    = col.product;
	        //rows.push(row);        


	        row = [];	    
	    }

	    row = [{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}];
      
        rows.push({
          cells: row
        });

        row = [{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:'Sum'},{value:Total.toFixed(2)}];

        rows.push({
          cells: row
        });

	    filename = filename + '-' + data[0].inboundId + '.xlsx'; 

  	    this.excelExport(rows,filename);
  	} 	

  }

  //导出到Excel
  excelExport(rows,filename){

  	var workbook = new kendo.ooxml.Workbook({
        sheets: [
            {
              /*
              columns: [
                { autoWidth: true },
                { autoWidth: true }
              ],*/
              columns: [ { width: 80 }, { width: 80 }, { width: 100 },{ width: 300 } , { width: 100 },{ width: 80 },{ width: 80 },{ width: 80 } ],
              title: "payment",
              rows: rows
            }
        ]
    });
    kendo.saveAs({
    	dataURI: workbook.toDataURL(), 
        //charset="utf-8",
    	fileName: filename
    	//proxyURL: "/home/data/release/weapp/markeapp/public",
    	//forceProxy: true
    });
  }

  setStatus(i,status) {

	const id = this.recordList[i].id;

	this.paymentService
	    .setStatus(id,status)
	    .pipe(first())
	    .subscribe(result => {

	    	if (this.iscompany) {
               this.privilege = true ;  
               //this.getRecords(this.currentUser,this.iscompany);
            } else {
              this.privilege = false;
              //this.getRecords(this.currentUser,this.iscompany);
            }
            this.getRecords(this.currentUser,this.iscompany);
     
	
	})

  }
 
}

