import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Map } from '../models/map';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class MapService {
  createMapUrl = environment.apiUrl + 'maps/map'; // URL to web api
  allUrl = environment.apiUrl + 'maps/allMaps';
  deleteMapUrl = environment.apiUrl + 'maps/deleteMap';
  constructor(private http: HttpClient) {}

  createMap(map: Map): Observable<Map> {
    return this.http.post<Map>(this.createMapUrl, map, httpOptions);
  }

  allMap(productId: number): Observable<string> {
    const productIdJSON = '{ "productId" : ' + '"' + productId + '"' + ' }';

    return this.http.post<string>(
      this.allUrl,
      JSON.parse(productIdJSON),
      httpOptions
    );
  }

  deleteMap(productId: number): Observable<string> {
    const productIdJSON = '{ "productId" : ' + '"' + productId + '"' + ' }';
    return this.http.post<string>(
      this.deleteMapUrl,
      JSON.parse(productIdJSON),
      httpOptions
    );
  }
}
