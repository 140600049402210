<div class="container">
  <h1>Bill info</h1>
  <hr/>
  <ul class="nav nav-tabs" id="dealTabs" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active bg-light"
        id="active-tab"
        data-toggle="tab"
        routerLink="active"
        role="tab"
        aria-controls="active"
        aria-selected="true"
        >Back</a
      >
    </li>
  </ul>
  <br />
  <div class="float-right my-2">
    <button
      type="button"
      class="btn btn-outline-success btn-block"
      data-toggle="modal"
      data-target="#billCreateFrom"
      data-whatever="@mdo"
    >
      Create
    </button>
  </div>
  <br />
  <table class="table table-hover" id="addressTable">
      <thead class="thead-light">
        <tr>
          <th scope="col" style="width: 10%" class="hide">#</th>
          <th scope="col" style="width: 45%">Bank Name</th>
          <th scope="col" style="width: 45%">Account No</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let bill of billList; index as i">
          <tr>
            <th scopr="row" class="hide">{{ ('000' + bill.id).slice(-4) }}</th>
            <td>
              {{ bill.bank }}
            </td>
            <td>
              {{ bill.account }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  <div
    class="modal fade"
    id="billCreateFrom"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bill Create Form</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="bank">bank:</label>
              <input type="text" class="form-control" id="bank" name="bank" [(ngModel)]="bank"/>
            </div>
            <div class="form-group">
              <label for="account">account:</label>
              <input type="text" class="form-control" id="account" name="account" [(ngModel)]="account"/>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  (click) = "create()"
                >
                  Add
                </button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
