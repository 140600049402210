<div ng-controller="signInFormController">
  <form class="form-signin" (ngSubmit)="onSubmit()" #userForm="ngForm">
    <div class="text-center mb-4">
      <h2>SignIn</h2>
    </div>
    <div class="alert alert-warning" role="alert" *ngIf="error">
        {{ error }}
    </div>
    <div class="form-label-group">
      <input
        type="text"
        class="form-control"
        id="email"
        required
        [(ngModel)]="user.email"
        name="email"
        #email="ngModel"
      />
      <label for="inputEmail">Email address</label>
    </div>
    <div class="form-label-group">
      <input
        type="password"
        class="form-control"
        id="password"
        required
        [(ngModel)]="user.password"
        name="password"
        #password="ngModel"
      />
      <label for="inputPassword">Password</label>
    </div>
    <!-- <div class="checkbox mb-3">
      <label> <input type="checkbox" value="remember-me" /> Remember me </label>
    </div> -->
    <button class="btn btn-lg btn-primary btn-block" type="submit">
      Sign in
    </button>
    <a class="card-link" routerLink="/req-reset-password"
              >Forgot Password ?
    </a>
  </form>
</div>
