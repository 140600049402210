<div class="container">
    <h3>Warehouse Policy</h3>
    <hr>
    <div class="float-center">
<p> 
If you are shipping to the warehouse, to avoid serious damage during transit that may cause the goods inside the box to be damaged or lost, please make sure to pack carefully:
</p>
<p>
Please do not skimp on the cost of boxes. It is better to buy new boxes from stores like HD and LOWES, preferably heavy-duty ones. Do not use lower quality boxes like those from Walmart.
If the size of the box is not suitable, please cut and trim it appropriately. The box must be packed tightly without leaving space to ensure the contents do not shake inside. Space inside the box is one of the main reasons for damage during transportation due to compression and collision.
</p>
<p>
For packing, please use heavy-duty wide tape. It’s not necessary to wrap it like a mummy, but please reinforce all the edges with tape. The package should be bound in at least three dimensions. Otherwise, it can easily fall apart during transit, which is another common cause of box damage.
If the loss or damage of goods is due to packing issues, we will not provide compensation.
Please ensure that the goods are brand new and undamaged.
When dropping off, remember to get a receipt.
</p>
<p>
If you are delivering directly to the warehouse, please ensure you have the consent of the receiving supervisor beforehand and deliver within the specified time (usually within 2 business days). Ensure the goods are brand new and undamaged.
If sending pallets to the warehouse, make sure all goods are tightly wrapped, preferably reinforced with edge protectors. Retain a photo of the pallet upon dispatch. Delivery to the warehouse must be between Monday to Friday, 10:00 AM to 4:00 PM.
Please confirm within 90 days of the goods’ arrival. Beyond 90 days, we will deduct 25% of the goods’ value, 50% after 120 days, 75% after 150 days, and after 180 days without confirmation, the goods will automatically enter the disposal process.
</p>
<p>
For packages delivered to the warehouse but not confirmed, please contact customer service 3 business days after delivery (this may be extended to 7-14 days during peak season) and provide relevant order information screenshots, including but not limited to Tracking #, Item Name, Purchase Price, Order Date, Ship to Address, proof of delivery, etc. After submitting the information, please allow us 3 business days (this may be extended to 7-14 days during peak season) to respond.
</p>
<p>
If the delivery address or signature does not match our warehouse address, please contact your vendor or carrier directly. The warehouse is not responsible for locating it.
Currently, we only compensate for packages signed and received by the majority during regular working hours with UPS. For packages from other carriers, the warehouse will actively cooperate in locating them.
If the number of items received in the package at the warehouse does not match the order quantity, the warehouse will provide camera data. The warehouse will only provide photos, not videos. The processing period is about 3-7 days. The warehouse will not inquire about the camera data for goods delivered over 60 days.
</p>
    </div>
</div>

