
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { GroupService } from '../../../services/group.service';
//import { first } from 'rxjs/operators';
@Component({
  selector: 'app-warehouse-policy',
  templateUrl: './warehouse-policy.component.html',
  styleUrls: ['./warehouse-policy.component.scss'],
  providers: [AuthenticationService]
})
export class WarehousePolicyComponent implements OnInit {
  public privilege: any;
  public currentUser: any;
  public userEmail: any;
  public company: any;

  constructor(
    private authenticationService: AuthenticationService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.userEmail = this.authenticationService.currentUserValue[4];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
      } else {
        this.privilege = false;
      }
    }
  }


}


