import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';


const BASEURL =  environment.apiUrl;
const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //countUrl = environment.apiUrl + 'bills/count';

  
  constructor(private http: HttpClient) { }

  registerUser(body): Observable<any> {
    return this.http.post(`${BASEURL}/register`, body);
  }

  loginUser(body): Observable<any> {
    return this.http.post(`${BASEURL}/login`, body);
  }

  requestReset(body): Observable<any> {
  	//console.log(`${BASEURL}users/req-reset-password`);
    return this.http.post<string>(`${BASEURL}users/req-reset-password`, body, httpOptions);
  }

  newPassword(body): Observable<any> {
    return this.http.post<string>(`${BASEURL}users/response-reset-password`, body, httpOptions);
  }

  ValidPasswordToken(body): Observable<any> {
    return this.http.post<string>(`${BASEURL}users/valid-password-token`, body, httpOptions);
  }
}