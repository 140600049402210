import { Component, OnInit,Input } from '@angular/core';

import { GroupService } from '../../../services/group.service';

import { AuthenticationService } from '../../../services/authentication.service';

import { Group } from '../../../models/group';
import { first } from 'rxjs/operators';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';

declare var $: any;
@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss'],
  providers: [GroupService]
})
export class UserGroupsComponent implements OnInit {
  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';
  @Input('answers') ans:any;

  public currentUser: any;
  public privilege: any;
  public groupList: any;
  public selectedGroup = new Group('', '', '');
  public createGroup = new Group('', '', '');
  public isCreated: any;
  public isDeleted: any;
  public selectedMember: any;
  public allMembers: any;
  public isEditing = false;
  public selectGroupMember: string;
  public selectId: any;

  constructor(
    private groupService: GroupService,
    public authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (!this.ans) return; 
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.groupRetrieve(this.currentUser);
        this.getAll(this.currentUser);
        this.getAllCount(this.currentUser);
      } else {
        this.privilege = false;
        // error handle, should not be there.
      }
    }
  }

  /**
   * Get all the subscribers for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getAll(individual) {
    this.groupService
      .allSubscribers(individual)
      .pipe(first())
      .subscribe(
        data => {
          this.allMembers = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  /**
   * Get the user groups for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  groupRetrieve(individual) {
    this.groupService
      .groupRetrieve(individual,this.filter,this.skip,this.pageSize)
      .pipe(first())
      .subscribe(data => {
        this.groupList = data;
      });
  }

  // create groups
  groupCreate() {
    if (this.createGroup.name) {
      this.createGroup.creator = this.currentUser;
      this.groupService
      .groupCreate(this.createGroup)
      .pipe(first())
      .subscribe(data => {
        this.isCreated = true;
        this.createGroup.clear();
        this.ngOnInit();
      });
    }
  }

  // delete groups
  groupDelete(id, pageId) {
    this.groupService
      .groupDelete(id)
      .pipe(first())
      .subscribe(data => {
        this.groupList.pop(pageId);
        this.isDeleted = true;
        this.ngOnInit();
      });
  }

  groupEdit(id) {
    this.isEditing = true;
  }

  selectGroup(i) {
    this.selectGroupMember = this.groupList[i].member;
    this.selectId = this.groupList[i].id;
  }

  checkExisted(member) {
    if (member && this.selectId) {
      if (this.selectGroupMember.includes(member)) {
        return true;
      } else {
        return false;
      }
    }
  }
  groupMemberAdd(i) {
    const memberAdd = this.allMembers[i].individual;
    let currentMemberArray = [];
    if(this.selectGroupMember!=="") {
      currentMemberArray = this.selectGroupMember.split(',');
    }
    currentMemberArray.push(memberAdd);
    const currentMemberString = currentMemberArray.toString();
    this.groupService
      .updateGroup(this.selectId, currentMemberString)
      .pipe(first())
      .subscribe(
        data => {},
        error => {
          console.log(error);
        },
        () => {
          this.ngOnInit();
        }
      );
  }

  groupMemberDelete(i) {
    const memberAdd = this.allMembers[i].individual;
    const currentMemberArray = this.selectGroupMember.split(',');
    var index = currentMemberArray.indexOf(memberAdd);
    if (index > -1) {
      currentMemberArray.splice(index, 1);
    }
    const currentMemberString = currentMemberArray.toString();
    this.groupService
      .updateGroup(this.selectId, currentMemberString)
      .pipe(first())
      .subscribe(
        data => {},
        error => {
          console.log(error);
        },
        () => {
          this.ngOnInit();
        }
      );
  }

  //统计总页数
  getAllCount(company) {
    this.groupService
      .getAllCount(company,this.filter,'user')
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }

  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.groupRetrieve(this.currentUser);
        this.getAll(this.currentUser);
    } else {
        this.privilege = false;
    } 
  }   

}
