export class Email {
  public emailList: string;
  public emailSubject: string;
  public emailTextBeforeImage: string;
  public emailTextAfterImage: string;
  public dealProduct: number;
  public receiver: string;
  constructor(emailList = null, emailSubject = null, emailTextBeforeImage = null, emailTextAfterImage = null, dealProduct = 0, receiver = null) {
    this.emailList = emailList;
    this.emailSubject = emailSubject;
    this.emailTextBeforeImage = emailTextBeforeImage;
    this.emailTextAfterImage = emailTextAfterImage;
    this.dealProduct = dealProduct;
    this.receiver = receiver;
  }

  clear() {
    this.emailList = null;
    this.emailSubject = null;
    this.emailTextBeforeImage = null;
    this.emailTextAfterImage = null;
    this.dealProduct = null;
    this.receiver = null;
  }
}
