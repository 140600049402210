import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { GroupService } from '../../services/group.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [
    AuthenticationService,
    GroupService
  ]
})
export class UsersComponent implements OnInit {
  public currentUser: any;
  public userLevel: any;
  public userPhone: any;
  public userEmail: any;
  public privilege: any;
  public followingList: any;

  constructor(
    private authenticationService: AuthenticationService,
    private groupService: GroupService,
  ) {}

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.userLevel = this.authenticationService.currentUserValue[2].toUpperCase();
      this.userPhone = this.authenticationService.currentUserValue[3];
      this.userEmail = this.authenticationService.currentUserValue[4];
      // console.log(this.authenticationService.currentUserValue);
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        // this.expiredRetrieve(this.currentUser);
      } else {
        this.privilege = false;
        this.getfollowing(this.currentUser);
      }
    } else {
      // error handle no user
    }
  }

  /**
   * Get the subscription following list when signing as an individual.
   * @author Guozhi Tang
   * @date 2020-05-01
   * @param creator - the username of current user
   */
  getfollowing(creator) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.followingList = data;
          // console.log(this.followingList);
        },
        error => {
          console.log(error);
        }
      );
  }

}
