<div class="mt-5">
  <br />
  <button
    type="button"
    class="btn btn-primary"
    data-toggle="modal"
    data-target="#groupCreateForm"
  >
    Create Group
  </button>
  <hr />
  <div
    class="modal fade"
    id="groupCreateForm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createFrom"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createFrom">Product Create Form</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="recipient-name" class="col-form-label">Member:</label>
              <select
                class="selectpicker"
                multiple
                data-live-search="true"
                name="selectedMember"
                [(ngModel)]="createGroup.member"
              >
                <option *ngFor="let member of allMembers" name="member">
                  {{ member.individual }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="groupName" class="col-form-label">Group Name:</label>
              <input
                class="form-control"
                type="text"
                id="groupName"
                name="groupName"
                [(ngModel)]="createGroup.name"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" (click)="groupCreate()">
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrapper">
<table class="table table-hover" id="GroupTable">
  <thead class="thead-light">
    <tr>
      <th scope="col" class="hide">#</th>
      <th scope="col">Name</th>
      <th scope="col" class="hide">Members</th>
      <th scope="col" class="hide">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let group of groupList; index as i">
      <tr>
        <th scopr="row" class="hide">{{ ('000' + group.id).slice(-4) }}</th>
        <td>
          {{ group.name }}
          <p class="text-primary show">Members: {{ group.member }}</p>

          <button type="button" class="btn btn-outline-success btn-block btn-sm mb-1 show" data-toggle="modal" data-target="#groupEditForm" (click)="selectGroup(i)">
              Edit
            </button>
          <button
            class="btn btn-outline-success btn-block btn-sm mb-1 show"
            (click)="groupDelete(group.id)"
          >
            Delete
          </button>
        </td>
        <td class="hide">
          {{ group.member }}
        </td>
        <td class="hide">
          <button type="button" class="btn btn-outline-success btn-block btn-sm mb-1" data-toggle="modal" data-target="#groupEditForm" (click)="selectGroup(i)">
            Edit
          </button>
          <div>
            <button
              class="btn btn-outline-success btn-block btn-sm mb-1"
              (click)="groupDelete(group.id)"
            >
              Delete
            </button>
            <!-- <button class="btn btn-primary btn-sm" *ngIf="!follower.status" (click)="setActive(i)">Approve</button> -->
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<!--分页实现Begin-->
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  (pageChange)="onPageChange($event)">
</kendo-datapager>
</div>
<div
  class="modal fade"
  id="groupEditForm"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Group Edit Form</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <hr>
        <div class="form-group mt-1">
          <label for="add">Members:</label>
          <ng-container *ngFor="let member of allMembers; index as i">
            <div class="form-group">
              <input type="text" class="form-control" value="{{member.individual}}"readonly>
              <button class="btn btn-primary btn-sm mt-1" *ngIf="!checkExisted(member.individual)" (click)="groupMemberAdd(i)" data-dismiss="modal"> Add </button>
              <button class="btn btn-primary btn-sm mt-1 ml-2" *ngIf="checkExisted(member.individual)" (click)="groupMemberDelete(i)" data-dismiss="modal"> Remove </button>
            </div>

          </ng-container>

          </div>
        <!-- <div *ngIf="delete"></div> -->
      </div>
      <div class="modal-footer">
<!--
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary">Save changes</button>
-->
      </div>
    </div>
  </div>
</div>
