import { Component, OnInit, ElementRef ,OnChanges, SimpleChanges, Input} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, EmailValidator, FormArray } from '@angular/forms';
import { Deal } from '../../../models/deal';
import { DealService } from '../../../services/deal.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { ProductService } from '../../../services/product.service';
import { GroupService } from '../../../services/group.service';
import { Inbound } from '../../../models/inbound';
import { InboundService } from '../../../services/inbound.service';
import { LinkifyPipe } from '../../../custom-pipes/linkify.pipe';
import { KeysPipe } from '../../../custom-pipes/keys.pipe';
import { StorageService } from '../../../services/storage.service';
import { Email } from '../../../models/email';
import { Router,ActivatedRoute } from '@angular/router';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';

//
import { AlertService } from '../../../_alert';


declare var $: any;

@Component({
  selector: 'app-active-deals',
  templateUrl: './active-deals.component.html',
  styleUrls: ['./active-deals.component.scss'],
  providers: [
    DealService,
    AuthenticationService,
    ProductService,
    GroupService,
    InboundService,
    LinkifyPipe,
    StorageService,
    KeysPipe
  ]
})
export class ActiveDealsComponent implements OnInit {
    // private activeDeal: Deal;

    options = {
        autoClose: false, //true Auto close alert after three seconds
        keepAfterRouteChange: true //true Keep displaying after one route change
    };

    public pageSize = 20; //每页显示条数
    public skip = 0; //页号
    public pagedDestinations = [];
    public total = 0; //destinations.length; //总条数
    public filter = '';
    public oldfilter = '';
    public IsCompany = false; 
    public currentUser :any ;
    @Input('answers') ans:any;
    //@Input() test: any;
    public temtor = '';
    public type = 'input';
    

    public dealList = new Array();
    public creator: string;
    public privilege: any;
    public followingList: any;
    public hasNext: any;
    public isCreated = false;
    public inbound = new Inbound('', '', '', null, '', '', '', null, null, null, true,null);
    public selectProductId: any;
    public noFollowing: any;
    public selectDeal = new Deal(
      null,
      '',
      null,
      null,
      '',
      '',
      false,
      false,
      false,
      false,
      ''
    );
    public publicWarehouse: any;
    public privateWarehouse: any;
    public isUpdated: any;
    public selfWarehouse = false;
    public isAwards = false;
    public awardsUsers: any;
    public awards = 0;
    public isUpdateAwards: boolean;
    public month: any;
    public days: any;
    public year: any;
    public hour: any;
    public minutes: any;
    public isPublic: any;
    public isPrivate: any;
    public memberList: any;
    public groupList: any;
    public membersTemp: any;
    public dealCreator: any;
    public selectPrivateWarehouse: any;
    public selectPublicWarehouse: any;
    public awardsUsersUnique = new Array();
    public awardsUsersUniqueQty = new Array();
    public checkQty: any;
    public email = new Email();
    public receiver: any;
    public documentId: number;
    public isngOnInit: boolean;
    /*
    public PackageSizeForm: FormGroup;
    public packlist : FormGroup;
    public packageList = [];
    */

    arrayItems: {
      id: number;
      long: number;
      wide: number;
      high: number;
      weight: number;
      title: string;
    }[];

  constructor(
    private dealService: DealService,
    private authenticationService: AuthenticationService,
    private groupService: GroupService,
    private inboundService: InboundService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private elem: ElementRef,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
  ) {
    route.params.subscribe(params => {
      if (params['Id']) {
        this.documentId = params['Id'];
      }     
    });
    /*
    this.PackageSizeForm = this._formBuilder.group({
         packageArray: this._formBuilder.array([])
    });*/
  }

  ngOnDestory() {
    //debugger;
  }

  ngAfterViewInit() {
    //debugger;
  }

  ngOnChanges(changes: SimpleChanges) {
    //debugger;
  }

  ngOnInit() {
    if (!this.ans) return;

    try {
      if (this.authenticationService.currentUserValue) {
        // console.log(this.authenticationService.currentUserValue);
        this.creator =     this.authenticationService.currentUserValue[0];
        this.receiver =    this.authenticationService.currentUserValue[4];
        this.currentUser = this.authenticationService.currentUserValue[0];

        this.IsCompany =  this.authenticationService.currentUserValue[2] === 'company' ? true : false  ;
        if (this.authenticationService.currentUserValue[2] === 'company') {
          this.enableCreate();
          this.privilege = true;
          this.getActiveList(this.creator);
          this.getAllCount(this.currentUser,this.currentUser,this.filter,'ActiveDeals',this.IsCompany);
          //let ll = document.getElementById('dealLists_router');
          let bb = document.getElementById('dealLists');
          this.isngOnInit = true;
          
        } else {
          this.privilege = false;
          this.getfollowing(this.creator);
        }
      }
    } catch (error) {
      console.log(error);
    }
    //添加尺寸
    /*
    this.PackageSizeForm = new FormGroup({
      packageList: this._formBuilder.array([this.addPackageSizeFormGroup()])
    }); */
    
    
  }  

  enableCreate() {
    const createButton = document.getElementById('createDeal') as HTMLInputElement;
    createButton.style.display = '';
  }

  /**
   * Get all the active deal list when the user is signed as a company.
   * @author Guozhi Tang
   * @date 2020-05-01
   * @param creator - the username of current user
   */
  async getActiveList(creator) {
    const data = await this.getActiveData(creator);
    this.dealList = [];    
    if (Object.keys(data).length > 0) {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.dealList.push(data[index]);
      }
    }    
  }

  getActiveData(creator) { 
    return new Promise((resolve, reject) => {
      this.dealService
        .activeDeals(creator,this.filter,this.skip,this.pageSize)
        .pipe(first())
        .subscribe(data => {
          //debugger;
          /*
          if (data.length != 0) {
            for (let index = 0; index < data.length; index++) {
              this.dealList.push(data[index]);
            }
          }*/
          resolve(data);
        });
    })

  }

  /**
   * Get all the active deal list when the user is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-05-01
   * @param creator - the username of current user
   */
  getfollowing(creator) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          this.followingList = data;
          // console.log(this.followingList);
        },
        error => {
          console.log(error);
        },
        () => {
          //this.dealList = [];
          this.temtor = '';
                  
          for (let index = 0; index < this.followingList.length; index++) {
            //console.log(this.followingList[index].company);
            if (this.followingList[index]) {
               this.temtor = this.temtor + this.followingList[index].company + ',';
               //this.getActiveListIndividual(this.followingList[index].company,this.creator);
            } else {
               this.noFollowing = true;
            }

          }
          //console.log(this.dealList);
          /*
          if (this.temtor.length>0) {
            this.temtor = this.temtor.substring(0,this.temtor.lastIndexOf(","));
            //计算总页数
            this.getAllCount(this.temtor,this.currentUser,this.filter,'ActiveDeals',this.IsCompany);
          }*/

          this.temtor = this.temtor.substring(0,this.temtor.lastIndexOf(","));
          //console.log(this.temtor);         
          this.getDealList(this.temtor);

        }
      );
    

  }

  //返回DealList
  async getDealList(creator: string) {
    //console.log(creator);
    if (creator.length>0) { 
      //反回列表        
      await this.getActiveListIndividual(creator, this.currentUser);
      //计算页数
      this.getAllCount(creator,this.currentUser,this.filter,'ActiveDeals',this.IsCompany);
    } else {
       this.noFollowing = true;
    }
  }

  /**
   * Get all the active deal list when the user is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-05-01
   * @param creator - the company's creator name
   * @param individual - the username of current user
   */
  async getActiveListIndividual(creator, individual) {   
    const data = await this.getActiveDataIndividual(creator, individual);
    this.dealList = [];    
    if (Object.keys(data).length > 0) {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.dealList.push(data[index]);
      }
    }
  }

  getActiveDataIndividual(creator, individual) {
     return new Promise((resolve, reject) => {
      this.dealService
        .individualDeals(creator, individual,this.filter,this.skip,this.pageSize)
        .pipe(first())
        .subscribe(data => {
          /*
          if (data.length != 0) {
            this.dealList = [];
            for (let index = 0; index < data.length; index++) {         
              this.dealList.push(data[index]);                           
            }
          } */      
          resolve(data);
      });
      
    });

  }

  takeDeal(productId: any) {
    this.selectProductId = productId;
    if (!this.privilege) {
      // console.log(this.dealList[productId]);
      this.dealCreator = this.dealList[productId].creator;
      this.Storage(this.dealCreator);
      if (productId >= 0) {
        this.inbound.product = this.dealList[productId].product_name;
        this.inbound.price = this.dealList[productId].price;
        this.inbound.quantity = 0;
        this.inbound.warehouse = this.publicWarehouse;
        this.inbound.company = this.dealList[productId].creator;
        this.inbound.individual = this.creator;
        this.inbound.status = 0;
        this.inbound.companyStatus = 0;
        this.inbound.dealId = this.dealList[productId].id;
        this.inbound.bonus = this.dealList[productId].bonus;
      }
    } else {
      this.isAwards = false;
      this.awardsUsers = null;
      this.isPublic = false;
      this.isPrivate = false;
      this.selectDeal.id = this.dealList[productId].id;
      this.selectDeal.productName = this.dealList[productId].product_name;
      this.selectDeal.products = this.dealList[productId].products;
      this.selectDeal.quantity = this.dealList[productId].quantity;
      this.selectDeal.price = this.dealList[productId].price;
      const currentTime = new Date(this.dealList[productId].expires_at);
      this.month = (currentTime.getMonth() + 1).toString();
      this.days = currentTime.getDate().toString();
      this.year = currentTime.getFullYear().toString();
      this.hour = currentTime.getHours().toString();
      this.minutes = currentTime.getMinutes().toString();
      this.selectDeal.note = this.dealList[productId].note;
      this.selectDeal.bonus = this.dealList[productId].bonus;
      this.selectDeal.serviceTag = this.dealList[productId].service_tag;
      this.selectDeal.notify = this.dealList[productId].notify;
      this.selectDeal.creator = this.dealList[productId].creator;
      this.selectDeal.members = this.dealList[productId].members;
      this.selectDeal.dealPublic = this.dealList[productId].public;
      // this.selectDeal.warehouse = this.publicWarehouse;
    }
  }

  /**
   * To get the exact quantity of a specific deal in the database and return a Promise object.
   * @author Guozhi Tang
   * @date 2020-04-24
   * @return Promise - return a Promise object containing the exact quantity user can take.
   */
  checkQtyInDB() {
    return new Promise((resolve, reject) => {
      this.dealService
      .checkDealQty(this.dealList[this.selectProductId].creator, this.dealList[this.selectProductId].id)
      .pipe(first())
      .subscribe(data => {
        this.checkQty = data[0].quantity;
        if(this.inbound.quantity > data[0].quantity) {
          this.inbound.quantity = data[0].quantity;
        }
        resolve(this.inbound.quantity);
      });
    });

  }

  /**
   * Get the exact quantity of tickets can take firstly, and then create an inbound
   * @author Guozhi Tang
   * @date 2020-04-24
   */
   async createInbound() {
     
    await this.checkQtyInDB();
    //如果检查数量为零，有可能已给别人申领了。
    if (this.checkQty===0) {
      this.alertService.error('Take Failure!!【#:' + this.inbound.dealId  + ',quantity:' + this.inbound.quantity + '】', this.options);
    } else {
      this.inbound.quantity
      this.alertService.success('Take Success!!【#:'+ this.inbound.dealId  + ',quantity:' + this.inbound.quantity + '】', this.options);
    }

    if (this.selfWarehouse) {
      this.inbound.warehouse = this.selectPrivateWarehouse;
    } else {
      this.inbound.warehouse = this.selectPublicWarehouse;
    }
     
    /* 
    //选择个人的仓库地址:
    if (this.selfWarehouse && !this.inbound.publicWarehouse) {
      //console.log(this.PackageSizeForm.get('packageList').value);
      let arr = {};
      this.packageList = [];
      this.PackageSizeForm.get('packageList').value.forEach( (currentValue, index) => {
          //过虑长*宽*高不为0数据
          
          if(currentValue.long>0 && currentValue.wide>0  && currentValue.high>0){
              //debugger;
              arr = {
                'dealId' :this.inbound.dealId,
                'inboundId':0,
                'long': currentValue.long,
                'wide': currentValue.wide,
                'high': currentValue.high,
                'weight':currentValue.weight == "" ? 0 : currentValue.weight
              };
              this.packageList.push(arr);
              //this.myArray.splice(index, 1);
          }
      });      
      //debugger;
    }
    */


    // if(this.inbound.quantity > this.dealList[this.selectProductId].quantity) {
    //   this.inbound.quantity = this.dealList[this.selectProductId].quantity;
    // }
    
    this.inboundService
    .create(this.inbound)
    .pipe(first())
    .subscribe(
      data => {
        //console.log(data);
        /*
        if (data.id>0) {
          //update inboundId
          this.packageList.forEach((currentValue, index) => {
            currentValue.inboundId = data.id;
          })
          //插入包装信息
          this.inboundService
          .Addpackage(JSON.stringify(this.packageList))
          .pipe(first())
          .subscribe(data => {           
          })
        }*/
        
        this.ngOnInit();
        this.isCreated = true;
        this.inbound.clear();
        this.selectProductId = null;        
      },
      error => {
        console.log(error);
      },
      () => {
        this.inbound.clear();
        this.selectPrivateWarehouse = null;
        this.selectPublicWarehouse = null;
      }
    );
  }

  updateCompany() {
/*
    if (this.awardsUsers) {
      this.update();
    }
*/
    if (this.isAwards) {
      this.update();
    }

    const date =
      this.year +
      '-' +
      this.month +
      '-' +
      this.days +
      ' ' +
      this.hour +
      ':' +
      this.minutes +
      ':' +
      '00';
    this.selectDeal.expiresAt = date;
    if (this.membersTemp) {
      this.selectDeal.members = this.membersTemp.join();
    }
    this.dealService
      .updateCompany(this.selectDeal)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
        this.awards = 0;
        this.selectDeal.clear();
        window.location.reload();
      });
  }

  emailNotification() {
    if (this.selectDeal.notify === true) {
      const date =
      this.year +
      '-' +
      this.month +
      '-' +
      this.days +
      ' ' +
      this.hour +
      ':' +
      this.minutes +
      ':' +
      '00';
      this.selectDeal.expiresAt = date;
      this.email.dealProduct = this.selectDeal.products;
      this.email.emailList = this.selectDeal.members;
      this.email.receiver = this.receiver;
      this.email.emailSubject = this.selectDeal.creator + ' | ' + ' New Offer : ';
      this.email.emailTextBeforeImage = this.selectDeal.creator + ' has created a new offer<br><br>' + 'Product: ' + this.selectDeal.productName + '<br><br>';
      var htmlNote = this.selectDeal.note.replace(/(\r\n|\n|\r)/gm, "<br>");
      this.email.emailTextAfterImage = '<br><br>Price: $' + this.selectDeal.price + '<br><br>Quantity: ' + this.selectDeal.quantity + '<br><br>Expire time: ' + this.selectDeal.expiresAt + '<br><br><font color="red">Note:<br><br>' + htmlNote + '</font><br><br>Visit the link below to see all active offers<br><br>m.brandnewegg.com/deals/active';
      this.dealService
        .emailNotification(this.email)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('done');
          }
        );
    }
  }

  useSelfWarehouse() {
    this.selfWarehouse = true;
    this.inbound.publicWarehouse = false;
    this.privateStorage(this.creator);
  }

  usePublicWarehouse() {
    this.selfWarehouse = false;
    this.inbound.publicWarehouse = true;
  }
  makeAwards() {
    this.isAwards = true;
    this.awardsUsersUnique = [];
    this.awardsUsersUniqueQty = [];
    
    this.inboundService
      .findAwardsUser(this.selectDeal.id)
      .pipe(first())
      .subscribe(data => {
        this.awardsUsers = data;
/*
        while (this.awardsUsers.length >= 1) {
          let currentAwardsUser = this.awardsUsers.pop();
          if (currentAwardsUser !== undefined) {
            if (
              !this.awardsUsersUnique.includes(currentAwardsUser.individual)
            ) {
              this.awardsUsersUnique.push(currentAwardsUser.individual);
              this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                currentAwardsUser.quantity;
            } else {
              this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                currentAwardsUser.quantity;
            }
          }
        }
*/
      var i;
      for(i=0;i<this.awardsUsers.length;i++) {
        let currentAwardsUser = this.awardsUsers[i];
          if (currentAwardsUser !== undefined) {
            if (
              !this.awardsUsersUnique.includes(currentAwardsUser.individual)
            ) {
              this.awardsUsersUnique.push(currentAwardsUser.individual);
              this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                currentAwardsUser.quantity;
            } else {
              this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                currentAwardsUser.quantity;
            }
          }
      }
      });
  }

  cancelAwards() {
    this.isAwards = false;
    this.awardsUsers = null;
  }

  update() {
    let i = 0;
    const awardsIds: number[] = new Array();
    if (this.awardsUsers) {
      while (this.awardsUsers[i]) {
        awardsIds.push(this.awardsUsers[i].id);
        i++;
      }
    }
    this.inboundService
      .updateAwards(awardsIds, this.selectDeal.price, this.awards)
      .pipe(first())
      .subscribe(
        data => {
          this.isUpdateAwards = true;
        },
        error => {
          console.log(error);
        },
        () => {
          this.awardsUsers = null;
        }
      );
  }
  makePublic() {
    this.membersTemp = '';
    this.isPublic = true;
    this.isPrivate = false;
    this.selectDeal.dealPublic = true;
    this.groupService
      .allSubscribers(this.selectDeal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.memberList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
            $('.selectpicker').selectpicker('selectAll');
          });
        }
      );
  }
  makePrivate() {
    this.membersTemp = '';
    this.isPublic = false;
    this.isPrivate = true;
    this.selectDeal.dealPublic = false;
    this.groupService
      .groupRetrieve(this.selectDeal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.groupList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  Storage(dealCreator) {
    this.storageService
      .retrieve(dealCreator)
      .pipe(first())
      .subscribe(
        data => {
          this.publicWarehouse = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  privateStorage(creator) {
    //console.log(creator);
    this.storageService
      .retrieve(creator)
      .pipe(first())
      .subscribe(
        data => {
          this.privateWarehouse = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  compareWh(warehouse1: any, warehouse2: any) {
    //return warehouse1.id === warehouse2.id;
    return warehouse1 && warehouse2 ? warehouse1.id === warehouse2.id : warehouse1 === warehouse2;
  }

  //统计总页数
  getAllCount(creator,company,filter,deals,iscompany) {
    this.dealService
      .getAllCount(creator,company,filter,deals,iscompany)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }
  
  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    if (this.authenticationService.currentUserValue[2] === 'company') {
      this.enableCreate();
      this.privilege = true;
      this.dealList = new Array();
      this.getActiveList(this.creator);
    } else {
      this.dealList = new Array();
      this.privilege = false;
      this.getfollowing(this.creator);
      //this.getRecords(this.currentUser);
    }    
   
  } 
  /*
  //定义包装尺寸及重量
  addPackageSizeFormGroup(): FormGroup {
    //console.log(this.PackageSizeForm);
    return this._formBuilder.group({
       packageId: ["", Validators.required],
       long: ["", Validators.required], //长
       wide : ["", Validators.required], //宽
       high: ["", Validators.required],  //高
       deep : [""], //深
       thick: [""], //厚
       weight: ["", Validators.required], //厚       
    });
  }

  addProductButtonClick(): void {    
    (<FormArray>this.PackageSizeForm.get("packages")).push(
      this.addPackageSizeFormGroup()
    );
  }
  //添加多个包装
  onAddPackage() {    
    (this.PackageSizeForm.get('packageList') as FormArray).push(this.addPackageSizeFormGroup());    
  }

  packageArray(group:FormGroup):FormArray  {
    
    return group.get('packageList') as FormArray
  }
  //移除多个包装
  removePackage(index:number)
  {
    (this.PackageSizeForm.get('packageList') as FormArray).removeAt(index)
  }

  //移除多个包装
  savePackage(index:number)
  {
    console.log(this.PackageSizeForm.get('packageList').value);
    debugger;
  }
  */

}
