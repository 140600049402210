import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Inbound } from '../models/inbound';
import { Package } from '../models/package';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json',
  })
  //.set('Access-Control-Allow-Origin', '*')
};
@Injectable()
export class InboundService {
  

  createUrl = environment.apiUrl + 'inbounds/inbound'; // URL to web api
  addpackageUrl = environment.apiUrl + 'inbounds/addpackage'; // URL to web api
  allUrl = environment.apiUrl + 'inbounds/individual';
  reportedUrl = environment.apiUrl + 'inbounds/reported/individual';
  toConfirmUrl = environment.apiUrl + 'inbounds/toConfirm/individual';
  allCompanyUrl = environment.apiUrl + 'inbounds/company';
  reportedCompanyUrl = environment.apiUrl + 'inbounds/reported/company';
  toConfirmCompanyUrl = environment.apiUrl + 'inbounds/toConfirm/company';
  proposeRetrieveUrl = environment.apiUrl + 'inbounds/propose/individual';
  proposeRetrieveCompanyUrl = environment.apiUrl + 'inbounds/propose/company';
  createProposeUrl = environment.apiUrl + 'inbounds/propose';
  proposeConfirmUrl = environment.apiUrl + 'inbounds/propose/confirm';
  cancelInboundUrl = environment.apiUrl + 'inbounds/cancel';
  findAwardsUsersUrl = environment.apiUrl + 'inbounds/awardsUser';
  updateAwardsUrl = environment.apiUrl + 'inbounds/awardsUpdate';
  updateInboundQtyUrl = environment.apiUrl + 'inbounds/qtyUpdate';
  confirmUrl = environment.apiUrl + 'inbounds/confirm';
  InboudFindAllCount = environment.apiUrl + 'inbounds/InboudFindAllCount';
  InboudFindAllCountConfirm = environment.apiUrl + 'inbounds/InboudFindAllCountConfirm';
  getPackageListUrl = environment.apiUrl + 'inbounds/getpackage';
  cancelpackageUrl = environment.apiUrl + 'inbounds/cancelpackage';     //发送邮件通知
  nocancelpackageUrl = environment.apiUrl + 'inbounds/nocancelpackage'; //不发送邮件通知

  getPaymentUrl = environment.apiUrl + 'payment/payment/individual';
  setProposeStatusUrl = environment.apiUrl + 'inbounds/setProposeStatus';

  constructor(private http: HttpClient) {}

  cancelInbound(inbound: Inbound): Observable<Inbound> {
    return this.http.post<Inbound>(this.cancelInboundUrl, inbound, httpOptions);
  }

  allRecordsCompany(company: string): Observable<string> {
    const creatorJSON = '{ "company" : ' + '"' + company + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.allCompanyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  allRecords(individual: string): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.allUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  reportedRecords(individual: string): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.reportedUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //kendo UI 分页
  //company 账号 pageSize 每页显示的条数 skip 当前页号 filter 搜索值
  reportedRecordsKendo(individual: string,pageSize: number,skip: number,filter: string): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ',"pageSize": "' + pageSize + '","skip" : "' + skip + '","filter" : "' + filter + '" }';
    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.reportedUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  
  reportedRecordsCompany(company: string): Observable<string> {
    const creatorJSON = '{ "company" : ' + '"' + company + '"' + ' }';   
    return this.http.post<string>(
      this.reportedCompanyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //kendo UI 分页
  //company 账号 pageSize 每页显示的条数 skip 当前页号 filter 搜索值
  reportedRecordsCompanyKendo(company: string,pageSize: number,skip: number,filter: string): Observable<string> {
    const creatorJSON = '{ "company" : ' + '"' + company + '","pageSize": "' + pageSize + '","skip" : "' + skip + '","filter" : "' + filter + '" }';    
    return this.http.post<string>(
      this.reportedCompanyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //
  toConfirmRecords(individual: string): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.toConfirmUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //
  toConfirmRecordsCompany(company: string): Observable<string> {
    const creatorJSON = '{ "company" : ' + '"' + company + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.toConfirmCompanyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //individual 账号 pageSize 每页显示的条数 skip 当前页号 filter 搜索值
  toConfirmRecordsKendo(individual: string,pageSize: number,skip: number,filter: string): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ',"pageSize": "' + pageSize + '","skip" : "' + skip + '","filter" : "' + filter + '" }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.toConfirmUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  //company 账号 pageSize 每页显示的条数 skip 当前页号 filter 搜索值
  toConfirmRecordsCompanyKendo(company: string,pageSize: number,skip: number,filter: string): Observable<string> {
    const creatorJSON = '{ "company" : ' + '"' + company + '","pageSize": "' + pageSize + '","skip" : "' + skip + '","filter" : "' + filter + '" }';    

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.toConfirmCompanyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }


  create(inbound: Inbound): Observable<Inbound> {    
    let result = this.http.post<Inbound>(this.createUrl, inbound, httpOptions)    
    return result;
  }

  //插入包装数据
  Addpackage(packages: string): Observable<Package> {   
    //console.log(httpOptions); 
    let result = this.http.post<Package>(this.addpackageUrl, packages, httpOptions)    
    return result;
  }

  //删除包装数据
  cancelPackage(packages: string): Observable<Package> {  
    let result = this.http.post<Package>(this.cancelpackageUrl, packages, httpOptions)    
    return result;
  }

  nocancelPackage(packages: string): Observable<Package> {  
    let result = this.http.post<Package>(this.nocancelpackageUrl, packages, httpOptions)    
    return result;
  }


  proposeRetrieve(individual: string, company: string, filter = '',skip=0 , pageSize=10000000000): Observable<string> {
    let creatorJSON = '{ "individual" : ' + '"' + individual + '",'
                      + '"company" : ' + '"' + company + '"'+ ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }';
    if (filter.length != 0) {
       creatorJSON = '{ "individual" : ' + '"' + individual + '",'
                      + '"company" : ' + '"' + company + '",' + '"filter" : ' + '"' + filter.toString() + '"'+ ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }';
    }

    //console.log(JSON.parse(creatorJSON));
    return this.http.post<string>(
      this.proposeRetrieveUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  proposeRetrieveCompany(company: string, filter = '',skip=0 , pageSize=10000000000): Observable<string> {
    //const creatorJSON = '{ "company" : ' + '"' + company + '"' + ' }';

    let creatorJSON = '{ "company" : ' + '"' + company + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    
    if (filter.length != 0) {
       creatorJSON = '{ "company" : ' + '"' + company + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }
    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.proposeRetrieveCompanyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  createPropose(inbound: Inbound): Observable<Inbound> {
    return this.http.post<Inbound>(this.createProposeUrl, inbound, httpOptions);
  }

  proposeConfirm(
    id: number,
    quantity: number,
    dealId: number
  ): Observable<string> {
    const creatorJSON =
      '{ "id" : ' +
      '"' +
      id +
      '"' +
      ', ' +
      '"quantity" : ' +
      '"' +
      quantity +
      '"' +
      ', ' +
      '"dealId" : ' +
      '"' +
      dealId +
      '"' +
      ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.proposeConfirmUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  confirm(
    id: number
  ): Observable<string> {
    const idJSON =
      '{ "id" : ' +
      '"' +
      id +
      '"' +
      ' }';

    return this.http.post<string>(
      this.confirmUrl,
      JSON.parse(idJSON),
      httpOptions
    );
  }

  findAwardsUser(dealId: number): Observable<number> {
    const creatorJSON = '{ "dealId" : ' + '"' + dealId + '"' + ' }';

    return this.http.post<number>(
      this.findAwardsUsersUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  updateAwards(
    inboundIds: Array<number>,
    price: number,
    awards: number
  ): Observable<any> {
    const creatorJSON =
      '{ "inboundIds" : ' +
      '"' +
      inboundIds +
      '"' +
      ', ' +
      '"price" : ' +
      '"' +
      price +
      '"' +
      ', ' +
      '"awards" : ' +
      '"' +
      awards +
      '"' +
      ' }';
    // console.log(JSON.parse(creatorJSON));
    return this.http.post<any>(
      this.updateAwardsUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  updateInboundQty(
    dealId: number,
    id: number,
    quantity: number
  ): Observable<any> {
    const creatorJSON =
      '{ "dealId" : ' +
      '"' +
      dealId +
      '"' +
      ', ' +
      '"id" : ' +
      '"' +
      id +
      '"' +
      ', ' +
      '"quantity" : ' +
      '"' +
      quantity +
      '"' +
      ' }';
    return this.http.post<any>(
      this.updateInboundQtyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //返回数据总条数
  FindAllCount(company: string,iscompany: boolean,filter: string): Observable<string> {
    //debugger;
    const creatorJSON = '{ "company" : ' + '"' + company + '","iscompany" : '+ iscompany + ',"filter" : "'+ filter + '" }';    
    return this.http.post<string>(
      this.InboudFindAllCount,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //返回数据总条数
  FindAllCountConfirm(company: string,iscompany: boolean,filter: string): Observable<string> {   
    const creatorJSON = '{ "company" : ' + '"' + company + '","iscompany" : '+ iscompany + ',"filter" : "'+ filter + '" }';    
    console.log(creatorJSON);
    const result = this.http.post<string>(
      this.InboudFindAllCountConfirm,
      JSON.parse(creatorJSON),
      httpOptions
    );
    console.log(result);
    return result;
  }

  //搜索包裹尺寸信息
  getPackageList(packid: string,dealid: string): Observable<string> {
    const creatorJSON = '{ "packid": "' + packid + '","dealid" : "' + dealid + '" }';
    return this.http.post<string>(
      this.getPackageListUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //发票
  paymentRecords(individual: string,pageSize: number,skip: number,filter: string,iscompany:boolean): Observable<string> {
    const paymentRecordsJSON = '{ "individual" : ' + '"' + individual + '"' + ',"pageSize": "' + pageSize + '","skip" : "' + skip + '","filter" : "' + filter + '","iscompany" : ' + iscompany + ' }';
    return this.http.post<string>(
      this.getPaymentUrl,
      JSON.parse(paymentRecordsJSON),
      httpOptions
    );
  }

  setProposeStatus(inboundId: string): Observable<string> {
    const inboundIdJSON = '{ "inboundId" : ' + '"' + inboundId +  '"' + ' }';
    return this.http.post<any>(
      this.setProposeStatusUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );    
  }

}
