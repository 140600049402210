import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Deal } from '../models/deal';
import { Email } from '../models/email';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class DealService {
  // createDealUrl = environment.apiUrl + 'createdeal'; // URL to web api
  // activeDealsUrl = environment.apiUrl + 'deal/active';
  // updateCompanyUrl = environment.apiUrl + 'deal/update';
  // expiredDealUrl = environment.apiUrl + 'deal/expiredDeal';
  // individualDealUrl = environment.apiUrl + 'deal/individualFind';
  // individualExpiredDealUrl = environment.apiUrl + 'deal/individualExpiredFind';
  // sendNotificationUrl = environment.apiUrl + 'send-notification';
  // giveBackQtyUrl = environment.apiUrl + 'deal/giveBackQty';
  // // IndividualDealsUrl = 'http://192.168.1.86:8081/api/deal/active/firstTenIndivivdual';
  // // updatePriceCompanyUrl = 'http://192.168.1.119:8081/api/deal/updatePrice';
  createDealUrl = environment.apiUrl + 'deals/deal'; // URL to web api
  checkDealQtyUrl = environment.apiUrl + 'deals/checkQty';
  activeDealsUrl = environment.apiUrl + 'deals/company/active';
  publicDealsUrl = environment.apiUrl + 'deals/company/public';
  updateCompanyUrl = environment.apiUrl + 'deals/update';
  expiredDealUrl = environment.apiUrl + 'deals/company/expired';
  individualDealUrl = environment.apiUrl + 'deals/individual/active';
  individualPublicDealUrl = environment.apiUrl + 'deals/individual/public';
  individualExpiredDealUrl = environment.apiUrl + 'deals/individual/expired';
  sendNotificationUrl = environment.apiUrl + 'deals/notification';
  giveBackQtyUrl = environment.apiUrl + 'deals/qtyProcess';
  getProductIdUrl = environment.apiUrl + 'deals/productId';
  DealsGetAllCount = environment.apiUrl + 'deals/dealsGetAllCount';
  getTableCountUrl = environment.apiUrl + 'deals/getTableCount';
  // IndividualDealsUrl = 'http://192.168.1.86:8081/api/deal/active/firstTenIndivivdual';
  // updatePriceCompanyUrl = 'http://192.168.1.119:8081/api/deal/updatePrice';
  constructor(private http: HttpClient) {}

  createDeal(deal: Deal): Observable<Deal> {
    return this.http.post<Deal>(this.createDealUrl, deal, httpOptions);
  }
  emailNotification(email: Email): Observable<Email> {
    return this.http.post<Email>(this.sendNotificationUrl, email, httpOptions);
  }
  /**
   * Get the active deal list of which the currentUser is in member list.
   * @param creator - brandnewegg
   * @param individual - the username of current user
   * @returns - the object data of the active deal list
   * @author Guozhi Tang
   * @date 2020-04-24
   */
  checkDealQty(creator: string, dealId: string): Observable<any> {
    const creatorJSON = 
      '{ "creator" : ' + '"' + creator + '"' + ', ' +
      '"dealId" : ' + '"' + dealId + '"' +
      ' }';
    return this.http.post<any>(
      this.checkDealQtyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  /**
   * Get the active deal list of which the currentUser is in member list.
   * @param creator - brandnewegg
   * @param individual - the username of current user
   * @returns - the object data of the active deal list
   */
  //active - deals - individual
  individualDeals(creator: string, individual: string, filter='', skip = 0, pageSize = 10000000000): Observable<string> {
     
    const creatorJSON = 
      '{ "creator" : ' + '"' + creator + '"' + ', ' +
      '"individual" : ' + '"' + individual + '"' +
      ',"filter" : ' + '"' + filter + '"' +
      ',"skip" : '  + skip.toString()  +
      ',"pageSize" : '  + pageSize.toString()  +
      ' }';
    return this.http.post<string>(
      this.individualDealUrl,
      JSON.parse(creatorJSON),
      httpOptions
    
    );  

  }

  individualDealsfilter(creator: string, individual: string, filter='', skip = 0, pageSize = 10000000000): Observable<string> {
     
    const creatorJSON = 
      '{ "creator" : ' + '"' + creator + '"' + ', ' +
      '"individual" : ' + '"' + individual + '"' +
      ',"filter" : ' + '"' + filter + '"' +
      ',"skip" : '  + skip.toString()  +
      ',"pageSize" : '  + pageSize.toString()  +
      ' }';
    return this.http.post<string>(
      this.individualDealUrl,
      JSON.parse(creatorJSON),
      httpOptions
    
    ); 
     /*
    let results='';
    let promise = new Promise((resolve, reject) => {
    let apiURL = this.individualDealUrl;
    this.http.post<string>(apiURL,JSON.parse(creatorJSON),httpOptions)
      .toPromise()
      .then(
        res => { // Success
        results = res.json().results;
        resolve();
        },
        msg => { // Error
        reject(msg);
        }
      );    
    });
    return results;*/

  }

  //public - deals - individual
  individualPublicDeals(creator: string, individual: string , filter='', skip = 0, pageSize = 10000000000): Observable<string> {
    
    const creatorJSON = 
      '{ "creator" : ' + '"' + creator + '"' + ', ' +
      '"individual" : ' + '"' + individual + '"' +
      ',"filter" : ' + '"' + filter + '"' +
      ',"skip" : '  + skip.toString()  +
      ',"pageSize" : '  + pageSize.toString()  +
      ' }';

    return this.http.post<string>(
      this.individualPublicDealUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  /**
   * Get the expired deal list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-03-27
   * @param creator - brandnewegg
   * @param individual - the username of current user
   * @returns - the object data of the expired deal list
   */
  //expired - deals
  individualExpiredDeals(creator: string, individual: string , filter='', skip = 0, pageSize = 10000000000): Observable<string> {
    //debugger;
    const creatorJSON = 
      '{ "creator" : ' + '"' + creator + '"' + ', ' +
      '"individual" : ' + '"' + individual + '"' +
      ',"filter" : ' + '"' + filter + '"' +
      ',"skip" : '  + skip.toString()  +
      ',"pageSize" : '  + pageSize.toString()  +
      ' }';
    return this.http.post<string>(this.individualExpiredDealUrl, JSON.parse(creatorJSON), httpOptions);
  }

  /**
   * Get the active deals list.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param creator - brandnewegg
   * @returns - the object data of the active deals list
   */
  activeDeals(creator: string, filter = '', skip=0 , pageSize=10000000000): Observable<string> {
    //const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';
    // debugger;
    let creatorJSON = '{ "creator" : ' + '"' + creator + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    if (filter.length != 0) {
       creatorJSON = '{ "creator" : ' + '"' + creator + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.activeDealsUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
  // public-deals
  publicDeals(creator: string, filter = '', skip=0 , pageSize=10000000000): Observable<string> {
    
    let creatorJSON = '{ "creator" : ' + '"' + creator + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    if (filter.length != 0) {
       creatorJSON = '{ "creator" : ' + '"' + creator + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }
    //const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';
    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.publicDealsUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  updateCompany(deal: Deal): Observable<Deal> {
    return this.http.post<Deal>(this.updateCompanyUrl, deal, httpOptions);
  }

  expiredDealRetrieve(creator: string, filter = '',skip=0 , pageSize=10000000000): Observable<string> {
    //debugger;
    let creatorJSON = '{ "creator" : ' + '"' + creator + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    if (filter.length != 0) {
       creatorJSON = '{ "creator" : ' + '"' + creator + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }
    
    return this.http.post<string>(
      this.expiredDealUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  giveBackQty(dealId: number, quantity: number): Observable<any> {
    const creatorJSON =
      '{ "dealId" : ' +
      '"' +
      dealId +
      '"' +
      ', ' +
      '"quantity" : ' +
      '"' +
      quantity +
      '"' +
      ' }';
    return this.http.post<any>(
      this.giveBackQtyUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  getProductId(dealId: number): Observable<any> {
    const creatorJSON =
      '{ "dealId" : ' +
      '"' +
      dealId +
      '"' +
      ' }';
    return this.http.post<any>(
      this.getProductIdUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //返回数据总条数
  getAllCount(creator: string, company: boolean ,filter: string , deals: string, iscompany: boolean): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + creator + '","company" : "'+ company + '","filter" : "'+ filter + '","deals" : "'+ deals + '","iscompany" : '+ iscompany + ' }';    
    return this.http.post<string>(
      this.DealsGetAllCount,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }


  getTableCount(userName: string, isComnapy: number ,TableName: string,tabName: string,companyGroup:string ): Observable<any> {
    const TablecountJSON = '{ "userName" : ' + '"' + userName + '","isComnapy" : '+ isComnapy + ',"TableName" : "'+ TableName + '","tabName" : "'+ tabName + '","companyGroup" : "'+ companyGroup+  '" }';    
    return this.http.post<any>(
      this.getTableCountUrl,
      JSON.parse(TablecountJSON),
      httpOptions
    );
  }


}
