<div class="container">
  <h1>User Information</h1>
  <hr/>
  <table class="table table-hover">
    <tbody>
      <tr>
        <th>Username:</th>
        <td>{{currentUser}}</td>
      </tr>
      <tr>
        <th>Level:</th>
        <td>{{userLevel}}</td>
      </tr>
      <tr>
        <th>Phone:</th>
        <td>{{userPhone}}</td>
      </tr>
      <tr>
        <th>Email:</th>
        <td>{{userEmail}}</td>
      </tr>
      <tr>
        <th>Storages:</th>
        <td>
          <a class="btn btn-outline-secondary" routerLink="/storages" (click)="ngOnInit()">Storages</a>
        </td>
      </tr>
      <tr>
        <th>BillPay info:</th>
        <td>
          <a class="btn btn-outline-secondary" routerLink="/billpays" (click)="ngOnInit()">BillPay info</a>
        </td>
      </tr>
      <tr>
        <th>Bill info:</th>
        <td>
          <a class="btn btn-outline-secondary" routerLink="/bills" (click)="ngOnInit()">Bill info</a>
        </td>
      </tr>
      <tr *ngIf="!privilege">
        <th>Subscription:</th>
        <td>
          <table>
            <tr *ngFor="let company of followingList">
              <td>{{company.company}}</td>
            </tr>
          </table>
        </td>
      </tr>

    </tbody>
  </table>
</div>
