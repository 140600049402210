import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class BillService {
  
  addBillUrl = environment.apiUrl + 'bills/bill';
  countUrl = environment.apiUrl + 'bills/count';
  retrieveUrl = environment.apiUrl + 'bills/allbills';
  constructor(private http: HttpClient) {}

  addBill(bank: string, account: string,creator: string): Observable<string> {
    const creatorJSON =
      '{ "bank" : ' +
      '"' +
      bank +
      '"' +
      ',' + '"account" : ' +
      '"' +
      account +
      '"' +
      ', ' +
      '"creator" : ' +
      '"' +
      creator +
      '"' +
      ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.addBillUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  count(creator: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.countUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  retrieve(creator: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.retrieveUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }
}
