//import { Component, OnInit ,ViewChild } from '@angular/core';
import { InboundService } from '../../services/inbound.service';
import { first } from 'rxjs/operators';
import { ReportedListComponent } from './reported-list/reported-list.component';
import { ToConfirmListComponent } from './to-confirm-list/to-confirm-list.component';
import { PaymentListComponent} from './payment-list/payment-list.component';
import { AuthenticationService } from '../../services/authentication.service';

//搜索防抖
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import { Router } from '@angular/router';

import { 
  Component,
  OnInit,
  ViewChild,
  VERSION,
  Renderer2,
  QueryList,
  ElementRef,
  ViewChildren
} from "@angular/core";
declare var $: any;


@Component({
  selector: 'app-inbound',  
  templateUrl: './inbound.component.html',
  styleUrls: ['./inbound.component.scss'],
  providers: [InboundService,AuthenticationService],
  //directives:[ReportedListComponent]
})

export class InboundComponent implements OnInit {

  //data: string; 
  @ViewChild(ReportedListComponent, {static: false})
  reported:ReportedListComponent; //父组件中获得子组件的引用

  @ViewChild(ToConfirmListComponent, {static: false})
  ToConfirm:ToConfirmListComponent; //父组件中获得子组件的引用

  @ViewChild(PaymentListComponent, {static: false})
  payment:PaymentListComponent; //父组件中获得子组件的引用
  
 
  public recordList: any;
  public currentUser: any;
  public ClassName: string;
  public searchvalue: string;
  public ansArray: any;

  private getDataTerms = new Subject<string>();
  

  constructor(
    private inboundService: InboundService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private renderer: Renderer2,
    private elRef: ElementRef
    //private reported: ReportedListComponent,
  ) { }


  activeTab(tabname: any) {
    let el = this.elRef.nativeElement
      .querySelector(".container")
      .querySelector("ul")      
      .querySelectorAll("a");
    el.forEach((value, index) => {
      //console.log(value);
      var tname = value.innerHTML;
      //alert(tabname + "==" + tname);      
      this.renderer.removeClass(el[index], "active");

      if (tabname == tname) {
        this.renderer.addClass(el[index], "active");
      } else {
      }
    });
  }

  ngOnInit() {
    this.getDataTerms
      .pipe(
        // 请求防抖 300毫秒
        debounceTime(300),
        distinctUntilChanged())
       .subscribe(term => {
          this.search(term);       
    });
  }

  //激活主页
  onRouterOutletActivate(event : any) {
     (<HTMLInputElement>document.getElementById('inboundSearchInput')).value = '';
    
    if (this.router.url === '/inbounds/inboundReported') {
      document.getElementById("inboundSearchInput").style.display = "block";
      this.ClassName = 'ReportedListComponent';
      this.activeTab('Reported');
    } else if (this.router.url === '/inbounds/inboundToConfirm') {
      document.getElementById("inboundSearchInput").style.display = "block";
      this.ClassName = 'ToConfirmListComponent';
      this.activeTab('To Confirm');
    } else if (this.router.url === '/inbounds/inboundPayment') {
      document.getElementById("inboundSearchInput").style.display = "block";
      this.ClassName = 'PaymentListComponent';
      this.activeTab('Payment');
    } else {
      this.ClassName = 'ToConfirmListComponent';
      document.getElementById("inboundSearchInput").style.display = "block";
      this.activeTab('To Confirm');
    }
    //this.ClassName = event.constructor.name; 
    this.ansArray = true;
    this.clearSearch(); //切换清除功能
  }

  getSearchValue() {
    const input = document.getElementById('inboundSearchInput') as HTMLInputElement;
    const value = input.value.toUpperCase();
    this.getDataTerms.next(value);    

  } 

  //切换时清除
  clearSearch()
  {
    this.getDataTerms.next('');
  }

  search(filter) { //(event : any) {
    // Declare variables
   
    const table = document.getElementById('inboundTable');
    const tr = table.getElementsByTagName('tr');
    this.currentUser = this.authenticationService.currentUserValue[0];    
    
    if (this.searchvalue === filter ) {
      return;
    }

    // Loop through all table rows, and hide those who don't match the search query
    // tslint:disable-next-line:prefer-for-of
    //debugger;

    switch (this.ClassName) {
      //Reported 
      case 'ReportedListComponent':
        if (this.authenticationService.currentUserValue[2] === 'company') {
          //公司账号
          this.reported.findAllCount(this.currentUser,true,filter); //返回总行数 
          this.reported.RecordsCompanySearch(this.currentUser,filter);
          this.reported.filter = filter;
        } else {
          //个人账号
          this.reported.findAllCount(this.currentUser,false,filter); //返回总行数 
          this.reported.RecordsSearch(this.currentUser,filter);
          this.reported.filter = filter;
        }
        break;
      //toconfirm
      case 'ToConfirmListComponent':

        if (this.authenticationService.currentUserValue[2] === 'company') {
          //公司账号
          this.ToConfirm.findAllCountConfirm(this.currentUser,true,filter); //返回总行数 
          this.ToConfirm.RecordsCompanySearch(this.currentUser,filter);
          this.ToConfirm.filter = filter;
        } else {
          //个人账号
          this.ToConfirm.findAllCountConfirm(this.currentUser,false,filter); //返回总行数 
          this.ToConfirm.RecordsSearch(this.currentUser,filter);
          this.ToConfirm.filter = filter;
        }
        break;
       //payment
      case 'PaymentListComponent':

        if (this.authenticationService.currentUserValue[2] === 'company') {
          //公司账号
          this.payment.PageCount(this.currentUser,true,filter); //返回总行数 
          this.payment.SearchList(this.currentUser,true,filter);
          this.payment.filter = filter;
        } else {
          //个人账号
          this.payment.PageCount(this.currentUser,false,filter); //返回总行数 
          this.payment.SearchList(this.currentUser,false,filter);
          this.payment.filter = filter;
        }
        break;  
      default :
        break;
    }
    
    this.searchvalue = filter;
    /* 取消 tom.lee by 20210311
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].getElementsByTagName('td')[0];
      if (td) {
        const txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    } */
  }
}
