<!-- {{router.url}} -->
<div class="container">
  <h1>UserGroups</h1>
  <hr/>
  <ul class="nav nav-tabs" id="dealTabs" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="group-active-tab"
        data-toggle="tab"
        routerLink="group_active"
        role="tab"
        aria-controls="active"
        aria-selected="true"
        >All Followers</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="user-group-tab"
        data-toggle="tab"
        routerLink="user_groups"
        role="tab"
        aria-controls="active"
        aria-selected="true"
        >Groups</a
      >
    </li>
  </ul>
  <br />
  <form class="form-inline float-left my-2">
    <input
      class="form-control mr-sm-2"
      type="search"
      placeholder="Search"
      aria-label="Search"
      id="groupSearchInput"
      (keyup)="getSearchValue()"
    />
  </form>

  <!--分页设置-->
  <div class="tab-content" id="groupsLists"  *ngIf="ClassName=='GroupsActiveListComponent'">
    <app-groups-active-list  [answers]='ansArray'></app-groups-active-list>
  </div>
  <div class="tab-content" id="groupsLists"  *ngIf="ClassName=='UserGroupsComponent'">
    <app-user-groups  [answers]='ansArray'></app-user-groups>
  </div>

  <div class="tab-content" id="groupsLists"  [hidden]="true">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>

  <!--
  <div class="wrapper">
    分页数据传输 页面切换隐藏/显示 tom.lee
    <app-groups-active-list  [hidden]="ClassName!='GroupsActiveListComponent'"></app-groups-active-list>
    <app-user-groups  [hidden]="ClassName!='UserGroupsComponent'"></app-user-groups>
  </div>-->

  <div
    class="modal fade"
    id="createForm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createFrom"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createFrom">Product Create Form</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="recipient-name" class="col-form-label"
                >Recipient:</label
              >
              <input type="text" class="form-control" id="recipient-name" />
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label">Message:</label>
              <textarea class="form-control" id="message-text"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Send message</button>
        </div>
      </div>
    </div>
  </div>
</div>
