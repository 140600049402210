import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { GroupService } from '../../../services/group.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-user-join',
  templateUrl: './user-join.component.html',
  styleUrls: ['./user-join.component.scss'],
  providers: [AuthenticationService]
})
export class UserJoinComponent implements OnInit {
  public privilege: any;
  public currentUser: any;
  public userEmail: any;
  public company: any;
  public isExisted: any;
  public isRequested: any;

  constructor(
    private authenticationService: AuthenticationService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.userEmail = this.authenticationService.currentUserValue[4];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
      } else {
        this.privilege = false;
      }
    }
  }

  checkRelationship() {
    this.groupService
      .checkRelationship(this.currentUser, this.company)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.isExisted = true;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          if (!this.isExisted) {
            this.request(this.currentUser, this.company, this.userEmail);
          }
        }
      );
  }

  request(individual, company, email) {
    this.groupService
      .create(individual, company, email)
      .pipe(first())
      .subscribe(data => {
        this.isRequested = true;
      });
  }
}
