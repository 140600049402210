//import { Component, OnInit ,ViewChild} from '@angular/core';
import { 
  Component,
  OnInit,
  ViewChild,
  VERSION,
  Renderer2,
  QueryList,
  ElementRef,
  ViewChildren
} from "@angular/core";
import { Router } from '@angular/router';

//导出模块
import { GroupsActiveListComponent } from './groups-active-list/groups-active-list.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';

//搜索防抖
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  public currentPath: any;
  public isGroupTab: any;
  public ClassName: string;
  public searchvalue: string;
  public ansArray: any;

  private getDataTerms = new Subject<string>();
  //public UserGroups: any;

  //data: string; 父组件中获得子组件的引用
  
  @ViewChild(UserGroupsComponent, {static: false})
  UserGroups:UserGroupsComponent; 

  @ViewChild(GroupsActiveListComponent, {static: false})
  GroupsActiveList:GroupsActiveListComponent; 
 

  constructor(private router: Router,
              private renderer: Renderer2,
              private elRef: ElementRef
  ) {}

  activeTab(tabname: any) {
    let el = this.elRef.nativeElement
      .querySelector(".container")
      .querySelector("ul")      
      .querySelectorAll("a");
    el.forEach((value, index) => {
      //console.log(value);
      var tname = value.innerHTML;
      //alert(tabname + "==" + tname);      
      this.renderer.removeClass(el[index], "active");

      if (tabname == tname) {
        this.renderer.addClass(el[index], "active");
      } else {
      }
    });
  }
  
  ngOnInit() {

    this.getDataTerms
      .pipe(
        // 请求防抖 300毫秒
        debounceTime(300),
        distinctUntilChanged())
       .subscribe(term => {
          //console.log(term);
          this.search(term);       
    });

    if (this.router.url === '/groups' || this.router.url === '/groups/group_active') {
      document.getElementById('group-active-tab').classList.add('active');
    } else if (this.router.url === '/groups/user_groups') {
      document.getElementById('user-group-tab').classList.add('active');
    }
    this.currentPath = this.router.url;
    if (this.currentPath === '/groups/user_groups') {
      this.isGroupTab = true;
    } else {
      this.isGroupTab = false;
    }
  }

  getSearchValue() {
    const input = document.getElementById('groupSearchInput') as HTMLInputElement;
    const value = input.value.toUpperCase();
    this.getDataTerms.next(value);
  } 

  //切换时清除
  clearSearch()
  {
    this.getDataTerms.next('');
  }


  search(filter) {
    // Declare variables
    //const input = document.getElementById('groupSearchInput') as HTMLInputElement;
    //const filter = input.value.toUpperCase();
    const table = document.getElementById('GroupTable');
    const tr = table.getElementsByTagName('tr');

    //if (this.searchvalue === filter ) {
    //  return;
    //}
    
    switch (this.ClassName) {

      case 'GroupsActiveListComponent':
         this.GroupsActiveList.filter = filter;
         this.GroupsActiveList.skip = 0;
         this.GroupsActiveList.currentUser = this.GroupsActiveList.authenticationService.currentUserValue[0];         
         if (this.GroupsActiveList.authenticationService.currentUserValue[2] === 'company') {
           this.GroupsActiveList.privilege = true;
           this.GroupsActiveList.getfollowersList(this.GroupsActiveList.currentUser);
           this.GroupsActiveList.subAllCount(this.GroupsActiveList.currentUser);
         } else {
           this.GroupsActiveList.privilege = false;
          // @TODO
         }        
         
         break;
      case 'UserGroupsComponent':
         
         this.UserGroups.filter = filter;
         this.UserGroups.skip =0 ;
         this.UserGroups.currentUser = this.UserGroups.authenticationService.currentUserValue[0];
         if (this.UserGroups.authenticationService.currentUserValue[2] === 'company') {
           this.UserGroups.privilege = true;
           this.UserGroups.groupRetrieve(this.UserGroups.currentUser);
           this.UserGroups.getAll(this.UserGroups.currentUser);
           this.UserGroups.getAllCount(this.UserGroups.currentUser);
         } else {
           this.UserGroups.privilege = false;
         }         
         break;     
      default :
         break;
    }   

    this.searchvalue = filter;

    // Loop through all table rows, and hide those who don't match the search query
    // tslint:disable-next-line:prefer-for-of
    /*
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].getElementsByTagName('td')[0];
      if (td) {
        const txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }*/

  }

  //激活主页  
  onRouterOutletActivate(event : any) {

    (<HTMLInputElement>document.getElementById('groupSearchInput')).value = '';
    
    if (this.router.url === '/groups/group_active') {
      this.ClassName = 'GroupsActiveListComponent';
      this.activeTab('All Followers');
    } else if (this.router.url === '/groups/user_groups') {
      this.ClassName = 'UserGroupsComponent';
      this.activeTab('Groups');
    } else {
      this.ClassName = 'GroupsActiveListComponent';
      this.activeTab('All Followers');
    }

    //this.ClassName = event.constructor.name;
    this.ansArray = true;

    this.clearSearch(); //切换清除功能

  }


}
