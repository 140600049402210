import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
//import { GeTuiSdkPlugin } from 'GeTuiSdkPlugin';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'content-Type': 'application/json'
    })
};
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http) {
        this.http = http;
        this.updateUrl = environment.apiUrl + 'user/updateUser';
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.login = function (user) {
        var _this = this;
        return this.http
            // .post<User>(environment.apiUrl + 'signin', user, httpOptions)
            .post(environment.apiUrl + 'users/signin', user, httpOptions)
            .pipe(map(function (logInUser) {
            //console.log(logInUser);
            // login successful if there's a jwt token in the response
            if (logInUser) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //console.log(typeof GeTuiSdkPlugin);
                if (typeof GeTuiSdkPlugin != 'undefined') {
                    GeTuiSdkPlugin.initialize(); //再次初次化GeTuiSdkPlugin
                }
                localStorage.setItem('currentUser', JSON.stringify(logInUser));
                _this.currentUserSubject.next(logInUser);
            }
            return logInUser;
        }));
    };
    //更新用户cid
    /*
    updateUser(user: User): Observable<User> {
      return this.http.post<User>(this.updateUrl, user, httpOptions);
    }*/
    AuthenticationService.prototype.logout = function () {
        // remove user from local storage to log user out
        //localStorage.removeItem('UserClientId');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
