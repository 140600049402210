import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Deal } from '../../../models/deal';
import { DealService } from '../../../services/deal.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { GroupService } from '../../../services/group.service';
import { Inbound } from '../../../models/inbound';
import { InboundService } from '../../../services/inbound.service';
import { StorageService } from '../../../services/storage.service';
import { Email } from '../../../models/email';
var PublicDealsComponent = /** @class */ (function () {
    function PublicDealsComponent(dealService, authenticationService, groupService, inboundService, storageService) {
        this.dealService = dealService;
        this.authenticationService = authenticationService;
        this.groupService = groupService;
        this.inboundService = inboundService;
        this.storageService = storageService;
        this.pageSize = 20; //每页显示条数
        this.skip = 0; //页号
        this.pagedDestinations = [];
        this.total = 0; //destinations.length; //总条数
        this.filter = '';
        this.IsCompany = false;
        this.temtor = '';
        this.type = 'input';
        // private activeDeal: Deal;
        this.dealList = new Array();
        this.isCreated = false;
        this.inbound = new Inbound('', '', '', null, '', '', '', null, null, null, true, null);
        this.selectDeal = new Deal(null, '', null, null, '', '', false, false, false, false, '');
        this.selfWarehouse = false;
        this.isAwards = false;
        this.awards = 0;
        this.awardsUsersUnique = new Array();
        this.awardsUsersUniqueQty = new Array();
        this.email = new Email();
    }
    PublicDealsComponent.prototype.ngOnInit = function () {
        if (!this.ans)
            return;
        if (this.authenticationService.currentUserValue) {
            // console.log(this.authenticationService.currentUserValue);
            this.creator = this.authenticationService.currentUserValue[0];
            this.receiver = this.authenticationService.currentUserValue[4];
            this.currentUser = this.authenticationService.currentUserValue[0];
            this.IsCompany = this.authenticationService.currentUserValue[2] === 'company' ? true : false;
            if (this.authenticationService.currentUserValue[2] === 'company') {
                this.enableCreate();
                this.privilege = true;
                this.getPublicList(this.creator);
                this.getAllCount(this.currentUser, this.currentUser, this.filter, 'PublicDeals', this.IsCompany);
            }
            else {
                this.privilege = false;
                this.getfollowing(this.creator);
            }
        }
    };
    PublicDealsComponent.prototype.enableCreate = function () {
        var createButton = document.getElementById('createDeal');
        createButton.style.display = '';
    };
    /**
     * Get all the active deal list when the user is signed as a company.
     * @author Guozhi Tang
     * @date 2020-05-01
     * @param creator - the username of current user
     */
    PublicDealsComponent.prototype.getPublicList = function (creator) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPublicData(creator)];
                    case 1:
                        data = _a.sent();
                        this.dealList = [];
                        if (Object.keys(data).length > 0) {
                            for (index = 0; index < Object.keys(data).length; index++) {
                                this.dealList.push(data[index]);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PublicDealsComponent.prototype.getPublicData = function (creator) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dealService
                .publicDeals(creator, _this.filter, _this.skip, _this.pageSize)
                .pipe(first())
                .subscribe(function (data) {
                /*
                if (data.length != 0) {
                  for (let index = 0; index < data.length; index++) {
                    this.dealList.push(data[index]);
                  }
                }*/
                resolve(data);
            });
        });
    };
    /**
     * Get all the active deal list when the user is signed as an individual.
     * @author Guozhi Tang
     * @date 2020-05-01
     * @param creator - the username of current user
     */
    PublicDealsComponent.prototype.getfollowing = function (creator) {
        var _this = this;
        this.groupService
            .findfollowing(creator)
            .pipe(first())
            .subscribe(function (data) {
            // console.log(data);
            _this.followingList = data;
            // console.log(this.followingList);
        }, function (error) {
            console.log(error);
        }, function () {
            //this.dealList = [];
            _this.temtor = '';
            for (var index = 0; index < _this.followingList.length; index++) {
                if (_this.followingList[index]) {
                    _this.temtor = _this.temtor + _this.followingList[index].company + ',';
                    //this.getPublicListIndividual(this.followingList[index].company,this.creator);
                }
                else {
                    _this.noFollowing = true;
                }
            }
            _this.temtor = _this.temtor.substring(0, _this.temtor.lastIndexOf(","));
            _this.getDealList(_this.temtor);
            /*
            if (this.temtor.length>0) {
              this.temtor = this.temtor.substring(0,this.temtor.lastIndexOf(","));
              //计算总页数
              this.getAllCount(this.temtor,this.currentUser,this.filter,'PublicDeals',this.IsCompany);
            }*/
        });
    };
    //返回DealList
    PublicDealsComponent.prototype.getDealList = function (creator) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(creator.length > 0)) return [3 /*break*/, 2];
                        //反回列表        
                        return [4 /*yield*/, this.getPublicListIndividual(creator, this.currentUser)];
                    case 1:
                        //反回列表        
                        _a.sent();
                        //计算页数
                        this.getAllCount(creator, this.currentUser, this.filter, 'PublicDeals', this.IsCompany);
                        return [3 /*break*/, 3];
                    case 2:
                        this.noFollowing = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get all the active deal list when the user is signed as an individual.
     * @author Guozhi Tang
     * @date 2020-05-01
     * @param creator - the company's creator name
     * @param individual - the username of current user
     */
    PublicDealsComponent.prototype.getPublicListIndividual = function (creator, individual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPublicDataIndividual(creator, individual)];
                    case 1:
                        data = _a.sent();
                        this.dealList = [];
                        if (Object.keys(data).length > 0) {
                            for (index = 0; index < Object.keys(data).length; index++) {
                                this.dealList.push(data[index]);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PublicDealsComponent.prototype.getPublicDataIndividual = function (creator, individual) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dealService
                .individualPublicDeals(creator, individual, _this.filter, _this.skip, _this.pageSize)
                .pipe(first())
                .subscribe(function (data) {
                // console.log(data);
                /*
                if (data.length != 0) {
                  this.dealList = [];
                  for (let index = 0; index < data.length; index++) {
                    this.dealList.push(data[index]);
                  }
                }*/
                resolve(data);
            });
        });
    };
    PublicDealsComponent.prototype.takeDeal = function (productId) {
        this.selectProductId = productId;
        if (!this.privilege) {
            // console.log(this.dealList[productId]);
            this.dealCreator = this.dealList[productId].creator;
            this.Storage(this.dealCreator);
            if (productId >= 0) {
                this.inbound.product = this.dealList[productId].product_name;
                this.inbound.price = this.dealList[productId].price;
                this.inbound.quantity = 0;
                this.inbound.warehouse = this.publicWarehouse;
                this.inbound.company = this.dealList[productId].creator;
                this.inbound.individual = this.creator;
                this.inbound.status = 0;
                this.inbound.companyStatus = 0;
                this.inbound.dealId = this.dealList[productId].id;
                this.inbound.bonus = this.dealList[productId].bonus;
            }
        }
        else {
            this.isAwards = false;
            this.awardsUsers = null;
            this.isPublic = false;
            this.isPrivate = false;
            this.selectDeal.id = this.dealList[productId].id;
            this.selectDeal.productName = this.dealList[productId].product_name;
            this.selectDeal.products = this.dealList[productId].products;
            this.selectDeal.quantity = this.dealList[productId].quantity;
            this.selectDeal.price = this.dealList[productId].price;
            var currentTime = new Date(this.dealList[productId].expires_at);
            this.month = (currentTime.getMonth() + 1).toString();
            this.days = currentTime.getDate().toString();
            this.year = currentTime.getFullYear().toString();
            this.hour = currentTime.getHours().toString();
            this.minutes = currentTime.getMinutes().toString();
            this.selectDeal.note = this.dealList[productId].note;
            this.selectDeal.bonus = this.dealList[productId].bonus;
            this.selectDeal.serviceTag = this.dealList[productId].service_tag;
            this.selectDeal.notify = this.dealList[productId].notify;
            this.selectDeal.creator = this.dealList[productId].creator;
            this.selectDeal.members = this.dealList[productId].members;
            this.selectDeal.dealPublic = this.dealList[productId].public;
            // this.selectDeal.warehouse = this.publicWarehouse;
        }
    };
    /**
     * To get the exact quantity of a specific deal in the database and return a Promise object.
     * @author Guozhi Tang
     * @date 2020-04-24
     * @return Promise - return a Promise object containing the exact quantity user can take.
     */
    PublicDealsComponent.prototype.checkQtyInDB = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dealService
                .checkDealQty(_this.dealList[_this.selectProductId].creator, _this.dealList[_this.selectProductId].id)
                .pipe(first())
                .subscribe(function (data) {
                _this.checkQty = data[0].quantity;
                if (_this.inbound.quantity > data[0].quantity) {
                    _this.inbound.quantity = data[0].quantity;
                }
                resolve(_this.inbound.quantity);
            });
        });
    };
    /**
     * Get the exact quantity of tickets can take firstly, and then create an inbound
     * @author Guozhi Tang
     * @date 2020-04-24
     */
    PublicDealsComponent.prototype.createInbound = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.checkQtyInDB()];
                    case 1:
                        _a.sent();
                        if (this.selfWarehouse) {
                            this.inbound.warehouse = this.selectPrivateWarehouse;
                        }
                        else {
                            this.inbound.warehouse = this.selectPublicWarehouse;
                        }
                        // if(this.inbound.quantity > this.dealList[this.selectProductId].quantity) {
                        //   this.inbound.quantity = this.dealList[this.selectProductId].quantity;
                        // }
                        this.inboundService
                            .create(this.inbound)
                            .pipe(first())
                            .subscribe(function (data) {
                            _this.ngOnInit();
                            _this.isCreated = true;
                            _this.inbound.clear();
                            _this.selectProductId = null;
                        }, function (error) {
                            console.log(error);
                        }, function () {
                            _this.inbound.clear();
                            _this.selectPrivateWarehouse = null;
                            _this.selectPublicWarehouse = null;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PublicDealsComponent.prototype.createPropose = function () {
        var _this = this;
        if (this.selfWarehouse) {
            this.inbound.warehouse = this.selectPrivateWarehouse;
        }
        else {
            this.inbound.warehouse = this.selectPublicWarehouse;
        }
        this.inbound.propose = 1;
        // console.log(this.inbound);
        this.inboundService
            .createPropose(this.inbound)
            .pipe(first())
            .subscribe(function (data) {
            _this.inbound.clear();
            _this.selectProductId = null;
        }, function (error) {
            console.log(error);
        }, function () {
            _this.inbound.clear();
            _this.selectPrivateWarehouse = null;
            _this.selectPublicWarehouse = null;
        });
    };
    PublicDealsComponent.prototype.updateCompany = function () {
        var _this = this;
        /*
            if (this.awardsUsers) {
              this.update();
            }
        */
        if (this.isAwards) {
            this.update();
        }
        var date = this.year +
            '-' +
            this.month +
            '-' +
            this.days +
            ' ' +
            this.hour +
            ':' +
            this.minutes +
            ':' +
            '00';
        this.selectDeal.expiresAt = date;
        if (this.membersTemp) {
            this.selectDeal.members = this.membersTemp.join();
        }
        this.dealService
            .updateCompany(this.selectDeal)
            .pipe(first())
            .subscribe(function (data) {
            _this.ngOnInit();
            _this.awards = 0;
            _this.selectDeal.clear();
            window.location.reload();
        });
    };
    PublicDealsComponent.prototype.emailNotification = function () {
        if (this.selectDeal.notify === true) {
            var date = this.year +
                '-' +
                this.month +
                '-' +
                this.days +
                ' ' +
                this.hour +
                ':' +
                this.minutes +
                ':' +
                '00';
            this.selectDeal.expiresAt = date;
            this.email.dealProduct = this.selectDeal.products;
            this.email.emailList = this.selectDeal.members;
            this.email.receiver = this.receiver;
            this.email.emailSubject = this.selectDeal.creator + ' | ' + ' New Offer : ';
            this.email.emailTextBeforeImage = this.selectDeal.creator + ' has created a new offer<br><br>' + 'Product: ' + this.selectDeal.productName + '<br><br>';
            var htmlNote = this.selectDeal.note.replace(/(\r\n|\n|\r)/gm, "<br>");
            this.email.emailTextAfterImage = '<br><br>Price: $' + this.selectDeal.price + '<br><br>Quantity: ' + this.selectDeal.quantity + '<br><br>Expire time: ' + this.selectDeal.expiresAt + '<br><br><font color="red">Note:<br><br>' + htmlNote + '</font><br><br>Visit the link below to see all active offers<br><br>m.brandnewegg.com/deals/active';
            this.dealService
                .emailNotification(this.email)
                .pipe(first())
                .subscribe(function (data) {
                console.log(data);
            }, function (error) {
                console.log(error);
            }, function () {
                console.log('done');
            });
        }
    };
    PublicDealsComponent.prototype.useSelfWarehouse = function () {
        this.selfWarehouse = true;
        this.inbound.publicWarehouse = false;
        this.privateStorage(this.creator);
    };
    PublicDealsComponent.prototype.usePublicWarehouse = function () {
        this.selfWarehouse = false;
        this.inbound.publicWarehouse = true;
    };
    PublicDealsComponent.prototype.makeAwards = function () {
        var _this = this;
        this.isAwards = true;
        this.awardsUsersUnique = [];
        this.awardsUsersUniqueQty = [];
        this.inboundService
            .findAwardsUser(this.selectDeal.id)
            .pipe(first())
            .subscribe(function (data) {
            _this.awardsUsers = data;
            /*
                    while (this.awardsUsers.length >= 1) {
                      let currentAwardsUser = this.awardsUsers.pop();
                      if (currentAwardsUser !== undefined) {
                        if (
                          !this.awardsUsersUnique.includes(currentAwardsUser.individual)
                        ) {
                          this.awardsUsersUnique.push(currentAwardsUser.individual);
                          this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                            currentAwardsUser.quantity;
                        } else {
                          this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                            currentAwardsUser.quantity;
                        }
                      }
                    }
            */
            var i;
            for (i = 0; i < _this.awardsUsers.length; i++) {
                var currentAwardsUser = _this.awardsUsers[i];
                if (currentAwardsUser !== undefined) {
                    if (!_this.awardsUsersUnique.includes(currentAwardsUser.individual)) {
                        _this.awardsUsersUnique.push(currentAwardsUser.individual);
                        _this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                            currentAwardsUser.quantity;
                    }
                    else {
                        _this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                            currentAwardsUser.quantity;
                    }
                }
            }
        });
    };
    PublicDealsComponent.prototype.cancelAwards = function () {
        this.isAwards = false;
        this.awardsUsers = null;
    };
    PublicDealsComponent.prototype.update = function () {
        var _this = this;
        var i = 0;
        var awardsIds = new Array();
        if (this.awardsUsers) {
            while (this.awardsUsers[i]) {
                awardsIds.push(this.awardsUsers[i].id);
                i++;
            }
        }
        this.inboundService
            .updateAwards(awardsIds, this.selectDeal.price, this.awards)
            .pipe(first())
            .subscribe(function (data) {
            _this.isUpdateAwards = true;
        }, function (error) {
            console.log(error);
        }, function () {
            _this.awardsUsers = null;
        });
    };
    PublicDealsComponent.prototype.makePublic = function () {
        var _this = this;
        this.membersTemp = '';
        this.isPublic = true;
        this.isPrivate = false;
        this.selectDeal.dealPublic = true;
        this.groupService
            .allSubscribers(this.selectDeal.creator)
            .pipe(first())
            .subscribe(function (data) {
            _this.memberList = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
                $('.selectpicker').selectpicker('selectAll');
            });
        });
    };
    PublicDealsComponent.prototype.makePrivate = function () {
        var _this = this;
        this.membersTemp = '';
        this.isPublic = false;
        this.isPrivate = true;
        this.selectDeal.dealPublic = false;
        this.groupService
            .groupRetrieve(this.selectDeal.creator)
            .pipe(first())
            .subscribe(function (data) {
            _this.groupList = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
            });
        });
    };
    PublicDealsComponent.prototype.Storage = function (dealCreator) {
        var _this = this;
        this.storageService
            .retrieve(dealCreator)
            .pipe(first())
            .subscribe(function (data) {
            _this.publicWarehouse = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
            });
        });
    };
    PublicDealsComponent.prototype.privateStorage = function (creator) {
        var _this = this;
        //console.log(creator);
        this.storageService
            .retrieve(creator)
            .pipe(first())
            .subscribe(function (data) {
            _this.privateWarehouse = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
            });
        });
    };
    PublicDealsComponent.prototype.compareWh = function (warehouse1, warehouse2) {
        //return warehouse1.id === warehouse2.id;
        return warehouse1 && warehouse2 ? warehouse1.id === warehouse2.id : warehouse1 === warehouse2;
    };
    //统计总页数
    PublicDealsComponent.prototype.getAllCount = function (creator, company, filter, deals, iscompany) {
        var _this = this;
        this.dealService
            .getAllCount(creator, company, filter, deals, iscompany)
            .pipe(first())
            .subscribe(function (data) {
            _this.total = data["count"];
        });
    };
    //Kendo分页函数  
    PublicDealsComponent.prototype.onPageChange = function (e) {
        this.skip = e.skip;
        this.pageSize = e.take;
        this.currentUser = this.authenticationService.currentUserValue[0];
        //this.getAllCount(this.currentUser,this.currentUser,this.filter,'PublicDeals',this.IsCompany);     
        this.dealList = new Array();
        if (this.authenticationService.currentUserValue[2] === 'company') {
            this.enableCreate();
            this.privilege = true;
            this.getPublicList(this.creator);
        }
        else {
            this.privilege = false;
            this.getfollowing(this.creator);
            //this.getRecords(this.currentUser);
        }
    };
    return PublicDealsComponent;
}());
export { PublicDealsComponent };
