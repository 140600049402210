import { Component, OnInit,Input } from '@angular/core';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../models/product';
import { Map } from '../../../models/map';
import { MapService } from '../../../services/map.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { first } from 'rxjs/operators';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-products-active-list',
  templateUrl: './products-active-list.component.html',
  styleUrls: ['./products-active-list.component.scss'],
  providers: [ProductService, AuthenticationService, MapService]
})
export class ProductsActiveListComponent implements OnInit {
  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';

  public currentUser: any;
  public privilege: any;
  public activeProductsList: any;
  public selectProduct = new Product(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
  public isUpdate = false;
  public mapArray : any;
  public mapType = "bundle";
  @Input('answers') ans:any;

  constructor(
    private productService: ProductService,
    public authenticationService: AuthenticationService,
    private mapService: MapService
  ) {}

  ngOnInit() {
    //debugger;
    if (!this.ans) return;
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.getActiveList(this.currentUser);
        this.getAllCount(this.currentUser,this.filter);
      } else {
        this.privilege = false;
      }
    }
  }

  addMap() {
    this.mapArray.push(new Map(null, '', '', null));
    //console.log(this.mapArray);
  }

  removeMap(mapId: any) {
    this.mapArray.splice(mapId, 1);
    //console.log(this.mapArray);
  }

  getProductMap(productId) {
    this.mapService
      .allMap(productId)
      .pipe(first())
      .subscribe(data => {
        this.mapArray = data;
        if (this.mapArray !== undefined && this.mapArray.length > 0) {
          this.mapType = this.mapArray[0].type;
        }
      });
  }

  deleteProductMap(productId) {
    this.mapService
      .deleteMap(productId)
      .pipe(first())
      .subscribe(data => {
      });
  }

  /**
   * Get the active products list for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getActiveList(individual) {
    this.productService
      .allProduct(individual,this.filter,this.skip,this.pageSize)
      .pipe(first())
      .subscribe(data => {
        this.activeProductsList = data;
      });
  }
  
  editProductSelect(i) {
    // console.log(i);
    if (i >= 0) {
      this.selectProduct.id = this.activeProductsList[i].id;
      this.selectProduct.name = this.activeProductsList[i].name;
      this.selectProduct.condition = this.activeProductsList[i].product_condition;
      this.selectProduct.msrp = this.activeProductsList[i].msrp;
      this.selectProduct.asin = this.activeProductsList[i].asin;
      this.selectProduct.upc = this.activeProductsList[i].upc;
      this.selectProduct.weight = this.activeProductsList[i].weight;
      this.selectProduct.note = this.activeProductsList[i].note;
      this.selectProduct.creator = this.activeProductsList[i].creator;
      this.selectProduct.link = this.activeProductsList[i].link;
      this.getProductMap(this.activeProductsList[i].id);
    }
  }

  update() {
    //console.log(this.mapArray);
    this.deleteProductMap(this.selectProduct.id);
    var i;
    for (i=0; i<this.mapArray.length; i++) {
      var currentUPC = this.mapArray[i].upc.trim();
      if(currentUPC!=="" && this.mapArray[i].quantity>0) {
        let singleMap = new Map(null, '', '', null);
        singleMap.productId = parseInt(this.selectProduct.id);
        singleMap.type = this.mapType;
        singleMap.upc = currentUPC;
        singleMap.quantity = this.mapArray[i].quantity;
        this.mapService
          .createMap(singleMap)
          .pipe(first())
          .subscribe(
            data => {
              //console.log(data);
            },
            error => {
              console.log(error);
            },
            () => {
            }
          );
      }
    }

    this.productService
      .updateProduct(this.selectProduct)
      .pipe(first())
      .subscribe(
        data => {
          this.isUpdate = true;
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngOnInit();
          this.selectProduct.clear();
        }
      );
  }

  //统计总页数
  getAllCount(company,filter) {
    this.productService
      .getAllCount(company,filter)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }

  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.getActiveList(this.currentUser);
        this.getAllCount(this.currentUser,this.filter);
    } else {
        this.privilege = true;
    } 
  }   



}
