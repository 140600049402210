/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./activate.component";
var styles_ActivateComponent = [i0.styles];
var RenderType_ActivateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivateComponent, data: {} });
export { RenderType_ActivateComponent as RenderType_ActivateComponent };
export function View_ActivateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["activated!"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "alert-link"], ["routerLink", "/signin"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Click here back to sign in"]))], function (_ck, _v) { var currVal_2 = "/signin"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_ActivateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activate", [], null, null, null, View_ActivateComponent_0, RenderType_ActivateComponent)), i1.ɵdid(1, 114688, null, 0, i4.ActivateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivateComponentNgFactory = i1.ɵccf("app-activate", i4.ActivateComponent, View_ActivateComponent_Host_0, {}, {}, []);
export { ActivateComponentNgFactory as ActivateComponentNgFactory };
