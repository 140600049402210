export class Map {
  public id: number;
  public productId: number;
  public type: string;
  public upc: string;
  public quantity: number;
  constructor(
    productId: number,
    type: string,
    upc: string,
    quantity: number
  ) {
    this.productId = productId;
    this.type = type;
    this.upc = upc;
    this.quantity = quantity;
  }

  public clear() {
    this.productId = null;
    this.type = '';
    this.upc = '';
    this.quantity = null;
  }
}
