<div class="wrapper" *ngIf="!noRecord">
<table class="table table-hover" id="inboundTable" >
  <thead class="thead-light">
    <tr>
      <th scope="col" style="width: 9%" class="hide">#</th>
      <th scope="col" style="width: 30%">Inbound</th>
      <th scope="col" style="width: 10%" *ngIf="privilege" class="hide">
        From
      </th>
      <th scope="col" style="width: 10%" *ngIf="!privilege" class="hide">Company</th>
      <th scope="col" style="width: 9%" class="hide">Price</th>
      <th scope="col" style="width: 9%" class="hide">Quantity</th>
      <th scope="col" style="width: 12%" class="hide">Warehouse</th>
      <th scope="col" style="width: 12%" class="hide">Created Date</th>
      <th scope="col" style="width: 9%" *ngIf="privilege" class="hide">Status</th>
      <!--<th scope="col" style="width: 9%" *ngIf="!privilege" class="hide">Action</th>-->
      <th scope="col" style="width: 9%" class="hide">Action</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let record of recordList; index as i">
      <tr>
<!--
        <th scopr="row" class="hide">{{ ('00000' + record.id).slice(-6) }}</th>
-->
        <th scopr="row" class="hide">
          <div>
            {{ ('00000' + record.id).slice(-6) }}
            <br><br>
            <img src="{{ record.product_link }}" />
          </div>
        </th>
        <td>
          <div>
            <b class="show">{{ ('00000' + record.id).slice(-6) }} </b>
            <span>{{ record.product }}</span>
            <p class="text-primary show">
              {{ record.createdAt | date: 'medium' }}
            </p>
            <p class="text-danger show" *ngIf="privilege">
              {{ record.individual }}
            </p>
            <p class="text-info show" *ngIf="!privilege">
              <b>{{ record.company }}</b>
            </p>
            <div class="text-danger show">
              {{ record.price }}
              <div *ngIf="!record.publicWarehouse && record.bonus!=0">+ {{ record.bonus }}(bonus)</div>
              <div *ngIf="record.awards!=0">+ {{ record.awards }}(awards)</div>
              Quantity: {{ record.quantity }}
            </div>
            <p class="text-danger show">Warehouse: {{ record.warehouse }}</p>
            <!-- </ng-container> -->
          </div>
          <button
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm show"
          data-toggle="modal"
          data-target="#proposeEditForm"
          *ngIf="!record.status && !privilege"
          (click)="select(i)"
        >
          Edit
        </button>
        <button
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm show"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          *ngIf="!record.status && !privilege"
          (click)="select(i)"
        >
          Confirm
        </button>
        <button *ngIf="privilege"
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm show"
          data-toggle="modal"
          data-target="#ViewproposeConfirmForm"
          (click)="select(i)"
          >
          View
          </button>
        </td>
        <td *ngIf="privilege" class="hide">{{ record.individual }}</td>
        <!-- <td *ngIf="!privilege">{{ record.individual }}</td> -->
        <td class="hide text-info" *ngIf="!privilege"><b>{{ record.company }}</b></td>
        <td class="hide text-success">
          ${{ record.price }}
          <div *ngIf="!record.publicWarehouse">+ {{ record.bonus }} (bonus)</div>
          <div *ngIf="record.awards">+ {{ record.awards }}(awards)</div>
        </td>
        <td class="hide">{{ record.quantity }}</td>
        <td class="hide">{{ record.warehouse }}</td> 
        <td class="hide">{{ record.createdAt | date: 'medium' }}</td>
        <td class="hide">
          <p *ngIf="privilege && record.status===0" class="text-warning hide">{{ status[record.status] }}</p>
          <p *ngIf="privilege && record.status===3" class="hide">{{ status[record.status] }}</p>
          <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#proposeEditForm"
          *ngIf="!record.status && !privilege"
          (click)="select(i)"
        >
          Edit
        </button>
        <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          *ngIf="!record.status && !privilege"
          (click)="select(i)"
        >
          Confirm
        </button>
        </td>
        <td class="hide" *ngIf="privilege">
         
        <!--允许公司账号查看-->
        <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#ViewproposeConfirmForm"
          *ngIf="privilege"
          (click)="select(i)"
          >
          View
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<!--分页实现Begin-->
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  [type]="type"
  (pageChange)="onPageChange($event)">
</kendo-datapager>

</div>

<div class="wrapper" *ngIf="noRecord">

<table class="table" id="inboundTable">
  <thead>
    <tr>
      <th scope="col" style="width: 10%" class="hide">#</th>
      <th scope="col" style="width: 30%">Inbound</th>
      <th scope="col" style="width: 10%" *ngIf="privilege" class="hide">
        From
      </th>
      <th scope="col" style="width: 10%" *ngIf="!privilege" class="hide">Company</th>
      <th scope="col" style="width: 9%" class="hide">Price</th>
      <th scope="col" style="width: 9%" class="hide">Quantity</th>
      <th scope="col" style="width: 11%" class="hide">Warehouse</th>
      <th scope="col" style="width: 11%" class="hide">Created Date</th>
      <th scope="col" style="width: 10%" *ngIf="privilege" class="hide">Status</th>
      <!--<th scope="col" style="width: 10%" *ngIf="!privilege" class="hide">8Action</th>-->
      <th scope="col" style="width: 10%" class="hide">8Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scopr="row" class="hide"></th>
      <td>
        No Inbound Record......
      </td>
      <td *ngIf="privilege" class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide">
      </td>
    </tr>
  </tbody>
</table>

<!--分页实现Begin-->
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  [type]="type"
  (pageChange)="onPageChange($event)">
</kendo-datapager>

</div>
<div
class="modal fade"
id="proposeEditForm"
tabindex="-1"
role="dialog"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="formTitle">Edit a propose</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="product" class="col-form-label"
            >Product:</label
          >
          <input type="text" class="form-control" value="{{selectInbound.product}}" readonly>
        </div>
        <div class="form-group">
          <label for="price" class="col-form-label">Price:</label>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="number" class="form-control" name="price" value="{{selectInbound.price}}" readonly>
            </div>
        </div>
        <div class="form-group">
          <label for="quantity" class="col-form-label"
            >Quantity:</label
          >
          <input
            type="number"
            class="form-control"
            id="quantity"
            name="quantity"
            [(ngModel)] = "selectInbound.quantity"
          />
        </div>
        <div class="form-group">
          <label for="warehouse" class="col-form-label"
            >Warehouse:</label
          >
          <input
            type="text"
            class="form-control"
            id="warehouse"
            name="warehouse"
            value="{{selectInbound.warehouse}}"
            readonly
          />
        </div>

      </form>
      
      <!--包裹尺寸begin-->
      <!--
      隐藏 Request Shipping Label tomlee by 2022.02.28
      <button class="btn btn-primary btn-sm mt-1" (click)="CheckShippingLabel(1)" *ngIf="!IsshippingLabel && !isPublicWarehouse">
              Request Shipping Label
      </button>
      -->

      <button class="btn btn-primary btn-sm mt-1" (click)="CheckShippingLabel(0)" *ngIf="IsshippingLabel && !isPublicWarehouse">
              Hide Shipping Label Info
      </button>    
      
      <hr />

      <form class="form-horizontal" *ngIf="PackageSizeForm && IsshippingLabel && !isPublicWarehouse" [formGroup]="PackageSizeForm">
                  
         
        <div class="form-group">
          <label for="packagesize" class="col-form-label">Add package dimension info:</label>
        </div>
        <div class="form-group">
           
            <button                
                type="button" 
                (click)="onAddPackage()"
                class="btn btn-primary btn-sm fas fa-plus-square"
                style="margin-right: 8px"
             ><!--<i class="btn btn-success far fa-plus"></i>-->
             Add 
            </button> 

            <button
             type="button" 
             class="btn btn-success btn-sm far fa-edit"
             (click)="onSavePackage()"
             data-dismiss="modal"
            >
                Update
          </button> 

        </div>
       

         <!--<table class="table table-bordered">-->
         <table class="table table-hover">
          <!--
          <thead>
            <tr>
              <th scope="col" style="width: 6%"></th>
              <th scope="col" style="width: 12%">Length</th>
              <th scope="col" style="width: 3%"></th>
              <th scope="col" style="width: 12%">Width</th>
              <th scope="col" style="width: 3%"></th>
              <th scope="col" style="width: 12%">Height</th>
              <th scope="col" style="width: 8%"></th>
              <th scope="col" style="width: 16%">Weight</th>
              <th scope="col" style="width: 8%"></th>
              <th scope="col" style="width: 10%">Qty</th>
            </tr>
          </thead>-->
          <tbody>
            <tr  formArrayName="packageList" *ngFor="let package of PackageSizeForm.get('packageList').controls; let i = index">              
              <ng-container [formGroupName]="i">

                <td [hidden]="true">
                  <input class="hide" type="text" [id]="'id' + i" placeholder="packageId"  formControlName="id"/>                
                </td>
                <!--
                <td *ngIf="i==0">
                  <button  
                      mat-button type="button" 
                      (click)="onAddPackage()"
                  ><i class="fas fa-plus-square"></i>          
                  </button>
                </td>-->                
                <td> <!-- *ngIf="i!=0">-->
                  <button  mat-button type="button" (click)="removePackage(i)" class="btn btn-info btn-sm"
                  > <span class="glyphicon glyphicon-minus"></span>
                  </button>
                </td>
                <td colspan="9">
                  <div class="row parent">
                   
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 5px">
                        <label for="inlineFormX">Length</label>
                        <div class="input-group">
                          <input
                           type="number"                     
                           min="0"
                           class="form-control" 
                           [id]="'length' + i" 
                           placeholder="Length"  
                           formControlName="length"
                          />  
                          <label for="inputX" class="col-form-label" style="padding-left: 15px;">X</label>
                        </div>                  
                    </div>
                    
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 5px">                    
                      <label for="inlineFormX">Width</label>
                      <div class="input-group">
                        <input 
                          type="number"                    
                          min="0"
                          class="form-control" 
                          [id]="'width' + i" 
                          placeholder="width"  
                          formControlName="width"                           
                        />
                        <label for="inputX" class="col-form-label" style="padding-left: 15px;">X</label>
                      </div>                      
                    </div>                    
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 1px">
                      <label for="inlineFormX">Height</label>
                      <div class="input-group">
                       <input 
                         type="number"                    
                         min="0"
                         class="form-control" 
                         [id]="'height' + i" 
                         placeholder="Height"  
                         formControlName="height"                           
                       />
                       <label for="inputX" class="col-form-label">(IN)</label>
                      </div>
                    </div>
                    <div class="col-md-1 form-group text-center"></div>
                    <div class="col-md-3 form-group text-center" style="padding-left: 15px;padding-right: 10px">
                        <label for="inlineFormX">Weight</label>
                        <div class="input-group">
                          <input 
                            type="number"
                            min="1" 
                            class="form-control" 
                            [id]="'weight' + i" 
                            placeholder="Weight"  
                            formControlName="weight"                           
                          />
                          <label for="inputX" class="col-form-label">(LB)</label>
                        </div>                      
                    </div>
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 40px">
                      <label for="inlineFormX">Qty</label>
                      <div class="input-group">
                       <input 
                        type="number"
                        min="1"
                        ng-init="qty=1"
                        class="form-control" 
                        [id]="'qty' + i" 
                        placeholder="Qty"  
                        formControlName="qty"                           
                       />
                     </div>
                    </div>

                    <!--
                    <div class="col-md-1" style="vertical-align: middle !important;text-align: center;"><span>X</span></div>-->

                  </div>                                                     
                </td>
                
              </ng-container>
            </tr>
          </tbody>
        </table>       
      </form>
    
      <!--包裹尺寸end-->
    </div>
    
    <div class="modal-footer">

      <!--<div class="container text-right">-->
      <!--
      <button   
         *ngIf="PackageSizeForm && IsshippingLabel && !isPublicWarehouse"       
         type="button" 
         class="btn btn-success far fa-edit"
         (click)="onSavePackage()"
         data-dismiss="modal"
      >
            Update
      </button>-->
         <!--</div> -->
      
      <button
        type="button"
        class="btn btn-secondary far fa-window-close"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
      type="button"
      class="btn btn-primary far fa-trash-alt"
      (click)="cancelInbound()"
      data-dismiss="modal"
    >
      Cancel
    </button>
<!--
      <button
        type="button"
        class="btn btn-primary"
        (click)="updateInboundQty()"
        data-dismiss="modal"
      >
        Confirm
      </button>
-->
    </div>
  </div>
</div>
</div>
<div
class="modal fade"
id="proposeConfirmForm"
tabindex="-1"
role="dialog"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="formTitle">Confirm a propose</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="product" class="col-form-label"
            >Product:</label
          >
          <input type="text" class="form-control" value="{{selectInbound.product}}" readonly>
        </div>
        <div class="form-group">
          <label for="price" class="col-form-label">Price:</label>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="number" class="form-control" name="price" value="{{selectInbound.price}}" readonly>
            </div>
        </div>
        <div class="form-group">
          <label for="quantity" class="col-form-label">Quantity:</label>
          <input type="number" class="form-control" id="quantity" name="quantity" value="{{selectInbound.quantity}}" readonly/>
        </div>
        <div class="form-group">
          <label for="warehouse" class="col-form-label"
            >Warehouse:</label
          >
          <input
            type="text"
            class="form-control"
            id="warehouse"
            name="warehouse"
            value="{{selectInbound.warehouse}}"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="inbound-tracking" class="col-form-label">Trackings:</label>
            <textarea
              type="text"
              class="form-control"
              id="inbound-tracking"
              rows="8"
              name="tracking"
              [(ngModel)]="inputTrackings"
            ></textarea>
        </div>
      </form>
      <!--包裹尺寸begin-->
      <!--
      隐藏 Request Shipping Label tomlee by 2022.02.28
      <button class="btn btn-primary btn-sm mt-1" (click)="CheckShippingLabel(1)" *ngIf="!IsshippingLabel && !isPublicWarehouse">
              Request Shipping Label
      </button>
      -->

      <button class="btn btn-primary btn-sm mt-1" (click)="CheckShippingLabel(0)" *ngIf="IsshippingLabel && !isPublicWarehouse">
              Hide Shipping Label Info
      </button>    
      
      <hr />

      <form class="form-horizontal" *ngIf="PackageSizeForm && IsshippingLabel && !isPublicWarehouse" [formGroup]="PackageSizeForm">
                  
         
      <div class="form-group">
          <label for="packagesize" class="col-form-label">Add package dimension info:</label>
        </div>
        <div class="form-group">
           
            <button                
                type="button" 
                (click)="onAddPackage()"
                class="btn btn-primary btn-sm fas fa-plus-square"
                style="margin-right: 8px"
             ><!--<i class="btn btn-success far fa-plus"></i>-->
             Add           
            </button> 
            <!--
            <button
             type="button" 
             class="btn btn-secondary btn-sm far fa-edit"
             (click)="onSavePackage()"
             data-dismiss="modal"           
            >
                Update
          </button> -->

        </div>
       
       <!--<table class="table table-bordered">-->
       <table class="table table-hover">
        <!--
        <thead>
          <tr>
            <th scope="col" style="width: 6%"></th>
            <th scope="col" style="width: 12%">Length</th>
            <th scope="col" style="width: 3%"></th>
            <th scope="col" style="width: 12%">Width</th>
            <th scope="col" style="width: 3%"></th>
            <th scope="col" style="width: 12%">Height</th>
            <th scope="col" style="width: 8%"></th>
            <th scope="col" style="width: 16%">Weight</th>
            <th scope="col" style="width: 8%"></th>
            <th scope="col" style="width: 10%">Qty</th>
          </tr>
        </thead>-->
        <tbody>
          <tr  formArrayName="packageList" *ngFor="let package of PackageSizeForm.get('packageList').controls; let i = index">              
              <ng-container [formGroupName]="i">

                <td [hidden]="true">
                  <input class="hide" type="text" [id]="'id' + i" placeholder="packageId"  formControlName="id"/>                
                </td>
                <!--
                <td *ngIf="i==0">
                  <button  
                      mat-button type="button" 
                      (click)="onAddPackage()"
                  ><i class="fas fa-plus-square"></i>          
                  </button>
                </td>-->               
                <td> <!-- *ngIf="i!=0">-->
                  <button  mat-button type="button" (click)="removePackage(i)"
                  ><i class="fas fa-minus-square"></i>
                  </button>
                </td>
                <td colspan="9">
                  <div class="row parent">
                   
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 5px">
                        <label for="inlineFormX">Length</label>
                        <div class="input-group">
                          <input
                           type="number"                     
                           min="1"
                           class="form-control" 
                           [id]="'length' + i" 
                           placeholder="Length"  
                           formControlName="length"
                          />  
                          <label for="inputX" class="col-form-label" style="padding-left: 15px;">X</label>
                        </div>                  
                    </div>
                    
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 5px">                    
                      <label for="inlineFormX">Width</label>
                      <div class="input-group">
                        <input 
                          type="number"                    
                          min="1"
                          class="form-control" 
                          [id]="'width' + i" 
                          placeholder="width"  
                          formControlName="width"                           
                        />
                        <label for="inputX" class="col-form-label" style="padding-left: 15px;">X</label>
                      </div>                      
                    </div>                    
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 1px">
                      <label for="inlineFormX">Height</label>
                      <div class="input-group">
                       <input 
                         type="number"                    
                         min="1"
                         class="form-control" 
                         [id]="'height' + i" 
                         placeholder="Height"  
                         formControlName="height"                           
                       />
                       <label for="inputX" class="col-form-label">(IN)</label>
                      </div>
                    </div>
                    <div class="col-md-1 form-group text-center"></div>
                    <div class="col-md-3 form-group text-center" style="padding-left: 15px;padding-right: 10px">
                        <label for="inlineFormX">Weight</label>
                        <div class="input-group">
                          <input 
                            type="number"
                            min="1" 
                            class="form-control" 
                            [id]="'weight' + i" 
                            placeholder="Weight"  
                            formControlName="weight"                           
                          />
                          <label for="inputX" class="col-form-label">(LB)</label>
                        </div>                      
                    </div>
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 40px">
                      <label for="inlineFormX">Qty</label>
                      <div class="input-group">
                       <input 
                        type="number"
                        min="1"
                        ng-init="qty=1"
                        class="form-control" 
                        [id]="'qty' + i" 
                        placeholder="Qty"  
                        formControlName="qty"                           
                       />
                     </div>
                    </div>

                    <!--
                    <div class="col-md-1" style="vertical-align: middle !important;text-align: center;"><span>X</span></div>-->

                  </div>                                                     
                </td>
                
              </ng-container>
            </tr>
        </tbody>
      </table>       
    </form>
    
      <!--包裹尺寸end-->
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
      type="button"
      class="btn btn-primary"
      (click)="addTracking()"
      data-dismiss="modal"
    >
      Confirm
    </button>
<!--
      <button
        type="button"
        class="btn btn-primary"
        (click)="updateInboundQty()"
        data-dismiss="modal"
      >
        Confirm
      </button>
-->
    </div>
  </div>
</div>
</div>

<!--公司账号查看-->
<div
class="modal fade"
id="ViewproposeConfirmForm"
tabindex="-1"
role="dialog"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="formTitle">Waiting for confirmation a propose</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="product" class="col-form-label"
            >Product:</label
          >
          <input type="text" class="form-control" value="{{selectInbound.product}}" readonly>
        </div>
        <div class="form-group">
          <label for="price" class="col-form-label">Price:</label>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="number" class="form-control" name="price" value="{{selectInbound.price}}" readonly>
            </div>
        </div>
        <div class="form-group">
          <label for="quantity" class="col-form-label">Quantity:</label>
          <input type="number" class="form-control" id="quantity" name="quantity" value="{{selectInbound.quantity}}" readonly/>
        </div>
        <div class="form-group">
          <label for="warehouse" class="col-form-label"
            >Warehouse:</label
          >
          <input
            type="text"
            class="form-control"
            id="warehouse"
            name="warehouse"
            value="{{selectInbound.warehouse}}"
            readonly
          />
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="map-upc" class="col-form-label">UPC:</label>
          </div>
          <div class="form-group col-md-3">
            <label for="inbound-qty" class="col-form-label">Proposed:</label>
          </div>
          <div class="form-group col-md-3">
            <label for="confirm-qty" class="col-form-label">Confirmed:</label>
          </div>
          <!-- 不清楚用意 注释
          <ng-container *ngFor="let currentMap of mapList; let i = index">
            <div class="form-group col-md-6">
              <input type="text" class="form-control" value="{{currentMap.upc}}" readonly>
            </div>
            <div class="form-group col-md-3">
              <input type="text" class="form-control" value="{{currentMap.quantity*selectInbound.quantity}}" readonly>
            </div>
            <div class="form-group col-md-3">
              <input type="text" class="form-control" value="{{confirmedList[i]}}" readonly>
            </div>
          </ng-container>
        
          <div class="form-group col-md-12" *ngIf="maptype=='single' && maplength>1">
            <label for="map-explaination" class="col-form-label text-danger">各个upc最终confirmed数量之和达到proposed数量即可</label>
          </div>  -->     
        </div>
        <!--
        <div class="form-group">
          <label for="inbound-tracking" class="col-form-label">Trackings:</label>
            <ng-container *ngFor="let currentTracking of trackingList; let i = index">
              <div class="form-group">
                <input type="text" class="form-control" value="{{currentTracking.tracking}}" readonly>
              </div>
            </ng-container>
            <textarea *ngIf="!privilege"
              type="text"
              class="form-control"
              id="inbound-tracking"
              rows="8"
              name="tracking"
              [(ngModel)]="inputTrackings"
            ></textarea>
        </div>-->
      </form>
      <!--包裹尺寸begin-->
      <form class="form-horizontal" *ngIf="PackageSizeForm && !isPublicWarehouse" [formGroup]="PackageSizeForm">                  
         
         <label for="packagesize" class="col-form-label" *ngIf="IsshippingLabel">Requested shipping label info:</label>     
         
         <!--<table class="table table-bordered">-->
         <table class="table table-condensed table-bordered">         
          <tbody>
            <tr  formArrayName="packageList" *ngFor="let package of PackageSizeForm.get('packageList').controls; let i = index">              
              <ng-container [formGroupName]="i">

               
                <td colspan="9">
                  <div class="row parent">
                   
                   <!--<label>Ville de chargement</label>-->
                    <div class="col-sm-2 form-group text-center" style="padding-left: 3px;padding-right: 1px">
                        <label for="inlineFormX" *ngIf="i==0">Length</label>
                        <div class="input-group">
                          <input
                           type="number"                     
                           min="1"
                           class="form-control" 
                           [id]="'length' + i" 
                           formControlName="length"
                           readonly
                          />  
                          <label for="inputX" class="col-form-label">X</label>
                        </div>                  
                    </div>
                    
                    <div class="col-sm-2 form-group text-center" style="padding-left: 1px;padding-right: 1px">                    
                      <label for="inlineFormX" *ngIf="i==0">Width</label>
                      <div class="input-group">
                        <input 
                          type="number"
                          min="1"
                          ng-init="width=0"
                          class="form-control" 
                          [id]="'width' + i" 
                          formControlName="width"
                          readonly
                        />
                        <label for="inputX" class="col-form-label">X</label>
                      </div>                      
                    </div>                    
                    <div class="col-sm-3 form-group text-center" style="padding-left: 0px;padding-right: 10px">
                      <label for="inlineFormX" *ngIf="i==0">Height</label>
                      <div class="input-group">
                       <input 
                         type="number"                    
                         min="1"
                         class="form-control" 
                         [id]="'height' + i" 
                         formControlName="height"
                         readonly                          
                       />
                       <label for="inputX" class="col-form-label">(IN)</label>              
                      </div>
                    </div><!--
                    <div class="col-sm-1 form-group text-center" style="padding-left: 1px;padding-right: 1px"></div>-->
                    <div class="col-sm-3 form-group text-center" style="padding-left: 15px;padding-right: 5px">
                        <label for="inlineFormX" *ngIf="i==0">Weight</label>
                        <div class="input-group">
                          <input 
                            type="number"
                            min="1" 
                            class="form-control" 
                            [id]="'weight' + i" 
                            formControlName="weight"
                            readonly                           
                          />
                          <label for="inputX" class="col-form-label">(LB)</label>
                        </div>                      
                    </div>
                    <div class="col-sm-2 form-group text-center" style="padding-left: 5px;padding-right: 5px">
                      <label for="inlineFormX" *ngIf="i==0">Qty</label>
                      <div class="input-group">
                       <input 
                        type="number"
                        min="1"
                        ng-init="qty=1"
                        class="form-control" 
                        [id]="'qty' + i" 
                        formControlName="qty"
                        readonly                           
                       />
                     </div>
                    </div>                  

                  </div>                                                    
                </td>   

              </ng-container>
            </tr>
          </tbody>
        </table>
        <!--管理员账号可以取消护士的包裹尺寸-->
        <div class="form-check k-form-field-checkbox-wrap" *ngIf="IsshippingLabel">
          <input
             class="form-check-input"
             type="checkbox"
             id="notify"
             name="emailnotify"
             [checked]="notify"
          />
          <label class="form-check-label" for="notify">
             Allow automatic email Notifications
          </label>
        </div>
        <br />
        <button
          type="button"
          class="btn btn-primary far fa-trash-alt"
          (click)="cancelPackage()"
          data-dismiss="modal"
          *ngIf="IsshippingLabel"
        >
          Cancel
        </button>
        


      </form>
    

    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
      <button *ngIf="!privilege"
      type="button"
      class="btn btn-primary"
      (click)="addTracking()"
    >
      Confirm
    </button>
    </div>
  </div>
</div>

</div>
