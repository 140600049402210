import { Component, OnInit } from '@angular/core';

import { BillService } from '../../services/bill.service';

import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss'],
  providers: [BillService]
})
export class BillComponent implements OnInit {
  public bank: string;
  public account: string;
  public creator: any;
  public privilege: boolean;
  public billList: any;
  public isCreated: any;

  constructor(
    private billService: BillService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.creator = this.authenticationService.currentUserValue[0];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
      } else {
        this.privilege = false;
      }
    }
    this.retrieve();
  }

  create() {
    if (this.bank) {
      this.billService
        .addBill(this.bank,this.account, this.creator)
        .pipe(first())
        .subscribe(
          data => {
            this.isCreated = true;
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngOnInit();
          }
        );
    }
  }
  retrieve() {
    this.billService
      .retrieve(this.creator)
      .pipe(first())
      .subscribe(data => {
        this.billList = data;
      });
  }
}
