<div class="wrapper" *ngIf="privilege">
<!-- <table class="table" id="inboundTable" *ngIf="!noRecord">  *ngIf="privilege" --> 
  <table class="table table-hover" id="dealtable">
    <thead class="thead-light">
      <tr>
        <th scope="col" style="width: 9%" class="hide">#</th>
        <th scope="col" style="width: 38%">Inbound</th>
        <th scope="col" style="width: 8%" *ngIf="privilege" class="hide">From</th>
        <th scope="col" style="width: 8%" class="hide">Price</th>
        <th scope="col" style="width: 8%" class="hide">Quantity</th>
        <th scope="col" style="width: 10%" class="hide">WareHouse</th>
        <th scope="col" style="width: 10%" class="hide">LastUpdate</th>
        <th scope="col" style="width: 9%" class="hide">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let record of proposeList; index as i">
        <tr>
  <!--
          <th scopr="row" class="hide">{{ ('00000' + record.id).slice(-6) }}</th>
  -->
          <th scopr="row" class="hide">
            <div>
              {{ ('00000' + record.id).slice(-6) }}
              <br><br>
              <img src="{{ record.product_link }}" />
            </div>
          </th>
          <td>
            <div>
              <b class="show">{{ ('00000' + record.id).slice(-6) }} </b>
              <span>{{ record.product }}</span>
              <p class="text-danger show">From: {{record.individual}} Warehouse: {{record.warehouse}}</p>
              <p class="text-danger show">Price: {{record.price}} Quantity: {{record.quantity}}</p>
              <p class="text-danger show">Update: {{record.updatedAt | date: 'medium'}}</p>
              <div *ngIf="!record.proposeStatus" class="show">
                  Action: Waiting
                  <div *ngIf="privilege">
                      <button
                      id="{{ i }}"
                      type="button"
                      class="btn btn-outline-success btn-block btn-sm"
                      data-toggle="modal"
                      data-target="#proposeConfirmForm"
                      *ngIf="privilege"
                      (click)="select(i)"
                    >
                      Confirm
                    </button>
                    <button 
                      id="{{ i }}"
                      type="button"
                      class="btn btn-outline-warning btn-block btn-sm"
                      *ngIf="privilege"
                      (click)="setProposeStatus(i)"
                    >
                    Reject
                   </button>

                  </div>
                </div>
                <div *ngIf="record.proposeStatus  && record.proposeStatus==1">
                  Action: Confirmed
                </div>
                <div class="text-red" *ngIf="record.proposeStatus && record.proposeStatus==2">
                  Canceled
                </div>

              <!-- </ng-container> -->
            </div>
          </td>
          <td *ngIf="privilege" class="hide">{{ record.individual }}</td>
          <!-- <td *ngIf="!privilege">{{ record.individual }}</td> -->
          <td class="hide text-success">${{ record.price }}</td>
          <td class="hide">{{ record.quantity }}</td>
          <td class="hide">{{ record.warehouse }}</td>
          <td class="hide">{{ record.updatedAt | date: 'medium'}}</td>
          <td class="hide">
            <div *ngIf="!record.proposeStatus" >
              Waiting
              <div *ngIf="privilege">
                  <button
                  id="{{ i }}"
                  type="button"
                  class="btn btn-outline-success btn-block btn-sm"
                  data-toggle="modal"
                  data-target="#proposeConfirmForm"
                  *ngIf="privilege"
                  (click)="select(i)"
                >
                  Confirm
                </button>
                 <button 
                  id="{{ i }}"
                  type="button"
                  class="btn btn-outline-warning btn-block btn-sm"
                  *ngIf="privilege"
                  (click)="setProposeStatus(i)"
                >
                Reject
               </button>    
               
                <!-- <button class="btn btn-outline-success btn-block btn-sm" (click)="proposeConfirm(record.id, record.quantity, record.dealId)">Confirm</button> -->
              </div>
            </div>
            <div *ngIf="record.proposeStatus && record.proposeStatus==1">
              Confirmed
            </div>
            <div class="text-red" *ngIf="record.proposeStatus && record.proposeStatus==2">
              Canceled
            </div>
            <!-- <button class="btn btn-primary btn-sm mb-2" *ngIf="!privilege">
              Confirm
            </button> -->
            <!-- <button class="btn btn-primary btn-sm">Cancel</button> -->
          </td>
        </tr>
      </ng-container>
    </tbody>
    

  </table>

  <!--分页实现Begin-->
  <kendo-datapager
    [style.width.%]="100"
    [pageSize]="pageSize"
    [skip]="skip"
    [total]="total"
    [type]="type"
    (pageChange)="onPageChange($event)">
  </kendo-datapager>
</div>


<div class="wrapper" *ngIf="!privilege">
  <!-- <table class="table" id="inboundTable" *ngIf="noRecord"> -->
  <table class="table table-hover" id="dealTable">
    <thead class="thead-light">
      <tr>
        <th scope="col" style="width: 9%" class="hide">#</th>
        <th scope="col" style="width: 38%">Inbound</th>
        <th scope="col" style="width: 8%" *ngIf="!privilege" class="hide">Company</th>
        <th scope="col" style="width: 8%" class="hide">Price</th>
        <th scope="col" style="width: 8%" class="hide">Quantity</th>
        <th scope="col" style="width: 10" class="hide">WareHouse</th>
        <th scope="col" style="width: 10" class="hide">LastUpdate</th>
        <th scope="col" style="width: 9%" class="hide">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let record of proposeList; index as i">
        <tr>
        <!--
          <th scopr="row" class="hide">{{ ('00000' + record.id).slice(-6) }}</th>
        -->
          <th scopr="row" class="hide">
            <div>
              {{ ('00000' + record.id).slice(-6) }}
              <br><br>
              <img src="{{ record.product_link }}" />
            </div>
          </th>
          <td>
            <b class="show">{{ ('00000' + record.id).slice(-6) }} </b>
            <span>{{ record.product }}</span>
            <p class="text-danger show">
              {{record.individual}}
              {{record.warehouse}}
            </p>
            <p class="text-info show" *ngIf="!privilege">
              <b>{{ record.company }}</b>
            </p>
            <p class="text-danger show">
              {{record.price}}
              {{record.quantity}}
            </p>
            <p class="text-danger show">
              {{record.updatedAt | date: 'medium'}}
            </p>
            <div *ngIf="!record.proposeStatus" class="show">
              Action: Waiting
              <div *ngIf="privilege">
                <button
                id="{{ i }}"
                type="button"
                class="btn btn-outline-success btn-block btn-sm"
                data-toggle="modal"
                data-target="#proposeConfirmForm"
                *ngIf="privilege"
                (click)="select(i)"
                >
                  Confirm
                </button>
              </div>
            </div>
            <div *ngIf="record.proposeStatus && record.proposeStatus==1">
              Action: Confirmed
            </div>
            <div class="text-red" *ngIf="record.proposeStatus && record.proposeStatus==2">
              Action: Canceled
            </div>
              <!-- </ng-container> -->
            <!-- </div> -->
          </td>
          <td *ngIf="privilege" class="hide">{{ record.individual }}</td>
          <!-- <td *ngIf="!privilege">{{ record.individual }}</td> -->
          <td class="hide text-info" *ngIf="!privilege"><b>{{ record.company }}</b></td>
          <td class="hide text-success">${{ record.price }}</td>
          <td class="hide">{{ record.quantity }}</td>
          <td class="hide">{{ record.warehouse }}</td>
          <td class="hide">{{ record.updatedAt | date: 'medium'}}</td>
          <td class="hide">
            <div *ngIf="!record.proposeStatus" >
              Waiting
              <div *ngIf="privilege">
                  <button
                  id="{{ i }}"
                  type="button"
                  class="btn btn-outline-success btn-block btn-sm"
                  data-toggle="modal"
                  data-target="#proposeConfirmForm"
                  *ngIf="privilege"
                  (click)="select(i)"
                >
                  Confirm
                </button>
                 <button 
                  id="{{ i }}"
                  type="button"
                  class="btn btn-outline-warning btn-block btn-sm"
                  *ngIf="privilege"
                  (click)="setProposeStatus(i)"
                >
                Reject
               </button> 
                <!-- <button class="btn btn-outline-success btn-block btn-sm" (click)="proposeConfirm(record.id, record.quantity, record.dealId)">Confirm</button> -->
              </div>
            </div>
            <!--
            <div *ngIf="record.proposeStatus">
              Confirmed
            </div>-->
            <div *ngIf="record.proposeStatus && record.proposeStatus==1">
              Confirmed
            </div>
            <div class="text-red" *ngIf="record.proposeStatus && record.proposeStatus==2">
              Canceled
            </div>
            <!-- <button class="btn btn-primary btn-sm mb-2" *ngIf="!privilege">
              Confirm
            </button> -->
            <!-- <button class="btn btn-primary btn-sm">Cancel</button> -->
          </td>
        </tr>
      </ng-container>
      <!-- <tr>
        <th scopr="row"></th>
        <td>
          No Inbound Record......
        </td>
        <td *ngIf="privilege"></td> -->
        <!-- <td *ngIf="!privilege">{{ record.individual }}</td> -->
        <!-- <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td> -->
          <!-- <button class="btn btn-primary btn-sm mb-2" *ngIf="!privilege">
                Confirm
              </button> -->
          <!-- <button class="btn btn-primary btn-sm">Cancel</button> -->
        <!-- </td>
      </tr> -->
    </tbody>
    
  </table>
  <!--分页实现Begin-->
    <kendo-datapager
      [style.width.%]="100"
      [pageSize]="pageSize"
      [skip]="skip"
      [total]="total"
      [type]="type"
      (pageChange)="onPageChange($event)">
    </kendo-datapager>
  </div>
  <div
  class="modal fade"
  id="proposeConfirmForm"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="formTitle">Confirm a Propose</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="product" class="col-form-label"
              >Product:</label
            >
            <input type="text" class="form-control" value="{{selectInbound.product}}" readonly>
          </div>
          <div class="form-group">
            <label for="price" class="col-form-label">price:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="number" class="form-control" name="price" value="{{selectInbound.price}}" readonly>
              </div>
          </div>
          <div class="form-group">
            <label for="quantity" class="col-form-label"
              >quantity:</label
            >
            <input
              type="number"
              class="form-control"
              id="quantity"
              name="quantity"
              [(ngModel)] = "selectInbound.quantity"
            />
          </div>
          <div class="form-group">
            <label for="warehouse" class="col-form-label"
              >warehouse:</label
            >
            <input
              type="text"
              class="form-control"
              id="warehouse"
              name="warehouse"
              [(ngModel)]="selectInbound.warehouse"
              readonly
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="proposeConfirm()"
          data-dismiss="modal"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
  </div>
  
