export class Tracking {
  public id: number;
  public inboundId: number;
  public dealId: number;
  public company: string;
  public individual: string;
  public tracking: string;
  public status: string;
  constructor(
    inboundId: number,
    dealId: number,
    company: string,
    individual: string,
    tracking: string,
    status: string
  ) {
    this.inboundId = inboundId;
    this.dealId = dealId;
    this.company = company;
    this.individual = individual;
    this.tracking = tracking;
    this.status = status;
  }

  public clear() {
    this.inboundId = null;
    this.dealId = null;
    this.company = '';
    this.individual = '';
    this.tracking = '';
    this.status = '';
  }
}
