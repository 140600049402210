import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { DealService } from '../../../services/deal.service';
import { GroupService } from '../../../services/group.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { Deal } from '../../../models/deal';
import { InboundService } from '../../../services/inbound.service';
import { StorageService } from '../../../services/storage.service';
import { Email } from '../../../models/email';
//
import { AlertService } from '../../../_alert';
var ExpiredDealsComponent = /** @class */ (function () {
    function ExpiredDealsComponent(authenticationService, dealService, groupService, inboundService, storageService, alertService) {
        this.authenticationService = authenticationService;
        this.dealService = dealService;
        this.groupService = groupService;
        this.inboundService = inboundService;
        this.storageService = storageService;
        this.alertService = alertService;
        this.options = {
            autoClose: false,
            keepAfterRouteChange: true //true Keep displaying after one route change
        };
        this.pageSize = 20; //每页显示条数
        this.skip = 0; //页号
        this.pagedDestinations = [];
        this.total = 0; //destinations.length; //总条数
        this.filter = '';
        this.IsCompany = false;
        this.temtor = '';
        this.type = 'input';
        this.expiredList = new Array();
        this.inbound = new Inbound('', '', '', null, '', '', '', null, null, null, true, null);
        this.selectDeal = new Deal(null, '', null, null, '', '', false, false, false, false, '');
        this.selectWarehouse = '';
        this.isAwards = false;
        this.awards = 0;
        this.awardsUsersUnique = new Array();
        this.awardsUsersUniqueQty = new Array();
        this.email = new Email();
    }
    ExpiredDealsComponent.prototype.ngOnInit = function () {
        if (!this.ans)
            return;
        if (this.authenticationService.currentUserValue) {
            this.currentUser = this.authenticationService.currentUserValue[0];
            this.receiver = this.authenticationService.currentUserValue[4];
            this.IsCompany = this.authenticationService.currentUserValue[2] === 'company' ? true : false;
            if (this.authenticationService.currentUserValue[2] === 'company') {
                this.disableCreate();
                this.privilege = true;
                this.expiredRetrieve(this.currentUser);
                this.getAllCount(this.currentUser, this.currentUser, this.filter, 'ExpiredDeals', this.IsCompany);
            }
            else {
                this.privilege = false;
                this.getfollowing(this.currentUser);
                //debugger;
                //if (typeof this.expiredList !== "undefined" && this.expiredList.length>0) {
                //  debugger;
                //}        
            }
        }
        else {
            // error handle no user
        }
    };
    ExpiredDealsComponent.prototype.disableCreate = function () {
        var createButton = document.getElementById('createDeal');
        createButton.style.display = 'none';
    };
    /**
     * Get the expired deal list for the user who is signed as an individual.
     * @author Guozhi Tang
     * @date 2020-03-27
     * @param creator - the username of current user
     */
    ExpiredDealsComponent.prototype.getfollowing = function (creator) {
        var _this = this;
        this.groupService
            .findfollowing(creator)
            .pipe(first())
            .subscribe(function (data) {
            // console.log(data);
            _this.followingList = data;
        }, function (error) {
            console.log(error);
        }, function () {
            _this.temtor = '';
            for (var index = 0; index < _this.followingList.length; index++) {
                if (_this.followingList[index]) {
                    _this.temtor = _this.temtor + _this.followingList[index].company + ',';
                }
                else {
                    _this.noFollowing = true;
                }
            }
            _this.temtor = _this.temtor.substring(0, _this.temtor.lastIndexOf(","));
            _this.getexpiredList(_this.temtor);
        });
    };
    //返回DealList
    ExpiredDealsComponent.prototype.getexpiredList = function (creator) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(creator.length > 0)) return [3 /*break*/, 2];
                        //反回列表        
                        return [4 /*yield*/, this.getExpiredListIndividual(creator, this.currentUser)];
                    case 1:
                        //反回列表        
                        _a.sent();
                        //计算页数
                        this.getAllCount(creator, this.currentUser, this.filter, 'ExpiredDeals', this.IsCompany);
                        return [3 /*break*/, 3];
                    case 2:
                        this.noFollowing = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get the expired deal list of which the currentUser is in member list.
     * @author Guozhi Tang
     * @date 2020-03-27
     * @param creator - brandnewegg
     * @param individual - the username of current user
     */
    ExpiredDealsComponent.prototype.getExpiredListIndividual = function (creator, individual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getExpiredDataIndividual(creator, individual)];
                    case 1:
                        data = _a.sent();
                        this.expiredList = [];
                        if (Object.keys(data).length > 0) {
                            for (index = 0; index < Object.keys(data).length; index++) {
                                this.expiredList.push(data[index]);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ExpiredDealsComponent.prototype.getExpiredDataIndividual = function (creator, individual) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dealService.individualExpiredDeals(creator, individual, _this.filter, _this.skip, _this.pageSize)
                .pipe(first()).subscribe(function (data) {
                /*
                this.expiredList = [];
                for (let index = 0; index < data.length; index++) {
                  this.expiredList.push(data[index]);
                }*/
                resolve(data);
            });
            // resolve(this.expiredList);
        });
    };
    /**
     * Get all the expired deal list when the user is signed as a company.
     * @param creator - the username of current user
     */
    ExpiredDealsComponent.prototype.expiredRetrieve = function (creator) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getexpiredData(creator)];
                    case 1:
                        data = _a.sent();
                        this.expiredList = [];
                        if (Object.keys(data).length > 0) {
                            for (index = 0; index < Object.keys(data).length; index++) {
                                this.expiredList.push(data[index]);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ExpiredDealsComponent.prototype.getexpiredData = function (creator) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dealService
                .expiredDealRetrieve(creator, _this.filter, _this.skip, _this.pageSize)
                .pipe(first())
                .subscribe(function (data) {
                /*
                if (data.length != 0) {
                  for (let index = 0; index < data.length; index++) {
                    this.expiredList.push(data[index]);
                  }
                }*/
                resolve(data); //this.expiredList);  
            });
        });
    };
    ExpiredDealsComponent.prototype.takeDeal = function (productId) {
        this.selectProductId = productId;
        if (!this.privilege) {
            this.dealCreator = this.expiredList[productId].creator;
            // console.log(this.expiredList[productId]);
            this.Storage(this.dealCreator);
            if (productId >= 0) {
                this.inbound.product = this.expiredList[productId].product_name;
                this.inbound.price = this.expiredList[productId].price;
                this.inbound.quantity = 0;
                this.inbound.warehouse = '';
                this.inbound.company = this.expiredList[productId].creator;
                this.inbound.individual = this.currentUser;
                this.inbound.status = 0;
                this.inbound.companyStatus = 0;
                this.inbound.propose = 1;
                this.inbound.dealId = this.expiredList[productId].id;
            }
        }
        else {
            this.isAwards = false;
            this.awardsUsers = null;
            this.isPublic = false;
            this.isPrivate = false;
            this.selectDeal.id = this.expiredList[productId].id;
            this.selectDeal.productName = this.expiredList[productId].product_name;
            this.selectDeal.products = this.expiredList[productId].products;
            this.selectDeal.quantity = this.expiredList[productId].quantity;
            this.selectDeal.price = this.expiredList[productId].price;
            var currentTime = new Date(this.expiredList[productId].expires_at);
            this.month = (currentTime.getMonth() + 1).toString();
            this.days = currentTime.getDate().toString();
            this.year = currentTime.getFullYear().toString();
            this.hour = currentTime.getHours().toString();
            this.minutes = currentTime.getMinutes().toString();
            this.selectDeal.note = this.expiredList[productId].note;
            this.selectDeal.bonus = this.expiredList[productId].bonus;
            this.selectDeal.serviceTag = this.expiredList[productId].service_tag;
            this.selectDeal.notify = this.expiredList[productId].notify;
            this.selectDeal.creator = this.expiredList[productId].creator;
            this.selectDeal.members = this.expiredList[productId].members;
            this.selectDeal.dealPublic = this.expiredList[productId].public;
            this.selectWarehouse = '';
        }
        // console.log(this.inbound);
    };
    ExpiredDealsComponent.prototype.makeAwards = function () {
        var _this = this;
        this.isAwards = true;
        this.awardsUsersUnique = [];
        this.awardsUsersUniqueQty = [];
        this.inboundService
            .findAwardsUser(this.selectDeal.id)
            .pipe(first())
            .subscribe(function (data) {
            _this.awardsUsers = data;
            /*
                    while (this.awardsUsers.length >= 1) {
                      let currentAwardsUser = this.awardsUsers.pop();
                      if (currentAwardsUser !== undefined) {
                        if (
                          !this.awardsUsersUnique.includes(currentAwardsUser.individual)
                        ) {
                          this.awardsUsersUnique.push(currentAwardsUser.individual);
                          this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                            currentAwardsUser.quantity;
                        } else {
                          this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                            currentAwardsUser.quantity;
                        }
                      }
                    }
            */
            var i;
            for (i = 0; i < _this.awardsUsers.length; i++) {
                var currentAwardsUser = _this.awardsUsers[i];
                if (currentAwardsUser !== undefined) {
                    if (!_this.awardsUsersUnique.includes(currentAwardsUser.individual)) {
                        _this.awardsUsersUnique.push(currentAwardsUser.individual);
                        _this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                            currentAwardsUser.quantity;
                    }
                    else {
                        _this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                            currentAwardsUser.quantity;
                    }
                }
            }
        });
    };
    ExpiredDealsComponent.prototype.cancelAwards = function () {
        this.isAwards = false;
        this.awardsUsers = null;
    };
    ExpiredDealsComponent.prototype.makePublic = function () {
        var _this = this;
        this.membersTemp = '';
        this.isPublic = true;
        this.isPrivate = false;
        this.selectDeal.dealPublic = true;
        this.groupService
            .allSubscribers(this.selectDeal.creator)
            .pipe(first())
            .subscribe(function (data) {
            _this.memberList = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
                $('.selectpicker').selectpicker('selectAll');
            });
        });
    };
    ExpiredDealsComponent.prototype.makePrivate = function () {
        var _this = this;
        this.membersTemp = '';
        this.isPublic = false;
        this.isPrivate = true;
        this.selectDeal.dealPublic = false;
        this.groupService
            .groupRetrieve(this.selectDeal.creator)
            .pipe(first())
            .subscribe(function (data) {
            _this.groupList = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
            });
        });
    };
    ExpiredDealsComponent.prototype.updateCompany = function () {
        var _this = this;
        if (this.isAwards) {
            this.update();
        }
        var date = this.year +
            '-' +
            this.month +
            '-' +
            this.days +
            ' ' +
            this.hour +
            ':' +
            this.minutes +
            ':' +
            '00';
        this.selectDeal.expiresAt = date;
        if (this.membersTemp) {
            this.selectDeal.members = this.membersTemp.join();
        }
        this.dealService
            .updateCompany(this.selectDeal)
            .pipe(first())
            .subscribe(function (data) {
            _this.ngOnInit();
            _this.awards = 0;
            _this.selectDeal.clear();
            window.location.reload();
        });
    };
    ExpiredDealsComponent.prototype.update = function () {
        var _this = this;
        var i = 0;
        var awardsIds = new Array();
        if (this.awardsUsers) {
            while (this.awardsUsers[i]) {
                awardsIds.push(this.awardsUsers[i].id);
                i++;
            }
        }
        this.inboundService
            .updateAwards(awardsIds, this.selectDeal.price, this.awards)
            .pipe(first())
            .subscribe(function (data) {
            _this.isUpdateAwards = true;
        }, function (error) {
            console.log(error);
        }, function () {
            _this.awardsUsers = null;
        });
    };
    ExpiredDealsComponent.prototype.emailNotification = function () {
        if (this.selectDeal.notify === true) {
            var date = this.year +
                '-' +
                this.month +
                '-' +
                this.days +
                ' ' +
                this.hour +
                ':' +
                this.minutes +
                ':' +
                '00';
            this.selectDeal.expiresAt = date;
            this.email.dealProduct = this.selectDeal.products;
            this.email.emailList = this.selectDeal.members;
            this.email.receiver = this.receiver;
            this.email.emailSubject = this.selectDeal.creator + ' | ' + ' New Offer : ';
            this.email.emailTextBeforeImage = this.selectDeal.creator + ' has created a new offer<br><br>' + 'Product: ' + this.selectDeal.productName + '<br><br>';
            var htmlNote = this.selectDeal.note.replace(/(\r\n|\n|\r)/gm, "<br>");
            this.email.emailTextAfterImage = '<br><br>Price: $' + this.selectDeal.price + '<br><br>Quantity: ' + this.selectDeal.quantity + '<br><br>Expire time: ' + this.selectDeal.expiresAt + '<br><br><font color="red">Note:<br><br>' + htmlNote + '</font><br><br>Visit the link below to see all active offers<br><br>m.brandnewegg.com/deals/active';
            this.dealService
                .emailNotification(this.email)
                .pipe(first())
                .subscribe(function (data) {
                console.log(data);
            }, function (error) {
                console.log(error);
            }, function () {
                console.log('done');
            });
        }
    };
    ExpiredDealsComponent.prototype.createPropose = function () {
        var _this = this;
        if (this.selfWarehouse) {
            this.inbound.warehouse = this.selectPrivateWarehouse;
        }
        else {
            this.inbound.warehouse = this.selectPublicWarehouse;
        }
        // console.log(this.inbound);
        this.inboundService
            .createPropose(this.inbound)
            .pipe(first())
            .subscribe(function (data) {
            _this.isProposed = true;
            _this.inbound.clear();
            _this.selectProductId = null;
            //确认申领。
            _this.alertService.success('Confirmed Propose!!', _this.options);
        }, function (error) {
            console.log(error);
            _this.alertService.error('Propose Failure!!', _this.options);
        }, function () {
            _this.inbound.clear();
            _this.selectPrivateWarehouse = null;
            _this.selectPublicWarehouse = null;
        });
    };
    ExpiredDealsComponent.prototype.useSelfWarehouse = function () {
        this.selfWarehouse = true;
        this.inbound.publicWarehouse = false;
        this.privateStorage(this.currentUser);
    };
    ExpiredDealsComponent.prototype.usePublicWarehouse = function () {
        this.selfWarehouse = false;
        this.inbound.publicWarehouse = true;
    };
    ExpiredDealsComponent.prototype.Storage = function (dealCreator) {
        var _this = this;
        // console.log(dealCreator);
        this.storageService
            .retrieve(dealCreator)
            .pipe(first())
            .subscribe(function (data) {
            // console.log(data);
            _this.publicWarehouse = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
            });
        });
    };
    ExpiredDealsComponent.prototype.privateStorage = function (creator) {
        var _this = this;
        // console.log(creator);
        this.storageService
            .retrieve(creator)
            .pipe(first())
            .subscribe(function (data) {
            _this.privateWarehouse = data;
        }, function (error) {
            console.log(error);
        }, function () {
            setTimeout(function () {
                $('.selectpicker').selectpicker('refresh');
            });
        });
    };
    ExpiredDealsComponent.prototype.compareWh = function (warehouse1, warehouse2) {
        //return warehouse1.id === warehouse2.id;
        return warehouse1 && warehouse2 ? warehouse1.id === warehouse2.id : warehouse1 === warehouse2;
    };
    //统计总页数
    ExpiredDealsComponent.prototype.getAllCount = function (creator, company, filter, deals, iscompany) {
        var _this = this;
        this.dealService
            .getAllCount(creator, company, filter, deals, iscompany)
            .pipe(first())
            .subscribe(function (data) {
            _this.total = data["count"];
        });
    };
    //Kendo分页函数  
    ExpiredDealsComponent.prototype.onPageChange = function (e) {
        this.skip = e.skip;
        this.pageSize = e.take;
        this.currentUser = this.authenticationService.currentUserValue[0];
        this.expiredList = new Array();
        if (this.authenticationService.currentUserValue[2] === 'company') {
            this.disableCreate();
            this.privilege = true;
            this.expiredRetrieve(this.currentUser);
        }
        else {
            this.privilege = false;
            this.getfollowing(this.currentUser);
            //this.getRecords(this.currentUser);
        }
    };
    return ExpiredDealsComponent;
}());
export { ExpiredDealsComponent };
