import { Component, OnInit,Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { InboundService } from '../../../services/inbound.service';
import { GroupService } from '../../../services/group.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { DealService } from '../../../services/deal.service';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-propose-deals',
  templateUrl: './propose-deals.component.html',
  styleUrls: ['./propose-deals.component.scss'],
  providers: [AuthenticationService, InboundService, DealService]
})
export class ProposeDealsComponent implements OnInit {

  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';
  public IsCompany = false;
  //public creator = '';
  public temtor = '';
  public type = 'input';

  @Input('answers') ans:any;


  public privilege: any;
  public currentUser: any;
  public noFollowing: any;
  public followingList: any;
  public proposeList = new Array();
  public selectInbound = new Inbound(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );

  constructor(
    private authenticationService: AuthenticationService,
    private inboundService: InboundService,
    private groupService: GroupService,
    private dealService: DealService,
  ) {}

  ngOnInit() {
    if (!this.ans) return;
    if (this.authenticationService.currentUserValue) {
      // console.log(this.authenticationService.currentUserValue);
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.IsCompany = this.authenticationService.currentUserValue[2] === 'company' ? true : false;
      // console.log(this.currentUser);
      //console.log(this.IsCompany);
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.disableCreate();
        this.privilege = true;
        this.proposeRetrieveCompany(this.currentUser);
        this.getAllCount(this.currentUser,this.currentUser,this.filter,'ProposeDeals',this.IsCompany);
      } else {
        this.privilege = false;
        // this.proposeRetrieve(this.currentUser);
        this.proposeIndividual(this.currentUser);
      }
    }
  }

  disableCreate() {
    const createButton = document.getElementById('createDeal') as HTMLInputElement;
    createButton.style.display = 'none';
  }
  
  /**
   * Get the proposed inbound list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-04-28
   * @param creator - the username of current user
   */
  proposeIndividual(creator) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.followingList = data;        
        },
        error => {
          console.log(error);
        },
        () => {
          this.temtor = '';
          //console.log(this.followingList);
          for (let index = 0; index < this.followingList.length; index++) {
            if (this.followingList[index]) {
              this.temtor = this.temtor +  this.followingList[index].company + ",";
              //this.proposeRetrieve(this.followingList[index].company, this.currentUser);
            } else {
              this.noFollowing = true;
            }
          }
          
          this.temtor = this.temtor.substring(0,this.temtor.lastIndexOf(","));
          //console.log(this.temtor);         
          this.getProposeList(this.temtor);         

        }


      );      

  }
  //返回ProposeList
  async getProposeList(company: string) {
      if (company.length>0) {        
        //反回列表        
        await this.proposeRetrieve(company, this.currentUser);
        //计算页数
        this.getAllCount(company,this.currentUser,this.filter,'ProposeDeals',this.IsCompany);
      } else {
         this.noFollowing = true;
      }
  }

  /**
   * Get the proposed inbound list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-04-28
   * @param company - the company name of the specific inbound
   * @param individual - the username of current user
   */
  async proposeRetrieve(company: string, individual: string) {
    const data = await this.proposeRetrieveData(company, individual);
    this.proposeList = [];    
    if (Object.keys(data).length > 0) {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.proposeList.push(data[index]);
      }
    }    
  }

  proposeRetrieveData(company: string, individual: string) { 
     return new Promise((resolve, reject) => {
      this.inboundService
        .proposeRetrieve(individual, company,this.filter,this.skip,this.pageSize)
        .pipe(first())
        .subscribe(data => {       
          /*
          if (data.length != 0) {
            this.proposeList = [];
            for (let index = 0; index < data.length; index++) {
              this.proposeList.push(data[index]);
            }
          }*/
          resolve(data);
        });        
      })
   }

  /**
   * Get the proposed inbound list of which the currentUser is a company user.
   * @author Guozhi Tang
   * @date 2020-04-28
   * @param individual - the username of current user
   */
  async proposeRetrieveCompany(individual: string) {
    const data = await this.proposeRetrieveCompanyData(individual);
    this.proposeList = [];    
    if (Object.keys(data).length > 0) {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.proposeList.push(data[index]);
      }
    }    
  }

  proposeRetrieveCompanyData(individual: string) {
    return new Promise((resolve, reject) => {
      this.inboundService
        .proposeRetrieveCompany(individual,this.filter,this.skip,this.pageSize)
        .pipe(first())
        .subscribe(data => {
          /*
          if (data.length != 0) {
            this.proposeList = [];
            for (let index = 0; index < data.length; index++) {
              this.proposeList.push(data[index]);
            }
          }*/
          resolve(data);
          // this.proposeList = data;
        });
    })

  }

  proposeConfirm() {
    this.inboundService
      .proposeConfirm(
        this.selectInbound.id,
        this.selectInbound.quantity,
        this.selectInbound.dealId
      )
      .pipe(first())
      .subscribe(
        data => {
          this.ngOnInit();
        },
        error => {
          console.log(error);
        },
        () => {
          this.selectInbound.clear();
        }
      );
  }

  select(i) {
    // if (i >= 0) {
    this.selectInbound.product = this.proposeList[i].product;
    this.selectInbound.product_link = this.proposeList[i].product_link;
    this.selectInbound.price = this.proposeList[i].price;
    this.selectInbound.quantity = this.proposeList[i].quantity;
    this.selectInbound.id = this.proposeList[i].id;
    this.selectInbound.dealId = this.proposeList[i].dealId;
    this.selectInbound.warehouse = this.proposeList[i].warehouse;
    // }
  }

  //统计总页数
  getAllCount(creator,company,filter,deals,iscompany) {
    this.dealService
      .getAllCount(creator,company,filter,deals,iscompany)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }

  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    this.proposeList = new Array();
    if (this.authenticationService.currentUserValue[2] === 'company') {
      this.disableCreate();
      this.privilege = true;
      this.proposeRetrieveCompany(this.currentUser);
      this.getAllCount(this.currentUser,this.currentUser,this.filter,'ProposeDeals',this.IsCompany);
    } else {      
      this.privilege = false;
      this.proposeIndividual(this.currentUser);
    }
  }

  setProposeStatus(i) {

    let id = this.proposeList[i].id;

    this.inboundService
      .setProposeStatus(id)
      .pipe(first())
      .subscribe(result => {
        this.proposeList[i].proposeStatus = 2;
        /*
        if (this.iscompany) {
               this.privilege = true ;  
               this.getRecords('');
            } else {
              this.privilege = false;
              this.getRecords(this.currentUser);
            }     */
  
  })

    // if (i >= 0) {   
    // }
  }      


}
