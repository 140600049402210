export class inboundPayment {
  public id: number;
  public inboundId: number;
  public dealId: number;
  public paymentId: number;  
  public company: string;
  public individual: string;
  public upc: string;
  public confirmedQty: number;
  public confirmedPrice: number;
  public billQty: number;
  public billPrice: number;
  public paidQty: number;
  public paidPrice: number;
  public amount: number;
  public status: number;
  public isDelete: number;
 
  constructor(
    inboundId: number,
    dealId: number,
    paymentId:number,
    company: string,
    individual: string,
    upc: string,
    confirmedQty:number,
    confirmedPrice:number,
    billQty:number,
    billPrice:number,
    paidQty:number,
    paidPrice:number,
    amount:number,
    status: number,
    isDelete: number,
  ) {
    this.inboundId = inboundId;
    this.dealId = dealId;
    this.paymentId = paymentId;
    this.company = company;    
    this.individual = individual;
    this.upc = upc;
    this.confirmedQty = confirmedQty;
    this.confirmedPrice = confirmedPrice;
    this.billQty=billQty;
    this.billPrice=billPrice;
    this.paidQty=paidQty;
    this.paidPrice = paidPrice;
    this.amount= amount;
    this.status= status;
    this.isDelete= isDelete;
  }

  public clear() {
    this.inboundId = null;
    this.dealId = null;
    this.paymentId = null;
    this.company = '';    
    this.individual = '';
    this.upc = '';
    this.confirmedQty = null;
    this.confirmedPrice = null;
    this.billQty=null;
    this.billPrice=null;
    this.paidQty=null;
    this.paidPrice = null;
    this.amount= null;
    this.status= 0;
    this.isDelete= 0;
  }
}
