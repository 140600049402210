import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Alert, AlertType } from './alert.model';
import * as i0 from "@angular/core";
var AlertService = /** @class */ (function () {
    function AlertService() {
        this.subject = new Subject();
        this.defaultId = 'default-alert';
    }
    // enable subscribing to alerts observable
    AlertService.prototype.onAlert = function (id) {
        if (id === void 0) { id = this.defaultId; }
        return this.subject.asObservable().pipe(filter(function (x) { return x && x.id === id; }));
    };
    // convenience methods
    AlertService.prototype.success = function (message, options) {
        this.alert(new Alert(tslib_1.__assign({}, options, { type: AlertType.Success, message: message })));
    };
    AlertService.prototype.error = function (message, options) {
        this.alert(new Alert(tslib_1.__assign({}, options, { type: AlertType.Error, message: message })));
    };
    AlertService.prototype.info = function (message, options) {
        this.alert(new Alert(tslib_1.__assign({}, options, { type: AlertType.Info, message: message })));
    };
    AlertService.prototype.warn = function (message, options) {
        this.alert(new Alert(tslib_1.__assign({}, options, { type: AlertType.Warning, message: message })));
    };
    // main alert method    
    AlertService.prototype.alert = function (alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    };
    // clear alerts
    AlertService.prototype.clear = function (id) {
        if (id === void 0) { id = this.defaultId; }
        this.subject.next(new Alert({ id: id }));
    };
    AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
