import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { InboundService } from '../../../services/inbound.service';
import { GroupService } from '../../../services/group.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { DealService } from '../../../services/deal.service';
var ProposeDealsComponent = /** @class */ (function () {
    function ProposeDealsComponent(authenticationService, inboundService, groupService, dealService) {
        this.authenticationService = authenticationService;
        this.inboundService = inboundService;
        this.groupService = groupService;
        this.dealService = dealService;
        this.pageSize = 20; //每页显示条数
        this.skip = 0; //页号
        this.pagedDestinations = [];
        this.total = 0; //destinations.length; //总条数
        this.filter = '';
        this.IsCompany = false;
        //public creator = '';
        this.temtor = '';
        this.type = 'input';
        this.proposeList = new Array();
        this.selectInbound = new Inbound(null, null, null, null, null, null, null, null, null, null, null, null);
    }
    ProposeDealsComponent.prototype.ngOnInit = function () {
        if (!this.ans)
            return;
        if (this.authenticationService.currentUserValue) {
            // console.log(this.authenticationService.currentUserValue);
            this.currentUser = this.authenticationService.currentUserValue[0];
            this.IsCompany = this.authenticationService.currentUserValue[2] === 'company' ? true : false;
            // console.log(this.currentUser);
            //console.log(this.IsCompany);
            if (this.authenticationService.currentUserValue[2] === 'company') {
                this.disableCreate();
                this.privilege = true;
                this.proposeRetrieveCompany(this.currentUser);
                this.getAllCount(this.currentUser, this.currentUser, this.filter, 'ProposeDeals', this.IsCompany);
            }
            else {
                this.privilege = false;
                // this.proposeRetrieve(this.currentUser);
                this.proposeIndividual(this.currentUser);
            }
        }
    };
    ProposeDealsComponent.prototype.disableCreate = function () {
        var createButton = document.getElementById('createDeal');
        createButton.style.display = 'none';
    };
    /**
     * Get the proposed inbound list of which the currentUser is in member list.
     * @author Guozhi Tang
     * @date 2020-04-28
     * @param creator - the username of current user
     */
    ProposeDealsComponent.prototype.proposeIndividual = function (creator) {
        var _this = this;
        this.groupService
            .findfollowing(creator)
            .pipe(first())
            .subscribe(function (data) {
            // console.log(data);
            _this.followingList = data;
        }, function (error) {
            console.log(error);
        }, function () {
            _this.temtor = '';
            //console.log(this.followingList);
            for (var index = 0; index < _this.followingList.length; index++) {
                if (_this.followingList[index]) {
                    _this.temtor = _this.temtor + _this.followingList[index].company + ",";
                    //this.proposeRetrieve(this.followingList[index].company, this.currentUser);
                }
                else {
                    _this.noFollowing = true;
                }
            }
            _this.temtor = _this.temtor.substring(0, _this.temtor.lastIndexOf(","));
            //console.log(this.temtor);         
            _this.getProposeList(_this.temtor);
        });
    };
    //返回ProposeList
    ProposeDealsComponent.prototype.getProposeList = function (company) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(company.length > 0)) return [3 /*break*/, 2];
                        //反回列表        
                        return [4 /*yield*/, this.proposeRetrieve(company, this.currentUser)];
                    case 1:
                        //反回列表        
                        _a.sent();
                        //计算页数
                        this.getAllCount(company, this.currentUser, this.filter, 'ProposeDeals', this.IsCompany);
                        return [3 /*break*/, 3];
                    case 2:
                        this.noFollowing = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get the proposed inbound list of which the currentUser is in member list.
     * @author Guozhi Tang
     * @date 2020-04-28
     * @param company - the company name of the specific inbound
     * @param individual - the username of current user
     */
    ProposeDealsComponent.prototype.proposeRetrieve = function (company, individual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.proposeRetrieveData(company, individual)];
                    case 1:
                        data = _a.sent();
                        this.proposeList = [];
                        if (Object.keys(data).length > 0) {
                            for (index = 0; index < Object.keys(data).length; index++) {
                                this.proposeList.push(data[index]);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProposeDealsComponent.prototype.proposeRetrieveData = function (company, individual) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.inboundService
                .proposeRetrieve(individual, company, _this.filter, _this.skip, _this.pageSize)
                .pipe(first())
                .subscribe(function (data) {
                /*
                if (data.length != 0) {
                  this.proposeList = [];
                  for (let index = 0; index < data.length; index++) {
                    this.proposeList.push(data[index]);
                  }
                }*/
                resolve(data);
            });
        });
    };
    /**
     * Get the proposed inbound list of which the currentUser is a company user.
     * @author Guozhi Tang
     * @date 2020-04-28
     * @param individual - the username of current user
     */
    ProposeDealsComponent.prototype.proposeRetrieveCompany = function (individual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.proposeRetrieveCompanyData(individual)];
                    case 1:
                        data = _a.sent();
                        this.proposeList = [];
                        if (Object.keys(data).length > 0) {
                            for (index = 0; index < Object.keys(data).length; index++) {
                                this.proposeList.push(data[index]);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProposeDealsComponent.prototype.proposeRetrieveCompanyData = function (individual) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.inboundService
                .proposeRetrieveCompany(individual, _this.filter, _this.skip, _this.pageSize)
                .pipe(first())
                .subscribe(function (data) {
                /*
                if (data.length != 0) {
                  this.proposeList = [];
                  for (let index = 0; index < data.length; index++) {
                    this.proposeList.push(data[index]);
                  }
                }*/
                resolve(data);
                // this.proposeList = data;
            });
        });
    };
    ProposeDealsComponent.prototype.proposeConfirm = function () {
        var _this = this;
        this.inboundService
            .proposeConfirm(this.selectInbound.id, this.selectInbound.quantity, this.selectInbound.dealId)
            .pipe(first())
            .subscribe(function (data) {
            _this.ngOnInit();
        }, function (error) {
            console.log(error);
        }, function () {
            _this.selectInbound.clear();
        });
    };
    ProposeDealsComponent.prototype.select = function (i) {
        // if (i >= 0) {
        this.selectInbound.product = this.proposeList[i].product;
        this.selectInbound.product_link = this.proposeList[i].product_link;
        this.selectInbound.price = this.proposeList[i].price;
        this.selectInbound.quantity = this.proposeList[i].quantity;
        this.selectInbound.id = this.proposeList[i].id;
        this.selectInbound.dealId = this.proposeList[i].dealId;
        this.selectInbound.warehouse = this.proposeList[i].warehouse;
        // }
    };
    //统计总页数
    ProposeDealsComponent.prototype.getAllCount = function (creator, company, filter, deals, iscompany) {
        var _this = this;
        this.dealService
            .getAllCount(creator, company, filter, deals, iscompany)
            .pipe(first())
            .subscribe(function (data) {
            _this.total = data["count"];
        });
    };
    //Kendo分页函数  
    ProposeDealsComponent.prototype.onPageChange = function (e) {
        this.skip = e.skip;
        this.pageSize = e.take;
        this.currentUser = this.authenticationService.currentUserValue[0];
        this.proposeList = new Array();
        if (this.authenticationService.currentUserValue[2] === 'company') {
            this.disableCreate();
            this.privilege = true;
            this.proposeRetrieveCompany(this.currentUser);
            this.getAllCount(this.currentUser, this.currentUser, this.filter, 'ProposeDeals', this.IsCompany);
        }
        else {
            this.privilege = false;
            this.proposeIndividual(this.currentUser);
        }
    };
    ProposeDealsComponent.prototype.setProposeStatus = function (i) {
        var _this = this;
        var id = this.proposeList[i].id;
        this.inboundService
            .setProposeStatus(id)
            .pipe(first())
            .subscribe(function (result) {
            _this.proposeList[i].proposeStatus = 2;
            /*
            if (this.iscompany) {
                   this.privilege = true ;
                   this.getRecords('');
                } else {
                  this.privilege = false;
                  this.getRecords(this.currentUser);
                }     */
        });
        // if (i >= 0) {   
        // }
    };
    return ProposeDealsComponent;
}());
export { ProposeDealsComponent };
