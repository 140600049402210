import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Tracking } from '../models/tracking';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class TrackingService {
  createTrackingUrl = environment.apiUrl + 'trackings/tracking'; // URL to web api
  allUrl = environment.apiUrl + 'trackings/allTracking'; // URL to web api
  constructor(private http: HttpClient) {}

  createTracking(tracking: Tracking): Observable<Tracking> {
    return this.http.post<Tracking>(this.createTrackingUrl, tracking, httpOptions);
  }

  allTracking(inboundId: number): Observable<any> {
    const inboundIdJSON = '{ "inboundId" : ' + '"' + inboundId + '"'+ ' }';

    return this.http.post<any>(
      this.allUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );
  }
 
}
