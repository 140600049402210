<nav class="navbar navbar-expand-lg navbar-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbar"
    aria-controls="navbar"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbar">
    <!-- <a class="navbar-brand" routerLink="/">Marketplace</a> -->
    <a class="navbar-brand" routerLink="/">MarketPlace</a>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item"  id="nav-dashboard" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/dashboard" *ngIf="currentUser" (click)="ngOnInit()"
          >Dashboard</a
        >
      </li>
      <li class="nav-item" id="nav-groups" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/deals" *ngIf="currentUser" (click)="ngOnInit()">Deals</a>
      </li>
      <!-- <li class="nav-item" id="nav-deal" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/home" *ngIf="currentUser" (click)="ngOnInit()">Home</a>
      </li> -->
      <li class="nav-item" id="nav-inbound" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/inbounds" *ngIf="currentUser" (click)="ngOnInit()"
          >Inbounds</a
        >
      </li>

      <li class="nav-item" id="nav-products" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/products" *ngIf="company" (click)="ngOnInit()"
          >Products</a
        >
      </li>

      <li class="nav-item" id="nav-groups" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/groups" *ngIf="company" (click)="ngOnInit()"
          >UserGroups</a
        >
      </li>
      <li class="nav-item" id="nav-groups" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}">
        <a class="nav-link" routerLink="/storages" *ngIf="currentUser" (click)="ngOnInit()">Storages</a>
      </li>
    </ul>

    <a *ngIf="individual">Individual User:</a>
    <a *ngIf="individual" class="btn text-info" routerLink="/userInfo"><i class="fas fa-user-nurse"></i>&nbsp;<b>{{currentUser[0]}}</b></a>
    <a *ngIf="company">Company User:</a>
    <a *ngIf="company" class="btn text-danger" routerLink="/userInfo"><i class="fas fa-user-md"></i>&nbsp;<b>{{currentUser[0]}}</b></a>
    <a
      class="btn btn-outline-primary"
      routerLink="/register"
      *ngIf="!currentUser"
      >Register</a
    >
    <a class="btn btn-outline-danger" *ngIf="currentUser" (click)="logout()"
      >Log out</a
    >
  </div>
</nav>

<!-- <div id='flush-block' class="container">
    <div class="alert alert-info" role="alert" *ngIf="company">
        You Signed In As a Member of Company
      </div>
      <div class="alert alert-info" role="alert" *ngIf="individual">
          You Signed In As an Individual
        </div>
</div> -->
<!-- <div id='storage-block' class="container">
  <div class="alert alert-danger" role="alert" *ngIf="company">
      click <a routerLink="/storage">here</a> to go to storage setting
  </div>
    <div class="alert alert-danger" role="alert" *ngIf="individual">
        click <a routerLink="/storage">here</a> to go to storage setting
      </div>
</div> -->
