import { Component, OnInit } from '@angular/core';

import { BillPayService } from '../../services/billpay.service';

import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-billpay',
  templateUrl: './billpay.component.html',
  styleUrls: ['./billpay.component.scss'],
  providers: [BillPayService]
})
export class BillPayComponent implements OnInit {
  public name: string;
  public address: string;
  public account: string;
  public routing: string;
  public phone: string;
  public creator: any;
  public privilege: boolean;
  public billpayList: any;
  public isCreated: any;

  constructor(
    private billpayService: BillPayService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.creator = this.authenticationService.currentUserValue[0];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
      } else {
        this.privilege = false;
      }
    }
    this.retrieve();
  }

  create() {
    if (this.name) {
      this.billpayService
        .addBillPay(this.name,this.address,this.account,this.routing,this.phone, this.creator)
        .pipe(first())
        .subscribe(
          data => {
            this.isCreated = true;
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngOnInit();
          }
        );
    }
  }
  retrieve() {
    this.billpayService
      .retrieve(this.creator)
      .pipe(first())
      .subscribe(data => {
        this.billpayList = data;
      });
  }
}
