import { Component, OnInit,Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { DealService } from '../../../services/deal.service';
import { GroupService } from '../../../services/group.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { Deal } from '../../../models/deal';
import { InboundService } from '../../../services/inbound.service';
import { StorageService } from '../../../services/storage.service';
import { Email } from '../../../models/email';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';
//
import { AlertService } from '../../../_alert';


declare var $: any;

@Component({
  selector: 'app-expired-deals',
  templateUrl: './expired-deals.component.html',
  styleUrls: ['./expired-deals.component.scss'],
  providers: [
    AuthenticationService,
    DealService,
    GroupService,
    InboundService,
    StorageService
  ]
})
export class ExpiredDealsComponent implements OnInit {

 options = {
      autoClose: false, //true Auto close alert after three seconds
      keepAfterRouteChange: true //true Keep displaying after one route change
  };


  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';
  public IsCompany = false;
  public temtor = '';
  public type = 'input';

  public currentUser: any;
  public privilege: any;
  public followingList: any;
  public noFollowing: any;
  public expiredList = new Array();
  public inbound = new Inbound('', '', '', null, '', '', '', null, null, null, true,null);
  public selectDeal = new Deal(
    null,
    '',
    null,
    null,
    '',
    '',
    false,
    false,
    false,
    false,
    ''
  );
  public selectWarehouse = '';
  public selectProductId: any;
  public isProposed: any;
  public publicWarehouse: any;
  public privateWarehouse: any;
  public selfWarehouse: any;
  public isAwards = false;
  public awards = 0;
  public isUpdateAwards: boolean;
  public awardsUsers: any;
  public awardsUsersUnique = new Array();
  public awardsUsersUniqueQty = new Array();
  public membersTemp: any;
  public month: any;
  public days: any;
  public year: any;
  public hour: any;
  public minutes: any;
  public isPublic: any;
  public isPrivate: any;
  public memberList: any;
  public groupList: any;
  public email = new Email();
  public receiver: any;
  public dealCreator: any;
  public selectPrivateWarehouse: any;
  public selectPublicWarehouse: any;
  @Input('answers') ans:any;

  constructor(
    private authenticationService: AuthenticationService,
    private dealService: DealService,
    private groupService: GroupService,
    private inboundService: InboundService,
    private storageService: StorageService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    if (!this.ans) return;
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.receiver = this.authenticationService.currentUserValue[4];

      this.IsCompany = this.authenticationService.currentUserValue[2] === 'company' ? true : false;
      
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.disableCreate();
        this.privilege = true;
        this.expiredRetrieve(this.currentUser);
        this.getAllCount(this.currentUser,this.currentUser,this.filter,'ExpiredDeals',this.IsCompany);
      } else {
        this.privilege = false;
        this.getfollowing(this.currentUser);
        //debugger;
        //if (typeof this.expiredList !== "undefined" && this.expiredList.length>0) {
        //  debugger;
        
        //}        
        
      }
    } else {
      // error handle no user
    }
  }

  disableCreate() {
    const createButton = document.getElementById('createDeal') as HTMLInputElement;
    createButton.style.display = 'none';
  }

  /**
   * Get the expired deal list for the user who is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-03-27
   * @param creator - the username of current user
   */
  getfollowing(creator) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.followingList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          this.temtor = '';
          for (let index = 0; index < this.followingList.length; index++) {
            if (this.followingList[index]) {
              this.temtor = this.temtor + this.followingList[index].company + ',';
            } else {
              this.noFollowing = true;
            }
          }

          this.temtor = this.temtor.substring(0,this.temtor.lastIndexOf(","));
          this.getexpiredList(this.temtor);

         
        }
      );
  }


  //返回DealList
  async getexpiredList(creator: string) {
    //console.log(creator);
    if (creator.length>0) { 
      //反回列表        
      await this.getExpiredListIndividual(creator, this.currentUser);
      //计算页数
      this.getAllCount(creator,this.currentUser,this.filter,'ExpiredDeals',this.IsCompany);
    } else {
       this.noFollowing = true;
    }
  }

  /**
   * Get the expired deal list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-03-27
   * @param creator - brandnewegg
   * @param individual - the username of current user
   */
  async getExpiredListIndividual(creator, individual) {

    const data = await this.getExpiredDataIndividual(creator, individual);
    this.expiredList = [];    
    if (Object.keys(data).length > 0) {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.expiredList.push(data[index]);
      }
    }    
  }

  getExpiredDataIndividual(creator, individual) {

    return new Promise((resolve, reject) => {
      this.dealService.individualExpiredDeals(creator, individual,this.filter,this.skip,this.pageSize)
        .pipe(first()).subscribe(data => {
          /*
          this.expiredList = [];
          for (let index = 0; index < data.length; index++) {
            this.expiredList.push(data[index]);
          }*/
          resolve(data);
      });
      // resolve(this.expiredList);
    });
  }

  /**
   * Get all the expired deal list when the user is signed as a company.
   * @param creator - the username of current user
   */
  async expiredRetrieve(creator) {

    const data = await this.getexpiredData(creator);
    this.expiredList = [];    
    if (Object.keys(data).length > 0) {
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.expiredList.push(data[index]);
      }
    }
   
  }

  getexpiredData(creator) {

     return new Promise((resolve, reject) => {
      this.dealService
        .expiredDealRetrieve(creator,this.filter,this.skip,this.pageSize)
        .pipe(first())
        .subscribe(data => {
          /*
          if (data.length != 0) {
            for (let index = 0; index < data.length; index++) {
              this.expiredList.push(data[index]);
            }
          }*/
          resolve(data); //this.expiredList);  
        });    
    })

  }

  takeDeal(productId: any) {
    this.selectProductId = productId;
    if (!this.privilege) {
      this.dealCreator = this.expiredList[productId].creator;
      // console.log(this.expiredList[productId]);
      this.Storage(this.dealCreator);
      if (productId >= 0) {
        this.inbound.product = this.expiredList[productId].product_name;
        this.inbound.price = this.expiredList[productId].price;
        this.inbound.quantity = 0;
        this.inbound.warehouse = '';
        this.inbound.company = this.expiredList[productId].creator;
        this.inbound.individual = this.currentUser;
        this.inbound.status = 0;
        this.inbound.companyStatus = 0;
        this.inbound.propose = 1;
        this.inbound.dealId = this.expiredList[productId].id;
      }
    } else {
      this.isAwards = false;
      this.awardsUsers = null;
      this.isPublic = false;
      this.isPrivate = false;
      this.selectDeal.id = this.expiredList[productId].id;
      this.selectDeal.productName = this.expiredList[productId].product_name;
      this.selectDeal.products = this.expiredList[productId].products;
      this.selectDeal.quantity = this.expiredList[productId].quantity;
      this.selectDeal.price = this.expiredList[productId].price;
      const currentTime = new Date(this.expiredList[productId].expires_at);
      this.month = (currentTime.getMonth() + 1).toString();
      this.days = currentTime.getDate().toString();
      this.year = currentTime.getFullYear().toString();
      this.hour = currentTime.getHours().toString();
      this.minutes = currentTime.getMinutes().toString();
      this.selectDeal.note = this.expiredList[productId].note;
      this.selectDeal.bonus = this.expiredList[productId].bonus;
      this.selectDeal.serviceTag = this.expiredList[productId].service_tag;
      this.selectDeal.notify = this.expiredList[productId].notify;
      this.selectDeal.creator = this.expiredList[productId].creator;
      this.selectDeal.members = this.expiredList[productId].members;
      this.selectDeal.dealPublic = this.expiredList[productId].public;
      this.selectWarehouse = '';
    }
    // console.log(this.inbound);
  }

  makeAwards() {
    this.isAwards = true;
    this.awardsUsersUnique = [];
    this.awardsUsersUniqueQty = [];
    
    this.inboundService
      .findAwardsUser(this.selectDeal.id)
      .pipe(first())
      .subscribe(data => {
        this.awardsUsers = data;
/*
        while (this.awardsUsers.length >= 1) {
          let currentAwardsUser = this.awardsUsers.pop();
          if (currentAwardsUser !== undefined) {
            if (
              !this.awardsUsersUnique.includes(currentAwardsUser.individual)
            ) {
              this.awardsUsersUnique.push(currentAwardsUser.individual);
              this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                currentAwardsUser.quantity;
            } else {
              this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                currentAwardsUser.quantity;
            }
          }
        }
*/
      var i;
      for(i=0;i<this.awardsUsers.length;i++) {
        let currentAwardsUser = this.awardsUsers[i];
          if (currentAwardsUser !== undefined) {
            if (
              !this.awardsUsersUnique.includes(currentAwardsUser.individual)
            ) {
              this.awardsUsersUnique.push(currentAwardsUser.individual);
              this.awardsUsersUniqueQty[currentAwardsUser.individual] =
                currentAwardsUser.quantity;
            } else {
              this.awardsUsersUniqueQty[currentAwardsUser.individual] +=
                currentAwardsUser.quantity;
            }
          }
      }
      });
  }

  cancelAwards() {
    this.isAwards = false;
    this.awardsUsers = null;
  }

  makePublic() {
    this.membersTemp = '';
    this.isPublic = true;
    this.isPrivate = false;
    this.selectDeal.dealPublic = true;
    this.groupService
      .allSubscribers(this.selectDeal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.memberList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
            $('.selectpicker').selectpicker('selectAll');
          });
        }
      );
  }

  makePrivate() {
    this.membersTemp = '';
    this.isPublic = false;
    this.isPrivate = true;
    this.selectDeal.dealPublic = false;
    this.groupService
      .groupRetrieve(this.selectDeal.creator)
      .pipe(first())
      .subscribe(
        data => {
          this.groupList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  updateCompany() {
    if (this.isAwards) {
      this.update();
    }

    const date =
      this.year +
      '-' +
      this.month +
      '-' +
      this.days +
      ' ' +
      this.hour +
      ':' +
      this.minutes +
      ':' +
      '00';
    this.selectDeal.expiresAt = date;
    if (this.membersTemp) {
      this.selectDeal.members = this.membersTemp.join();
    }
    this.dealService
      .updateCompany(this.selectDeal)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
        this.awards = 0;
        this.selectDeal.clear();
        window.location.reload();
      });
  }

  update() {
    let i = 0;
    const awardsIds: number[] = new Array();
    if (this.awardsUsers) {
      while (this.awardsUsers[i]) {
        awardsIds.push(this.awardsUsers[i].id);
        i++;
      }
    }
    this.inboundService
      .updateAwards(awardsIds, this.selectDeal.price, this.awards)
      .pipe(first())
      .subscribe(
        data => {
          this.isUpdateAwards = true;
        },
        error => {
          console.log(error);
        },
        () => {
          this.awardsUsers = null;
        }
      );
  }

  emailNotification() {
    if (this.selectDeal.notify === true) {
      const date =
      this.year +
      '-' +
      this.month +
      '-' +
      this.days +
      ' ' +
      this.hour +
      ':' +
      this.minutes +
      ':' +
      '00';
      this.selectDeal.expiresAt = date;
      this.email.dealProduct = this.selectDeal.products;
      this.email.emailList = this.selectDeal.members;
      this.email.receiver = this.receiver;
      this.email.emailSubject = this.selectDeal.creator + ' | ' + ' New Offer : ';
      this.email.emailTextBeforeImage = this.selectDeal.creator + ' has created a new offer<br><br>' + 'Product: ' + this.selectDeal.productName + '<br><br>';
      var htmlNote = this.selectDeal.note.replace(/(\r\n|\n|\r)/gm, "<br>");
      this.email.emailTextAfterImage = '<br><br>Price: $' + this.selectDeal.price + '<br><br>Quantity: ' + this.selectDeal.quantity + '<br><br>Expire time: ' + this.selectDeal.expiresAt + '<br><br><font color="red">Note:<br><br>' + htmlNote + '</font><br><br>Visit the link below to see all active offers<br><br>m.brandnewegg.com/deals/active';
      this.dealService
        .emailNotification(this.email)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('done');
          }
        );
    }
  }

  createPropose() {
    if (this.selfWarehouse) {
      this.inbound.warehouse = this.selectPrivateWarehouse;
    } else {
      this.inbound.warehouse = this.selectPublicWarehouse;
    }
    // console.log(this.inbound);
    this.inboundService
      .createPropose(this.inbound)
      .pipe(first())
      .subscribe(
        data => {
          this.isProposed = true;
          this.inbound.clear();
          this.selectProductId = null;
          //确认申领。
          this.alertService.success('Confirmed Propose!!', this.options);
         
        },
        error => {
          console.log(error);
          this.alertService.error('Propose Failure!!', this.options);
        },
        () => {
          this.inbound.clear();
          this.selectPrivateWarehouse = null;
          this.selectPublicWarehouse = null;
        }
      );
  }

  useSelfWarehouse() {
    this.selfWarehouse = true;
    this.inbound.publicWarehouse = false;
    this.privateStorage(this.currentUser);
  }

  usePublicWarehouse() {
    this.selfWarehouse = false;
    this.inbound.publicWarehouse = true;
  }

  Storage(dealCreator) {
    // console.log(dealCreator);
    this.storageService
      .retrieve(dealCreator)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.publicWarehouse = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  privateStorage(creator) {
    // console.log(creator);
    this.storageService
      .retrieve(creator)
      .pipe(first())
      .subscribe(
        data => {
          this.privateWarehouse = data;
        },
        error => {
          console.log(error);
        },
        () => {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          });
        }
      );
  }

  compareWh(warehouse1: any, warehouse2: any) {
    //return warehouse1.id === warehouse2.id;
    return warehouse1 && warehouse2 ? warehouse1.id === warehouse2.id : warehouse1 === warehouse2;
  }

  //统计总页数
  getAllCount(creator,company,filter,deals,iscompany) {
    this.dealService
      .getAllCount(creator,company,filter,deals,iscompany)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }

  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    this.expiredList = new Array();
    if (this.authenticationService.currentUserValue[2] === 'company') {
      this.disableCreate();
      this.privilege = true;      
      this.expiredRetrieve(this.currentUser);
    } else {      
      this.privilege = false;
      this.getfollowing(this.currentUser);
      //this.getRecords(this.currentUser);
    }    
   
  }  
}
