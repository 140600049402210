import { Component, OnInit, Input,Injectable } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, EmailValidator, FormArray } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { InboundService } from '../../../services/inbound.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { DealService } from '../../../services/deal.service';
import { TrackingService } from '../../../services/tracking.service';
import { Tracking } from '../../../models/tracking';
import { MapService } from '../../../services/map.service';
import { ConfirmService } from '../../../services/confirm.service';
//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PaymentService } from '../../../services/payment.service';

import { Payment } from '../../../models/payment';
import { inboundPayment } from '../../../models/inboundpayment';

import { AlertService } from '../../../_alert';



//import { LabelModule } from '@progress/kendo-angular-label';

declare var $: any;
declare var jQuery: any;
declare var kendo:any;


@Component({
  selector: 'app-reported-list',
  templateUrl: './reported-list.component.html',
  styleUrls: ['./reported-list.component.scss'],
  providers: [InboundService, AuthenticationService, DealService, TrackingService, MapService, ConfirmService,PaymentService]
})


export class ReportedListComponent implements OnInit {

  options = {
    autoClose: false, //true Auto close alert after three seconds
    keepAfterRouteChange: true //true Keep displaying after one route change
  };
  
  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';
  public commponent = "ReportedList";

  public pageSizes = true; //每页显示多少条
  public info = true;      //显示总页数信息
  public prevNext = true;  //显示下一页按钮
  public type = 'input';   //input手动输入页数 numeric数字类型
  public position = 'top' ;
  public notificationText: string;
  

  public packageList = [];
  public oldpackageList = [];
  public PackageSizeForm: FormGroup;
  public isPublicWarehouse = true;
  public IsshippingLabel =false;

  //public count: any;
  public inputTrackings: any;
  public status = ['Waiting', 'Confirming', 'Confirmed', 'Canceled'];
  public currentUser: any;
  public privilege: any;
  public recordList: any;
  //@Input() recordList: any; //parent模板传数据。
  //@Input() data: any;
  public productId: any;
  public mapList: any;
  public maptype: any;
  public maplength = 0;
  public confirmedList = [];
  public currentConfirmQty: any;
  public billList = [];
  public currentBillQty: any;
  public paidList = [];
  public currentPaidQty: any;

  public trackingList: any;
  public trackingConfirmedList: any;
  public trackingTemp: any;
  public selectInbound = new Inbound(
    '',
    '',
    '',
    null,
    '',
    '',
    '',
    null,
    null,
    null,
    null,
    null
  );
  //public isCollapsed:boolean;
  public selectId: number;
  public inValid: boolean;
  public qtyGiveBack: number;
  public receiptForm: FormGroup;
  constructor(
    private inboundService: InboundService,
    private authenticationService: AuthenticationService,
    private dealService: DealService,
    private trackingService: TrackingService,
    private mapService: MapService,
    private confirmService: ConfirmService,
    private _formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private alertService: AlertService,
  ) {
    
     this.receiptForm = new FormGroup({
       ReceiptNumber: new FormControl(),
       IndividualUser: new FormControl(),
       receiptDate: new FormControl(new Date()),
       DueDate: new FormControl(new Date()),    
       ConditionsValue: new FormControl(),
       Conditions: new FormControl(),
       ConfirmedQty: new FormControl(),
         BillTotal: new FormControl(),
      });
     //this.isCollapsed = true;

  }

  public noRecord: any;
  @Input('answers') ans:any;

  public dealid = 0;
  public inboundid = 0;
  public individual = "";
  public notify = true;

  public receipt = [];
  public receiptList = [];
  public payfields = [];
  public isAdd = 'add';

  //public receiptDate = '77766';
  //public Conditions = '34343';

  /*
  public receiptForm: FormGroup = new FormGroup({
    ReceiptNumber: new FormControl(),
    IndividualUser: new FormControl(),
    receiptDate: new FormControl(new Date()),
    DueDate: new FormControl(new Date()),    
    ConditionsValue: new FormControl(),
    ConfirmedQty: new FormControl(),
    BillTotal: new FormControl(),
  });*/

  public payments = new Payment( null,null,'','','',null,null,null,null,null,null,null,null,'','', null, '' );
  public inboundPayments = new inboundPayment( null,null,null,'','','',null,null,null,null,null,null,null,null,null);

  //id、inboundId、dealId、receiptNumber、company、individual、billQty、confirmedQty、paidQty、billTotal、confirmedTotal、paidTotal、adjustment、status、description、isDelete、receiptDate
  //id、inboundId、dealId、paymentId、company、individual、upc、confirmedQty、confirmedPrice、billQty、billPrice、 paidQty、paidPrice、amount、status、isDelete



  ngOnInit() {
    
    if (!this.ans) return;
    let desc = $("#re-description").kendoTextArea({
      rows: 8,
      maxLength:200,
      placeholder: "Payment is due within 3 days."
    });

    if (this.authenticationService.currentUserValue) {
      // console.log(this.authenticationService.currentUserValue);
      //this.filter = '';
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.findAllCount(this.currentUser,this.authenticationService.currentUserValue[2] === 'company' ? true : false,this.filter); //获取总条数 

      if (this.authenticationService.currentUserValue[2] === 'company') {
        //$("#NewReceipt").hide()
        this.privilege = true;
        this.getRecordsCompany(this.currentUser);
      } else {
        this.privilege = false;
        this.getRecords(this.currentUser);
      } 
      // this.userEmail = 'admin';
    } else {
      // TODO: add no userEmail error
    }

  }

  addTracking() {
    if(this.inputTrackings!=="") {
      let inputTrackingArray = [];
      inputTrackingArray = this.inputTrackings.split(/\r?\n/);
      var i;
      for (i=0; i<inputTrackingArray.length; i++) {
        inputTrackingArray[i] = inputTrackingArray[i].trim();
        if(inputTrackingArray[i]==="") continue;
        let singleTracking = new Tracking(
          null,
          null,
          '',
          '',
          '',
          ''
        );
        singleTracking.inboundId = this.selectInbound.id;
        singleTracking.dealId = this.selectInbound.dealId;
        singleTracking.company = this.recordList[this.selectId].company;
        singleTracking.individual = this.recordList[this.selectId].individual;
        singleTracking.tracking = inputTrackingArray[i];
        singleTracking.status = "waiting";
        //console.log(singleTracking);
        this.trackingService
          .createTracking(singleTracking)
          .pipe(first())
          .subscribe(
            data => {
              //console.log(data);
              this.getInboundTracking(this.selectInbound.id);
              this.inputTrackings = "";
            },
            error => {
              console.log(error);
            },
            () => {
            }
          );
      }
    }
  }
  //个人账号
  getRecords(userEmail) {
    this.inboundService
      .reportedRecordsKendo(userEmail, this.pageSize , this.skip, this.filter)
      .pipe(first())
      .subscribe(
        data => {
          this.recordList = data;
          /*
          if (this.recordList !== undefined && this.recordList.length > 0) {
            var i;
            let confirmedQty = 0,
                billQty      = 0; 

            for (i=0; i<this.recordList.length; i++) {
              confirmedQty = this.recordList[i].paymentConfirm != null && this.recordList[i].paymentConfirm.confirmedQty != null ? this.recordList[i].paymentConfirm.confirmedQty : 0;
              billQty      = this.recordList[i].paymentConfirm != null && this.recordList[i].paymentConfirm.billQty != null ? this.recordList[i].paymentConfirm.billQty : 0;
              if (confirmedQty-billQty>0) {
                this.recordList[i].payable = 1; 
              } else {
                this.recordList[i].payable = 0;
              }
            }
          }*/
          // console.log(this.recordList);
        },
        error => {
          console.log(error);
        },
        () => {
          if (this.recordList.length < 1) {
            this.noRecord = true;
          }
        }
      );
  }
  //公司账号
  getRecordsCompany(userEmail) {
    this.inboundService
      .reportedRecordsCompanyKendo(userEmail, this.pageSize , this.skip, this.filter)
      .pipe(first())
      .subscribe(data => {
        this.recordList = data;
      });
  }
  
  //搜索过虑 userEmail 账号 filter 搜索值  个人用户
  RecordsSearch(userEmail,filter) {
    this.inboundService
      .reportedRecordsKendo(userEmail, this.pageSize , 0 , filter)
      .pipe(first())
      .subscribe(data => {
        this.recordList = data;
      });
  }

  //搜索过虑 userEmail 账号 filter 搜索值  公司用户
  RecordsCompanySearch(userEmail,filter) {
    this.inboundService
      .reportedRecordsCompanyKendo(userEmail, this.pageSize , 0 , filter)
      .pipe(first())
      .subscribe(data => {
        this.recordList = data;
      });
  }

  //统计总页数
  findAllCount(userEmail,iscompany,filter) {
    //debugger;
    this.inboundService
      .FindAllCount(userEmail,iscompany,filter)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
        //this.total = this.count;
      });
  }  

  cancelInbound() {
    this.selectInbound.dealId = this.recordList[this.selectId].dealId;
    this.selectInbound.id = this.recordList[this.selectId].id;
    if (this.selectInbound.quantity > this.recordList[this.selectId].quantity) {
      this.selectInbound.quantity = this.recordList[this.selectId].quantity;
    }
    this.inboundService
      .cancelInbound(this.selectInbound)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
      });
  }

  select(i) {
    this.trackingList = [];
    this.confirmedList = [];
    this.trackingConfirmedList = [];
    //this.confirmedList = [];
    this.billList = [];
    this.paidList = [];
    this.maptype = "";
    this.maplength = 0;
    this.inputTrackings = "";
    this.currentConfirmQty = 0;
    this.currentBillQty =0;
    this.currentPaidQty =0;
    this.selectId = i;
    this.selectInbound.dealId = this.recordList[i].dealId;
    this.selectInbound.quantity = this.recordList[i].quantity;
    this.selectInbound.id = this.recordList[i].id;
    this.selectInbound.product = this.recordList[i].product;
    this.selectInbound.price = this.recordList[i].price;
    this.selectInbound.warehouse = this.recordList[i].warehouse;
    this.getInboundTracking(this.recordList[i].id);
    this.getProductId();

    this.dealid = this.recordList[i].dealId;
    this.inboundid = this.recordList[i].id;
    this.individual = this.recordList[i].individual;


    //判断是否是使用公司仓库
    //debugger;
    this.isPublicWarehouse = this.recordList[i].publicWarehouse == 0 ? false : true;
    let packid = this.selectInbound.id.toString();
    let dealid = this.selectInbound.dealId.toString();
    this.PackageSizeForm = new FormGroup({
      packageList: this._formBuilder.array([this.addPackageSizeFormGroup()])
    });
    this.inboundService
      .getPackageList(packid,dealid)
      .pipe(first())
      .subscribe(data => {
        if (data.length > 0) {
           this.IsshippingLabel = true ;
           this.addPackageSizeFormGroup(Object.values(data));
        } else {
          (this.PackageSizeForm.get('packageList') as FormArray).removeAt(0)
          this.IsshippingLabel = false ;
        }
        //debugger;
       //this.addPackageSizeFormGroup(Object.values(data));        
    });


  }

  getProductId() {
    this.dealService
      .getProductId(this.selectInbound.dealId)
      .pipe(first())
      .subscribe(data => {
        this.productId = data[0].products;
        this.getProductMaps();
      });
  }

  getProductIda(dealId) {
    return new Promise((resolve, reject) => {
      this.dealService
        .getProductId(dealId)
        .pipe(first())
        .subscribe(data => {
          this.productId = data[0].products;
          this.getProductMaps();

          resolve(this.currentBillQty);
      });
    });
  }

  getProductMaps() {
    this.mapService
      .allMap(this.productId)
      .pipe(first())
      .subscribe(data => {
        this.mapList = data;
        if (this.mapList !== undefined && this.mapList.length > 0) {
          this.maptype = this.mapList[0].type;
          this.maplength = this.mapList.length;
          var i;
          for (i=0; i<this.mapList.length; i++) {
            this.getInboundConfirm(this.selectInbound.id, this.mapList[i].upc);
          }
        }
      });
  }

  getInboundConfirm(inboundId, upc) {
    this.confirmService
      .allConfirm(inboundId, upc)
      .pipe(first())
      .subscribe(data => {
        if (data !== undefined && data.length > 0) {
          this.currentConfirmQty = 0;
          this.currentBillQty = 0;
          this.currentPaidQty = 0
          var i;
          for(i=0;i<data.length;i++) {
            this.currentConfirmQty += data[i].confirmedQty;
            this.currentBillQty += data[i].billQty;
            this.currentPaidQty += data[i].paidQty;
          }
        } else {
          this.currentConfirmQty = 0;
        }
        this.confirmedList.push(this.currentConfirmQty);
        this.billList.push(this.currentBillQty);
        this.paidList.push(this.currentPaidQty);
      });
  }

  /*
  getInboundTracking(inboundId) {
    this.trackingService
      .allTracking(inboundId)
      .pipe(first())
      .subscribe(data => {
        this.trackingList = data;
      });
  }*/

  //Kendo页更改函数
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    if (this.authenticationService.currentUserValue[2] === 'company') {
      this.getRecordsCompany(this.currentUser);
    } else {
      this.getRecords(this.currentUser);
    }
     
    //debugger;
    //this.pageData();
  }
  //获取页数据
  pageData(): void {
      //this.pagedArticles = articles.slice(this.skip, this.skip + this.pageSize);
  }

  //定义包装尺寸及重量  
  addPackageSizeFormGroup(data=[]): FormGroup {    
    if (data.length == 0){          
      return this._formBuilder.group({
         id: [-1],
         length: ["", Validators.required], //长
         width : ["", Validators.required], //宽
         height: ["", Validators.required],  //高
         deep : [0], //深
         thick: [0], //厚
         weight: [0], //厚
         qty: [1, Validators.required],    
      });
    } else {
      let arr = {};
      (this.PackageSizeForm.get('packageList') as FormArray).removeAt(0); //删除第一个
      data.forEach((currentValue, index) => {
        arr = {
          id:currentValue.id,
          length:currentValue.length, 
          width :currentValue.width, 
          height:currentValue.height,
          qty: isNaN(parseInt(currentValue.qty)) ? 1 : parseInt(currentValue.qty),
          deep :0, 
          thick: 0, 
          weight:currentValue.weight,
        };
        (this.PackageSizeForm.get('packageList') as FormArray).push(this._formBuilder.group(arr));
      })
      this.packageList = this.PackageSizeForm.get('packageList').value; //赋值

    }
  }

  CheckShippingLabel(ship:number) {

    if (ship==0) {
      this.IsshippingLabel = false ;
    } else {
      this.IsshippingLabel = true ;
    }
    //this.IsshippingLabel = ship == 0 ? false : true;    
  }

  //添加多个包装
  onAddPackage() {    
    (this.PackageSizeForm.get('packageList') as FormArray).push(this.addPackageSizeFormGroup());    
  }
  
  //移除多个包装
  removePackage(index:number)
  {
    (this.PackageSizeForm.get('packageList') as FormArray).removeAt(index)
  }

  //保存数据
  onSavePackage() {
   
    let arr = {};

    this.oldpackageList = [];
    if (this.packageList.length>0) {
      this.oldpackageList = this.packageList;
    }
    //console.log(this.packageList);

    this.packageList = [];
    this.PackageSizeForm.get('packageList').value.forEach((currentValue, index) => {
        //过虑长*宽*高不为0数据     
        //debugger;   
        if(currentValue.length>0 && currentValue.width>0  && currentValue.height>0){
            //debugger;
            arr = {
              'dealId' :this.dealid,
              'inboundId':this.inboundid,
              'length': currentValue.length,
              'width': currentValue.width,
              'height': currentValue.height,
              'weight':currentValue.weight == "" ? 0 : currentValue.weight,
              'qty':currentValue.qty,
              'individual': this.individual,
              'id':currentValue.id,
              'type': currentValue.id == -1 ? 'add' : 'update'
            };
            this.packageList.push(arr);
            //this.myArray.splice(index, 1);
        }
    });

    //console.log(this.packageList.length);
    //console.log(this.oldpackageList.length);

    if (this.packageList.length>0 || this.oldpackageList.length>0) {
      
       let delid = [];
       this.oldpackageList.forEach((currentValue, index) => {
         if (currentValue.id > 0) {
           delid = this.packageList.filter(p => p.id == currentValue.id);
           
           if (delid.length == 0) {
             //删除的行
              arr = {
                'dealId'    : this.dealid,
                'inboundId' : this.inboundid,
                'length'    : currentValue.length,
                'width'     : currentValue.width,
                'height'    : currentValue.height,
                'weight'    : currentValue.weight,
                'qty'       : currentValue.qty,
                'individual': this.individual,
                'id'        : currentValue.id,
                'type'      : 'delete'
              };
              this.packageList.push(arr);

           }
         }
       })
      
      //console.log(this.packageList);      

      //插入包装信息
      this.inboundService
      .Addpackage(JSON.stringify(this.packageList))
      .pipe(first())
      .subscribe(data => {           
      })

      this.packageList = [];
    }

  }

  //取消包裹尺寸
  cancelPackage() {

    let arr = {};

    this.packageList = [];
    this.PackageSizeForm.get('packageList').value.forEach((currentValue, index) => {
        //过虑长*宽*高不为0数据     
        if(currentValue.length>0 && currentValue.width>0  && currentValue.height>0){
            //debugger;
            arr = {
                'dealId' :this.dealid,
                'inboundId':this.inboundid,
                'length': currentValue.length,
                'width': currentValue.width,
                'height': currentValue.height,
                'weight':currentValue.weight == "" ? 0 : currentValue.weight,
                'qty':currentValue.qty,
                'individual': this.individual,
                'id':currentValue.id               
            };
            this.packageList.push(arr);
        }
    });

    if (this.packageList.length>0) {
       //删除包装信息
       //let packageList = ["package:" + this.packageList + ",notify:" + this.notify];
       if (this.notify) {
         this.inboundService
          .cancelPackage(JSON.stringify(this.packageList))
          .pipe(first())
          .subscribe(data => {           
         })
       } else {
         this.inboundService
         .nocancelPackage(JSON.stringify(this.packageList))
         .pipe(first())
         .subscribe(data => {           
        })
       }
       

    } 
  }
  async NewReceipt() {
    let selectRows = [],
        row        = {},
        r          = {},
        crow       = [],
        inboundIds = '',
        upcBillQty: any,
        em         = this;

    let bonus = 0,
        awards= 0; 
            

    let list = this.recordList;
    if (list.length==0) return;

    for (const item of list) {
    //list.forEach(function(item){

      if (typeof(item.isSelected) !='undefined' && item.isSelected) {

        bonus = item.bonus === null ? 0 : parseFloat(item.bonus);
        awards= item.awards === null ? 0 : parseFloat(item.awards);
        row['id']              =  item.id;
        row['awards']          =  item.awards;
        row['company']         =  item.company;
        row['companyStatus']   =  item.companyStatus;
        row['createAt']        =  item.createAt;
        row['updateAt']        =  item.updateAt;
        row['dealId']          =  item.dealId;
        row['individual']      =  item.individual;
        row['payable']         =  item.payable;
        row['price']           =  parseFloat(item.price) + bonus + awards;
        row['product']         =  item.product;
        row['product_link']    =  item.product_link;
        row['propose']         =  item.propose;
        row['proposeStatus']   =  item.proposeStatus;
        row['publicWarehouse'] =  item.publicWarehouse;
        //row['quantity']        =  item.quantity;
        row['status']          =  item.status;
        row['warehouse']       =  item.warehouse;       
        row['inboundId']       =  item.id;  

        r                      =  await em.getUpcBillQty(item.id,row);

        //row['tax']       =  ''; //item.tax;
        /*
        if (upcBillQty.length>0) {
          for (const value of await upcBillQty)
          {
            row['upc']             =  value.upc; //upcBillQty[0].upc;
            row['quantity']        =  value.BillQty; // upcBillQty[0].BillQty;
            
             if (parseInt(row['quantity'])>0) {
                //数量大于零
                console.log(row);
                selectRows.push(row);
                console.log(selectRows);
             }
          }
        }*/
        
        //row['upc']             = upcBillQty[0].upc;
        //row['quantity']        = upcBillQty[0].BillQty;

        inboundIds = inboundIds + item.id + ',';

        if (Object.keys(r).length != 0) {
          crow = Object.values(r);          
          crow.forEach(function(value){
            selectRows.push(value);
          })          
        }       
                  

        row = {};
      
      }

      
      //row['upc']       =  item.upc;      
      //row['amount']    =  item.amount;
      //row['tracking']  =  item.tracking;       

    };

    inboundIds = inboundIds.substring(0,inboundIds.lastIndexOf(",")); 
    if (inboundIds != '') {
      this.getBillTotal(inboundIds,this.currentUser);
    }
    /*
    if (selectRows.length != 0) {
      //this.alertService.error('No matching rows! Please select at least one row', this.options);
      //$("#ReceiptModal").hide();
      $('#ReceiptModal').modal('hide')
    }*/
    
    this.CreateReceipt(selectRows);
    //console.log(list);
  }
  getProoduct(isSelected, id){
    //console.log(isSelected, id)
  }

  //日期转字符串
 formatDate(date) {

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
 }

 //获取付款编号
  getBillTotal(inboundIds,individual) {

    this.paymentService
      .getBillTotal(inboundIds,individual)
      .pipe(first())
      .subscribe(data => {
         
         //let str = data[0]["receiptNumber"] === null ? '1' : (parseInt(data[0]["receiptNumber"])+1).toString();      
         let str = data[0]["receiptNumber"]; // str.padStart(6,'0');
         
         this.receiptForm.patchValue({
            ReceiptNumber: str,
            //BillTotal:data[0]['BillTotal'],
            //ConfirmedQty:data[0]['confirmedQty']

         });  
              
    });

  }

  //添加付款单
  CreateReceipt(rows) {

      
    //var dt = new Date();
    let begin_date = new Date();
    let end_date   = new Date();
    let individual = '';                  
  
    let receiptDate = $("#receiptDate").kendoDatePicker({
      format: "yyyy-MM-dd",
      value: begin_date
    });

    end_date.setDate(end_date.getDate() + 3);              

    let dueDate     =  $("#dueDate").kendoDatePicker({
      format: "yyyy-MM-dd",
      value: end_date
    });        

    /*
    $("#Conditions").on('input', function (e) {
        $('.k-counter-container .k-counter-value').html($(e.target).val().length);
    });*/           

    let row = {},
        currentamount = 0.00;

    //var test = [ { product: "Jane", quantity: 2000 }, { product: "Bill", quantity: 2000 } ];     

    this.receipt = []; 
    //rows = Object.values(rows);   
            
    if (rows.length > 0) {
      for (let arr = 0 ; arr < rows.length; arr++) {
        row['id']        =  rows[arr]['id'];
        row['dealId']    =  rows[arr]['dealId'];
        row['company']   =  rows[arr]['company'];
        row['inboundId'] =  rows[arr]['inboundId'];
        row['product']   =  rows[arr]['product'];
        row['individual']=  rows[arr]['individual'];
        row['upc']       =  rows[arr]['upc'];
        row['quantity']  =  rows[arr]['quantity'];
        row['price']     =  rows[arr]['price'];
        row['paidQty']   =  rows[arr]['quantity'];
        row['paidPrice'] =  rows[arr]['price'];
        row['amount']    =  (row['paidQty'] * row['paidPrice']).toFixed(2);
        currentamount += row['amount'];
        //row['tracking']  =  rows[arr]['tracking'];
        row['status']    =  rows[arr]['status'];
        row['tax']       =  0;

        row['CheckpaidQty']   =  rows[arr]['quantity'];
        row['idupc']         =  rows[arr]['id'] + '_' + rows[arr]['upc'];


        this.receipt.push(row);
        //individual = row['individual'];
        //em.receiptForm.value.IndividualUser = row['individual'];            
        row = {};

          
      }
    };

    //getPaymentNumber()

    let billTotal = (currentamount * 1).toFixed(2);

    const receiptNumber = '001' ; //em.getReceiptNumber(individual);

    this.receiptForm.patchValue({
      IndividualUser: this.currentUser,
      receiptDate: this.formatDate(begin_date),
      //DueDate: this.formatDate(end_date),
      BillTotal:billTotal,
      ConditionsValue:''
    });    

    this.payfields = [
      {
        field: "product",
        title: "Product" ,
        width: 350
      }, {
        field: "upc",
        title: "upc",
        width: 100
      }, {
        field: "paidQty",
        title: "Paid Qty",        
        width: 90,        
        template: "#if (paidQty > CheckpaidQty) { amount=CheckpaidQty * paidPrice; #<span>#: CheckpaidQty #<span>#} else { amount=paidQty * paidPrice;#<span>#: paidQty #<span>#}#",
        aggregates: ["sum"],footerTemplate:"paidQty: #=sum#"
        //template: "#if (paidQty < 10) {#<span>#: paidQty #<span>#} else if (paidQty  > 40) {#<span style='color:red;'>#: paidQty #<span>#}#"
        //;let curamount = document.getElementById('BillTotal').value ; document.getElementById('BillTotal').value = parseFloat(curamount).toFixed(2) + parseFloat(amount).toFixed(2);
      }, {
        field: "paidPrice",
        title: "Paid Price",
        format: "{0:c}",
        width: 90
      } , {
        field: "amount",
        title: "Amount",
        format: "{0:c}",
        width: 90,
        /*attributes: {
          "class": "totalSpan",
          style: "text-align: right; font-size: 14px"
        },
        editor: function(cont, options) {
            $("<span class='totalSpan'>" + options.model.amount + " &euro;</span>").appendTo(cont);
        },*/
        aggregates: ["sum"],footerTemplate:"paidTotal: #=sum#"/*,
        template: "#=paidQty * paidPrice#"*/

      },
      {
        field:"CheckpaidQty",
        hidden:true
      },
      {
        field:"id",
        hidden:true
      },
      {
        field:"idupc",
        hidden:true
      }

     
    ];

    this.isAdd = 'add';
    let th = this;

    $("#newreceipt").kendoGrid({            
      columns: this.payfields, 
      editable: true, 
      //toolbar: ["create"],
      height: 460,
      //selectable: "multiple cell",
      //editable: "inline",
      //selectable: "multiple, row",
      //dataSource: em.receipt, //[ { product: "Jane", quantity: 2000 }, { product: "Bill", quantity: 2000 } ]              
      dataSource: {
        data:this.receipt,
        autoSync: true,
        schema: {
                    model: {
                        id: "idupc",
                        fields: {
                            id: { editable: false, nullable: true },
                            product: { editable: false,validation: { required: true } },
                            paidPrice: { editable: false,field: "paidPrice", type: "number"},
                            paidQty: { editable: true,field: "paidQty", type: "number"},
                            amount:{ editable: false,field: "amount", type: "number"},
                            upc: { editable: false,field: "upc", type: "string"}
                        }
                    }
                },
                aggregate: [{field: "paidQty", aggregate: "sum"},
                            {field: "amount", aggregate: "sum",format: "{0:c}"}],
                edit: function(e) {
                  console.log(e);
                },
                change: function (e) {

                  //if (e.field && e.action == "itemchange") {
                    let grid = $("#newreceipt").data("kendoGrid");
                    //let data = grid.dataSource.options.data;
                    let model = e.items[0];
                    let items = grid.dataSource.options.data;//this.view();
                    let amount= 0.00;
                    let qty = 0;

                    if (typeof(e.items[0]) == "undefined") return;
                    
                    if (e.items[0].paidQty>e.items[0].CheckpaidQty){
                      e.items[0].paidQty = e.items[0].CheckpaidQty;
                      //e.items[0].amount  = (e.items[0].paidQty * e.items[0].paidPrice).toFixed(2);

                    }

                    //e.items[0].amount  = (e.items[0].paidQty * e.items[0].paidPrice).toFixed(2);

                    for (var idx = 0; idx < items.length; idx++) {
                      var item = items[idx];
                      //this.receipt[idx].amount =  (this.receipt[idx].paidQty * this.receipt[idx].paidPrice).toFixed(2);
                      var paidQty = item.paidQty > item.CheckpaidQty ? item.CheckpaidQty : parseInt(item.paidQty); 
                      e.items[idx].paidQty = paidQty;
                      items[idx].paidQty   = paidQty;
                      qty += paidQty;
                      amount += paidQty * item.paidPrice;   // parseFloat(item.amount);
                    }

                                       
                    this.aggregates().amount.sum = amount.toFixed(2);
                    this.aggregates().paidQty.sum = qty;

                    (<HTMLInputElement>document.getElementById('BillTotal')).value = amount.toFixed(2); //.toString();

                    th.receiptForm.patchValue({
                      BillTotal:amount.toFixed(2)                      
                    });   
                    
                    if (typeof(grid.footer) != "undefined") {
                      grid.footer.find(".k-footer-template").replaceWith(grid.footerTemplate(this.aggregates()));
                    }
                   
                  //}          
                }
      } 

    
    });

  }
  async saveReceipt(send) {
    let grid = $("#newreceipt").getKendoGrid(),
        data = grid.dataSource.options.data,
        ts   = this,
        paidQty = 0,
        confirmedQty = {};
   
    //检查数量是否是零    
    for (const col of data) {
      paidQty = paidQty +  parseInt(col.paidQty);      
    }

    if (paidQty == 0) return;

    if (data.length>0) {

      confirmedQty = await ts.getInboundConfirmBill(data[0].dealId,data[0].inboundId);

      ts.payments.inboundId     = data[0].inboundId;
      ts.payments.dealId        = data[0].dealId;
      ts.payments.receiptNumber = ts.receiptForm.value.ReceiptNumber;
      ts.payments.company       = data[0].company;
      ts.payments.individual    = data[0].individual;
      ts.payments.billQty       = confirmedQty[0].billQty === null ? 0 : confirmedQty[0].billQty ;
      ts.payments.confirmedQty  = confirmedQty[0].confirmedQty === null ? 0 : confirmedQty[0].confirmedQty ;

      ts.payments.paidQty       = data[0].paidQty;
      ts.payments.billTotal     = ts.receiptForm.value.BillTotal;
      ts.payments.confirmedTotal= 0;
      ts.payments.paidTotal     = ts.receiptForm.value.BillTotal;
      ts.payments.adjustment    = 0;
      ts.payments.status        = 0;
      ts.payments.description   = ts.receiptForm.value.ConditionsValue;
      ts.payments.isDelete      = 0;
      ts.payments.receiptDate   = ts.receiptForm.value.receiptDate;
      
      let paymentId = await ts.createPayments(ts.payments);

      for (const col of data) {
      //data.forEach(function (col, index) {
        //id、inboundId、dealId、paymentId、company、individual、upc、confirmedQty、confirmedPrice、billQty、billPrice、 paidQty、paidPrice、amount、status、isDelete
        ts.inboundPayments.inboundId     = col.inboundId;
        ts.inboundPayments.dealId        = col.dealId;
        ts.inboundPayments.paymentId     = paymentId['id'];
        ts.inboundPayments.company       = col.company;
        ts.inboundPayments.individual    = col.individual;
        ts.inboundPayments.upc           = col.upc;
        
        ts.inboundPayments.billQty       = ts.payments.billQty;
        ts.inboundPayments.confirmedQty  = ts.payments.confirmedQty;
        ts.inboundPayments.paidQty       = col.paidQty;

        ts.inboundPayments.confirmedPrice= col.paidPrice;
        ts.inboundPayments.billPrice     = col.paidPrice;
        ts.inboundPayments.paidPrice     = col.paidPrice;
        
        ts.inboundPayments.amount        = col.paidQty * col.paidPrice; //col.amount; 
        ts.inboundPayments.status        = 0; 
        ts.inboundPayments.isDelete      = 0;

        if (ts.inboundPayments.paidQty != 0) {
          await ts.CreateInboundPayments(ts.inboundPayments);
        }        
      }

      if (send == 'send') {
        this.exportData(data);
      }


    }
   
    //console.log(data);
  }

  getInboundConfirmBill(dealid,inboundId) {

    return new Promise((resolve, reject) => {
      this.paymentService
        .getInboundConfirmBill(dealid,inboundId)
        .pipe(first())
        .subscribe(data => {  
           resolve(data);           
                
      });
    })


  }

  createPayments(payments) {
    return new Promise((resolve, reject) => { 
      this.paymentService
        .CreatePayments(payments)
        .pipe(first())
        .subscribe(
          data => {
            resolve(data);            
          }          
      )
    })
  }

  CreateInboundPayments(inboundPayments) {
    return new Promise((resolve, reject) => {  
      this.paymentService
        .CreateInboundPayments(inboundPayments)
        .pipe(first())
        .subscribe(
          data => {
            resolve(data);            
          }          
      )
    })    
  }

  //获取upc billQty
  getUpcBillQty(inboundId,row) {

    return new Promise((resolve, reject) => {
      this.paymentService
        .getUpcBillQty(inboundId)
        .pipe(first())
        .subscribe(data => {
           let selectRows = [];
           let tow = {};
           if (data.length>0) {
             for (const item of data)
              {
                 Object.assign(tow, row);
                 tow['upc']     =  item['upc'];  //upcBillQty[0].upc;
                 tow['quantity']=  item['BillQty']; // upcBillQty[0].BillQty;
            
                 //if (parseInt(tow['quantity'])>0) {                
                   selectRows.push(tow);
                 //}
                 tow = {};
              }
            } else {
              row['upc']      = '';
              row['quantity'] = 0;
              row['paidTotal']= 0.00;
            }
            
            if (selectRows.length>0) {
              resolve(selectRows);              
            } else {
              selectRows.push(row); // = Object.values(row);
              resolve(selectRows);
            }            
                
      });
    })
  }
  //汇出数据
  exportData(data) {

    let rows =[],
        row  =[],
        frow =[],
        Qty  =0.00,
        Total=0.00;

    let str = '';
    str = JSON.parse("{\"value\":\"Date\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"Receipt Number\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"Inbound\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"Product Name\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"UPC\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"Quantity\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"Price\"}");
    frow.push(str);
    str = JSON.parse("{\"value\":\"Total\"}");
    frow.push(str);

    let filename = 'PAYMENT';

    rows.push({
      cells: frow
    })


    if (data.length>0) {
      for (const col of data) {
        
        str = JSON.parse("{\"value\":\"" + this.receiptForm.value.receiptDate + "\"}");
        row.push(str);
        str = JSON.parse("{\"value\":\"" + this.receiptForm.value.ReceiptNumber + "\"}");
        row.push(str);
        str = JSON.parse("{\"value\":\"" + col.inboundId + "_" + col.individual  + "\"}");
        row.push(str);
        str = JSON.parse("{\"value\":\"" + col.product + "\"}");
        row.push(str);
        str = JSON.parse("{\"value\":\"" + col.upc + "\"}");
        row.push(str);
        str = JSON.parse("{\"value\":\"" + col.paidQty + "\"}");
        row.push(str);
        str = JSON.parse("{\"value\":\"" + col.paidPrice.toFixed(2) + "\"}");
        row.push(str);
        Qty = parseFloat(col.paidQty) * parseFloat(col.paidPrice);
        str = JSON.parse("{\"value\":\"" + Qty.toFixed(2) + "\"}");
        row.push(str);
       
        Total = Total + Qty;
        if (parseInt(col.paidQty) != 0) {
          rows.push({
            cells: row
          })
        }
       
        row = [];

      }

      row = [{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}];
      
      rows.push({
          cells: row
      });

      row = [{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:'Sum'},{value:Total.toFixed(2)}];

      rows.push({
          cells: row
      });



      filename = filename + '-' + data[0].inboundId + '.csv';
        this.excelExport(rows,filename);
    }   

  }

  //导出到Excel
  excelExport(rows,filename){

    var workbook = new kendo.ooxml.Workbook({
        sheets: [
            {
              /*
              columns: [
                { autoWidth: true },
                { autoWidth: true }
              ],*/
              columns: [ { width: 80 }, { width: 80 }, { width: 100 },{ width: 300 } , { width: 100 },{ width: 80 },{ width: 80 },{ width: 80 } ],
              title: "payment",
              rows: rows
            }
        ]
    });
    kendo.saveAs({
      dataURI: workbook.toDataURL(), 
      fileName: filename
      //proxyURL: "/home/data/release/weapp/markeapp/public",
      //forceProxy: true
    });
  }

  getInboundTracking(inboundId) {
    this.trackingService
      .allTracking(inboundId)
      .pipe(first())
      .subscribe(data => {
        if (data !== undefined && data.length > 0) {
          var i;
          for (i=0; i<data.length; i++) {
            this.getInboundTrackingConfirm(this.selectInbound.id, data[i].tracking);
          }
        }
      });
  }

  getInboundTrackingConfirm(inboundId, tracking) {
    //const a = this;
    this.confirmService
      .allTrackingConfirm(inboundId, tracking)
      .pipe(first())
      .subscribe(data => {
        var trackingConfirmQty = 0;
        if (data !== undefined && data.length > 0) {
          var i;
          for(i=0;i<data.length;i++) {
            trackingConfirmQty += data[i].confirmedQty;
          }
        } else {
          trackingConfirmQty = 0;
        }
        this.trackingList.push(tracking);
        this.trackingConfirmedList.push(trackingConfirmQty);
      });
  }



}
