import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Product } from '../models/product';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class ProductService {
  // createUrl = environment.apiUrl + 'product/add'; // URL to web api
  // /* --- ABANDONED in v2 Start --- */
  // tenUrl = environment.apiUrl + 'product/findten';
  // /* --- ABANDONED in v2 End --- */
  // allUrl = environment.apiUrl + 'product/find';
  // NameIdUrl = environment.apiUrl + 'product/NameId';
  // updateUrl = environment.apiUrl + 'product/update';
  // // NameUrl = 'http://192.168.1.119:8081/api/product/name';

  createUrl = environment.apiUrl + 'products/product'; // URL to web api
  allUrl = environment.apiUrl + 'products/allProducts';
  NameIdUrl = environment.apiUrl + 'products/nameAndId';
  updateUrl = environment.apiUrl + 'products/updateProduct';
  GetAllCount = environment.apiUrl + 'products/getAllCount';
  // NameUrl = 'http://192.168.1.119:8081/api/product/name';
  constructor(private http: HttpClient) {}

  createProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(this.createUrl, product, httpOptions);
  }

  updateProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(this.updateUrl, product, httpOptions);
  }

  allProduct(creator: string, filter = '',skip=0 , pageSize=10000000000): Observable<string> {
    
    let creatorJSON = '{ "creator" : ' + '"' + creator + '"'  + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize.toString()  + ' }' ;
    if (filter.length != 0) {
       creatorJSON = '{ "creator" : ' + '"' + creator + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' +  skip.toString() + ',"pageSize" : ' +  pageSize + ' }';
    }
    //const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';
    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.allUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  productNameId(creator: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';

    // console.log(JSON.parse(creator));
    return this.http.post<string>(
      this.NameIdUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

   //返回数据总条数
  getAllCount(company: string ,filter: string): Observable<string> {
    const creatorJSON = '{ "creator" : ' + '"' + company  + '","filter" : "'+ filter +  '" }';    
    return this.http.post<string>(
      this.GetAllCount,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  // productName(id: number): Observable<string> {
  //   const creatorJSON = '{ "id" : ' + '"' + id + '"' + ' }';

  //   // console.log(JSON.parse(creator));
  //   return this.http.post<string>(
  //     this.NameUrl,
  //     JSON.parse(creatorJSON),
  //     httpOptions
  //   );
  // }
}
