import { Component, OnInit,ViewChild } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Product } from '../../models/product';
import { first } from 'rxjs/operators';

//导出模块
import { ProductsActiveListComponent } from './products-active-list/products-active-list.component';
//搜索防抖
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import { Router } from '@angular/router';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ProductService, AuthenticationService]
})
export class ProductsComponent implements OnInit {
  
  public product = new Product(null, 'New', null, '', '', '', '', '');
  public ClassName: string;
  public searchvalue : string;
  public ansArray: any;

  //data: string; 父组件中获得子组件的引用
  @ViewChild(ProductsActiveListComponent, {static: false})
  ProductsActiveList:ProductsActiveListComponent; 

  // public debug: any;
  private getDataTerms = new Subject<string>();
  private isCreated = false;


  constructor(
    private productService: ProductService,
    private authenticationSerive: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {

    //input输入搜索防抖
    this.getDataTerms
      .pipe(
        // 请求防抖 300毫秒
        debounceTime(300),
        distinctUntilChanged())
       .subscribe(term => {
          this.search(term);      
    });

    if (this.authenticationSerive.currentUserValue) {
      this.product.creator = this.authenticationSerive.currentUserValue[0];
    } else {
      // TODO: handle no user error
    }
  }

  create() {
    this.productService
      .createProduct(this.product)
      .pipe(first())
      .subscribe(data => {
        // console.log(data);
        this.isCreated = true;
        window.location.reload();
        this.product.clear();
      });
  }
  
  getSearchValue() {
    const input = document.getElementById('productSearchInput') as HTMLInputElement;
    const value = input.value.toUpperCase();
    this.getDataTerms.next(value);
  }

  //切换时清除
  clearSearch()
  {
    this.getDataTerms.next('');
  }

  search(filter) {
    // Declare variables
    //const input = document.getElementById('productSearchInput') as HTMLInputElement;
    //const filter = input.value.toUpperCase();

    const table = document.getElementById('productTable');
    const tr = table.getElementsByTagName('tr');

    //if (this.searchvalue === filter ) {
    //  return;
    //}

     if (this.ProductsActiveList.authenticationService.currentUserValue) {
       
       this.ProductsActiveList.currentUser = this.ProductsActiveList.authenticationService.currentUserValue[0];
       this.ProductsActiveList.privilege = true;
       this.ProductsActiveList.filter = filter;
       this.ProductsActiveList.skip   = 0;
       this.ProductsActiveList.getActiveList(this.ProductsActiveList.currentUser);
       this.ProductsActiveList.getAllCount(this.ProductsActiveList.currentUser,this.ProductsActiveList.filter);
      
     } else {
       this.ProductsActiveList.privilege = false;
     }

     this.searchvalue = filter;

    // Loop through all table rows, and hide those who don't match the search query
    // tslint:disable-next-line:prefer-for-of
    /*
    for (let i = 0; i < tr.length; i++) {
      const td = tr[i].getElementsByTagName('td')[0];
      if (td) {
        const txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }*/
  }

  //激活主页
  
  onRouterOutletActivate(event : any) {
    
    if (this.router.url === '/products') {
      this.ClassName = 'ProductsActiveListComponent';
    } else {
      this.ClassName = 'ProductsActiveListComponent';
    }
    //this.ClassName = event.constructor.name;
    this.ansArray = true;
    this.clearSearch(); //切换清除功能
    (<HTMLInputElement>document.getElementById('productSearchInput')).value = '';
  
  }
}
