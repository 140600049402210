<div class="wrapper" *ngIf="!noRecord">
<!--
隐藏 payment tomlee 2022.02.28
<div>  
   <button 
   (click)="NewReceipt()" 
   class="btn btn-primary float-right" 
   data-toggle="modal"
   id="NewReceipt"
   data-target="#ReceiptModal"
   *ngIf="!privilege"
   type="button">New Receipt
 </button>
</div>
-->
<!--
 <button
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          (click)="select(i)"
        >
-->
<div>
  <alert></alert>
</div>
<table class="table table-hover tablereported" id="inboundTable" >
  <thead class="thead-light">
    <tr>
      <th scope="col" style="width: 10%" class="hide">#</th> <!--9-->
      <!--
      <th scope="col" style="width: 5%" class="hide"></th>
      -->
      <th scope="col" style="width: 28%">Inbound</th>
      <th scope="col" style="width: 8%" *ngIf="privilege" class="hide"> <!--10-->
        From
      </th>
      <th scope="col" style="width: 9%" *ngIf="!privilege" class="hide">Company</th> <!--10-->
      <th scope="col" style="width: 7%" class="hide">Price</th> <!--9-->
      <th scope="col" style="width: 9%" class="hide">Quantity</th> <!--9-->
      <th scope="col" style="width: 11%" class="hide">Warehouse</th> <!--12-->
      <th scope="col" style="width: 12%" class="hide">Created Date</th> <!--12-->
      <th scope="col" style="width: 9%" class="hide">Status</th> <!--10-->      
      <!--
      <th scope="col" style="width: 8%" *ngIf="!privilege" class="hide">payable</th>
       -->
      <th scope="col" style="width: 7%" class="hide">Action</th> <!--9-->
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let record of recordList; index as i">
      <tr>
<!--
        <th scopr="row" class="hide">{{ ('00000' + record.id).slice(-6) }}</th>
-->
        <th scopr="row" class="hide">
          <div>
            {{ ('00000' + record.id).slice(-6) }}
            <br><br>
            <img src="{{ record.product_link }}" />
          </div>
        </th>
        
        <!--
        <td class="text-center">
            <input type="checkbox" [(ngModel)]='record.isSelected'>
        </td>-->
	<!--  
        <td class="hide"> <input type="checkbox" [(ngModel)]="record.isSelected" (change)="getProoduct(record.isSelected, record.id)"> </td>  
	-->
        <td>
          <div>
            <b class="show">{{ ('00000' + record.id).slice(-6) }} </b>
            <span>{{ record.product }}</span>
            <p class="text-primary show">
              {{ record.createdAt | date: 'medium' }}
            </p>            
            <p *ngIf="record.status===0" class="text-warning show">
              {{ status[record.status] }}
            </p>
            <p *ngIf="record.status===1" class="text-danger show">
              {{ status[record.status] }}
            </p>
            <p *ngIf="record.status===2" class="text-success show">
              {{ status[record.status] }}
            </p>
            <p *ngIf="record.status===3" class="show">
              {{ status[record.status] }}
            </p>
            <p class="text-danger show" *ngIf="privilege">
              {{ record.individual }}
            </p>
            <p class="text-info show" *ngIf="!privilege">
              <b>{{ record.company }}</b>
            </p>
            <div class="text-danger show">
              {{ record.price }}
              <div *ngIf="!record.publicWarehouse && record.bonus!=0">+ {{ record.bonus }}(bonus)</div>
              <div *ngIf="record.awards!=0">+ {{ record.awards }}(awards)</div>
              Quantity: {{ record.quantity }}
            </div>
            <p class="text-danger show">Warehouse: {{ record.warehouse }}</p>
            <!--
            <div class="text-danger show" *ngIf="!privilege">
              <p *ngIf="record.payable===0" class="text-danger show">No              
              </p>
              <p *ngIf="record.payable===1" class="text-success show">Yes              
              </p>
              <input type="checkbox" [(ngModel)]="record.isSelected" (change)="getProoduct(record.isSelected, record.id)">
            </div>-->
          </div>
          <button *ngIf="!privilege"
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm show"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          (click)="select(i)"
          >
          Edit
          </button>
          <button *ngIf="privilege"
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm show"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          (click)="select(i)"
          >
          View
          </button>
        </td>
        <td *ngIf="privilege" class="hide">{{ record.individual }}</td>
        <!-- <td *ngIf="!privilege">{{ record.individual }}</td> -->
        <td class="hide text-info" *ngIf="!privilege"><b>{{ record.company }}</b></td>
        <td class="hide text-success">
          ${{ record.price }}
          <div *ngIf="!record.publicWarehouse">+ {{ record.bonus }} (bonus)</div>
          <div *ngIf="record.awards">+ {{ record.awards }}(awards)</div>
        </td>
        <td class="hide">{{ record.quantity }}</td>
        <td class="hide">{{ record.warehouse }}</td>
        <td class="hide">{{ record.createdAt | date: 'medium' }}</td>
       
        <td *ngIf="record.status===0" class="hide text-warning">{{ status[record.status] }}</td>
        <td *ngIf="record.status===1" class="hide text-danger">{{ status[record.status] }}</td>
        <td *ngIf="record.status===2" class="hide text-success">{{ status[record.status] }}</td>
        <td *ngIf="record.status===3" class="hide">{{ status[record.status] }}</td>
        <!--
        <td *ngIf="record.payable===0 && !privilege" class="hide text-warning">No</td>  
        <td *ngIf="record.payable===1 && !privilege" class="hide text-success">Yes</td>
        -->
        <td class="hide" *ngIf="!privilege">
          <button
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          (click)="select(i)"
        >
          Edit
        </button>
        </td>
        <td class="hide" *ngIf="privilege">
          <button
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#proposeConfirmForm"
          (click)="select(i)"
        >
          View
        </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<!--分页实现Begin-->

<kendo-datapager 
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [buttonCount]="5"
  [total]="total"
  [pageSizeValues]="pageSizes"
  [info]="info"
  [previousNext]="prevNext"
  [type]="type"
  [previousNext] = "prevNext"
  scrollable="none"
  (pageChange)="onPageChange($event)">
</kendo-datapager>

<!--
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  (pageChange)="onPageChange($event)">
</kendo-datapager>
-->

<!--
<div data-pagination=""
     data-num-pages="numOfPages()" 
     data-current-page="curPage"
     data-max-size="maxSize" 
     data-boundary-links="true"> 
</div>
-->
<!--分页实现End-->

</div>

<div class="wrapper" *ngIf="noRecord">
<table class="table" id="inboundTable" >
  <thead>
    <tr>
      <th scope="col" style="width: 9%" class="hide">#</th>
      <th scope="col" style="width: 20%">Inbound</th>
      <th scope="col" style="width: 10%" *ngIf="privilege" class="hide">
        From
      </th>
      <th scope="col" style="width: 10%" *ngIf="!privilege" class="hide">Company</th>
      <th scope="col" style="width: 9%" class="hide">Price</th>
      <th scope="col" style="width: 9%" class="hide">Quantity</th>
      <th scope="col" style="width: 12%" class="hide">Warehouse</th>
      <th scope="col" style="width: 12%" class="hide">Created Date</th>
      <th scope="col" style="width: 10%" class="hide">Status</th>
      <th scope="col" style="width: 9%" class="hide">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scopr="row" class="hide"></th>
      <td>
        No Inbound Record......
      </td>
      <td *ngIf="privilege" class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide">
      </td>
    </tr>
  </tbody>
</table>
<!--分页实现Begin-->
<!--
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  (pageChange)="onPageChange($event)">
</kendo-datapager>
-->
<kendo-datapager style="width: 770px"
  [pageSize]="pageSize"
  [skip]="skip"
  [buttonCount]="5"
  [total]="total"
  [pageSizeValues]="pageSizes"
  [info]="info"
  [previousNext]="prevNext"
  [type]="type"
  (pageChange)="onPageChange($event)">
</kendo-datapager>

</div>
<div
class="modal fade"
id="proposeEditForm"
tabindex="-1"
role="dialog"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="formTitle">Edit a propose</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="product" class="col-form-label"
            >Product:</label
          >
          <input type="text" class="form-control" value="{{selectInbound.product}}" readonly>
        </div>
        <div class="form-group">
          <label for="price" class="col-form-label">Price:</label>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="number" class="form-control" name="price" value="{{selectInbound.price}}" readonly>
            </div>
        </div>
        <div class="form-group">
          <label for="quantity" class="col-form-label"
            >Quantity:</label
          >
          <input
            type="number"
            class="form-control"
            id="quantity"
            name="quantity"
            [(ngModel)] = "selectInbound.quantity"
          />
        </div>
        <div class="form-group">
          <label for="warehouse" class="col-form-label"
            >Warehouse:</label
          >
          <input
            type="text"
            class="form-control"
            id="warehouse"
            name="warehouse"
            value="{{selectInbound.warehouse}}"
            readonly
          />
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
      type="button"
      class="btn btn-primary"
      (click)="cancelInbound()"
      data-dismiss="modal"
    >
      Cancel
    </button>
    </div>
  </div>
</div>
</div>
<div
class="modal fade"
id="proposeConfirmForm"
tabindex="-1"
role="dialog"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="formTitle">Confirm a propose</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="product" class="col-form-label"
            >Product:</label
          >
          <input type="text" class="form-control" value="{{selectInbound.product}}" readonly>
        </div>
        <div class="form-group">
          <label for="price" class="col-form-label">Price:</label>
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="number" class="form-control" name="price" value="{{selectInbound.price}}" readonly>
            </div>
        </div>
        <div class="form-group">
          <label for="quantity" class="col-form-label">Quantity:</label>
          <input type="number" class="form-control" id="quantity" name="quantity" value="{{selectInbound.quantity}}" readonly/>
        </div>
        <div class="form-group">
          <label for="warehouse" class="col-form-label"
            >Warehouse:</label
          >
          <input
            type="text"
            class="form-control"
            id="warehouse"
            name="warehouse"
            value="{{selectInbound.warehouse}}"
            readonly
          />
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="map-upc" class="col-form-label">UPC:</label>
          </div>
          <div class="form-group col-md-2">
            <label for="inbound-qty" class="col-form-label">Proposed:</label>
          </div>
          <div class="form-group col-md-2">
            <label for="confirm-qty" class="col-form-label">Confirmed:</label>
          </div>
          <div class="form-group col-md-2">
            <label for="confirm-qty" class="col-form-label">Billed:</label>
          </div>
          <div class="form-group col-md-2">
            <label for="confirm-qty" class="col-form-label">Paid:</label>
          </div>
          <ng-container *ngFor="let currentMap of mapList; let i = index">
            <div class="form-group col-md-4">
              <input type="text" class="form-control" value="{{currentMap.upc}}" readonly>
            </div>
            <div class="form-group col-md-2">
              <input type="text" class="form-control" value="{{currentMap.quantity*selectInbound.quantity}}" readonly>
            </div>
            <div class="form-group col-md-2">
              <input type="text" class="form-control" value="{{confirmedList[i]}}" readonly>
            </div>
            <div class="form-group col-md-2">
              <input type="text" class="form-control" value="{{billList[i]}}" readonly>
            </div>
            <div class="form-group col-md-2">
              <input type="text" class="form-control" value="{{paidList[i]}}" readonly>
            </div>
          </ng-container>
          <div class="form-group col-md-12" *ngIf="maptype=='single' && maplength>1">
            <label for="map-explaination" class="col-form-label text-danger">各个upc最终confirmed数量之和达到proposed数量即可</label>
          </div>       
        </div>
        <div class="form-group">
          <label for="inbound-tracking" class="col-form-label">Trackings:</label>
             
             <ng-container *ngFor="let currentTracking of trackingList; let i = index">
              <div class="form-group" *ngIf="trackingConfirmedList[i]!=0">
                <input type="text" class="form-control" value="{{currentTracking}} (Confirmed {{trackingConfirmedList[i]}})" readonly>
              </div>
              <div class="form-group" *ngIf="trackingConfirmedList[i]==0">
                <input type="text" class="form-control" value="{{currentTracking}}" readonly>
              </div>
            </ng-container>

            <textarea *ngIf="!privilege"
              type="text"
              class="form-control"
              id="inbound-tracking"
              rows="8"
              name="tracking"
              [(ngModel)]="inputTrackings"
            ></textarea>
        </div>
      </form>

      <!--包裹尺寸begin-->
      <!--
      <button class="btn btn-primary btn-sm mt-1" (click)="CheckShippingLabel(1)" *ngIf="!IsshippingLabel && !isPublicWarehouse && !privilege">
              Request Shipping Label
      </button>
      隐藏 Request Shipping Label tomlee.lee by 2022.02.28
      -->

      <button class="btn btn-primary btn-sm mt-1" (click)="CheckShippingLabel(0)" *ngIf="IsshippingLabel && !isPublicWarehouse && !privilege">
              Hide Shipping Label Info
      </button>    
      
      <hr />
      
      <form class="form-horizontal" *ngIf="PackageSizeForm && IsshippingLabel && !isPublicWarehouse && !privilege" [formGroup]="PackageSizeForm">
                  
         
        <div class="form-group">
          <label for="packagesize" class="col-form-label">Add package dimension info:</label>
        </div>
        <div class="form-group">
           
            <button                
                type="button" 
                (click)="onAddPackage()"
                class="btn btn-primary btn-sm fas fa-plus-square"
                style="margin-right: 8px"
             ><!--<i class="btn btn-success far fa-plus"></i>-->
             Add 
            </button> 

            <button
             type="button" 
             class="btn btn-success btn-sm far fa-edit"
             (click)="onSavePackage()"
             data-dismiss="modal"
            >
                Update
          </button> 

        </div>
       

         <!--<table class="table table-bordered">-->
         <table class="table table-hover">
          
          <tbody>
            <tr  formArrayName="packageList" *ngFor="let package of PackageSizeForm.get('packageList').controls; let i = index">              
              <ng-container [formGroupName]="i">

                <td [hidden]="true">
                  <input class="hide" type="text" [id]="'id' + i" placeholder="packageId"  formControlName="id"/>                
                </td>
                  
                <td style="padding-top: 50px;padding-bottom: 50px;padding-left: 30px;"> <!-- ngIf="i!=0">-->
                  <button  mat-button type="button" (click)="removePackage(i)" class="btn btn-info btn-sm"
                  > <span class="glyphicon glyphicon-minus"></span>
                  </button>
                </td>

                <td colspan="9">
                  <div class="row parent">
                   
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 5px">
                        <label for="inlineFormX">Length</label>
                        <div class="input-group">
                          <input
                           type="number"                     
                           min="0"
                           class="form-control" 
                           [id]="'length' + i" 
                           placeholder="Length"  
                           formControlName="length"
                          />  
                          <label for="inputX" class="col-form-label" style="padding-left: 15px;">X</label>
                        </div>                  
                    </div>
                    
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 5px">                    
                      <label for="inlineFormX">Width</label>
                      <div class="input-group">
                        <input 
                          type="number"                    
                          min="0"
                          class="form-control" 
                          [id]="'width' + i" 
                          placeholder="width"  
                          formControlName="width"                           
                        />
                        <label for="inputX" class="col-form-label" style="padding-left: 15px;">X</label>
                      </div>                      
                    </div>                    
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 1px">
                      <label for="inlineFormX">Height</label>
                      <div class="input-group">
                       <input 
                         type="number"                    
                         min="0"
                         class="form-control" 
                         [id]="'height' + i" 
                         placeholder="Height"  
                         formControlName="height"                           
                       />
                       <label for="inputX" class="col-form-label">(IN)</label>
                      </div>
                    </div>
                    <div class="col-md-1 form-group text-center"></div>
                    <div class="col-md-3 form-group text-center" style="padding-left: 15px;padding-right: 10px">
                        <label for="inlineFormX">Weight</label>
                        <div class="input-group">
                          <input 
                            type="number"
                            min="1" 
                            class="form-control" 
                            [id]="'weight' + i" 
                            placeholder="Weight"  
                            formControlName="weight"                           
                          />
                          <label for="inputX" class="col-form-label">(LB)</label>
                        </div>                      
                    </div>
                    <div class="col-md-2 form-group text-center" style="padding-left: 15px;padding-right: 40px">
                      <label for="inlineFormX">Qty</label>
                      <div class="input-group">
                       <input 
                        type="number"
                        min="1"
                        ng-init="qty=1"
                        class="form-control" 
                        [id]="'qty' + i" 
                        placeholder="Qty"  
                        formControlName="qty"                           
                       />
                     </div>
                    </div>

                   

                  </div>                                                     
                </td>
                
              </ng-container>
            </tr>
          </tbody>
        </table>
       
      </form>
    
      <!--包裹尺寸end-->
      <!--包裹尺寸begin-->
      
      <form class="form-horizontal" *ngIf="IsshippingLabel && !isPublicWarehouse && privilege" [formGroup]="PackageSizeForm">                  
         
         <label for="packagesize" class="col-form-label">Requested shipping label info:</label>     
         
         <table class="table table-hover">         
          <tbody>
            <tr  formArrayName="packageList" *ngFor="let package of PackageSizeForm.get('packageList').controls; let i = index">              
              <ng-container [formGroupName]="i">

               
                <td colspan="9">
                  <div class="row parent">
                   
                    <div class="col-sm-2 form-group text-center" style="padding-left: 3px;padding-right: 1px">
                        <label for="inlineFormX" *ngIf="i==0">Length</label>
                        <div class="input-group">
                          <input
                           type="number"                     
                           min="1"
                           class="form-control" 
                           [id]="'length' + i" 
                           formControlName="length"
                           readonly
                          />  
                          <label for="inputX" class="col-form-label">X</label>
                        </div>                  
                    </div>
                    
                    <div class="col-sm-2 form-group text-center" style="padding-left: 1px;padding-right: 1px">                    
                      <label for="inlineFormX" *ngIf="i==0">Width</label>
                      <div class="input-group">
                        <input 
                          type="number"                    
                          min="1"
                          ng-init="width=0"
                          class="form-control" 
                          [id]="'width' + i" 
                          formControlName="width"
                          readonly                         
                        />
                        <label for="inputX" class="col-form-label">X</label>
                      </div>                      
                    </div>                    
                    <div class="col-sm-3 form-group text-center" style="padding-left: 0px;padding-right: 10px">
                      <label for="inlineFormX" *ngIf="i==0">Height</label>
                      <div class="input-group">
                       <input 
                         type="number"                    
                         min="1"
                         class="form-control" 
                         [id]="'height' + i" 
                         formControlName="height"
                         readonly                          
                       />
                       <label for="inputX" class="col-form-label">(IN)</label>              
                      </div>
                    </div>
                    <div class="col-sm-3 form-group text-center" style="padding-left: 15px;padding-right: 5px">
                        <label for="inlineFormX" *ngIf="i==0">Weight</label>
                        <div class="input-group">
                          <input 
                            type="number"
                            min="1" 
                            class="form-control" 
                            [id]="'weight' + i" 
                            formControlName="weight"
                            readonly                           
                          />
                          <label for="inputX" class="col-form-label">(LB)</label>
                        </div>                      
                    </div>
                    <div class="col-sm-2 form-group text-center" style="padding-left: 5px;padding-right: 5px">
                      <label for="inlineFormX" *ngIf="i==0">Qty</label>
                      <div class="input-group">
                       <input 
                        type="number"
                        min="1"
                        ng-init="qty=1"
                        class="form-control" 
                        [id]="'qty' + i" 
                        formControlName="qty"
                        readonly                           
                       />
                     </div>
                    </div>                  

                  </div>                                                    
                </td>
                
              </ng-container>
            </tr>
          </tbody>
        </table>
        <!--管理员账号可以取消护士的包裹尺寸-->
        <div class="form-check k-form-field-checkbox-wrap" *ngIf="IsshippingLabel && privilege">
          <input
             class="form-check-input"
             type="checkbox"
             id="notify"
             name="emailnotify"
             [checked]="notify"
          />
          <label class="form-check-label" for="notify">
             Allow automatic email Notifications
          </label>
        </div>
        <br />
        <button
          type="button"
          class="btn btn-primary far fa-trash-alt"
          (click)="cancelPackage()"
          data-dismiss="modal"
          *ngIf="IsshippingLabel && privilege"
        >
          Cancel
        </button>      
    </form>
      <!--包裹尺寸end-->


    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
      <button *ngIf="!privilege"
      type="button"
      class="btn btn-primary"
      (click)="addTracking()"
    >
      Confirm
    </button>
    </div>
  </div>
</div>
</div>

<!--Receipt Modal Begin-->
<div 
class="modal fade" 
id="ReceiptModal" 
role="dialog" 
tabindex="-1"
aria-hidden="true"
>
  <div class="modal-dialog modal-xl"  role="document">

    <!-- Modal content-->
    <div class="modal-content">
            
      <div class="modal-header">
        <!--
        隐藏 payment tomlee 2022.02.28
        <h5 class="modal-title" id="formTitle">New Receipt</h5>
        -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>        
      </div>
      
       <div class="receipt-wrap">
        <form class="k-form" [formGroup]="receiptForm">        
        <fieldset class="editorder left-column">
            
            <kendo-formfield>
              <kendo-label [for]="IndividualUser" text="Individual User: "></kendo-label> 
              <input
                formControlName="IndividualUser"
                kendoTextBox
                readOnly
                #IndividualUser
              />   
            </kendo-formfield>
            
            <kendo-formfield>
              <kendo-label [for]="ReceiptNumber" text="No#"></kendo-label>
              <input
                formControlName="ReceiptNumber"
                kendoTextBox
                readOnly
                #ReceiptNumber
              />
              <kendo-formerror>Error: receipt is required</kendo-formerror>
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label
                [optional]="true"
                text="Receipt Date"
              ></kendo-label>
              <input id="receiptDate" formControlName="receiptDate" value="01/01/2021" title="datepicker" style="width: 100%" />             
            </kendo-formfield>

            <kendo-formfield [hidden]="true">
              <kendo-label text="Confirmed Total"></kendo-label>
              <input
                formControlName="ConfirmedQty"
                kendoTextBox
                readOnly
                #ConfirmedQty
              />
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label text="Bill Total"></kendo-label>
              <input
                formControlName="BillTotal"
                kendoTextBox
                id="BillTotal"
                readOnly
                #BillTotal
              />
            </kendo-formfield>
            <!--
            <kendo-formfield [hidden]="true">
              <kendo-label
                [for]="DueDate" Receipt Date
                [optional]="true"
                text="Due Date"
              ></kendo-label>
              <input id="dueDate" formControlName="DueDate" value="01/01/2021" title="datepicker" style="width: 100%" />            
            </kendo-formfield> -->                       
            
            <kendo-formfield>

              <kendo-label
                [optional]="true"
                text="Note" 
              ></kendo-label>
              <!-- Terms & Conditions"-->
               <textarea id="re-description" style="width: 100%" formControlName="ConditionsValue">
               </textarea>
            </kendo-formfield>

        </fieldset>
        </form>
        <div class="right-column">
          <div id="newreceipt"></div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" id="saveReceiptSend" class="btn btn-info" (click)="saveReceipt('send')" data-dismiss="modal">Save & Export XLSX
        </button>
        <button type="button" id="saveReceipt" class="btn btn-primary" (click)="saveReceipt('NoSend')" data-dismiss="modal">Save
        </button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
<!--Receipt Modal End-->



