import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, EmailValidator, FormArray } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { InboundService } from '../../../services/inbound.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { DealService } from '../../../services/deal.service';
import { TrackingService } from '../../../services/tracking.service';
import { Tracking } from '../../../models/tracking';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-to-confirm-list',
  templateUrl: './to-confirm-list.component.html',
  styleUrls: ['./to-confirm-list.component.scss'],
  providers: [InboundService, AuthenticationService, DealService, TrackingService]
})
export class ToConfirmListComponent implements OnInit {
  
  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';
  public commponent = "ToConfirmList";
  public dealid = 0;
  public inboundid = 0;
  public individual = "";
  public type = 'input';
  
  public packageList = [];
  public oldpackageList = [];
  public PackageSizeForm: FormGroup;
  public isPublicWarehouse = true;
  public IsshippingLabel =false;
  //@Input('formGroup') PackageSizeForm:FormGroup;
  //public count: any;

  public inputTrackings: any;
  public status = ['Waiting', 'Confirming', 'Confirmed', 'Canceled'];
  public currentUser: any;
  public privilege: any;
  public recordList: any;
  public selectInbound = new Inbound(
    '',
    '',
    '',
    null,
    '',
    '',
    '',
    null,
    null,
    null,
    null,
    null
  );
  public selectId: number;
  public inValid: boolean;
  public qtyGiveBack: number;
  constructor(
    private inboundService: InboundService,
    private authenticationService: AuthenticationService,
    private dealService: DealService,
    private trackingService: TrackingService,
    private _formBuilder: FormBuilder
  ) {}

  public noRecord: any;
  public notify = true;
  @Input('answers') ans:any;
  

  ngOnInit() {
    

    if (!this.ans) return;

    if (this.authenticationService.currentUserValue) {
      // console.log(this.authenticationService.currentUserValue);
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.findAllCountConfirm(this.currentUser,this.authenticationService.currentUserValue[2] === 'company' ? true : false,this.filter); //获取总条数 

      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true ; //领票允许公司账号查看。 
        this.getRecordsCompany(this.currentUser);
      } else {
        this.privilege = false;
        this.getRecords(this.currentUser);
      }
      // this.userEmail = 'admin';
    } else {
      // TODO: add no userEmail error
    }

    //添加尺寸
    /*
    this.PackageSizeForm = new FormGroup({
      packageList: this._formBuilder.array([this.addPackageSizeFormGroup()])
    });*/

    
  }

  addTracking() {
    
    if(typeof(this.inputTrackings) !== "undefined" && this.inputTrackings!=="") {
      let confirmInbound = false;
      let inputTrackingArray = [];
      inputTrackingArray = this.inputTrackings.split(/\r?\n/);
      var i;
      for (i=0; i<inputTrackingArray.length; i++) {
        inputTrackingArray[i] = inputTrackingArray[i].trim();
        if(inputTrackingArray[i]==="") continue;
        let singleTracking = new Tracking(
          null,
          null,
          '',
          '',
          '',
          ''
        );
        singleTracking.inboundId = this.selectInbound.id;
        singleTracking.dealId = this.selectInbound.dealId;
        singleTracking.company = this.recordList[this.selectId].company;
        singleTracking.individual = this.recordList[this.selectId].individual;
        singleTracking.tracking = inputTrackingArray[i];
        singleTracking.status = "waiting";
        //console.log(singleTracking);
        this.trackingService
          .createTracking(singleTracking)
          .pipe(first())
          .subscribe(
            data => {
              //console.log(data);
            },
            error => {
              console.log(error);
            },
            () => {
            }
          );
        confirmInbound = true;
      }
      if(confirmInbound===true) {
        this.inboundService
          .confirm(this.selectInbound.id)
          .pipe(first())
          .subscribe(
            data => {
              this.ngOnInit();
            },
            error => {
              console.log(error);
            },
            () => {
            }
          );
      }
    }
    //保存Pageage Size info
    this.onSavePackage();
  }
  //个人账号
  getRecords(userEmail) {
    this.inboundService
      .toConfirmRecordsKendo(userEmail, this.pageSize , this.skip, this.filter)
      .pipe(first())
      .subscribe(
        data => {
          this.recordList = data;
          //console.log(this.recordList);
          //debugger;
        },
        error => {
          console.log(error);
        },
        () => {
          if (this.recordList.length < 1) {
            this.noRecord = true;
          }
        }
      );
  }
  //公司账号
  getRecordsCompany(userEmail) {
    this.inboundService
      .toConfirmRecordsCompanyKendo(userEmail,this.pageSize , this.skip, this.filter)
      .pipe(first())
      .subscribe(data => {
        //debugger;
        this.recordList = data;
        /*
        if (this.recordList.length < 1) {
            this.noRecord = true;
        } else {
            this.noRecord = false;
        }*/
      });
  }

  //搜索过虑 userEmail 账号 filter 搜索值  个人用户
  RecordsSearch(userEmail,filter) {
    this.inboundService
      .toConfirmRecordsKendo(userEmail, this.pageSize , 0 , filter)
      .pipe(first())
      .subscribe(data => {
        this.recordList = data;
      });
  }

  //搜索过虑 userEmail 账号 filter 搜索值  公司用户
  RecordsCompanySearch(userEmail,filter) {
    this.inboundService
      .toConfirmRecordsCompanyKendo(userEmail, this.pageSize , 0 , filter)
      .pipe(first())
      .subscribe(data => {
        this.recordList = data;
      });
  }
  /*
  //统计总页数
  findAllCount(userEmail,iscompany,filter) {
    //debugger;
    this.inboundService
      .FindAllCount(userEmail,iscompany,filter)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }*/

  cancelInbound() {
    this.selectInbound.dealId = this.recordList[this.selectId].dealId;
    this.selectInbound.id = this.recordList[this.selectId].id;
    if (this.selectInbound.quantity > this.recordList[this.selectId].quantity) {
      this.selectInbound.quantity = this.recordList[this.selectId].quantity;
    }
    this.inboundService
      .cancelInbound(this.selectInbound)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
      });
  }

  select(i) {
    this.selectId = i;
    this.selectInbound.dealId = this.recordList[i].dealId;
    this.selectInbound.quantity = this.recordList[i].quantity;
    this.selectInbound.id = this.recordList[i].id;
    this.selectInbound.product = this.recordList[i].product;
    this.selectInbound.price = this.recordList[i].price;
    this.selectInbound.warehouse = this.recordList[i].warehouse;
    
    this.dealid = this.recordList[i].dealId;
    this.inboundid = this.recordList[i].id;
    this.individual = this.recordList[i].individual;

    //判断是否是使用公司仓库
    //debugger;
    this.isPublicWarehouse = this.recordList[i].publicWarehouse == 0 ? false : true;
    this.IsshippingLabel = false;
    let packid = this.selectInbound.id.toString();
    let dealid = this.selectInbound.dealId.toString();
    
    this.PackageSizeForm = new FormGroup({
      packageList: this._formBuilder.array([this.addPackageSizeFormGroup()])
    });
    this.inboundService
      .getPackageList(packid,dealid)
      .pipe(first())
      .subscribe(data => {
        if (data.length > 0) {
          this.IsshippingLabel = true ;
          this.addPackageSizeFormGroup(Object.values(data));
        } else {
          (this.PackageSizeForm.get('packageList') as FormArray).removeAt(0)
          this.IsshippingLabel = false ;
        }
        //console.log(data.length);
        //debugger;
             
    });

  }

  updateInboundQty() {
    if (this.selectInbound.quantity > this.recordList[this.selectId].quantity) {
      this.inValid = true;
    } else {
      this.qtyGiveBack =
        this.recordList[this.selectId].quantity - this.selectInbound.quantity;
      this.inboundService
        .updateInboundQty(
          this.selectInbound.dealId,
          this.selectInbound.id,
          this.selectInbound.quantity
        )
        .pipe(first())
        .subscribe(
          data => {
            this.giveBackQty();
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngOnInit();
          }
        );
    }
  }

  giveBackQty() {
    this.dealService
      .giveBackQty(this.selectInbound.dealId, this.qtyGiveBack)
      .pipe(first())
      .subscribe(data => {
        console.log(data);
      });
  }

  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    if (this.authenticationService.currentUserValue[2] === 'company') {
      this.getRecordsCompany(this.currentUser);
    } else {
      this.getRecords(this.currentUser);
    }    
   
  }

  //统计总页数
  findAllCountConfirm(userEmail,iscompany,filter) {
    //debugger;
    this.inboundService
      .FindAllCountConfirm(userEmail,iscompany,filter)
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
        //this.total = this.count;
      });
  }

  //定义包装尺寸及重量
  
  addPackageSizeFormGroup(data=[]): FormGroup {    
    if (data.length == 0){          
      return this._formBuilder.group({
         id: [-1],
         length: [0], //长
         width : [0], //宽
         height: [0],  //高
         deep : [0], //深
         thick: [0], //厚
         weight: [0], //厚
         qty: [1, Validators.required],    
      });
    } else {
      let arr = {};
      this.packageList = [];
      (this.PackageSizeForm.get('packageList') as FormArray).removeAt(0); //删除第一个
      data.forEach((currentValue, index) => {
        arr = {
          id:currentValue.id,
          length:currentValue.length, 
          width :currentValue.width, 
          height:currentValue.height,
          qty: isNaN(parseInt(currentValue.qty)) ? 1 : parseInt(currentValue.qty),
          deep :0, 
          thick: 0, 
          weight:currentValue.weight,
          //qty:0,
        };
        (this.PackageSizeForm.get('packageList') as FormArray).push(this._formBuilder.group(arr));
        this.packageList.push(arr);
      })
      this.packageList = this.PackageSizeForm.get('packageList').value; //赋值

    }
  }
  //


  //添加多个包装
  onAddPackage() {    
    (this.PackageSizeForm.get('packageList') as FormArray).push(this.addPackageSizeFormGroup());    
  }

  packageArray(group:FormGroup):FormArray  {    
    return group.get('packageList') as FormArray
  }
  //移除多个包装
  removePackage(index:number)
  {
    (this.PackageSizeForm.get('packageList') as FormArray).removeAt(index)
  }

  CheckShippingLabel(ship:number) {

    if (ship==0) {
      this.IsshippingLabel = false ;
    } else {
      this.IsshippingLabel = true ;
    }
    //this.IsshippingLabel = ship == 0 ? false : true;    
  }

  //保存数据
  onSavePackage() {
   
    let arr = {};

    this.oldpackageList = [];
    if (this.packageList.length>0) {
      this.oldpackageList = this.packageList;
    }
    //console.log(this.packageList);

    this.packageList = [];
    this.PackageSizeForm.get('packageList').value.forEach((currentValue, index) => {
        //过虑长*宽*高不为0数据     
        //debugger;   
        if(currentValue.length>0 && currentValue.width>0  && currentValue.height>0){
            //debugger;
            arr = {
              'dealId' :this.dealid,
              'inboundId':this.inboundid,
              'length': currentValue.length,
              'width': currentValue.width,
              'height': currentValue.height,
              'weight':currentValue.weight == "" ? 0 : currentValue.weight,
              'qty':currentValue.qty,
              'individual': this.individual,
              'id':currentValue.id,
              'type': currentValue.id == -1 ? 'add' : 'update'
            };
            this.packageList.push(arr);
            //this.myArray.splice(index, 1);
        }
    });

    //console.log(this.packageList.length);
    //console.log(this.oldpackageList.length);

    if (this.packageList.length>0 || this.oldpackageList.length>0) {
      
       let delid = [];
       this.oldpackageList.forEach((currentValue, index) => {
         if (currentValue.id > 0) {
           delid = this.packageList.filter(p => p.id == currentValue.id);
           
           if (delid.length == 0) {
             //删除的行
              arr = {
                'dealId'    : this.dealid,
                'inboundId' : this.inboundid,
                'length'    : currentValue.length,
                'width'     : currentValue.width,
                'height'    : currentValue.height,
                'weight'    : currentValue.weight,
                'qty'       : currentValue.qty,
                'individual': this.individual,
                'id'        : currentValue.id,
                'type'      : 'delete'
              };
              this.packageList.push(arr);

           }
         }
       })
      
      //console.log(this.packageList);      

      //插入包装信息
      this.inboundService
      .Addpackage(JSON.stringify(this.packageList))
      .pipe(first())
      .subscribe(data => {           
      })

      this.packageList = [];
    }

  }
  //取消包裹尺寸
  cancelPackage() {

    let arr = {};

    this.packageList = [];
    this.PackageSizeForm.get('packageList').value.forEach((currentValue, index) => {
        //过虑长*宽*高不为0数据     
        if(currentValue.length>0 && currentValue.width>0  && currentValue.height>0){
            //debugger;
            arr = {
                'dealId' :this.dealid,
                'inboundId':this.inboundid,
                'length': currentValue.length,
                'width': currentValue.width,
                'height': currentValue.height,
                'weight':currentValue.weight == "" ? 0 : currentValue.weight,
                'qty':currentValue.qty,
                'individual': this.individual,
                'id':currentValue.id               
            };
            this.packageList.push(arr);
        }
    });

    if (this.packageList.length>0) {
       //删除包装信息
       //let packageList = ["package:" + this.packageList + ",notify:" + this.notify];
       if (this.notify) {
         this.inboundService
          .cancelPackage(JSON.stringify(this.packageList))
          .pipe(first())
          .subscribe(data => {           
         })
       } else {
         this.inboundService
         .nocancelPackage(JSON.stringify(this.packageList))
         .pipe(first())
         .subscribe(data => {           
        })
       }
       

    } 
  }

}
