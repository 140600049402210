import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { InboundService } from '../../../services/inbound.service';
import { first } from 'rxjs/operators';
import { Inbound } from '../../../models/inbound';
import { DealService } from '../../../services/deal.service';
import { TrackingService } from '../../../services/tracking.service';
import { Tracking } from '../../../models/tracking';
import { MapService } from '../../../services/map.service';
import { ConfirmService } from '../../../services/confirm.service';
import { PaymentService } from '../../../services/payment.service';
import { Payment } from '../../../models/payment';
import { inboundPayment } from '../../../models/inboundpayment';
import { AlertService } from '../../../_alert';
var ReportedListComponent = /** @class */ (function () {
    function ReportedListComponent(inboundService, authenticationService, dealService, trackingService, mapService, confirmService, _formBuilder, paymentService, alertService) {
        this.inboundService = inboundService;
        this.authenticationService = authenticationService;
        this.dealService = dealService;
        this.trackingService = trackingService;
        this.mapService = mapService;
        this.confirmService = confirmService;
        this._formBuilder = _formBuilder;
        this.paymentService = paymentService;
        this.alertService = alertService;
        this.options = {
            autoClose: false,
            keepAfterRouteChange: true //true Keep displaying after one route change
        };
        this.pageSize = 20; //每页显示条数
        this.skip = 0; //页号
        this.pagedDestinations = [];
        this.total = 0; //destinations.length; //总条数
        this.filter = '';
        this.commponent = "ReportedList";
        this.pageSizes = true; //每页显示多少条
        this.info = true; //显示总页数信息
        this.prevNext = true; //显示下一页按钮
        this.type = 'input'; //input手动输入页数 numeric数字类型
        this.position = 'top';
        this.packageList = [];
        this.oldpackageList = [];
        this.isPublicWarehouse = true;
        this.IsshippingLabel = false;
        this.status = ['Waiting', 'Confirming', 'Confirmed', 'Canceled'];
        this.maplength = 0;
        this.confirmedList = [];
        this.billList = [];
        this.paidList = [];
        this.selectInbound = new Inbound('', '', '', null, '', '', '', null, null, null, null, null);
        this.dealid = 0;
        this.inboundid = 0;
        this.individual = "";
        this.notify = true;
        this.receipt = [];
        this.receiptList = [];
        this.payfields = [];
        this.isAdd = 'add';
        //public receiptDate = '77766';
        //public Conditions = '34343';
        /*
        public receiptForm: FormGroup = new FormGroup({
          ReceiptNumber: new FormControl(),
          IndividualUser: new FormControl(),
          receiptDate: new FormControl(new Date()),
          DueDate: new FormControl(new Date()),
          ConditionsValue: new FormControl(),
          ConfirmedQty: new FormControl(),
          BillTotal: new FormControl(),
        });*/
        this.payments = new Payment(null, null, '', '', '', null, null, null, null, null, null, null, null, '', '', null, '');
        this.inboundPayments = new inboundPayment(null, null, null, '', '', '', null, null, null, null, null, null, null, null, null);
        this.receiptForm = new FormGroup({
            ReceiptNumber: new FormControl(),
            IndividualUser: new FormControl(),
            receiptDate: new FormControl(new Date()),
            DueDate: new FormControl(new Date()),
            ConditionsValue: new FormControl(),
            Conditions: new FormControl(),
            ConfirmedQty: new FormControl(),
            BillTotal: new FormControl(),
        });
        //this.isCollapsed = true;
    }
    //id、inboundId、dealId、receiptNumber、company、individual、billQty、confirmedQty、paidQty、billTotal、confirmedTotal、paidTotal、adjustment、status、description、isDelete、receiptDate
    //id、inboundId、dealId、paymentId、company、individual、upc、confirmedQty、confirmedPrice、billQty、billPrice、 paidQty、paidPrice、amount、status、isDelete
    ReportedListComponent.prototype.ngOnInit = function () {
        if (!this.ans)
            return;
        var desc = $("#re-description").kendoTextArea({
            rows: 8,
            maxLength: 200,
            placeholder: "Payment is due within 3 days."
        });
        if (this.authenticationService.currentUserValue) {
            // console.log(this.authenticationService.currentUserValue);
            //this.filter = '';
            this.currentUser = this.authenticationService.currentUserValue[0];
            this.findAllCount(this.currentUser, this.authenticationService.currentUserValue[2] === 'company' ? true : false, this.filter); //获取总条数 
            if (this.authenticationService.currentUserValue[2] === 'company') {
                //$("#NewReceipt").hide()
                this.privilege = true;
                this.getRecordsCompany(this.currentUser);
            }
            else {
                this.privilege = false;
                this.getRecords(this.currentUser);
            }
            // this.userEmail = 'admin';
        }
        else {
            // TODO: add no userEmail error
        }
    };
    ReportedListComponent.prototype.addTracking = function () {
        var _this = this;
        if (this.inputTrackings !== "") {
            var inputTrackingArray = [];
            inputTrackingArray = this.inputTrackings.split(/\r?\n/);
            var i;
            for (i = 0; i < inputTrackingArray.length; i++) {
                inputTrackingArray[i] = inputTrackingArray[i].trim();
                if (inputTrackingArray[i] === "")
                    continue;
                var singleTracking = new Tracking(null, null, '', '', '', '');
                singleTracking.inboundId = this.selectInbound.id;
                singleTracking.dealId = this.selectInbound.dealId;
                singleTracking.company = this.recordList[this.selectId].company;
                singleTracking.individual = this.recordList[this.selectId].individual;
                singleTracking.tracking = inputTrackingArray[i];
                singleTracking.status = "waiting";
                //console.log(singleTracking);
                this.trackingService
                    .createTracking(singleTracking)
                    .pipe(first())
                    .subscribe(function (data) {
                    //console.log(data);
                    _this.getInboundTracking(_this.selectInbound.id);
                    _this.inputTrackings = "";
                }, function (error) {
                    console.log(error);
                }, function () {
                });
            }
        }
    };
    //个人账号
    ReportedListComponent.prototype.getRecords = function (userEmail) {
        var _this = this;
        this.inboundService
            .reportedRecordsKendo(userEmail, this.pageSize, this.skip, this.filter)
            .pipe(first())
            .subscribe(function (data) {
            _this.recordList = data;
            /*
            if (this.recordList !== undefined && this.recordList.length > 0) {
              var i;
              let confirmedQty = 0,
                  billQty      = 0;
  
              for (i=0; i<this.recordList.length; i++) {
                confirmedQty = this.recordList[i].paymentConfirm != null && this.recordList[i].paymentConfirm.confirmedQty != null ? this.recordList[i].paymentConfirm.confirmedQty : 0;
                billQty      = this.recordList[i].paymentConfirm != null && this.recordList[i].paymentConfirm.billQty != null ? this.recordList[i].paymentConfirm.billQty : 0;
                if (confirmedQty-billQty>0) {
                  this.recordList[i].payable = 1;
                } else {
                  this.recordList[i].payable = 0;
                }
              }
            }*/
            // console.log(this.recordList);
        }, function (error) {
            console.log(error);
        }, function () {
            if (_this.recordList.length < 1) {
                _this.noRecord = true;
            }
        });
    };
    //公司账号
    ReportedListComponent.prototype.getRecordsCompany = function (userEmail) {
        var _this = this;
        this.inboundService
            .reportedRecordsCompanyKendo(userEmail, this.pageSize, this.skip, this.filter)
            .pipe(first())
            .subscribe(function (data) {
            _this.recordList = data;
        });
    };
    //搜索过虑 userEmail 账号 filter 搜索值  个人用户
    ReportedListComponent.prototype.RecordsSearch = function (userEmail, filter) {
        var _this = this;
        this.inboundService
            .reportedRecordsKendo(userEmail, this.pageSize, 0, filter)
            .pipe(first())
            .subscribe(function (data) {
            _this.recordList = data;
        });
    };
    //搜索过虑 userEmail 账号 filter 搜索值  公司用户
    ReportedListComponent.prototype.RecordsCompanySearch = function (userEmail, filter) {
        var _this = this;
        this.inboundService
            .reportedRecordsCompanyKendo(userEmail, this.pageSize, 0, filter)
            .pipe(first())
            .subscribe(function (data) {
            _this.recordList = data;
        });
    };
    //统计总页数
    ReportedListComponent.prototype.findAllCount = function (userEmail, iscompany, filter) {
        var _this = this;
        //debugger;
        this.inboundService
            .FindAllCount(userEmail, iscompany, filter)
            .pipe(first())
            .subscribe(function (data) {
            _this.total = data["count"];
            //this.total = this.count;
        });
    };
    ReportedListComponent.prototype.cancelInbound = function () {
        var _this = this;
        this.selectInbound.dealId = this.recordList[this.selectId].dealId;
        this.selectInbound.id = this.recordList[this.selectId].id;
        if (this.selectInbound.quantity > this.recordList[this.selectId].quantity) {
            this.selectInbound.quantity = this.recordList[this.selectId].quantity;
        }
        this.inboundService
            .cancelInbound(this.selectInbound)
            .pipe(first())
            .subscribe(function (data) {
            _this.ngOnInit();
        });
    };
    ReportedListComponent.prototype.select = function (i) {
        var _this = this;
        this.trackingList = [];
        this.confirmedList = [];
        this.trackingConfirmedList = [];
        //this.confirmedList = [];
        this.billList = [];
        this.paidList = [];
        this.maptype = "";
        this.maplength = 0;
        this.inputTrackings = "";
        this.currentConfirmQty = 0;
        this.currentBillQty = 0;
        this.currentPaidQty = 0;
        this.selectId = i;
        this.selectInbound.dealId = this.recordList[i].dealId;
        this.selectInbound.quantity = this.recordList[i].quantity;
        this.selectInbound.id = this.recordList[i].id;
        this.selectInbound.product = this.recordList[i].product;
        this.selectInbound.price = this.recordList[i].price;
        this.selectInbound.warehouse = this.recordList[i].warehouse;
        this.getInboundTracking(this.recordList[i].id);
        this.getProductId();
        this.dealid = this.recordList[i].dealId;
        this.inboundid = this.recordList[i].id;
        this.individual = this.recordList[i].individual;
        //判断是否是使用公司仓库
        //debugger;
        this.isPublicWarehouse = this.recordList[i].publicWarehouse == 0 ? false : true;
        var packid = this.selectInbound.id.toString();
        var dealid = this.selectInbound.dealId.toString();
        this.PackageSizeForm = new FormGroup({
            packageList: this._formBuilder.array([this.addPackageSizeFormGroup()])
        });
        this.inboundService
            .getPackageList(packid, dealid)
            .pipe(first())
            .subscribe(function (data) {
            if (data.length > 0) {
                _this.IsshippingLabel = true;
                _this.addPackageSizeFormGroup(Object.values(data));
            }
            else {
                _this.PackageSizeForm.get('packageList').removeAt(0);
                _this.IsshippingLabel = false;
            }
            //debugger;
            //this.addPackageSizeFormGroup(Object.values(data));        
        });
    };
    ReportedListComponent.prototype.getProductId = function () {
        var _this = this;
        this.dealService
            .getProductId(this.selectInbound.dealId)
            .pipe(first())
            .subscribe(function (data) {
            _this.productId = data[0].products;
            _this.getProductMaps();
        });
    };
    ReportedListComponent.prototype.getProductIda = function (dealId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dealService
                .getProductId(dealId)
                .pipe(first())
                .subscribe(function (data) {
                _this.productId = data[0].products;
                _this.getProductMaps();
                resolve(_this.currentBillQty);
            });
        });
    };
    ReportedListComponent.prototype.getProductMaps = function () {
        var _this = this;
        this.mapService
            .allMap(this.productId)
            .pipe(first())
            .subscribe(function (data) {
            _this.mapList = data;
            if (_this.mapList !== undefined && _this.mapList.length > 0) {
                _this.maptype = _this.mapList[0].type;
                _this.maplength = _this.mapList.length;
                var i;
                for (i = 0; i < _this.mapList.length; i++) {
                    _this.getInboundConfirm(_this.selectInbound.id, _this.mapList[i].upc);
                }
            }
        });
    };
    ReportedListComponent.prototype.getInboundConfirm = function (inboundId, upc) {
        var _this = this;
        this.confirmService
            .allConfirm(inboundId, upc)
            .pipe(first())
            .subscribe(function (data) {
            if (data !== undefined && data.length > 0) {
                _this.currentConfirmQty = 0;
                _this.currentBillQty = 0;
                _this.currentPaidQty = 0;
                var i;
                for (i = 0; i < data.length; i++) {
                    _this.currentConfirmQty += data[i].confirmedQty;
                    _this.currentBillQty += data[i].billQty;
                    _this.currentPaidQty += data[i].paidQty;
                }
            }
            else {
                _this.currentConfirmQty = 0;
            }
            _this.confirmedList.push(_this.currentConfirmQty);
            _this.billList.push(_this.currentBillQty);
            _this.paidList.push(_this.currentPaidQty);
        });
    };
    /*
    getInboundTracking(inboundId) {
      this.trackingService
        .allTracking(inboundId)
        .pipe(first())
        .subscribe(data => {
          this.trackingList = data;
        });
    }*/
    //Kendo页更改函数
    ReportedListComponent.prototype.onPageChange = function (e) {
        this.skip = e.skip;
        this.pageSize = e.take;
        this.currentUser = this.authenticationService.currentUserValue[0];
        if (this.authenticationService.currentUserValue[2] === 'company') {
            this.getRecordsCompany(this.currentUser);
        }
        else {
            this.getRecords(this.currentUser);
        }
        //debugger;
        //this.pageData();
    };
    //获取页数据
    ReportedListComponent.prototype.pageData = function () {
        //this.pagedArticles = articles.slice(this.skip, this.skip + this.pageSize);
    };
    //定义包装尺寸及重量  
    ReportedListComponent.prototype.addPackageSizeFormGroup = function (data) {
        var _this = this;
        if (data === void 0) { data = []; }
        if (data.length == 0) {
            return this._formBuilder.group({
                id: [-1],
                length: ["", Validators.required],
                width: ["", Validators.required],
                height: ["", Validators.required],
                deep: [0],
                thick: [0],
                weight: [0],
                qty: [1, Validators.required],
            });
        }
        else {
            var arr_1 = {};
            this.PackageSizeForm.get('packageList').removeAt(0); //删除第一个
            data.forEach(function (currentValue, index) {
                arr_1 = {
                    id: currentValue.id,
                    length: currentValue.length,
                    width: currentValue.width,
                    height: currentValue.height,
                    qty: isNaN(parseInt(currentValue.qty)) ? 1 : parseInt(currentValue.qty),
                    deep: 0,
                    thick: 0,
                    weight: currentValue.weight,
                };
                _this.PackageSizeForm.get('packageList').push(_this._formBuilder.group(arr_1));
            });
            this.packageList = this.PackageSizeForm.get('packageList').value; //赋值
        }
    };
    ReportedListComponent.prototype.CheckShippingLabel = function (ship) {
        if (ship == 0) {
            this.IsshippingLabel = false;
        }
        else {
            this.IsshippingLabel = true;
        }
        //this.IsshippingLabel = ship == 0 ? false : true;    
    };
    //添加多个包装
    ReportedListComponent.prototype.onAddPackage = function () {
        this.PackageSizeForm.get('packageList').push(this.addPackageSizeFormGroup());
    };
    //移除多个包装
    ReportedListComponent.prototype.removePackage = function (index) {
        this.PackageSizeForm.get('packageList').removeAt(index);
    };
    //保存数据
    ReportedListComponent.prototype.onSavePackage = function () {
        var _this = this;
        var arr = {};
        this.oldpackageList = [];
        if (this.packageList.length > 0) {
            this.oldpackageList = this.packageList;
        }
        //console.log(this.packageList);
        this.packageList = [];
        this.PackageSizeForm.get('packageList').value.forEach(function (currentValue, index) {
            //过虑长*宽*高不为0数据     
            //debugger;   
            if (currentValue.length > 0 && currentValue.width > 0 && currentValue.height > 0) {
                //debugger;
                arr = {
                    'dealId': _this.dealid,
                    'inboundId': _this.inboundid,
                    'length': currentValue.length,
                    'width': currentValue.width,
                    'height': currentValue.height,
                    'weight': currentValue.weight == "" ? 0 : currentValue.weight,
                    'qty': currentValue.qty,
                    'individual': _this.individual,
                    'id': currentValue.id,
                    'type': currentValue.id == -1 ? 'add' : 'update'
                };
                _this.packageList.push(arr);
                //this.myArray.splice(index, 1);
            }
        });
        //console.log(this.packageList.length);
        //console.log(this.oldpackageList.length);
        if (this.packageList.length > 0 || this.oldpackageList.length > 0) {
            var delid_1 = [];
            this.oldpackageList.forEach(function (currentValue, index) {
                if (currentValue.id > 0) {
                    delid_1 = _this.packageList.filter(function (p) { return p.id == currentValue.id; });
                    if (delid_1.length == 0) {
                        //删除的行
                        arr = {
                            'dealId': _this.dealid,
                            'inboundId': _this.inboundid,
                            'length': currentValue.length,
                            'width': currentValue.width,
                            'height': currentValue.height,
                            'weight': currentValue.weight,
                            'qty': currentValue.qty,
                            'individual': _this.individual,
                            'id': currentValue.id,
                            'type': 'delete'
                        };
                        _this.packageList.push(arr);
                    }
                }
            });
            //console.log(this.packageList);      
            //插入包装信息
            this.inboundService
                .Addpackage(JSON.stringify(this.packageList))
                .pipe(first())
                .subscribe(function (data) {
            });
            this.packageList = [];
        }
    };
    //取消包裹尺寸
    ReportedListComponent.prototype.cancelPackage = function () {
        var _this = this;
        var arr = {};
        this.packageList = [];
        this.PackageSizeForm.get('packageList').value.forEach(function (currentValue, index) {
            //过虑长*宽*高不为0数据     
            if (currentValue.length > 0 && currentValue.width > 0 && currentValue.height > 0) {
                //debugger;
                arr = {
                    'dealId': _this.dealid,
                    'inboundId': _this.inboundid,
                    'length': currentValue.length,
                    'width': currentValue.width,
                    'height': currentValue.height,
                    'weight': currentValue.weight == "" ? 0 : currentValue.weight,
                    'qty': currentValue.qty,
                    'individual': _this.individual,
                    'id': currentValue.id
                };
                _this.packageList.push(arr);
            }
        });
        if (this.packageList.length > 0) {
            //删除包装信息
            //let packageList = ["package:" + this.packageList + ",notify:" + this.notify];
            if (this.notify) {
                this.inboundService
                    .cancelPackage(JSON.stringify(this.packageList))
                    .pipe(first())
                    .subscribe(function (data) {
                });
            }
            else {
                this.inboundService
                    .nocancelPackage(JSON.stringify(this.packageList))
                    .pipe(first())
                    .subscribe(function (data) {
                });
            }
        }
    };
    ReportedListComponent.prototype.NewReceipt = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1, _a, selectRows, row, r, crow, inboundIds, upcBillQty, em, bonus, awards, list, list_1, list_1_1, item, e_1_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        selectRows = [], row = {}, r = {}, crow = [], inboundIds = '', em = this;
                        bonus = 0, awards = 0;
                        list = this.recordList;
                        if (list.length == 0)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        list_1 = tslib_1.__values(list), list_1_1 = list_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!list_1_1.done) return [3 /*break*/, 5];
                        item = list_1_1.value;
                        if (!(typeof (item.isSelected) != 'undefined' && item.isSelected)) return [3 /*break*/, 4];
                        bonus = item.bonus === null ? 0 : parseFloat(item.bonus);
                        awards = item.awards === null ? 0 : parseFloat(item.awards);
                        row['id'] = item.id;
                        row['awards'] = item.awards;
                        row['company'] = item.company;
                        row['companyStatus'] = item.companyStatus;
                        row['createAt'] = item.createAt;
                        row['updateAt'] = item.updateAt;
                        row['dealId'] = item.dealId;
                        row['individual'] = item.individual;
                        row['payable'] = item.payable;
                        row['price'] = parseFloat(item.price) + bonus + awards;
                        row['product'] = item.product;
                        row['product_link'] = item.product_link;
                        row['propose'] = item.propose;
                        row['proposeStatus'] = item.proposeStatus;
                        row['publicWarehouse'] = item.publicWarehouse;
                        //row['quantity']        =  item.quantity;
                        row['status'] = item.status;
                        row['warehouse'] = item.warehouse;
                        row['inboundId'] = item.id;
                        return [4 /*yield*/, em.getUpcBillQty(item.id, row)];
                    case 3:
                        r = _b.sent();
                        //row['tax']       =  ''; //item.tax;
                        /*
                        if (upcBillQty.length>0) {
                          for (const value of await upcBillQty)
                          {
                            row['upc']             =  value.upc; //upcBillQty[0].upc;
                            row['quantity']        =  value.BillQty; // upcBillQty[0].BillQty;
                            
                             if (parseInt(row['quantity'])>0) {
                                //数量大于零
                                console.log(row);
                                selectRows.push(row);
                                console.log(selectRows);
                             }
                          }
                        }*/
                        //row['upc']             = upcBillQty[0].upc;
                        //row['quantity']        = upcBillQty[0].BillQty;
                        inboundIds = inboundIds + item.id + ',';
                        if (Object.keys(r).length != 0) {
                            crow = Object.values(r);
                            crow.forEach(function (value) {
                                selectRows.push(value);
                            });
                        }
                        row = {};
                        _b.label = 4;
                    case 4:
                        list_1_1 = list_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (list_1_1 && !list_1_1.done && (_a = list_1.return)) _a.call(list_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        ;
                        inboundIds = inboundIds.substring(0, inboundIds.lastIndexOf(","));
                        if (inboundIds != '') {
                            this.getBillTotal(inboundIds, this.currentUser);
                        }
                        /*
                        if (selectRows.length != 0) {
                          //this.alertService.error('No matching rows! Please select at least one row', this.options);
                          //$("#ReceiptModal").hide();
                          $('#ReceiptModal').modal('hide')
                        }*/
                        this.CreateReceipt(selectRows);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportedListComponent.prototype.getProoduct = function (isSelected, id) {
        //console.log(isSelected, id)
    };
    //日期转字符串
    ReportedListComponent.prototype.formatDate = function (date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    };
    //获取付款编号
    ReportedListComponent.prototype.getBillTotal = function (inboundIds, individual) {
        var _this = this;
        this.paymentService
            .getBillTotal(inboundIds, individual)
            .pipe(first())
            .subscribe(function (data) {
            //let str = data[0]["receiptNumber"] === null ? '1' : (parseInt(data[0]["receiptNumber"])+1).toString();      
            var str = data[0]["receiptNumber"]; // str.padStart(6,'0');
            _this.receiptForm.patchValue({
                ReceiptNumber: str,
            });
        });
    };
    //添加付款单
    ReportedListComponent.prototype.CreateReceipt = function (rows) {
        //var dt = new Date();
        var begin_date = new Date();
        var end_date = new Date();
        var individual = '';
        var receiptDate = $("#receiptDate").kendoDatePicker({
            format: "yyyy-MM-dd",
            value: begin_date
        });
        end_date.setDate(end_date.getDate() + 3);
        var dueDate = $("#dueDate").kendoDatePicker({
            format: "yyyy-MM-dd",
            value: end_date
        });
        /*
        $("#Conditions").on('input', function (e) {
            $('.k-counter-container .k-counter-value').html($(e.target).val().length);
        });*/
        var row = {}, currentamount = 0.00;
        //var test = [ { product: "Jane", quantity: 2000 }, { product: "Bill", quantity: 2000 } ];     
        this.receipt = [];
        //rows = Object.values(rows);   
        if (rows.length > 0) {
            for (var arr = 0; arr < rows.length; arr++) {
                row['id'] = rows[arr]['id'];
                row['dealId'] = rows[arr]['dealId'];
                row['company'] = rows[arr]['company'];
                row['inboundId'] = rows[arr]['inboundId'];
                row['product'] = rows[arr]['product'];
                row['individual'] = rows[arr]['individual'];
                row['upc'] = rows[arr]['upc'];
                row['quantity'] = rows[arr]['quantity'];
                row['price'] = rows[arr]['price'];
                row['paidQty'] = rows[arr]['quantity'];
                row['paidPrice'] = rows[arr]['price'];
                row['amount'] = (row['paidQty'] * row['paidPrice']).toFixed(2);
                currentamount += row['amount'];
                //row['tracking']  =  rows[arr]['tracking'];
                row['status'] = rows[arr]['status'];
                row['tax'] = 0;
                row['CheckpaidQty'] = rows[arr]['quantity'];
                row['idupc'] = rows[arr]['id'] + '_' + rows[arr]['upc'];
                this.receipt.push(row);
                //individual = row['individual'];
                //em.receiptForm.value.IndividualUser = row['individual'];            
                row = {};
            }
        }
        ;
        //getPaymentNumber()
        var billTotal = (currentamount * 1).toFixed(2);
        var receiptNumber = '001'; //em.getReceiptNumber(individual);
        this.receiptForm.patchValue({
            IndividualUser: this.currentUser,
            receiptDate: this.formatDate(begin_date),
            //DueDate: this.formatDate(end_date),
            BillTotal: billTotal,
            ConditionsValue: ''
        });
        this.payfields = [
            {
                field: "product",
                title: "Product",
                width: 350
            }, {
                field: "upc",
                title: "upc",
                width: 100
            }, {
                field: "paidQty",
                title: "Paid Qty",
                width: 90,
                template: "#if (paidQty > CheckpaidQty) { amount=CheckpaidQty * paidPrice; #<span>#: CheckpaidQty #<span>#} else { amount=paidQty * paidPrice;#<span>#: paidQty #<span>#}#",
                aggregates: ["sum"], footerTemplate: "paidQty: #=sum#"
                //template: "#if (paidQty < 10) {#<span>#: paidQty #<span>#} else if (paidQty  > 40) {#<span style='color:red;'>#: paidQty #<span>#}#"
                //;let curamount = document.getElementById('BillTotal').value ; document.getElementById('BillTotal').value = parseFloat(curamount).toFixed(2) + parseFloat(amount).toFixed(2);
            }, {
                field: "paidPrice",
                title: "Paid Price",
                format: "{0:c}",
                width: 90
            }, {
                field: "amount",
                title: "Amount",
                format: "{0:c}",
                width: 90,
                /*attributes: {
                  "class": "totalSpan",
                  style: "text-align: right; font-size: 14px"
                },
                editor: function(cont, options) {
                    $("<span class='totalSpan'>" + options.model.amount + " &euro;</span>").appendTo(cont);
                },*/
                aggregates: ["sum"], footerTemplate: "paidTotal: #=sum#" /*,
                template: "#=paidQty * paidPrice#"*/
            },
            {
                field: "CheckpaidQty",
                hidden: true
            },
            {
                field: "id",
                hidden: true
            },
            {
                field: "idupc",
                hidden: true
            }
        ];
        this.isAdd = 'add';
        var th = this;
        $("#newreceipt").kendoGrid({
            columns: this.payfields,
            editable: true,
            //toolbar: ["create"],
            height: 460,
            //selectable: "multiple cell",
            //editable: "inline",
            //selectable: "multiple, row",
            //dataSource: em.receipt, //[ { product: "Jane", quantity: 2000 }, { product: "Bill", quantity: 2000 } ]              
            dataSource: {
                data: this.receipt,
                autoSync: true,
                schema: {
                    model: {
                        id: "idupc",
                        fields: {
                            id: { editable: false, nullable: true },
                            product: { editable: false, validation: { required: true } },
                            paidPrice: { editable: false, field: "paidPrice", type: "number" },
                            paidQty: { editable: true, field: "paidQty", type: "number" },
                            amount: { editable: false, field: "amount", type: "number" },
                            upc: { editable: false, field: "upc", type: "string" }
                        }
                    }
                },
                aggregate: [{ field: "paidQty", aggregate: "sum" },
                    { field: "amount", aggregate: "sum", format: "{0:c}" }],
                edit: function (e) {
                    console.log(e);
                },
                change: function (e) {
                    //if (e.field && e.action == "itemchange") {
                    var grid = $("#newreceipt").data("kendoGrid");
                    //let data = grid.dataSource.options.data;
                    var model = e.items[0];
                    var items = grid.dataSource.options.data; //this.view();
                    var amount = 0.00;
                    var qty = 0;
                    if (typeof (e.items[0]) == "undefined")
                        return;
                    if (e.items[0].paidQty > e.items[0].CheckpaidQty) {
                        e.items[0].paidQty = e.items[0].CheckpaidQty;
                        //e.items[0].amount  = (e.items[0].paidQty * e.items[0].paidPrice).toFixed(2);
                    }
                    //e.items[0].amount  = (e.items[0].paidQty * e.items[0].paidPrice).toFixed(2);
                    for (var idx = 0; idx < items.length; idx++) {
                        var item = items[idx];
                        //this.receipt[idx].amount =  (this.receipt[idx].paidQty * this.receipt[idx].paidPrice).toFixed(2);
                        var paidQty = item.paidQty > item.CheckpaidQty ? item.CheckpaidQty : parseInt(item.paidQty);
                        e.items[idx].paidQty = paidQty;
                        items[idx].paidQty = paidQty;
                        qty += paidQty;
                        amount += paidQty * item.paidPrice; // parseFloat(item.amount);
                    }
                    this.aggregates().amount.sum = amount.toFixed(2);
                    this.aggregates().paidQty.sum = qty;
                    document.getElementById('BillTotal').value = amount.toFixed(2); //.toString();
                    th.receiptForm.patchValue({
                        BillTotal: amount.toFixed(2)
                    });
                    if (typeof (grid.footer) != "undefined") {
                        grid.footer.find(".k-footer-template").replaceWith(grid.footerTemplate(this.aggregates()));
                    }
                    //}          
                }
            }
        });
    };
    ReportedListComponent.prototype.saveReceipt = function (send) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2, _a, e_3, _b, grid, data, ts, paidQty, confirmedQty, data_1, data_1_1, col, paymentId, data_2, data_2_1, col, e_3_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        grid = $("#newreceipt").getKendoGrid(), data = grid.dataSource.options.data, ts = this, paidQty = 0, confirmedQty = {};
                        try {
                            //检查数量是否是零    
                            for (data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                                col = data_1_1.value;
                                paidQty = paidQty + parseInt(col.paidQty);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        if (paidQty == 0)
                            return [2 /*return*/];
                        if (!(data.length > 0)) return [3 /*break*/, 11];
                        return [4 /*yield*/, ts.getInboundConfirmBill(data[0].dealId, data[0].inboundId)];
                    case 1:
                        confirmedQty = _c.sent();
                        ts.payments.inboundId = data[0].inboundId;
                        ts.payments.dealId = data[0].dealId;
                        ts.payments.receiptNumber = ts.receiptForm.value.ReceiptNumber;
                        ts.payments.company = data[0].company;
                        ts.payments.individual = data[0].individual;
                        ts.payments.billQty = confirmedQty[0].billQty === null ? 0 : confirmedQty[0].billQty;
                        ts.payments.confirmedQty = confirmedQty[0].confirmedQty === null ? 0 : confirmedQty[0].confirmedQty;
                        ts.payments.paidQty = data[0].paidQty;
                        ts.payments.billTotal = ts.receiptForm.value.BillTotal;
                        ts.payments.confirmedTotal = 0;
                        ts.payments.paidTotal = ts.receiptForm.value.BillTotal;
                        ts.payments.adjustment = 0;
                        ts.payments.status = 0;
                        ts.payments.description = ts.receiptForm.value.ConditionsValue;
                        ts.payments.isDelete = 0;
                        ts.payments.receiptDate = ts.receiptForm.value.receiptDate;
                        return [4 /*yield*/, ts.createPayments(ts.payments)];
                    case 2:
                        paymentId = _c.sent();
                        _c.label = 3;
                    case 3:
                        _c.trys.push([3, 8, 9, 10]);
                        data_2 = tslib_1.__values(data), data_2_1 = data_2.next();
                        _c.label = 4;
                    case 4:
                        if (!!data_2_1.done) return [3 /*break*/, 7];
                        col = data_2_1.value;
                        //data.forEach(function (col, index) {
                        //id、inboundId、dealId、paymentId、company、individual、upc、confirmedQty、confirmedPrice、billQty、billPrice、 paidQty、paidPrice、amount、status、isDelete
                        ts.inboundPayments.inboundId = col.inboundId;
                        ts.inboundPayments.dealId = col.dealId;
                        ts.inboundPayments.paymentId = paymentId['id'];
                        ts.inboundPayments.company = col.company;
                        ts.inboundPayments.individual = col.individual;
                        ts.inboundPayments.upc = col.upc;
                        ts.inboundPayments.billQty = ts.payments.billQty;
                        ts.inboundPayments.confirmedQty = ts.payments.confirmedQty;
                        ts.inboundPayments.paidQty = col.paidQty;
                        ts.inboundPayments.confirmedPrice = col.paidPrice;
                        ts.inboundPayments.billPrice = col.paidPrice;
                        ts.inboundPayments.paidPrice = col.paidPrice;
                        ts.inboundPayments.amount = col.paidQty * col.paidPrice; //col.amount; 
                        ts.inboundPayments.status = 0;
                        ts.inboundPayments.isDelete = 0;
                        if (!(ts.inboundPayments.paidQty != 0)) return [3 /*break*/, 6];
                        return [4 /*yield*/, ts.CreateInboundPayments(ts.inboundPayments)];
                    case 5:
                        _c.sent();
                        _c.label = 6;
                    case 6:
                        data_2_1 = data_2.next();
                        return [3 /*break*/, 4];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_3_1 = _c.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (data_2_1 && !data_2_1.done && (_b = data_2.return)) _b.call(data_2);
                        }
                        finally { if (e_3) throw e_3.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        if (send == 'send') {
                            this.exportData(data);
                        }
                        _c.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    ReportedListComponent.prototype.getInboundConfirmBill = function (dealid, inboundId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.paymentService
                .getInboundConfirmBill(dealid, inboundId)
                .pipe(first())
                .subscribe(function (data) {
                resolve(data);
            });
        });
    };
    ReportedListComponent.prototype.createPayments = function (payments) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.paymentService
                .CreatePayments(payments)
                .pipe(first())
                .subscribe(function (data) {
                resolve(data);
            });
        });
    };
    ReportedListComponent.prototype.CreateInboundPayments = function (inboundPayments) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.paymentService
                .CreateInboundPayments(inboundPayments)
                .pipe(first())
                .subscribe(function (data) {
                resolve(data);
            });
        });
    };
    //获取upc billQty
    ReportedListComponent.prototype.getUpcBillQty = function (inboundId, row) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.paymentService
                .getUpcBillQty(inboundId)
                .pipe(first())
                .subscribe(function (data) {
                var e_4, _a;
                var selectRows = [];
                var tow = {};
                if (data.length > 0) {
                    try {
                        for (var data_3 = tslib_1.__values(data), data_3_1 = data_3.next(); !data_3_1.done; data_3_1 = data_3.next()) {
                            var item = data_3_1.value;
                            Object.assign(tow, row);
                            tow['upc'] = item['upc']; //upcBillQty[0].upc;
                            tow['quantity'] = item['BillQty']; // upcBillQty[0].BillQty;
                            //if (parseInt(tow['quantity'])>0) {                
                            selectRows.push(tow);
                            //}
                            tow = {};
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (data_3_1 && !data_3_1.done && (_a = data_3.return)) _a.call(data_3);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
                else {
                    row['upc'] = '';
                    row['quantity'] = 0;
                    row['paidTotal'] = 0.00;
                }
                if (selectRows.length > 0) {
                    resolve(selectRows);
                }
                else {
                    selectRows.push(row); // = Object.values(row);
                    resolve(selectRows);
                }
            });
        });
    };
    //汇出数据
    ReportedListComponent.prototype.exportData = function (data) {
        var e_5, _a;
        var rows = [], row = [], frow = [], Qty = 0.00, Total = 0.00;
        var str = '';
        str = JSON.parse("{\"value\":\"Date\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"Receipt Number\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"Inbound\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"Product Name\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"UPC\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"Quantity\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"Price\"}");
        frow.push(str);
        str = JSON.parse("{\"value\":\"Total\"}");
        frow.push(str);
        var filename = 'PAYMENT';
        rows.push({
            cells: frow
        });
        if (data.length > 0) {
            try {
                for (var data_4 = tslib_1.__values(data), data_4_1 = data_4.next(); !data_4_1.done; data_4_1 = data_4.next()) {
                    var col = data_4_1.value;
                    str = JSON.parse("{\"value\":\"" + this.receiptForm.value.receiptDate + "\"}");
                    row.push(str);
                    str = JSON.parse("{\"value\":\"" + this.receiptForm.value.ReceiptNumber + "\"}");
                    row.push(str);
                    str = JSON.parse("{\"value\":\"" + col.inboundId + "_" + col.individual + "\"}");
                    row.push(str);
                    str = JSON.parse("{\"value\":\"" + col.product + "\"}");
                    row.push(str);
                    str = JSON.parse("{\"value\":\"" + col.upc + "\"}");
                    row.push(str);
                    str = JSON.parse("{\"value\":\"" + col.paidQty + "\"}");
                    row.push(str);
                    str = JSON.parse("{\"value\":\"" + col.paidPrice.toFixed(2) + "\"}");
                    row.push(str);
                    Qty = parseFloat(col.paidQty) * parseFloat(col.paidPrice);
                    str = JSON.parse("{\"value\":\"" + Qty.toFixed(2) + "\"}");
                    row.push(str);
                    Total = Total + Qty;
                    if (parseInt(col.paidQty) != 0) {
                        rows.push({
                            cells: row
                        });
                    }
                    row = [];
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (data_4_1 && !data_4_1.done && (_a = data_4.return)) _a.call(data_4);
                }
                finally { if (e_5) throw e_5.error; }
            }
            row = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }];
            rows.push({
                cells: row
            });
            row = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: 'Sum' }, { value: Total.toFixed(2) }];
            rows.push({
                cells: row
            });
            filename = filename + '-' + data[0].inboundId + '.csv';
            this.excelExport(rows, filename);
        }
    };
    //导出到Excel
    ReportedListComponent.prototype.excelExport = function (rows, filename) {
        var workbook = new kendo.ooxml.Workbook({
            sheets: [
                {
                    /*
                    columns: [
                      { autoWidth: true },
                      { autoWidth: true }
                    ],*/
                    columns: [{ width: 80 }, { width: 80 }, { width: 100 }, { width: 300 }, { width: 100 }, { width: 80 }, { width: 80 }, { width: 80 }],
                    title: "payment",
                    rows: rows
                }
            ]
        });
        kendo.saveAs({
            dataURI: workbook.toDataURL(),
            fileName: filename
            //proxyURL: "/home/data/release/weapp/markeapp/public",
            //forceProxy: true
        });
    };
    ReportedListComponent.prototype.getInboundTracking = function (inboundId) {
        var _this = this;
        this.trackingService
            .allTracking(inboundId)
            .pipe(first())
            .subscribe(function (data) {
            if (data !== undefined && data.length > 0) {
                var i;
                for (i = 0; i < data.length; i++) {
                    _this.getInboundTrackingConfirm(_this.selectInbound.id, data[i].tracking);
                }
            }
        });
    };
    ReportedListComponent.prototype.getInboundTrackingConfirm = function (inboundId, tracking) {
        var _this = this;
        //const a = this;
        this.confirmService
            .allTrackingConfirm(inboundId, tracking)
            .pipe(first())
            .subscribe(function (data) {
            var trackingConfirmQty = 0;
            if (data !== undefined && data.length > 0) {
                var i;
                for (i = 0; i < data.length; i++) {
                    trackingConfirmQty += data[i].confirmedQty;
                }
            }
            else {
                trackingConfirmQty = 0;
            }
            _this.trackingList.push(tracking);
            _this.trackingConfirmedList.push(trackingConfirmQty);
        });
    };
    return ReportedListComponent;
}());
export { ReportedListComponent };
