import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService } from '../../../_alert';



@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
})
export class RequestResetComponent implements OnInit {
  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;

  options = {
  	autoClose: false, //true Auto close alert after three seconds
    keepAfterRouteChange: true //true Keep displaying after one route change
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
   ) {

  }


  ngOnInit() {

    this.RequestResetForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email], this.forbiddenEmails),
    });
  }


  RequestResetUser(form) {
    console.log(form)
    if (form.valid) {
      this.IsvalidForm = true;
      this.authService
      .requestReset(this.RequestResetForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data == 'Email does not exist') {
          	this.errorMessage = 'Email does not exist';
          	this.alertService.error('Email does not exist!', this.options);
          } else {
          	 this.RequestResetForm.reset();
	         this.successMessage = "Reset password link send to email sucessfully.";
	         setTimeout(() => {
	           this.successMessage = null;
	           this.router.navigate(['sign-in']);
	         }, 3000);
          }  
        
        },
        error => {
        	console.log(error);
        	this.errorMessage = error;
        	/*
        	console.log(typeof(error));
            console.log(error);
            if (error.message) {
              this.errorMessage = error.message;
            }*/
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }
}