import { Component, OnInit,Input } from '@angular/core';

import { GroupService } from '../../../services/group.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { first } from 'rxjs/operators';
import { Subscription } from '../../../models/subscription';

//引入kendo 分页模块
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { InputsModule } from '@progress/kendo-angular-inputs';


@Component({
  selector: 'app-groups-active-list',
  templateUrl: './groups-active-list.component.html',
  styleUrls: ['./groups-active-list.component.scss'],
  providers: [AuthenticationService, GroupService]
})
export class GroupsActiveListComponent implements OnInit {
  public pageSize = 20; //每页显示条数
  public skip = 0; //页号
  public pagedDestinations = [];
  public total = 0; //destinations.length; //总条数
  public filter = '';
  @Input('answers') ans:any;

  public privilege: any;
  public currentUser: any;
  public followersList: any;
  // status mapping from 0,1,2 to specific meaning
  public statusLevel = ['Ask To Follow', 'Active', 'Blocked'];
  // used to make sure which one is selected
  public selectId: any;
  // used to pass data to API
  public selectSubscription = new Subscription(null, null, null);

  constructor(
    private groupService: GroupService,
    public authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    // privilege check
    if (!this.ans) return; 
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.getfollowersList(this.currentUser);
        this.subAllCount(this.currentUser);
      } else {
        this.privilege = false;
        // @TODO
      }
    }
  }

  /**
   * Get the active followers for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getfollowersList(individual) {
    //debugger;
    this.groupService
      .allSubscribers(individual,this.filter,this.skip,this.pageSize)
      .pipe(first())
      .subscribe(data => {
        this.followersList = data;
      });
  }

  approve(i) {
    this.selectSubscription.individual = this.followersList[i].individual;
    this.selectSubscription.status = this.followersList[i].status;
    this.selectSubscription.company = this.followersList[i].company;
    this.groupService
      .setActive(this.selectSubscription)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
      });
  }
  setActive() {
    this.groupService
      .setActive(this.selectSubscription)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
      });
  }
  setBlock() {
    this.groupService
      .setBlock(this.selectSubscription)
      .pipe(first())
      .subscribe(data => {
        this.ngOnInit();
      });
  }

  subSelect(i: any) {
    this.selectId = i;
    this.selectSubscription.individual = this.followersList[i].individual;
    this.selectSubscription.status = this.followersList[i].status;
    this.selectSubscription.company = this.followersList[this.selectId].company;
  }

  //统计总页数
  subAllCount(company) {
    this.groupService
      .subAllCount(company,this.filter,'groups')
      .pipe(first())
      .subscribe(data => {
        this.total = data["count"];
      });
  }

  //Kendo分页函数  
  onPageChange(e: PageChangeEvent): void {    
    this.skip = e.skip;
    this.pageSize = e.take;    
    this.currentUser = this.authenticationService.currentUserValue[0];
    if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        this.getfollowersList(this.currentUser);
        this.subAllCount(this.currentUser);
    } else {
        this.privilege = false;
    } 
  }   

}
