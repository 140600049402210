<div class="wrapper" *ngIf="!noRecord">
<table class="table table-hover" id="inboundTable" >
  <thead class="thead-light">
    <tr>
      <th scope="col" style="width: 7%" class="hide">#</th>
      <th scope="col" style="width: 12%" *ngIf="privilege" class="hide">
        From
      </th>
      <th scope="col" style="width: 12%" *ngIf="!privilege" class="hide">Company</th>
      <!--<th scope="col" style="width: 13%" class="hide">Confirmed total</th>
      <th scope="col" style="width: 10%" class="hide">Bill total</th>-->
      <th scope="col" style="width: 11%" class="hide">Receipt#</th>
      <th scope="col" style="width: 11%" class="hide">Receipt date</th>

      <th scope="col" style="width: 11%" class="hide">Adjustment</th>

      <th scope="col" style="width: 10%" class="hide">Bill total</th>      
      <th scope="col" style="width: 19%">Note</th>
      <th scope="col" style="width: 11%" class="hide">Status</th>
      <!--<th scope="col" style="width: 9%" *ngIf="!privilege" class="hide">Action</th>-->
      <th scope="col" style="width: 10%" class="hide">Action</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let record of recordList; index as i">
      <tr>
  
      	<td class="show">
          <div>
            <b class="show">{{ record.receiptNumber}}</b>
            <p class="text-primary show">
              {{ record.receiptDate | date: 'medium' }}
            </p>
            <p class="text-danger show" *ngIf="privilege">
              {{ record.individual }}
            </p>
            <p class="text-info show" *ngIf="!privilege">
              <b>{{ record.company }}</b>
            </p>
            <div class="text-danger show">
              {{ record.paidTotal }}              
            </div>
            <div class="text-danger show">
              {{ record.adjustment }}              
            </div>
            <div class="text-danger show">
              {{ record.billTotal }}              
            </div>
            <p class="text-danger show">Note: {{ record.description }}</p>
            <p class="text-danger show">Note(company): {{ record.note }}</p>
            <p class="text-warning show" *ngIf="record.status===0">
              <b>{{ status[record.status] }}</b>
            </p>
            <p class="text-danger show" *ngIf="record.status===1">
              <b>{{ status[record.status] }}</b>
            </p>
            <p class="text-success show" *ngIf="record.status===2">
              <b>{{ status[record.status] }}</b>
            </p>
          </div>
          <button 
            id="{{ i }}"
            type="button"
            class="btn btn-outline-success btn-block btn-sm show"
            data-toggle="modal"
            data-target="#ReceiptModal"
            *ngIf="!record.status && !privilege"
            (click)="select(i)"
          >
          Edit
         </button>
          <button 
            id="{{ i }}"
            type="button"
            class="btn btn-outline-success btn-block btn-sm show"
            *ngIf="!record.status && !privilege"
            (click)="DeletePayment(Delete,i)"
          >
          Delete
         </button>         
         <button 
           id="{{ i }}"
           type="button"
           class="btn btn-outline-success btn-block btn-sm show"
           data-toggle="modal"
           data-target="#ReceiptModal"
           *ngIf="!record.status && privilege"
           (click)="select(i)"
         >
          Edit
         </button>
        <!--</td>
        <td class="hide">-->         
        <!--允许公司账号查看-->
        <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm show"
          data-toggle="modal"
          data-target="#ReceiptModal"
          *ngIf="(record.status && !privilege) || (record.status && privilege)"
          (click)="select(i)"
          >
          View
          </button>
        </td>
        
        <td class="hide">{{ ('000000' + record.id).slice(-5) }}</td>        
        <td *ngIf="privilege" class="hide">{{ record.individual }}</td>
        <!-- <td *ngIf="!privilege">{{ record.individual }}</td> -->
        <td class="hide text-info" *ngIf="!privilege"><b>{{ record.company }}</b></td>
        <!--<td class="hide">${{ record.confirmedTotal }}</td>
        <td class="hide">{{ record.billTotal }}</td>-->       
         <td class="hide">{{ record.receiptNumber}}</td>
        <td class="hide text-ellipsis">{{ record.receiptDate | date: 'medium' }}</td>
        <td class="hide">${{ record.adjustment }}</td> 
        <td class="hide">${{ record.billTotal }}</td> 
        <td class="hide text-ellipsis" data-toggle="tooltip" *ngIf="!privilege">{{ record.description }}</td> 
        <td class="hide text-ellipsis" data-toggle="tooltip" *ngIf="privilege">{{ record.note }}</td>        
        <td class="text-red hide" *ngIf="record.status===0"><strong>{{ status[record.status] }}</strong></td>
        <td class="text-yellow hide" *ngIf="record.status===1"><strong>{{ status[record.status] }}</strong></td>
        <td class="text-green hide" *ngIf="record.status===2"><strong>{{ status[record.status] }}</strong></td>
        <!--
        	<p *ngIf="record.status===0" class="text-warning show">1{{ status[record.status] }}</p>
          <p *ngIf="record.status===1" class="text-danger show">2
              {{ status[record.status] }}
          </p>
          <p *ngIf="record.status===2" class="text-success show">3{{ status[record.status] }}</p>
        </td>-->
        <td class="hide">          
          <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#ReceiptModal"
          *ngIf="!record.status && !privilege"
          (click)="select(i)"
        >
          Edit
        </button> 
        <button 
            id="{{ i }}"
            type="button"
            class="btn btn-outline-success btn-block btn-sm"
            *ngIf="!record.status && !privilege"
            (click)="DeletePayment(Delete,i)"
          >
          Delete
        </button>        
        <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#ReceiptModal"
          *ngIf="record.status < 2 && privilege"
          (click)="select(i)"
        >
          Edit
        </button>
        <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"          
          *ngIf="record.status == 0 && privilege"
          (click)="setStatus(i,1)"
        >
          Confirm
        </button>
        <!--</td>
        <td class="hide">-->         
        <!--允许公司账号查看-->
        <button 
          id="{{ i }}"
          type="button"
          class="btn btn-outline-success btn-block btn-sm"
          data-toggle="modal"
          data-target="#ReceiptModal"
          *ngIf="(record.status && !privilege) || (record.status  == 2 && privilege)"
          (click)="select(i)"
          >
          View
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<!--分页实现Begin-->
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  [type]="type"
  (pageChange)="onPageChange($event)">
</kendo-datapager>

</div>

<div class="wrapper" *ngIf="noRecord">

<table class="table" id="inboundTable">
  <thead>
    <tr>
      <th scope="col" style="width: 9%" class="hide">#</th>
      <th scope="col" style="width: 9%" *ngIf="privilege" class="hide">
        From
      </th>
      <th scope="col" style="width: 9%" *ngIf="!privilege" class="hide">Company</th>
      <!--<th scope="col" style="width: 12%" class="hide">Confirmed total</th>
      <th scope="col" style="width: 10%" class="hide">Bill total</th>-->
      <th scope="col" style="width: 9%" class="hide">Receipt#</th>
      <th scope="col" style="width: 12%" class="hide">Receipt date</th>
      <th scope="col" style="width: 12%" class="hide">Paid total</th>
      <th scope="col" style="width: 28%">Note</th>
      <th scope="col" style="width: 9%" class="hide">Status</th>
      <!--<th scope="col" style="width: 9%" *ngIf="!privilege" class="hide">Action</th>-->
      <th scope="col" style="width: 9%" class="hide">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scopr="row" class="hide"></th>
      <td>
        No Inbound Record......
      </td>
      <td *ngIf="privilege" class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide"></td>
      <td class="hide">
      </td>
    </tr>
  </tbody>
</table>

<!--分页实现Begin-->
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total"
  [type]="type"
  (pageChange)="onPageChange($event)">
</kendo-datapager>

</div>




<!--Receipt Modal Begin-->
<div 
class="modal fade" 
id="ReceiptModal" 
role="dialog" 
tabindex="-1"
aria-hidden="true"
>
  <div class="modal-dialog modal-xl"  role="document">

    <!-- Modal content-->
    <div class="modal-content">
            
      <div class="modal-header">
        <h5 class="modal-title" id="formTitle">Edit Receipt</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>        
      </div>
      
       <div class="receipt-wrap">
        <form class="k-form" [formGroup]="receiptForm">        
        <fieldset class="editorder left-column">
            
            <kendo-formfield>
              <kendo-label [for]="IndividualUser" text="Individual User: "></kendo-label> 
              <input
                formControlName="IndividualUser"
                kendoTextBox
                readOnly
                #IndividualUser
              />   
            </kendo-formfield>
            
            <kendo-formfield>
              <kendo-label [for]="ReceiptNumber" text="No#"></kendo-label>
              <input
                formControlName="ReceiptNumber"
                kendoTextBox
                readOnly
                #ReceiptNumber
              />
              <kendo-formerror>Error: receipt is required</kendo-formerror>
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label
                [for]="receiptDate" Receipt Date
                [optional]="true"
                text="Receipt Date"
              ></kendo-label>
              <input id="receiptDate" formControlName="receiptDate" value="01/01/2021" title="datepicker" style="width: 100%" />             
            </kendo-formfield>
            <!--
            <kendo-formfield [hidden]="true">
              <kendo-label text="Confirmed Total"></kendo-label>
              <input
                formControlName="ConfirmedQty"
                kendoTextBox
                readOnly
                #ConfirmedQty
              />
            </kendo-formfield>
            -->

            <kendo-formfield>
              <kendo-label text="Bill Total"></kendo-label>
              <input
                formControlName="BillTotal"
                kendoTextBox
                id="BillTotal"
                readOnly
                #BillTotal
              />
            </kendo-formfield>
            <!--
            <kendo-formfield [hidden]="true">
              <kendo-label
                [for]="DueDate" Receipt Date
                [optional]="true"
                text="Due Date"
              ></kendo-label>
              <input id="dueDate" formControlName="DueDate" value="01/01/2021" title="datepicker" style="width: 100%" />            
            </kendo-formfield>       
            -->

            <!--(valueChange)="onChange($event)" 每个值变化-->
             <kendo-formfield *ngIf="privilege">
                <kendo-label
                  text="Adjustment" 
                ></kendo-label>
                <kendo-numerictextbox
                 [decimals]="decimals"
                 format="n2"                 
                 formControlName="Adjustment"                 
                 (blur)="AdjonBlur()"
                 (focus)="AdjonFocus()"
                ></kendo-numerictextbox>              
            </kendo-formfield>                 
            
             <kendo-formfield>
              <kendo-label
                text="Note(Company)" 
              ></kendo-label>
               <textarea id="note" style="width: 100%" formControlName="note">
               </textarea>
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label
                [for]="Conditions" Conditions
                text="Note(individual)"  
              ></kendo-label>
              <!-- Terms & Conditions"-->
               <textarea id="py-description" style="width: 100%" formControlName="ConditionsValue">
               </textarea>
            </kendo-formfield>
            

        </fieldset>
        </form>
        <div class="right-column">
          <div id="newreceipt"></div>
        </div>

      </div>

      <div class="modal-footer">
      	<button type="button" id="saveReceiptSend" class="btn btn-info" (click)="saveReceipt('send')" data-dismiss="modal">Export XLSX
        </button>
        <button type="button" id="saveReceipt" class="btn btn-primary" (click)="saveReceipt('NoSend')" data-dismiss="modal">Save
        </button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
<!--Receipt Modal End-->

<ng-template #Delete let-c="close" let-d="dismiss">    
    <div class="modal-header">    
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>    
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">    
            <span aria-hidden="true">×</span>    
        </button>    
    </div>    
    <div class="modal-body">    
        <p>Are you sure to delete this?</p>    
    </div>    
    <div class="modal-footer">    
        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')">Cancel</button>    
        <button type="button" class="btn btn-outline-dark" (click)="c('yes')">Yes</button>    
    </div>    
</ng-template>    
