import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BASEURL = environment.apiUrl;
var httpOptions = {
    headers: new HttpHeaders({
        'content-Type': 'application/json'
    })
};
var AuthService = /** @class */ (function () {
    //countUrl = environment.apiUrl + 'bills/count';
    function AuthService(http) {
        this.http = http;
    }
    AuthService.prototype.registerUser = function (body) {
        return this.http.post(BASEURL + "/register", body);
    };
    AuthService.prototype.loginUser = function (body) {
        return this.http.post(BASEURL + "/login", body);
    };
    AuthService.prototype.requestReset = function (body) {
        //console.log(`${BASEURL}users/req-reset-password`);
        return this.http.post(BASEURL + "users/req-reset-password", body, httpOptions);
    };
    AuthService.prototype.newPassword = function (body) {
        return this.http.post(BASEURL + "users/response-reset-password", body, httpOptions);
    };
    AuthService.prototype.ValidPasswordToken = function (body) {
        return this.http.post(BASEURL + "users/valid-password-token", body, httpOptions);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
