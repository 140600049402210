import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Tracking } from '../models/tracking';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Paymentold } from '../models/paymentold';
import { Receipt } from '../models/receipt';

import { Payment } from '../models/payment';
import { inboundPayment } from '../models/inboundpayment';

const httpOptions = {
  headers: new HttpHeaders({
    'content-Type': 'application/json'
  })
};
@Injectable()
export class PaymentService {
  createTrackingUrl = environment.apiUrl + 'trackings/tracking'; // URL to web api
  allUrl = environment.apiUrl + 'trackings/allTracking'; // URL to web api
  getUrl = environment.apiUrl + 'payment/getComfirmed'; 
  getCom = environment.apiUrl + 'payment/getCompanyList';
  confirmUrl = environment.apiUrl + 'payment/confirm';
  createPaymentUrl = environment.apiUrl + 'payment/payment'; 
  createReceiptUrl = environment.apiUrl + 'payment/receipt';
  updateReceiptUrl = environment.apiUrl + 'payment/updateReceipt'; 
  getPaidUrl = environment.apiUrl + 'payment/getPaidList';
  getPayingUrl = environment.apiUrl + 'payment/getPayingList';
  getReceiptNumberUrl = environment.apiUrl + 'payment/getReceiptNumber';
  getReceiptListUrl = environment.apiUrl + 'payment/getReceiptList';
  setReceiptChangeUrl=environment.apiUrl + 'payment/setReceiptChange';
  getBillUrl = environment.apiUrl + 'payment/getBillTotal';
  getConfirmBillUrl = environment.apiUrl + 'payment/getConfirmBillQty';

  CreatePaymentsUrl = environment.apiUrl + 'payment/CreatePayment';
  UpdatePaymentsUrl = environment.apiUrl + 'payment/UpdatePayment';
  UpdatePaymentsEmailUrl = environment.apiUrl + 'payment/UpdatePaymentEmail';
  CreateInboundPaymentUrl = environment.apiUrl + 'payment/CreateInboundPayment';

  getPaymentListUrl = environment.apiUrl + 'payment/getPaymentList';
  PageCountUrl      = environment.apiUrl + 'payment/PageCount';
  SearchListUrl     = environment.apiUrl + 'payment/SearchList';
  PaymentDeleteUrl  = environment.apiUrl + 'payment/Delete';
  getUpcBillQtyUrl  = environment.apiUrl + 'payment/getUpcBillQty';
  sendEmailUrl      = environment.apiUrl + 'payment/sendEmail';
  setStatusUrl      = environment.apiUrl + 'payment/setStatus';

  constructor(private http: HttpClient) {}

  createTracking(tracking: Tracking): Observable<Tracking> {
    return this.http.post<Tracking>(this.createTrackingUrl, tracking, httpOptions);
  }

  allTracking(inboundId: number): Observable<any> {
    const inboundIdJSON = '{ "inboundId" : ' + '"' + inboundId + '"'+ ' }';

    return this.http.post<any>(
      this.allUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );
  }
  //护士账号
  getConfirmed(individual: string): Observable<any> {
    const paymentJSON = '{ "individual" : ' + '"' + individual + '"'+ ' }';    
    return this.http.post<any>(
      this.getUrl,
      JSON.parse(paymentJSON),
      httpOptions
    );
  }
  //公司账号
  getCompanyList(Company: string): Observable<any> {
    const paymentJSON = '{ "company" : ' + '"' + Company + '"'+ ' }';    
    return this.http.post<any>(
      this.getCom,
      JSON.parse(paymentJSON),
      httpOptions
    );
  }
  //dashboard 面版payment个人账号
  getPaidList(individual: string): Observable<any> {
    const paymentJSON = '{ "individual" : ' + '"' + individual + '"'+ ' }';    
    return this.http.post<any>(
      this.getPaidUrl,
      JSON.parse(paymentJSON),
      httpOptions
    );
  }

  //dashboard 面版payment公司账号
  getPayingList(Company: string): Observable<any> {
    const paymentJSON = '{ "company" : ' + '"' + Company + '"'+ ' }';    
    return this.http.post<any>(
      this.getPayingUrl,
      JSON.parse(paymentJSON),
      httpOptions
    );
  }
  //获取付款编码
  
  getReceiptNumber(individual: string): Observable<string> {
    const receiptJSON = '{ "individual" : ' + '"' + individual + '"'+ ' }';    
    //const data = this.httpClient.get(this.apiUrl).toPromise();
    return this.http.post<string>(
      this.getReceiptNumberUrl,
      JSON.parse(receiptJSON),
      httpOptions
    );
  }
  /*
  async getReceiptNumber(individual: string){
    //const data = await this.httpClient.get(this.apiUrl).toPromise();
    const receiptJSON = '{ "individual" : ' + '"' + individual + '"'+ ' }';    
    return await this.http.post<string>(
      this.getReceiptNumberUrl,
      JSON.parse(receiptJSON),
      httpOptions
    ).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
  }*/

  //公司确认求款请求
  confirm(ids: string,notifications:number): Observable<string> {

     const idJSON ='{ "ids" : ' + '"' + ids + '",' + '"notifications" : ' + '"' + notifications + '"' + ' }';
     return this.http.post<string>(
       this.confirmUrl,
       JSON.parse(idJSON),
       httpOptions
     );
  }

  createPayment(payment: Paymentold): Observable<Paymentold> {
    //console.log('test');
    return this.http.post<Paymentold>(this.createPaymentUrl, payment, httpOptions);
  }

  createReceipt(receipt: Receipt,isadd: string): Observable<Receipt> {
    if (isadd == "add") {
      return this.http.post<Receipt>(this.createReceiptUrl, receipt, httpOptions);
    } else {
      return this.http.post<Receipt>(this.updateReceiptUrl, receipt, httpOptions);
    }
    
  }
  
  getReceiptList(individual: string): Observable<any> {
    const receiptJSON = '{ "individual" : ' + '"' + individual + '"'+ ' }';    
    return this.http.post<any>(
      this.getReceiptListUrl,
      JSON.parse(receiptJSON),
      httpOptions
    );
  }

  setReceiptChange(receiptNumber: string,individual: string): Observable<any> {
     const receiptJSON = '{ "receiptNumber" : ' + '"' + receiptNumber + '","individual" :' + '"' + individual + '"' + ' }'; 
     return this.http.post<any>(
       this.setReceiptChangeUrl,
       JSON.parse(receiptJSON),
       httpOptions
    );
  }

  getBillTotal(inboundIds: string,individual:string): Observable<string> {
    const inboundIdJSON = '{ "inboundIds" : ' + '"' + inboundIds + '",' + '"individual" : ' + '"' + individual + '"' + ' }';
    return this.http.post<any>(
      this.getBillUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );    
  }

  getInboundConfirmBill(dealId: string,inboundId: string): Observable<string> {
    const dealIdJSON = '{ "dealId" : ' + '"' + dealId + '",' + '"inboundId" : ' + '"' + inboundId + '"' + ' }';
    return this.http.post<any>(
      this.getConfirmBillUrl,
      JSON.parse(dealIdJSON),
      httpOptions
    );    
  }
 
  CreatePayments(payment: Payment): Observable<Payment> {
    return this.http.post<Payment>(this.CreatePaymentsUrl, payment, httpOptions);
  }

  CreateInboundPayments(inboundpayment: inboundPayment): Observable<inboundPayment> {
    return this.http.post<inboundPayment>(this.CreateInboundPaymentUrl, inboundpayment, httpOptions);
  }

  UpdatePayments(payment: Payment,isemail: string): Observable<Payment> {
    if (isemail == 'send') {
      return this.http.post<Payment>(this.UpdatePaymentsEmailUrl, payment, httpOptions);
    } else {
      return this.http.post<Payment>(this.UpdatePaymentsUrl, payment, httpOptions);
    }    
  }

  getPaymentList(receiptNumber: string,individual: string): Observable<any> {
     const receiptJSON = '{ "receiptNumber" : ' + '"' + receiptNumber + '","individual" :' + '"' + individual + '"' + ' }'; 
     return this.http.post<any>(
       this.getPaymentListUrl,
       JSON.parse(receiptJSON),
       httpOptions
    );
  }

  //返回数据总条数
  PageCount(individual: string, iscompany: boolean, filter: string): Observable<string> {
    //debugger;
    const creatorJSON = '{ "individual" : ' + '"' + individual + '","iscompany" : '+ iscompany + ',"filter" : "'+ filter + '" }';    
    return this.http.post<string>(
      this.PageCountUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  //company 账号 pageSize 每页显示的条数 skip 当前页号 filter 搜索值
  SearchList(individual:string ,pageSize: number,skip: number,filter: string,iscompany:boolean): Observable<string> {
    const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ',"pageSize": "' + pageSize + '","skip" : "' + skip + '","filter" : "' + filter + '","iscompany" : ' + iscompany + ' }';
    return this.http.post<string>(
      this.SearchListUrl,
      JSON.parse(creatorJSON),
      httpOptions
    );
  }

  Delete(paymentId: string): Observable<any> {
    const receiptJSON = '{ "paymentId" : ' + '"' + paymentId  +  '" }'; 
    return this.http.post<any>(
      this.PaymentDeleteUrl,
      JSON.parse(receiptJSON),
      httpOptions
    );
  }

  getUpcBillQty(inboundId: string): Observable<string> {
    const inboundIdJSON = '{ "inboundId" : ' + '"' + inboundId + '"' + ' }';
    return this.http.post<any>(
      this.getUpcBillQtyUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );    
  }

  sendEmail(paymentId: string): Observable<string> {
    const inboundIdJSON = '{ "paymentId" : ' + '"' + paymentId + '"' + ' }';
    return this.http.post<any>(
      this.sendEmailUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );    
  }

  setStatus(paymentId: string,status): Observable<string> {
    const inboundIdJSON = '{ "paymentId" : ' + '"' + paymentId + '",' + '"status" : ' + '"' + status + '"' + ' }';
    return this.http.post<any>(
      this.setStatusUrl,
      JSON.parse(inboundIdJSON),
      httpOptions
    );    
  }
  
 
}
