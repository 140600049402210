<div class="container">
  <h1>Deals</h1>
  <hr/>
  <ul class="nav nav-tabs" id="dealTabs" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="active-tab"
        data-toggle="tab"
        routerLink="active"
        role="tab"
        aria-controls="active"
        aria-selected="true"
        >Active Deals</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="expired-tab"
        data-toggle="tab"
        routerLink="expired"
        role="tab"
        aria-controls="expired"
        aria-selected="false"
        >Expired Deals</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="public-tab"
        data-toggle="tab"
        routerLink="public"
        role="tab"
        aria-controls="public"
        aria-selected="false"
        >Public Deals</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="proposed-tab"
        data-toggle="tab"
        routerLink="propose"
        role="tab"
        aria-controls="proposed"
        aria-selected="false"
        >Proposed Deals</a
      >
    </li>
  </ul>
  <br />
  <form class="form-inline float-left my-2">
    <!--方法1 keyup.enter Enter 键 keyup 松开的触发 keydown 按下触发-->    
    <input
      class="form-control mr-sm-2"
      type="search"
      placeholder="Search"
      aria-label="Search"
      id="searchInput"
      (keyup)="getSearchValue()"
    />
    <!--方法2-->
    <!--
    <div class="input-group">
      <input
        class="form-control mr-sm-2"
        type="text"
        placeholder="Search"
        aria-label="Search"
        id="searchInput"
        style="padding-right: 13px;"
      />
      <button 
        type="button" 
        class="btn btn-primary" 
        (click)="search()"
        > 
        <i class="fa fa-search"></i>
      </button>
    </div>-->   
    
  </form> 

  <div class="float-right my-2">
    <button
      type="button"
      class="btn btn-outline-success btn-block"
      data-toggle="modal"
      data-target="#createForm"
      data-whatever="@mdo"
      id="createDeal"
      *ngIf="privilege"
    >
      Create
    </button>
  </div>
  <br />
  <!--分页设置 tom.lee-->
  
  <div class="tab-content mt-5" id="dealLists"  *ngIf="ClassName=='ActiveDealsComponent'">
     <app-active-deals [answers]='ansArray'></app-active-deals>
  </div>
  <div class="tab-content mt-5" id="dealLists" *ngIf="ClassName=='ExpiredDealsComponent'">
    <app-expired-deals [answers]='ansArray'></app-expired-deals>
  </div>
  <div class="tab-content mt-5" id="dealLists" *ngIf="ClassName=='ProposeDealsComponent'">
    <app-propose-deals [answers]='ansArray'></app-propose-deals>
  </div>
  <div class="tab-content mt-5" id="dealLists" *ngIf="ClassName=='PublicDealsComponent'">
     <app-public-deals [answers]='ansArray'></app-public-deals>
  </div>

  <div class="tab-content mt-5" id="dealLists" [hidden]="true">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>    
  </div>

  <!--
  <div class="wrapper">
    分页数据传输 页面切换隐藏/显示 tom.lee
    <app-active-deals  [hidden]="ClassName!='ActiveDealsComponent'"></app-active-deals>
    <app-expired-deals [hidden]="ClassName!='ExpiredDealsComponent'"></app-expired-deals>
    <app-propose-deals [hidden]="ClassName!='ProposeDealsComponent'"></app-propose-deals>
    <app-public-deals  [hidden]="ClassName!='PublicDealsComponent'"></app-public-deals>
  </div>-->

  <div
    class="modal fade"
    id="createForm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createFrom"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createFrom">Deal Create Form</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group select-hide">
              <label for="deal-product" class="col-form-label">Product:</label>
              <br />
              <p class="text-danger">
                Products only shows first Words, use id to search for
                accurate product(like: #17):
              </p>

              <select
                class="selectpicker form-control selectwidthauto"
                data-live-search="true"
                name="product"
                [(ngModel)]="selectProduct"
                (ngModelChange)="setProductNameId()"
                style="width: auto;"
              >
                <option
                  *ngFor="let nameid of productList"
                  name="productsId"
                  [ngValue]="nameid"
                  class="selectOption"
                  data-width="auto"
                  data-mobile="true"
                >
                  #{{ nameid.id }} {{ nameid.name | slice: 0:84 }}
                </option>
              </select>
              <small>* Required</small>
            </div>
            <div class="form-group show">
              <label for="deal-product" class="col-form-label">Product:</label>
              <br />
              <p class="text-danger">
                Products only shows product id on mobile view, use id to search
                for accurate product(like: #17):
              </p>

              <select
                class="selectpicker form-control selectwidthauto"
                data-live-search="true"
                name="product"
                [(ngModel)]="selectProduct"
                (ngModelChange)="setProductNameId()"
                style="width: auto;"
              >
                <option
                  *ngFor="let nameid of productList"
                  name="productsId"
                  [ngValue]="nameid"
                  class="selectOption"
                  data-width="auto"
                  data-mobile="true"
                >
                  #{{ nameid.id }}
                </option>
              </select>
              <small>* Required</small>
            </div>
            <div class="form-group">
              <label for="deal-price" class="col-form-label">Price:</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="deal-price"
                  name="price"
                  [(ngModel)]="deal.price"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="deal-quantity" class="col-form-label"
                >Quantity:</label
              >
              <input
                type="number"
                class="form-control"
                id="deal-quantity"
                name="quantity"
                [(ngModel)]="deal.quantity"
              />
            </div>
            <div class="form-group">
              <label for="deal-expires" class="col-form-label"
                >Expires At:</label
              >
              <div class="row">
                <div class="col-md-3">
                  <select
                    class="custom-select d-block w-100"
                    id="month"
                    name="month"
                    [(ngModel)]="month"
                    required
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                  <p class="text-center">Month</p>
                </div>
                <div class="col-md-2">
                  <select
                    class="custom-select d-block w-100"
                    id="days"
                    name="days"
                    [(ngModel)]="days"
                    required
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                    <option>31</option>
                  </select>
                  <p class="text-center">Days</p>
                </div>
                <div class="col-md-2">
                  <select
                    class="custom-select d-block w-100"
                    id="years"
                    name="year"
                    [(ngModel)]="year"
                    required
                  >
                    <option value="">2014</option>
                    <option>2014</option>
                    <option>2015</option>
                    <option>2016</option>
                    <option>2017</option>
                    <option>2018</option>
                    <option>2019</option>
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                    <option>2023</option>
                    <option>2024</option>
                  </select>
                  <p class="text-center">Year</p>
                </div>
                <div class="col-md-2">
                  <select
                    class="custom-select d-block w-100"
                    id="hours"
                    name="hour"
                    [(ngModel)]="hour"
                    required
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                  </select>
                  <p class="text-center">Hour</p>
                </div>
                <div class="col-md-2">
                  <select
                    class="custom-select d-block w-100"
                    id="minutes"
                    name="minutes"
                    [(ngModel)]="minutes"
                    required
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                    <option>31</option>
                    <option>32</option>
                    <option>33</option>
                    <option>34</option>
                    <option>35</option>
                    <option>36</option>
                    <option>37</option>
                    <option>38</option>
                    <option>39</option>
                    <option>40</option>
                    <option>41</option>
                    <option>42</option>
                    <option>43</option>
                    <option>44</option>
                    <option>45</option>
                    <option>46</option>
                    <option>47</option>
                    <option>48</option>
                    <option>49</option>
                    <option>50</option>
                    <option>51</option>
                    <option>52</option>
                    <option>53</option>
                    <option>54</option>
                    <option>55</option>
                    <option>56</option>
                    <option>57</option>
                    <option>58</option>
                    <option>59</option>
                  </select>
                  <p class="text-center">Minutes</p>
                </div>
              </div>
              By default, expires in 3 days.
            </div>
            <div class="form-group">
              <label for="product-Note" class="col-form-label">Note:</label>
              <textarea
                type="text"
                class="form-control"
                id="product-note"
                cols="30"
                rows="5"
                name="note"
                [(ngModel)]="deal.note"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="deal-bonus" class="col-form-label">Bonus:</label>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="deal-bonus"
                  name="bonus"
                  [(ngModel)]="deal.bonus"
                />
              </div>
              <small>Give bonus for inbound items to self storages.</small>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="deal-service-tag"
                name="serviceTag"
                [(ngModel)]="deal.serviceTag"
              />
              <label class="form-check-label" for="deal-service-tag">
                Service Tag
              </label>
            </div>
            Require service tags when members confirm inbound items.
            <hr />
            Make Deal Public Or Private
            <br />
            <button
              type="button"
              class="btn btn-primary mt-1"
              (click)="makePublic()"
            >
              Public
            </button>
            <button
              type="button"
              class="btn btn-primary mt-1 ml-3"
              (click)="makePrivate()"
            >
              Private
            </button>
            <br />
            <div class="form-group" *ngIf="isPublic">
              <label for="recipient-name" class="col-form-label">Member:</label>
              <select
                class="selectpicker"
                multiple
                data-live-search="true"
                name="selectedMember"
                [(ngModel)]="membersTemp"
              >
                <option *ngFor="let member of memberList" name="member">
                  {{ member.individual }}
                </option>
              </select>
            </div>
            <div class="form-group" *ngIf="isPrivate">
              <label for="recipient-name" class="col-form-label">Member:</label>
              <select
                class="selectpicker"
                multiple
                data-live-search="true"
                name="selectedMember"
                [(ngModel)]="membersTemp"
              >
                <option
                  *ngFor="let group of groupList"
                  name="group"
                  value="{{ group.member }}"
                >
                  {{ group.name }}
                </option>
              </select>
            </div>
            <hr />
            Every member can see public offers. Only selected users can see
            private offers.
            <br />
            <br />
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="notify"
                name="notify"
                [(ngModel)]="deal.notify"
              />
              <label class="form-check-label" for="notify">
                Notify everyone who can see the offer
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="create()"
            data-dismiss="modal"
            *ngIf="deal.products"
          >
            Add
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="create()"
            data-dismiss="modal"
            *ngIf="!deal.products"
            disabled
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
