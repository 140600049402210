<div class="container">
  <h1>Inbounds</h1>
  <hr/>
  <ul class="nav nav-tabs" id="inboundTabs" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active bg-light"
        id="active-tab"
        data-toggle="tab"
        routerLink="inboundToConfirm"
        role="tab"
        aria-controls="active"
        aria-selected="true"
        >To Confirm</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="expired-tab"
        data-toggle="tab"
        routerLink="inboundReported"
        role="tab"
        aria-controls="expired"
        aria-selected="false"
        >Reported</a
      >
    </li>
    <!--
    隐藏payment tomlee 2022.02.28
    <li class="nav-item">
      <a
        class="nav-link bg-light"
        id="payment-tab"
        data-toggle="tab"
        routerLink="inboundPayment"
        role="tab"
        aria-controls="expired"
        aria-selected="false"
        >Payment</a
      >
    </li>
    -->
    <!-- <li class="nav-item">
      <a
        class="nav-link"
        id="favorite-tab"
        data-toggle="tab"
        routerLink="inboundHistory"
        role="tab"
        aria-controls="favorite"
        aria-selected="false"
        >Inbound History</a
      >
    </li> -->
  </ul>
  <br>
  <div>
    <form class="float-left my-2">
      <input
        class="form-control mr-sm-2"
        type="search"
        placeholder="Search"
        aria-label="Search"
        id="inboundSearchInput"
        (keyup)="getSearchValue()"
      />
    </form>
  </div>

  <!--分页设置-->
  <div class="tab-content" id="inboundLists" *ngIf="ClassName=='ReportedListComponent'">
     <app-reported-list [answers]='ansArray'></app-reported-list>
  </div>
  <div class="tab-content" id="inboundLists" *ngIf="ClassName=='ToConfirmListComponent'">
    <app-to-confirm-list [answers]='ansArray'></app-to-confirm-list>
  </div>  
  <!--
  隐藏 payment tomlee by 2022.02.28
  <div class="tab-content" id="paymentLists" *ngIf="ClassName=='PaymentListComponent'">
    <app-payment-list [answers]='ansArray'></app-payment-list>
  </div>
  -->
    
  <!--<app-reported-list [recordList]="recordList"></app-reported-list>-->
  <div class="tab-content hide" id="inboundLists"  [hidden]="true">    
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet> 
  </div>  
  
  <script>
    angular.module('link', []).directive(
      'Link', ['$location', function(location) {
       return {
               link: function(scope, element, attrs) {
                   var active = attrs.activeLink;
                   var path = attrs.href;
                path = path.substring(1); 
                scope.location = location;
                alert(location);
                scope.$watch(
                  'location.path()', function(newPath) {
                    if (path === newPath) {
                           element.addClass(active);
                    } 
                    else {
                           element.removeClass(active);
                       }
                   });
               }
           };
      }]);
  </script>
