import { Component, OnInit } from '@angular/core';
import { Register } from '../../../models/register';
import { RegisterService } from '../../../services/register.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  providers: [RegisterService, AuthenticationService],
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  // register = new Register();
  register = new Register();
  confirmedPassword: string;
  error = null;

  isRegistered = false;

  constructor(
    private registerService: RegisterService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  formValid() {
    if (
      this.register.username &&
      this.register.phone &&
      this.register.email &&
      this.register.password &&
      this.pwdValid() &&
      this.validateEmail()
    ) {
      return true;
    }
    return false;
  }

  user_register() {
    this.registerService
      .userRegister(this.register)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.isRegistered = true;
          this.register.clear();
          this.confirmedPassword = '';
          this.error = null;
        },
        error => {
          this.error = error;
        },
        () => {}
      );
  }

  ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home']);
    }
  }

  pwdValid() {
    if (this.confirmedPassword === this.register.password) {
      return true;
    }
    return false;
  }

  /**
   * To judge that whether the email information is in correct type
   * @param email email information from input
   * @author Guozhi Tang
   * @date 2020-04-22
   */
  validateEmail() {
    let email = this.register.email;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
