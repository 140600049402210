import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import { GroupService } from '../../services/group.service';
import { DealService } from '../../services/deal.service';
import { InboundService } from '../../services/inbound.service';
import { ProductService } from '../../services/product.service';
import { PaymentService } from '../../services/payment.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [AuthenticationService, DealService, GroupService, InboundService, ProductService,PaymentService]
})
export class DashboardComponent implements OnInit {
  public privilege: any;
  public currentUser: any;
  public followingList: any;
  public noFollowing: any;
  public activeLen = 0;
  public expiredLen = 0;
  public publicLen = 0;
  public proposedLen = 0;
  public toConfirmLen: any;
  public reportedLen: any;
  public productLen: any;
  public followerLen: any;
  public groupLen: any;
  public paymentLen = 0;
  public IsCompany = 0; 
  public companyGroup = '';


  constructor(
    private authenticationService: AuthenticationService,
    private groupService: GroupService,
    private dealService: DealService,
    private inboundService: InboundService,
    private productService: ProductService,
    private paymentService: PaymentService
    ) {}

  ngOnInit() {
    // privilege check
    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue[0];
      this.IsCompany =  this.authenticationService.currentUserValue[2] === 'company' ? 1 : 0  ;
      if (this.authenticationService.currentUserValue[2] === 'company') {
        this.privilege = true;
        //this.getActiveList(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Deal','deals-active','activeLen','');
        //this.getPublicList(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Deal','deals-pulbic','publicLen','');
        //this.expiredRetrieve(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Deal','deals-expired','expiredLen','');
        //this.proposeRetrieveCompany(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'InboundModel','deals-propose','proposedLen','');
        //this.getToConfirmRecordsCompany(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'InboundModel','inbounds-toconfirm','toConfirmLen','');
        //this.getReportedRecordsCompany(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'InboundModel','inbounds-reported','reportedLen','');
        //this.getfollowersList(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Subscription','subscription','followerLen','');
        //this.groupRetrieve(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'userGroup','usergroups','groupLen','');       
        //this.getActiveProductsList(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Product','product','productLen','');
        //this.getPayingList(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Payment','payment','paymentLen','');

      } else {
        this.privilege = false;
        this.getActiveFollowing(this.currentUser);
        this.getExpiredFollowing(this.currentUser);
        this.proposeIndividual(this.currentUser);
        //this.getToConfirmRecords(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'InboundModel','inbounds-toconfirm','toConfirmLen','');
        //this.getReportedRecords(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'InboundModel','inbounds-reported','reportedLen','');
        //this.getPaidList(this.currentUser);
        this.Count_All(this.currentUser,this.IsCompany,'Payment','payment','paymentLen','');
      }
    }
  }

  /**
   * Get all the active deal list when the user is signed as a company.
   * @author Guozhi Tang
   * @date 2020-03-31
   * @param creator - the username of current user
   */
  getActiveList(creator) {
    this.dealService
      .activeDeals(creator)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.activeLen = 0;
        } else {
          this.activeLen = data.length;
        }
      });
  }

  getPublicList(creator) {
    this.dealService
      .publicDeals(creator)
      .pipe(first())
      .subscribe(data => {
        console.log(data);
        if (data.length == 0) {
          this.publicLen = 0;
        } else {
          this.publicLen = data.length;
        }
      });
  }

  /**
   * Get all the expired deal list when the user is signed as a company.
   * @author Guozhi Tang
   * @date 2020-03-31
   * @param creator - the username of current user
   */
  expiredRetrieve(creator) {
    this.dealService
      .expiredDealRetrieve(creator)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.expiredLen = 0;
        } else {
          this.expiredLen = data.length;
        }
      });
  }

  /**
   * Get the active deal list for the user who is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-03-31
   * @param creator - the username of current user
   */
  getActiveFollowing(creator) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.followingList = data;
          // console.log(this.followingList);
        },
        error => {
          console.log(error);
        },
        () => {
          // this.dealList = [];
          this.companyGroup = '';
          for (let index = 0; index < this.followingList.length; index++) {
            if (this.followingList[index]) {
              this.companyGroup = this.companyGroup +  this.followingList[index].company + ",";
              /*
              this.getActiveListIndividual(
                this.followingList[index].company,
                this.currentUser
              );
              this.getPublicListIndividual(
                this.followingList[index].company,
                this.currentUser
              );*/
            } else {
              this.noFollowing = true;
            }
          }
          this.companyGroup = this.companyGroup.substring(0,this.companyGroup.lastIndexOf(","));
         
          this.Count_All(this.currentUser,this.IsCompany,'Deal','deals-active','activeLen',this.companyGroup);
          this.Count_All(this.currentUser,this.IsCompany,'Deal','deals-pulbic','publicLen',this.companyGroup);

        }
      );
  }

  /**
   * Get the active deal list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-03-27
   * @param creator - brandnewegg
   * @param individual - the username of current user
   */
  getActiveListIndividual(creator, individual) {
    this.dealService
      .individualDeals(creator, individual)
      .pipe(first())
      .subscribe(data => {
        // console.log(data);
        if (data.length != 0) {
          // console.log(data.length);
          this.activeLen = data.length;
        }
      });
  }

  getPublicListIndividual(creator, individual) {
    this.dealService
      .individualPublicDeals(creator, individual)
      .pipe(first())
      .subscribe(data => {
        // console.log(data);
        if (data.length != 0) {
          // console.log(data.length);
          this.publicLen = data.length;
        }
      });
  }

  /**
   * Get the expired deal list for the user who is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-03-31
   * @param creator - the username of current user
   */
  getExpiredFollowing(creator) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          this.followingList = data;
          // console.log(this.followingList);
        },
        error => {
          console.log(error);
        },
        () => {
          this.companyGroup = '';
          for (let index = 0; index < this.followingList.length; index++) {
            if (this.followingList[index]) {
              this.companyGroup = this.companyGroup +  this.followingList[index].company + ",";
              /*
              this.getExpiredListIndividual(
                this.followingList[index].company,
                this.currentUser
              );*/
            } else {
              this.noFollowing = true;
            }
          }
          this.companyGroup = this.companyGroup.substring(0,this.companyGroup.lastIndexOf(","));
          this.Count_All(this.currentUser,this.IsCompany,'Deal','deals-expired','expiredLen',this.companyGroup);

        }
      );
  }

  /**
   * Get the expired deal list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-03-27
   * @param creator - brandnewegg
   * @param individual - the username of current user
   */
  getExpiredListIndividual(creator, individual) {
    this.dealService.individualExpiredDeals(creator, individual)
      .pipe(first()).subscribe(data => {
        if (data.length != 0) {
          // console.log(data.length);
          this.expiredLen += data.length;
        }
      });
  }

  /**
   * Get the proposed deal list for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  proposeRetrieveCompany(individual: string) {
    this.inboundService
      .proposeRetrieveCompany(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length != 0) {
          // console.log(data.length);
          this.proposedLen += data.length;
        }
      });
  }

  /**
   * Get the proposed inbound list of which the currentUser is in member list.
   * @author Guozhi Tang
   * @date 2020-04-29
   * @param creator - the username of current user
   */
  proposeIndividual(creator:string) {
    this.groupService
      .findfollowing(creator)
      .pipe(first())
      .subscribe(
        data => {
          this.followingList = data;
        },
        error => {
          console.log(error);
        },
        () => {
          this.companyGroup = '';
          for (let index = 0; index < this.followingList.length; index++) {
            if (this.followingList[index]) {
              // console.log(this.followingList[index].company);
              this.companyGroup = this.companyGroup +  this.followingList[index].company + ",";
              //this.proposeRetrieve(this.followingList[index].company, this.currentUser);
            } else {
              this.noFollowing = true;
            }
          }
          this.companyGroup = this.companyGroup.substring(0,this.companyGroup.lastIndexOf(","));
          this.Count_All(this.currentUser,this.IsCompany,'InboundModel','deals-propose','proposedLen',this.companyGroup);
        }
      );
  }

  /**
   * Get the proposed deal list for the user who is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-04-29
   * @param company - the company name of the specific inbound
   * @param individual - the username of current user
   */
  proposeRetrieve(company: string, individual: string) {
    this.inboundService
      .proposeRetrieve(individual, company)
      .pipe(first())
      .subscribe(data => {
        if (data.length != 0) {
          // console.log(data.length);
          this.proposedLen += data.length;
        }
      });
  }

  /**
   * Get the to confirm inbound records for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getToConfirmRecordsCompany(individual) {
    this.inboundService
      .toConfirmRecordsCompany(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.toConfirmLen = 0;
        } else {
          this.toConfirmLen = data.length;
        }
      });
  }

  /**
   * Get the reported inbound records for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getReportedRecordsCompany(individual) {
    this.inboundService
      .reportedRecordsCompany(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.reportedLen = 0;
        } else {
          this.reportedLen = data.length;
        }
      });
  }

  /**
   * Get the to confirm inbound records for the user who is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getToConfirmRecords(individual) {
    this.inboundService
      .toConfirmRecords(individual)
      .pipe(first())
      .subscribe(
        data => {
          if (data.length == 0) {
            this.toConfirmLen = 0;
          } else {
            this.toConfirmLen = data.length;
          }
        },
        error => {
          console.log(error);
        }
      )
  }

  /**
   * Get the reported inbound records for the user who is signed as an individual.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getReportedRecords(individual) {
    this.inboundService
      .reportedRecords(individual)
      .pipe(first())
      .subscribe(
        data => {
          if (data.length == 0) {
            this.reportedLen = 0;
          } else {
            this.reportedLen = data.length;
          }
        },
        error => {
          console.log(error);
        }
      )
  }

  /**
   * Get the active followers for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getfollowersList(individual) {
    this.groupService
      .allSubscribers(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.followerLen = 0;
        } else {
          this.followerLen = data.length;
        }
      });
  }

  /**
   * Get the user groups for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  groupRetrieve(individual) {
    this.groupService
      .groupRetrieve(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.groupLen = 0;
        } else {
          this.groupLen = data.length;
        }
      });
  }

  /**
   * Get the active products list for the user who is signed as a company.
   * @author Guozhi Tang
   * @date 2020-04-01
   * @param individual - the username of current user
   */
  getActiveProductsList(individual) {
    this.productService
      .allProduct(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.productLen = 0
        } else {
          this.productLen = data.length;
        }
      });
  }
  //护士账号 - 获取已付款的列表
  getPaidList(individual) {
    this.paymentService
      .getPaidList(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.paymentLen = 0
        } else {
          this.paymentLen = data.length;
        }
      });
  }
  //公司账号 - 获取待付款的列表
  getPayingList(individual) {
    this.paymentService
      .getPayingList(individual)
      .pipe(first())
      .subscribe(data => {
        if (data.length == 0) {
          this.paymentLen = 0
        } else {
          this.paymentLen = data.length;
        }
      });
  }

  Count_All(userName,isComnapy,TableName,tabName,TableCount,companyGroup) {
     const ts = this;
    
     this.dealService.getTableCount(userName,isComnapy,TableName,tabName,companyGroup)
      .pipe(first())
      .subscribe(result => {
        if (result.count != 0) {          
          ts[TableCount] = result.count;
        }
      });

  }

}
